import React, { useState } from "react";
import "../../../colors.css";
import { DATATYPE, NAVIGATION_DATA, STAGE_FILTERS } from "../../../type/commons-artplace";
import CustomDataGrid from "../../../componets/dataGrid/CustomDataGrid";
import { formatStage } from "../../artplace-insight/commons";

const StagesList: React.FC = () => {
	const type = NAVIGATION_DATA.STAGE;
	const [searchField, setSearchField] = useState<string>(STAGE_FILTERS.name);

	return (
		<>
			<CustomDataGrid type={type} numberRows={DATATYPE.STAGE} searchField={searchField} formatRows={formatStage} isButtonVisible={false} />
		</>
	);
};

export default StagesList;

import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css";
import { t } from "i18next";

interface ITextEditorProps {
	defaultValue: string | undefined;
	setFieldValue: (val: string) => void;
}

const customToolbarOptions = {
	options: ["blockType", "inline", "list", "link"], // Opzioni da mostrare nella toolbar
	inline: {
		options: ["bold", "italic", "underline"], // Opzioni di formattazione inline
	},
	list: {
		options: ["unordered", "ordered"], // Opzioni per elenchi puntati e numerati
	},
	textAlign: {
		options: ["left", "center", "right"], // Opzioni per l'allineamento del testo
	},
};

export const TextEditor = ({ defaultValue, setFieldValue }: ITextEditorProps) => {
	const prepareDraft = (defaultValue: string | undefined) => {
		const draft = htmlToDraft(defaultValue || "");
		const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);
		return editorState;
	};

	const [editorState, setEditorState] = useState(() => prepareDraft(defaultValue));

	const onEditorStateChange = (editorState: EditorState) => {
		setEditorState(editorState);
	};

	const handleBlur = () => {
		const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		setFieldValue(forFormik);
	};

	useEffect(() => {
		setEditorState(prepareDraft(defaultValue));
	}, [defaultValue]);

	return (
		<div onBlur={handleBlur}>
			<Editor
				placeholder={t("COMMONS.ADD_DESCRIPTION")}
				editorState={editorState}
				wrapperClassName="custom-wrapper"
				editorClassName="custom-editor"
				onEditorStateChange={onEditorStateChange}
			/>
		</div>
	);
};

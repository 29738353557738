import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders, HeadersDefaults, RawAxiosRequestHeaders } from "axios";
import { toast_error } from "./custom-toast";
// NON UTILIZZATO
import { API_UPLOAD_FILE, API_UPLOAD_PHOTO } from "../api/api";
import { axiosInstance } from "../api/axios-interceptor";
import { ApiMethod, NAVIGATION_DATA } from "../type/commons-artplace";
import { t } from "i18next";
import { toast } from "react-toastify";
import { attachedFile } from "../type/rest/insight-rest-interfaces";
import { boolean } from "yup";

const URI_CLOUD = process.env.REACT_APP_CLOUD_PHOTO_URL;

export const API_METHOD = {
	POST: "POST",
	PUT: "PUT",
	DELETE: "DELETE",
	GET: "GET",
	GETALL: "GET_ALL",
	PATCH: "PATCH",
};

/**
 * Effettua una chiamata all'API utilizzando il metodo specificato.
 * @param method Il metodo HTTP da utilizzare per la chiamata (GET, POST, ecc.).
 * @param url L'URL dell'API a cui fare la chiamata.
 * @param data I dati da includere nella richiesta (opzionale).
 * @param params I parametri da includere nell'URL della richiesta (opzionale - solo GET).
 * @param multipart Se true, il "Content-type" dell'header va modificato in multi-part.
 * @returns Una promessa che si risolve con la risposta dall'API.
 */
const callApi = async (method: string, url: string, data?: any, params?: any, multipart?: boolean) => {
	let headers: RawAxiosRequestHeaders = {
		//"x-access-token": aToken,
		Accept: "application/json",
	};
	// Set Content-Type header based on the multi-part parameter
	if (multipart) {
		headers["Content-Type"] = "multipart/form-data";
	}

	try {
		const response: any = await axiosInstance({
			method: method,
			url: url,
			data: data,
			params: params,
			headers: headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 * Recupera sia un'entità singola tramite ID che l'intera lista di entità di un determinato tipo.
 * @param url L'URL dell'API a cui fare la chiamata.
 * @param id (Opzionale) L'ID dell'entità da recuperare. Se non fornito, recupera l'intera lista.
 * @param populate Se deve popolare le entità correlate o lasciarle come array di ID.
 * @param tenant Tenant dei dati da mostrare. Solo nel caso di SUPERADMIN.
 * @param searchTerm Stringa che l'utente sta ricercando.
 * @param searchField Campi in cui effettuare la ricerca.
 * @param page Pagina corrente.
 * @param pageSize Elementi per pagina.
 * @param orderBy Campo che determina l'ordinamento.
 * @param sort Ascendente/discendente
 * @returns Una promessa che si risolve con la risposta dall'API.
 */
export const get = async (
	url: string,
	id?: string,
	populate?: boolean,
	tenant?: string,
	searchTerm?: string,
	searchField?: string,
	page?: number,
	pageSize?: number,
	orderBy?: string,
	sort?: number,
) => {
	const params: any = {
		tenant: tenant,
		populate: populate,
		page: page,
		limit: pageSize,
		searchFullText: searchTerm,
		searchField: searchField,
		orderBy: orderBy,
		sort: sort,
	};

	// Se l'ID è fornito, costruisce l'URL di conseguenza
	let newUrl = id ? `${url}${id}` : url;

	return await callApi(ApiMethod.GET, newUrl, undefined, params);
};

export const post = async (url: string, data?: any, tenant?: string, multipart?: boolean) => {
	const params = {
		tenant: tenant,
	};
	return await callApi(ApiMethod.POST, url, data, params, multipart);
};

export const patch = async (url: string, id?: string, data?: any, multipart?: boolean, tenant?: string) => {
	const params = {
		tenant: tenant,
	};
	const newUrl: string = url + id;
	return await callApi(ApiMethod.PATCH, newUrl, data, params, multipart);
};

export const del = async (url: string, id?: string, tenant?: string, token?: string) => {
	const newUrl = url + (id ? id : "");
	let data = {};
	const params = {
		tenant: tenant,
	};
	/* if (token) {
		data = { refreshToken: token };
	} */
	return await callApi(ApiMethod.DELETE, newUrl, /* token ? data :  */ undefined, params);
};

export const uploadPhoto = async (
	photosList: File[],
	id: string,
	type: NAVIGATION_DATA,
	userRole: string,
	deletedPhoto?: string[],
	tenant?: string,
) => {
	const formDataList = photosList.map((photo) => {
		const formData = new FormData();
		formData.append("file", photo as unknown as Blob);
		return formData;
	});
	let response: any = {};
	for (const formData of formDataList) {
		try {
			response = await post(API_UPLOAD_PHOTO(type, id, userRole), formData, tenant, true);
		} catch (error) {
			throw error;
		}
	}
	//fa il return dell'ultima response, dovuta al caricamente dell'ultima foto in assoluto
	return response.data;
};

export const deletePhoto = async (photo: string, id: string, type: NAVIGATION_DATA, userRole: string) => {
	try {
		//fix temporaneo delete foto - fino a quando non si ci stanno i fix di lorenzo
		let normalizedKey = (URI_CLOUD ? photo.replace(URI_CLOUD, "") : photo)?.trim(); //michela passava https://urlbucket.../etc..
		if (normalizedKey.startsWith("http")) return; //eventuale fix per url di picsum o altro
		const response = await del(`${API_UPLOAD_PHOTO(type, id, userRole)}/${encodeURIComponent(normalizedKey)}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const uploadFile = async (
	files: attachedFile[],
	type: NAVIGATION_DATA,
	id: string,
	isAudio: boolean,
	userRole: string,
	deletedFile?: string[],
	tenant?: string,
) => {
	// Execute delete operations for deletedAudio
	//debugger

	// Filter and prepare the files to be uploaded
	const formDataList = files.filter((element: attachedFile) => element.file !== undefined);
	const formDataList2 = formDataList.map((file) => {
		const formData = new FormData();
		formData.append("file", file.file as unknown as Blob);
		formData.append("lang", file.lang);
		return formData;
	});

	// Upload files

	if (formDataList.length) {
		for (const formData of formDataList2) {
			try {
				await post(API_UPLOAD_FILE(type, id, isAudio, userRole), formData, tenant, true);
			} catch (error) {
				console.log("Errore uploadFile: ", error);
				throw error;
			}
		}
	} else {
		return;
	}
};

export const deleteFile = async (type: NAVIGATION_DATA, id: string, isAudio: boolean, userRole: string, lang: string, tenant?: string) => {
	try {
		await del(`${API_UPLOAD_FILE(type, id, isAudio, userRole)}/${lang}`);
	} catch (error) {
		console.log("Errore deleteFile: ", error);
	}
};

async function catchError(error: AxiosError) {
	let req = error.request;
	let res = error.response;
	let msg = error.message;

	if (res) {
		// Risposta con status code != da 2xx
		return res;
	} else if (error.request) {
		// Nessuna risposta ricevuta
		// console.log("ERROR.REQUEST: ", req);
	} else {
		// Something happened in setting up the pages that triggered an Error
		toast_error(msg);
		// console.log("ERROR: ", msg);
	}
}

export const fetchTypologics = async (url: string) => {
	try {
		const response = await get(url);
		return response.data;
	} catch (error) {
		// console.log("Error fetching typologics");
		return;
	}
};

import { Box, Divider } from "@mui/material";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { getLegalLinks } from "./legalLinks";
import { LanguageStore, LanguageStoreContext } from "../../stores/LanguageStore";

const Footer = () => {
	const version = process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}`: undefined ;

	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const currentLanguage: string = languageStore.getGlobalLanguage;
	//TODO : TRANSLATE

	// const links: { href: string; label: string }[] = [
	// 	{
	// 		href: COOKIE_POLICY,
	// 		label: "COOKIE_POLICY",
	// 	},
	// 	{
	// 		href: PRIVACY_POLICY,
	// 		label: "PRIVACY_POLICY",
	// 	},
	// 	{
	// 		href: TERMS_AND_CONDITIONS,
	// 		label: "TERMS_AND_CONDITIONS",
	// 	},
	// ];

	const links = getLegalLinks(currentLanguage);

	return (
		<Box
			sx={{
				position: "relative", // Mantiene il footer sempre visibile
				bottom: 0,
				left: 0,
				width: "100%", // Assicura che il footer si estenda per tutta la larghezza dello schermo
				backgroundColor: "background.paper", // Scegli un colore di sfondo appropriato
				//zIndex: "tooltip", // Assicura che il footer rimanga sopra agli altri elementi della pagina
			}}
		>
			<Box>
				<Divider />
			</Box>

			<Box sx={{ width: "100%", padding: "5px", paddingRight: "20px" }} textAlign="end" color="grey.300">
				{links.map((link: any, index: any) => (
					<Box key={index} display="inline-block" marginX="5px">
						<a
							style={{
								fontSize: "12px",
								color: "black",
							}}
							href={link.href}
							target="_blank"
							rel="noreferrer"
							title={t(`FOOTER.${link.label}`)}
						>
							{t(`FOOTER.${link.label}`)}
						</a>
						{index < links.length-1 && " | "}
					</Box>
				))}
				<Box display="inline-block" marginLeft="15px">
					<div style={{position:"relative", color: '#a0a0a0'}}>{version?? '1.0.0'}</div>
				</Box>
			</Box>
		</Box>
	);
};

export default Footer;

import { Box, Card, CardContent, Grid, Rating, Typography } from "@mui/material";
import { t } from "i18next";

interface RatingsInterface {
	averageRate?: number;
	numberOfRate?: number;
	favorites?: number;
}

const Ratings = ({ averageRate, numberOfRate, favorites }: RatingsInterface) => {
	const hasNoReviews = !averageRate && !numberOfRate && !favorites;
	return (
		<Card className="cardContainer">
			<CardContent sx={{ mt: 0 }}>
				<Typography variant="h6" component="div" mb={2}>
					{t("RATINGS.TITLE")}
				</Typography>
				{hasNoReviews ? (
					<Typography variant="body1" component="div">
						{t("RATINGS.NO_RATINGS")} {/* Make sure to add this to your i18n translations */}
					</Typography>
				) : (
					<Box>
						<Grid item xs={12} sm={12}>
							<Typography variant="body1" component="div" mt={2}>
								{t("RATINGS.AVG_RATING")}
							</Typography>
							<Grid container flexDirection={"row"} alignItems={"center"}>
								<Typography variant="body2">{averageRate?.toFixed(1) || 0} </Typography>
								<Rating defaultValue={averageRate || 0} precision={0.1} readOnly />
							</Grid>
						</Grid>
						<Grid container item xs={12} sm={12} flexDirection={"column"} /*  alignItems={"center"} */>
							<Typography variant="body1" component="div" mt={2}>
								{t("RATINGS.NUMBER_RATINGS")}
							</Typography>
							<Typography variant="body2" component="div">
								{numberOfRate || 0}
							</Typography>
						</Grid>
						<Grid container item xs={12} sm={12} flexDirection={"column"} /* alignItems={"center"} */>
							<Typography variant="body1" component="div" mt={2}>
								{t("RATINGS.FAVORITES")}
							</Typography>
							<Typography variant="body2" component="div">
								{favorites || 0}
							</Typography>
						</Grid>
					</Box>
				)}
			</CardContent>
		</Card>
	);
};

export default Ratings;

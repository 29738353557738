import React, { useState } from "react";
import ReactPlayer, { FilePlayerProps } from "react-player/file";
import { JSX } from "react/jsx-runtime";
//import "./styles.css";
import styled from "styled-components"; //x
import { TokenKeys } from "../../../type/commons-artplace";

interface Props {
	url: string;
}
const PlayerVideo = ({ url }: Props) => {
	const aToken = localStorage.getItem(TokenKeys.ACCESS_TOKEN);

	var config = {
		xhrSetup: function (xhr: any, url: string) {
			xhr.setRequestHeader("Authorization", aToken);
			xhr.withCredentials = true; // do send cookies
		},
	};

	return (
		<ReactPlayer
			url={url}
			//url="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4"
			//url="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
			playing={true}
			controls={true}
			// volume={null}
			// muted={false}
			// width="64vw"
			// height='60vh'
			width="100%"
			height="100%"
			light
			style={{ borderRadius: 17 }}
			xhrSetup={config}
		/>
	);
};
export default PlayerVideo;

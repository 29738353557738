import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { NAVIGATION_DATA } from "../../type/commons-artplace";
import { t } from "i18next";

// Define props interface
interface CustomBreadcrumbsProps {
	dataType: NAVIGATION_DATA;
	currentPage: string;
}

const CustomBreadcrumbs = ({ dataType, currentPage }: CustomBreadcrumbsProps) => {
	const calculateRoot = (dataType: NAVIGATION_DATA) => {
		const label = `${dataType}.LIST.TITLE`;
		return t(label);
	};

	return (
		<Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 5 }}>
			<Link underline="hover" color="inherit" href={`/${dataType.toLowerCase()}/view`}>
				{calculateRoot(dataType)}
			</Link>
			<Typography color="text.primary" noWrap>
				{currentPage}
			</Typography>
		</Breadcrumbs>
	);
};

export default CustomBreadcrumbs;

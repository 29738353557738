import React, { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { t } from "i18next";
import { MenuItem, Select, useMediaQuery, useTheme } from "@mui/material";
import { SupportedDynamicLanguageEnum } from "../../type/commons-artplace";
import { LanguageStore, LanguageStoreContext } from "../../stores/LanguageStore";

/*
* Conviene non modificare più questo, i molteplici useeffect e inizializzazioni fatte in questo modo sono brutte ma coprono ogni caso provato di cancellazione del localstorage e cambi lingua vari
*/ 
const GlobalLanguageSelector = () => {
    let currentLang = localStorage.getItem("lang") ?? SupportedDynamicLanguageEnum.ITALIAN;
    const [selectedLanguage, setSelectedLanguage] = useState(currentLang); //sovrascritto subito
    //const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // non prende comunque la lingua del browser, ritorna it-IT la prima volta in assoluto, mentre le lingue sono `en`, `it`, `fr`, `de`, `es`, `ja`, `ru`, `zh`, `uk`. Vedere errori/warn in console.
    const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const theme = useTheme();

    const chooseLanguage = (e: any) => {
        e.preventDefault();      
        console.log('Change Language', e.target.value);

        i18n.changeLanguage(e.target.value); //va fatto prima del setSelectedLanguage
        setSelectedLanguage(e.target.value);
    };

    useEffect(() => {
        languageStore.setGlobalLanguage = selectedLanguage;
        localStorage.setItem("lang", selectedLanguage);
    }, [languageStore, selectedLanguage]);

    //BRUTTO, ma copre un edge case minore
    useEffect(() => {
        i18n.changeLanguage(currentLang); 
        languageStore.setGlobalLanguage = selectedLanguage;
        localStorage.setItem("lang", selectedLanguage);
    }, []);

    // Use media query to check if the screen size is mobile
    // const isMobile = useMediaQuery('(max-width:600px)');
	// const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // Conditionally render the Select component based on screen size
    // if (isMobile) {
    //     return null;
    // }

    return (
        <Select name="language" value={selectedLanguage} onChange={chooseLanguage} style={{ display : window.location.toString()?.includes('/login') ? "none": "visible"}}>
            {Object.entries(SupportedDynamicLanguageEnum).map(([key, value]) => {
                return (
                    <MenuItem key={key} value={value}>
                        {t(`SUPPORTED_LANGUAGES.${key}`)}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default GlobalLanguageSelector;

import {
	Card,
	Grid,
	CircularProgress,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	CardContent,
	Switch,
	FormHelperText,
	Divider,
	Tooltip,
	Button,
} from "@mui/material";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import { getEnumKeyByValue, handleModal, orderCustomersByBusinessName } from "../../../utils/util";
import { clientMockup } from "../../../data/poi.mockup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import GoogleMapsCard from "../../../componets/googleMapsCard/GoogleMapsCard";
import CheckBoxList from "../poi-detail/CheckBoxList";
import { OpeningHours } from "../poi-detail/OpeningHours";
import PoiInsightsList from "../poi-detail/PoiInsightsList";
import { PoiRelatedPoiList } from "../poi-detail/PoiRelatedPoiList";
import { AvailableConnection, availableConnection, availableSocials } from "../../../constants/artplace.constant";
import { ClientInterface, PoiDetailInterface, PoiInterface } from "../../../type/rest/poi-rest-interfaces";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
	DATATYPE,
	LangField,
	NAVIGATION_DATA,
	SupportedDynamicLanguageEnum,
	TypologicType,
	emptyPoi,
	langField,
} from "../../../type/commons-artplace";
import { Form, Formik, FormikErrors, getIn } from "formik";
import { intlPhoneRegex, urlRegex } from "../../../utils/regex";
import { TextEditor } from "../../../componets/textField/textEditor/TextEditor";
import ExpectedConnection from "../../../componets/pageComponents/expectedConnection/ExpectedConnection";
import PhotoDropZone from "../../artplace-insight/insight-add/photoDropZone";
import { MuiPhone } from "../../../componets/muiPhone/muiphone";
import SocialInputGrid from "../../../componets/socialGrid/SocialInputGrid";
import AddButton from "../../../componets/buttons/AddButton";
import { Autocomplete } from "@react-google-maps/api";
import AddEntityModal from "../../../componets/modals/addEntityModal/AddEntityModal";
import { InsightDetailInterface } from "../../../type/rest/insight-rest-interfaces";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { TypologicStoreContext } from "../../../stores/TypologicStore";
import { BE_LINKS } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { get, post, uploadPhoto } from "../../../utils/call-api";
import EntityLanguageSelector from "../../../componets/entityLanguageSelector/EntityLanguageSelector";
import StatusSelector from "../../../componets/statusSelector/StatusSelector";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { LanguageStoreContext } from "../../../stores/LanguageStore";
import { Spinner } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import IdName from "../../../componets/pageComponents/IdName/IdName";
import LangFieldText from "../../../componets/langFieldText/LangFieldText";
import PhoneInput from "../../../componets/muiPhone/PhoneInput";
import PhoneInputComponent from "../../../componets/muiPhone/PhoneInput";
import i18next from "i18next";
import { MuiPhoneOld } from "../../../componets/muiPhone/MuiPhoneOld";

const PoiAdd = () => {
	const { t } = useTranslation(); //OK
	const navigate = useNavigate(); //OK
	const languageStore = useContext(LanguageStoreContext);
	const [address, setAddress] = useState(""); //OK
	const userStore: UserStore = useContext(UserStoreContext); //OK
	const [poiDetail, setPoiDetail] = useState<Partial<PoiDetailInterface>>({}); //OK
	const [duration, setDuration] = useState<Date | null>(null); //OK
	const [enabledStringsArray, setEnabledStringsArray] = useState<string[]>([]); //NOPE
	const [onRequest, setOnRequest] = useState<boolean>(false); //OK
	//TODO: CcurrentLanaguage va gestita globalmente in uno store, in modo da inizializzare i nuovi oggetti con un oggetto "langField" dentro langFields con la lingua giusta. Il primo oggetto deve sempre essere della lingua corrente
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage); //OK
	//console.log("🚀 ~ PoiAdd ~ currentLanguage:", currentLanguage);
	const typologicStore = useContext(TypologicStoreContext); //OK
	const [loadingPoiDetail, setLoadingPoiDetail] = useState<boolean>(false); //OK
	const [allStringsArray, setAllStringsArray] = useState<TypologicType[] | undefined>([]); //OK
	//console.log("🚀 ~ PoiAdd ~ allStringsArray:", allStringsArray);
	//totale categorie presenti
	const [categories, setCategories] = useState<TypologicType[] | undefined>([]); //OK
	//totale sottocategorie presenti
	const [insightsList, setInsightsList] = useState<InsightDetailInterface[]>([]); //OK
	//console.log("🚀 ~ PoiAdd ~ insightsList:", insightsList);
	//lista POI correlati a quello corrente
	const [formikCallback, setFormikCallback] = useState<any>(); //OK
	const [formikLabel, setFormikLabel] = useState<string>(""); //OK
	const [poiList, setPoiList] = useState<PoiDetailInterface[]>();

	const [subCategories, setSubCategories] = useState<TypologicType[] | undefined>([]);
	const [clients, setClients] = useState<ClientInterface[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<InsightDetailInterface[] | PoiInterface[] | undefined>();
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const [searchResult, setSearchResult] = useState<any>();
	const [selectedTenant, setSelectedTenant] = useState<string>("");
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [photos, setPhotos] = useState<File[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);

	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		//console.log("userStore on init:", userStore);
		//console.log("getTenant method on init:", userStore.getTenant);

		function fetchTenant() {
			const tenant = userStore.getTenant;
			//console.log("Fetched tenant:", tenant);
			setSelectedTenant(tenant);
		}

		fetchTenant();
	}, []);

	useEffect(() => {
		// Questo codice viene eseguito ogni volta che `selectedTenant` cambia
		setInsightsList([]); // Svuota la lista degli insights
		setPoiList([]); // Svuota la lista dei POI correlati
	}, [selectedTenant]);

	const userRole: string = userStore.getUserRole();
	const { handleNameChange, handleSubtitleChange, handleDescriptionChange, handleLanguageChange, correctLanguageValue, getIndexFromLangFields } =
		EntityUtilities(); //OK
	//controller apertura/chiusura modale
	const formatTimeData = (data: Date) => {
		//OK
		const hours = data.getHours();
		const minutes = data.getMinutes();
		//console.log("Duration seconds value: ", hours * 3600 + minutes * 60);
		return hours * 3600 + minutes * 60;
	};
	const fetchServices = async () => {
		//OK
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getServices(DATATYPE.POI);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching services:", error);
			return [];
		}
	};

	const fetchCategories = async () => {
		//OK
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getCategories(DATATYPE.POI);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchSubCategories = async () => {
		//OK
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getSubcategories(DATATYPE.POI);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	function onLoad(autocomplete: any) {
		setSearchResult(autocomplete);
	}

	const onPlaceChanged = async (formikCallback: any) => {
		if (searchResult != null) {
			//variable to store the result
			const place = await searchResult.getPlace();
			setAddress(place.formatted_address); //per aggiornare agilmente il TextField
			formikCallback("address", place.formatted_address); //per aggiornare il formik
		}
	};

	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);

			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};

	const fetchOptions = async () => {
		//OK - PIù COMPLETO
		try {
			// Fetch dati per tipologiche POI
			const [services, categories, clients, subcategories] = await Promise.all([
				fetchServices(),
				fetchCategories(),
				fetchClients(),
				fetchSubCategories(),
			]);

			//Per sicurezza, prima azzera tutto e re-inizializzo tutto
			setSubCategories([]);
			setAllStringsArray([]);
			setCategories([]);
			setClients([]);

			// Inizializzo le tipologiche del POI
			setSubCategories(subcategories);
			setAllStringsArray(services.sort((a, b) => t(a.label).localeCompare(t(b.label))));
			setCategories(categories);
			setClients(clients);

			// Handle other fetched data as needed
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	};

	useEffect(() => {
		fetchOptions();

		setPoiDetail({
			...emptyPoi,
			langFields: [{ ...langField, lang: currentLanguage }],
		});
	}, [typologicStore.getTypologicsReady]);

	let delayDebounceFn: NodeJS.Timeout | null = null;

	const handleInputChangeDebounce = (address: string) => {
		if (delayDebounceFn) {
			clearTimeout(delayDebounceFn);
		}
		delayDebounceFn = setTimeout(() => {
			setAddress(address);
		}, 500);
	};
	const langFieldSchema = Yup.object().shape({
		name: Yup.string().required("ERRORS.FORM.REQUIRED"),
	});
	return (
		<>
			{loadingPoiDetail || !poiDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<>
					<Formik<Partial<PoiDetailInterface>>
						enableReinitialize
						initialValues={poiDetail}
						validationSchema={Yup.object({
							langFields: Yup.array().of(langFieldSchema).required("ERRORS.FORM.REQUIRED"),
							category: Yup.object().shape({
								key: Yup.string().required("ERRORS.FORM.REQUIRED"),
								label: Yup.string().required("ERRORS.FORM.REQUIRED"),
							}),
							address: Yup.string().required("ERRORS.FORM.REQUIRED"),
							contacts: Yup.object().shape({
								email: Yup.string().trim().notRequired().email("ERRORS.FORM.INVALID_EMAIL"),
								phone: Yup.string().trim().matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
								phone2: Yup.string().trim().matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
							}),
							socials: Yup.array().of(
								Yup.object().shape({
									url: Yup.string().matches(urlRegex, "ERRORS.FORM.INVALID_URL"),
								}),
							),
						})}
						onSubmit={async (values, { setValues, setFieldValue, setSubmitting }) => {
							setIsRequestSubmitted(true);

							const filteredLangFields = values.langFields?.filter((langField) => langField.name.trim() !== "");

							// Aggiorna `values` con il nuovo array filtrato
							const newValues = {
								...values,
								langFields: filteredLangFields,
							};

							try {
								const relatedPoisIds = poiList?.map((el) => el._id) || [];
								const insightsIds = insightsList?.map((el) => el._id) || [];

								const result = {
									...newValues,
									relatedPois: relatedPoisIds,
									statusActive: true,
									insights: insightsIds,
									socials: values.socials?.filter((element) => (element.type.trim() && element.url.trim()) !== ""),
									//photos: photos,
									contacts: {
										...values.contacts,
										email: values.contacts && values.contacts.email?.trim() !== "" ? values.contacts.email : undefined,
									},
								};
								const response = await post(BE_LINKS[userRole][NAVIGATION_DATA.POI].ALL, result, result.tenant);
								//ADD: Le foto vanno aggiunte dopo la creazione dell'entità
								if (photos.length) {
									try {
										await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.POI, userRole);
									} catch (error) {
										toast.error(t("Errore upload photo"));
									}
								}

								// console.log("BE RESPONSE FROM POI ADD:", response.data);
								toast.success(t("MESSAGES.CREATION_OK"));
								setTimeout(() => {
									setDeletedPhoto([]);
									setPhotos([]);
									if (resetFiles) resetFiles();
									navigate(`/poi/detail/${response.data._id}`);
								}, 1000);
							} catch (error: any) {
								// console.log("ERROR PoiAdd: ", error);
								toast.error(error.message);
							} finally {
								setTimeout(() => {
									setIsRequestSubmitted(false);
								}, 500);
								setSubmitting(false);
							}
							//TODO
							//	console.log("Submit relatedPOIs: ", poiList);
							//TODO
							//	console.log("Submit Insights: ", insightsList);
						}}
					>
						{(formik) => (
							<>
								<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
								<Grid container item xs={12} mb={5} justifyContent={"space-between"} alignItems={"center"}>
									<Grid flexDirection={"column"}>
										<Typography variant={"h1"}>{t("POI.ADD.TITLE")}</Typography>
										<CustomBreadcrumbs dataType={NAVIGATION_DATA.POI} currentPage={t("POI.ADD.TITLE").split(" ")[0]} />
									</Grid>
									{/* <SaveButton onClick={formik.handleSubmit} disabled={isRequestSubmitted} /> */}
									<Button
										variant="contained"
										color="secondary"
										disabled={isRequestSubmitted || isUploading}
										onClick={() => {
											if (
												!formik.values.address ||
												!formik.values.category ||
												formik.values.langFields?.some((value) => value.name === undefined || value.name === "")
											) {
												toast.warn(t("MESSAGES.WARNING_FORM"));
												if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
													const emptyLangs =
														formik.values.langFields?.filter((value) => value.name === undefined || value.name === "").map((value) => value.lang) ??
														[];
													const keyValues = emptyLangs?.map((element) => getEnumKeyByValue(element));
													const translatedKeys = keyValues
														.filter((key): key is string => key !== undefined)
														.map((key: string) => t(`SUPPORTED_LANGUAGES.${key}`))
														.join(",\n");
													toast.warn(t("MESSAGES.CHECK_LANGUAGES") + translatedKeys);
												}
												formik.setFieldTouched("address", true);
												formik.setFieldTouched("category", true);
												formik.setFieldTouched("langFields", true);
												formik.setFieldError("langFields", undefined);
											} else {
												formik.handleSubmit();
											}
										}}
										startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
										size="large"
									>
										{t("COMMONS.SAVE_CHANGES")}
									</Button>
								</Grid>
								<Form onSubmit={formik.handleSubmit} noValidate>
									<Grid container spacing={2}>
										<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} mb={5} alignContent={"flex-start"}>
											<Grid item xs={4} sm={4}>
												<EntityLanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} formik={formik} />
											</Grid>
											{/* TODO: NON RIMUOVERE, ANDRA' REINTRODOTTO */}
											{/* <Grid item xs={1} sm={1}>
												<StatusSelector value={formik?.values?.statusActive || true} callBack={formik.setFieldValue} />
											</Grid> */}

											{/* R2 */}
											<IdName id={undefined} formik={formik} lang={currentLanguage} />

											{/* <Grid item xs={12} sm={12}>
												<TextField
													fullWidth
													label={t("FORM.NAME")}
													required
													name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.name`}
													variant="outlined"
													onBlur={formik.handleBlur}
													error={
														((formik.touched.langFields as unknown as LangField[])?.[
															getIndexFromLangFields(formik.values.langFields, currentLanguage)
														] ??
															false) &&
														(!!formik.errors.langFields?.[getIndexFromLangFields(formik.values.langFields, currentLanguage)] ?? false)
													}
													helperText={
														formik.errors.langFields &&
														(formik.touched.langFields as unknown as LangField[])?.[
															getIndexFromLangFields(formik.values.langFields, currentLanguage)
														] &&
														!!formik.errors.langFields &&
														!!formik.errors.langFields[getIndexFromLangFields(formik.values.langFields, currentLanguage)]
															? t(getIn(formik.errors.langFields[getIndexFromLangFields(formik.values.langFields, currentLanguage)], "name"))
															: ""
													}
													value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.name}
													onChange={(event) => handleNameChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
												/>
											</Grid> */}

											<LangFieldText formik={formik} fieldName={"subtitle"} callBack={handleSubtitleChange} lang={currentLanguage} />

											{/* <Grid item xs={12} sm={12}>
												<TextField
													fullWidth
													label={t("FORM.SUBTITLE")}
													name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.subtitle`}
													variant="outlined"
													//helperText={formik.touched.title && t(formik.errors.title ?? "")}
													value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.subtitle}
													onChange={(event) => handleSubtitleChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
													// InputProps={{ sx: { borderRadius: 2 } }}
												/>
											</Grid> */}
											{/* R2 */}
											<Grid item xs={12} sm={6}>
												<FormControl fullWidth variant="outlined">
													<InputLabel required error={formik.touched.category && !!formik.errors.category}>
														{t("FORM.CATEGORY")}
													</InputLabel>
													<Select
														label={t("FORM.CATEGORY")}
														name="category"
														required
														value={formik.values.category?.key || ""}
														onBlur={formik.handleBlur}
														onChange={(event) => {
															const selectedCategory = categories && categories.find((el) => el.key === event.target.value);
															formik.setFieldValue("category", selectedCategory); // Set the entire category object
														}}
														error={formik.touched.category && !!formik.errors.category}
													>
														{categories &&
															categories.map((el: TypologicType) => (
																<MenuItem key={el.key} value={el.key}>
																	{t(el.label)}
																</MenuItem>
															))}
													</Select>
													{formik.touched.category && !!formik.errors.category && (
														<FormHelperText style={{ color: "#d32f2f" }}>{t(getIn(formik.errors.category, "key"))}</FormHelperText>
													)}
												</FormControl>
											</Grid>

											{/* <Grid item xs={12} sm={4}>
												<FormControl fullWidth variant="outlined">
													<InputLabel>{t("FORM.SUBCATEGORY")}</InputLabel>
													<Select
														disabled
														label={t("FORM.SUBCATEGORY")}
														name="subcategory"
														value={formik.values.subcategory?.key || ""}
														onChange={(event) => {
															const selectedCategory = subCategories && subCategories.find((el) => el.key === event.target.value);
															formik.setFieldValue("subcategory", selectedCategory); // Set the entire category object
														}}
														error={formik.touched.subcategory && !!formik.errors.subcategory}
													>
														{subCategories &&
															subCategories.map((el: TypologicType) => (
																<MenuItem key={el.key} value={el.key}>
																	{t(el.label)}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Grid> */}

											<Grid item xs={12} sm={6}>
												<FormControl fullWidth variant="outlined">
													<InputLabel></InputLabel>
													<LocalizationProvider dateAdapter={AdapterDateFns}>
														<TimePicker
															label={t("FORM.DURATION")}
															value={duration}
															onChange={(newValue: any) => {
																setDuration(newValue);
																formik.setFieldValue("durationSeconds", formatTimeData(newValue));
															}}
															views={["hours", "minutes"]}
															format="HH:mm"
															ampm={false}
															//	onChange={formik.handleChange}
														/>
													</LocalizationProvider>

													{/* <div>{`Initialized Hours: ${valueDuration?.getHours()}, Minutes: ${valueDuration?.getMinutes()}, Seconds: ${valueDuration?.getSeconds()}`}</div> */}
												</FormControl>
											</Grid>

											{/* R3 */}
											{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
												<Grid item xs={12} sm={12}>
													<FormControl fullWidth variant="outlined">
														<InputLabel>{t("FORM.CUSTOMER")}</InputLabel>
														<Select
															label={t("FORM.CUSTOMER")}
															name="tenant"
															value={formik.values.tenant || userStore.getTenant}
															onChange={(event) => {
																setSelectedTenant(event.target.value);
																formik.setFieldValue("tenant", event.target.value);
																setSelectedTenant(event.target.value);
																formik.setFieldValue("tenant", event.target.value);
															}}
															style={{ borderRadius: 8 }}
															error={formik.touched.tenant && !!formik.errors.tenant}
														>
															{/* <MenuItem value="fake">MeStesso</MenuItem> */}
															{clients.map((el: ClientInterface) => (
																<MenuItem key={el._id} value={el.tenant}>
																	{el.business_name}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
											)}

											{/* R4 */}
											<Grid item xs={12}>
												<Typography variant="h6" component="div" mb={1}>
													{t("POI.DETAIL.DESCRIPTION")}
												</Typography>
												<Card>
													<TextEditor
														setFieldValue={(value) => handleDescriptionChange(currentLanguage, value, formik.values.langFields, formik.setFieldValue)}
														defaultValue={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.description}
													/>
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h6" component="div" mb={1}>
													{t("POI.DETAIL.POSITION")}
												</Typography>
												{/* 	<GoogleWrapper> */}

												<Autocomplete onLoad={onLoad} onPlaceChanged={() => onPlaceChanged(formik.setFieldValue)}>
													<TextField
														fullWidth
														label={t("FORM.ADDRESS")}
														required
														variant="outlined"
														onBlur={() => formik.setFieldTouched("address", true)}
														value={address}
														error={formik.touched.address && !!formik.errors.address}
														onChange={(e) => {
															if (!formik.touched.address) {
																formik.setFieldTouched("address", true);
															}
															setAddress(e.target.value);
														}}
													/>
												</Autocomplete>
												{formik.touched.address && !!formik.errors.address && (
													<FormHelperText style={{ color: "#d32f2f" }}>{t(formik.errors.address)}</FormHelperText>
												)}
												{formik.values.address ? (
													<GoogleMapsCard
														lat={formik.values?.latlng?.lat}
														lng={formik.values?.latlng?.lng}
														address={formik.values.address}
														setCoordinates={formik.setFieldValue}
													/>
												) : (
													<Grid
														container
														mt={2}
														height={"280px"}
														width={"100%"}
														alignItems={"center"}
														justifyContent={"center"}
														borderRadius={2}
														style={{ backgroundColor: "grey" }}
													>
														<Typography color={"white"}>{t("COMMONS.NO_ADDRESS")}</Typography>
													</Grid>
												)}
												{/* </GoogleWrapper> */}
											</Grid>

											<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} mb={2}>
												<Grid item>
													<Typography variant="h6" component="div" mb={1} mt={2}>
														{t("POI.DETAIL.INSIGHTS")}
													</Typography>
												</Grid>
												<Grid item>
													<AddButton
														length={insightsList?.length || 0}
														onClick={() => {
															setModalTitle(() => t("INSIGHTS.ADD.TITLE"));
															setModalContent(() => insightsList);
															setModalCallBack(() => setInsightsList);
															setFormikCallback(() => formik.setFieldValue);
															setFormikLabel("insights");
															setModalDataType(() => DATATYPE.INSIGHTS);
															handleModal(open, setOpen);
														}}
														component={undefined}
													/>
												</Grid>
												<Grid item xs={12}>
													<PoiInsightsList insights={insightsList ?? []} dataType={DATATYPE.INSIGHTS} />
												</Grid>
											</Grid>
											<Divider />

											<Grid container item xs={12} justifyContent="space-between" alignItems={"center"} mb={2}>
												<Grid item>
													<Typography variant="h6" component="div" mb={1} mt={2}>
														{t("POI.DETAIL.RELATED")}
													</Typography>
												</Grid>
												<Grid item>
													<AddButton
														length={poiList?.length || 0}
														onClick={() => {
															setModalTitle(() => t("POI.ADD.TITLE"));
															setModalContent(() => poiList);
															setModalCallBack(() => setPoiList);
															setFormikCallback(() => formik.setFieldValue);
															setFormikLabel("relatedPois");
															setModalDataType(() => DATATYPE.POI);
															handleModal(open, setOpen);
														}}
														component={undefined}
													/>
												</Grid>
												<PoiRelatedPoiList relatedPois={poiList ?? []} />
											</Grid>
											<Divider />
											<Grid container item xs={12} spacing={2}>
												<Grid item xs={12} sm={12}>
													<Typography variant="h6" component="div" mt={2}>
														{t("POI.DETAIL.CONTACTS")}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={12} md={6}>
													<Grid container item>
														<MuiPhoneOld
															value={formik.values.contacts?.phone}
															onChange={(phone: any) => {
																if (phone === "" || phone.length < 5) {
																	formik.setFieldValue("contacts.phone", undefined);
																} else {
																	formik.setFieldValue("contacts.phone", phone);
																}
															}}
															onBlur={() => formik.setFieldTouched("contacts.phone")}
															error={getIn(formik.touched.contacts, "phone") && !!getIn(formik.errors.contacts, "phone")}
															helperText={getIn(formik.touched.contacts, "phone") && t(getIn(formik.errors.contacts, "phone") ?? "")}
															label={t("SIGN_UP.PHONE")}
															disabled={loadingPoiDetail}
														/>
													</Grid>
													<Grid container item pt={2}>
														<MuiPhoneOld
															value={formik.values.contacts?.phone2}
															onChange={(phone2: any) => {
																if (phone2 === "" || phone2.length < 5) {
																	formik.setFieldValue("contacts.phone2", undefined);
																} else {
																	formik.setFieldValue("contacts.phone2", phone2);
																}
															}}
															onBlur={() => formik.setFieldTouched("contacts.phone2")}
															error={getIn(formik.touched.contacts, "phone2") && !!getIn(formik.errors.contacts, "phone2")}
															helperText={getIn(formik.touched.contacts, "phone2") && t(getIn(formik.errors.contacts, "phone2") ?? "")}
															label={t("SIGN_UP.PHONE2")}
															disabled={loadingPoiDetail}
														/>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={12} md={6}>
													<Grid container item>
														<TextField
															fullWidth
															label="Email"
															name="contacts.email"
															id="contacts.email"
															type="contacts.email"
															variant="outlined"
															error={getIn(formik.touched.contacts, "email") && !!getIn(formik.errors.contacts, "email")}
															helperText={
																getIn(formik.touched.contacts, "email") && getIn(formik.errors.contacts, "email")
																	? t(getIn(formik.errors.contacts, "email"))
																	: ""
															}
															//error={!!formik.errors.email}
															value={formik.values.contacts?.email}
															onBlur={() => formik.setFieldTouched("contacts.email")}
															onChange={(e) => {
																if (e.target.value === "") {
																	formik.setFieldValue("contacts.email", undefined);
																} else {
																	formik.setFieldValue("contacts.email", e.target.value.trim());
																}
															}}
															InputProps={{ sx: { borderRadius: 2 } }}
														/>
													</Grid>
													<Grid container item pt={2}>
														<TextField
															fullWidth
															label="Website"
															name="website"
															id="website"
															type="website"
															variant="outlined"
															value={formik.values.website || ""}
															onChange={formik.handleChange}
														/>
													</Grid>
												</Grid>
											</Grid>

											<Grid container item xs={12} spacing={2}>
												<SocialInputGrid availableSocials={availableSocials} formik={formik} />
											</Grid>
										</Grid>
										<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
											<Grid item xs={12}>
												<Grid item xs={12} pt={9}>
													<PhotoDropZone
														resetFiles={(action) => setResetFiles(action)}
														photos={formik.values.photos || []}
														setPhotoList={setPhotos}
														navigationData={NAVIGATION_DATA.POI}
														setIsUploading={setIsUploading}
													/>
												</Grid>
											</Grid>
											<Grid item mb={2} xs={12} style={{ height: "30rem", overflow: "scroll", overflowX: "hidden" }}>
												<Card style={{}}>
													<CardContent sx={{ mt: 0 }}>
														<Typography variant="h6" component="div" mb={2}>
															{t("POI.DETAIL.AVAILABLE_SERVICES")}
														</Typography>
														{/* TODO-IMPLEMENTARE ONCHANGE */}
														<CheckBoxList
															allStrings={allStringsArray}
															enabledStrings={formik.values.services || []}
															onChange={formik.setFieldValue}
														/>
													</CardContent>
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Card>
													<CardContent sx={{ mt: 0 }}>
														<Grid container xs={12} alignItems={"space-between"}>
															<Grid item xs={6}>
																<Typography variant="h6" component="div" mb={2}>
																	{t("POI.DETAIL.OPENINGS")}
																</Typography>
															</Grid>
															<Grid container item xs={6} alignItems={"baseline"}>
																<Grid item xs={5}>
																	<Typography variant="subtitle2" component="div" mb={2}>
																		{t("POI.DETAIL.REQUEST_OPENING")}
																	</Typography>
																</Grid>
																<Grid item xs={1}>
																	<Switch
																		checked={onRequest}
																		onChange={() => {
																			setOnRequest(!onRequest);
																			formik.setFieldValue("disableOpenings", !onRequest);
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														<OpeningHours
															openingHours={poiDetail?.openings ?? {}}
															setOpeningHours={formik.setFieldValue}
															onRequest={formik.values.disableOpenings || false}
														/>
													</CardContent>
												</Card>
											</Grid>
											{/* <Grid item xs={12}>
                <Card style={{ border:'1px solid rgba(0,0,0,0.2)'}}>
                  <CardContent sx={{ mt:0}} >
                    <Typography variant="h6" component="div" mb={2}>
                      {`${t("POI.DETAIL.CONNECTED_ITINERARIES")} ${poiDetail.itinerary ? '(1)':'(0)'} `}
                    </Typography>
                      <FormGroup>
                     </FormGroup>
                    
                  </CardContent>
                </Card>
               </Grid> */}
											<Grid item xs={12}>
												<ExpectedConnection
													state={formik.values.connection || AvailableConnection.HIGH.toLowerCase()}
													setState={formik.setFieldValue}
													isvalid={formik.isValid}
													error={!!formik.touched.connection && Boolean(formik.errors.connection)}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Form>
							</>
						)}
					</Formik>
				</>
			)}

			{open && modalDataType && (
				<Grid container xs={12}>
					<AddEntityModal
						open={open}
						title={modalTitle}
						onClose={() => handleModal(open, setOpen)}
						callBack={modalCallBack}
						formikCallback={formikCallback}
						formikLabel={formikLabel}
						initialList={modalContent}
						dataType={modalDataType}
						tenant={selectedTenant}
					/>
				</Grid>
			)}
		</>
	);
};

export default observer(PoiAdd);

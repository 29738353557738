import { Grid, Card, CardContent, Typography, Avatar, FormGroup, Box, IconButton, Divider, ButtonBase } from "@mui/material"; // Assicurati di importare i componenti di Material-UI necessari
import { useTranslation } from "react-i18next";
import no_pic from "../../../img/no_pic.jpg";
import { ItineraryDetailInterface } from "../../../type/rest/itinerary-rest-interfaces";
import { formattedLanguageFields } from "../../../type/rest/insight-rest-interfaces";
import { transformLanguageArrayToObject } from "../../../modules/artplace-insight/commons";
import { useNavigate } from "react-router-dom";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import { useContext } from "react";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";

interface ConnectedItineraryProps {
	itineraries: ItineraryDetailInterface[];
	language: string;
}

const ConnectedItineraries: React.FC<ConnectedItineraryProps> = ({ itineraries, language }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { createUrlFile } = EntityUtilities();
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	if (!itineraries || itineraries.length === 0) {
		// Handle the case when itineraries is undefined or an empty array
		return null; // or render some default content
	}

	// useEffect(() => {
	// 	transformLanguageArrayToObject();
	//   }, []);

	const format = (el: ItineraryDetailInterface): formattedLanguageFields => transformLanguageArrayToObject(el.langFields);

	return (
		<Grid item xs={12}>
			<Card style={{ border: "1px solid rgba(0,0,0,0.2)", padding: "16px 16px 0px 16px" }}>
				<Typography variant="h6" component="div" mb={2}>
					{itineraries.length > 1 ? `${t("POI.DETAIL.CONNECTED_ITINERARIES")} (${itineraries.length})` : `${t("POI.DETAIL.CONNECTED_ITINERARY")}`}
				</Typography>
				{itineraries?.map((el: ItineraryDetailInterface) => {
					// console.log("el: ", el);
					const firstLanguage = Object.keys(format(el))[0];
					return (
						<Grid container flexDirection={"column"} key={el._id} sx={{ width: "100%", alignItems: "left" }}>
							<Card style={{ maxWidth: "100%", marginBottom: "16px" }}>
								<CardContent sx={{ width: "100%", padding: "10px !important" }}>
									<ButtonBase
										sx={{ width: "100%" }}
										onClick={() => {
											navigate(`/itineraries/detail/${el._id}`);
										}}
									>
										<Grid item style={{ width: "auto", minWidth: 40, flexShrink: 0 }}>
											<Avatar
												sx={{
													bgcolor: "#E5E4E2", // Colore di sfondo
													borderRadius: "10%", // Angoli arrotondati
													width: 40, // Larghezza fissa
													height: 40, // Altezza fissa
													objectFit: "cover", // Mantiene le proporzioni dell'immagine, riempiendo il frame
													objectPosition: "center", // Centra l'immagine nel frame
													flexShrink: 0, // Impedisce che l'avatar si riduca
													mr: 1,
												}}
												variant="square"
												src={createUrlFile(el.photos[0]) /* || no_pic */}
											/>
										</Grid>
										<Grid item xs={11}>
											<Typography
												variant="h6"
												noWrap
												component="div"
												// sx={{ overflow: "hidden", textOverflow: "ellipsis", textAlign: "left", width: "100%" }}
												sx={{
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap", // ensures the text is on a single line
													textAlign: "left",
													maxWidth: "100%",
													width: "100%",
												}}
											>
												{format(el)[language]
													? format(el)[language]?.name
													: format(el)[languageStore.getDefaultLanguage]
													  ? format(el)[languageStore.getDefaultLanguage].name
													  : format(el)[firstLanguage]
													    ? format(el)[firstLanguage].name
													    : t("COMMONS.NO_VALUE")}
											</Typography>
											<Typography variant="subtitle2" sx={{ color: "text.secondary", textAlign: "left" }}>
												{el && el.category ? t(el.category.label) : null}
											</Typography>
										</Grid>
									</ButtonBase>
								</CardContent>
							</Card>
						</Grid>
					);
				})}
			</Card>
		</Grid>
	);
};

export default ConnectedItineraries;

import { LangField, TypologicType } from "../commons-artplace";
import { Coordinate } from "../type";
import { ItineraryInterface } from "./itinerary-rest-interfaces";

export interface StageDetailInterface {
	_id: string;
	averageRate?: number;
	favorites?: string[];
	numberOfRate?: number;
	tenant: string;
	langFields: LangField[];
	distance: number;
	travelTime: number;
	maxElevation: number;
	minElevation: number;
	coordinates: Coordinate[];
	itinerary?: any;
	order: number;
	pois: string[];
	photos: string[];
	gpxFile: any;
	category: TypologicType | undefined;
}

export const chipColors = {
	poi: { primary: "#F8D98C", dark: "#f8bb23" },
	commercialActivity: { primary: "#F7D4FF", dark: "#e26ffc" },
};

// File BeaconDeleteModal.tsx
import { Button, Modal, Card, Box } from "@mui/material";
import { t } from "i18next";
import React, { ReactNode } from "react";

interface Props {
	open: boolean;
	onClose: () => void;
	children: ReactNode;
	onclickConfirm: () => void;
	onClickCancel: () => void;
}

const GeneralModal: React.FC<Props> = ({ open, onClose, children, onclickConfirm, onClickCancel }) => {
	return (
		<Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
			<Card
				sx={{
					position: "absolute",
					width: 400,
					p: 4,
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				{children}
				<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mt: 2 }}>
					<Button variant="contained" color="primary" onClick={onclickConfirm}>
						{t("COMMONS.CONFIRM")}
					</Button>
					<Button variant="contained" color="secondary" onClick={onClickCancel}>
						{t("COMMONS.CANCEL")}
					</Button>
				</Box>
			</Card>
		</Modal>
	);
};

export default GeneralModal;

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

interface Props {
	onDelete?: () => void;
	onPlay?: () => void;
	onEdit?: () => void;
}

const AttachedButtons = ({ onDelete, onPlay, onEdit }: Props) => {
	const styles = {
		icons: { color: "grey", marginRight: "0.5rem" },
	};

	return (
		<div style={{ display: "flex", justifyContent: "flex-end" }}>
			{onDelete && (
				<IconButton onClick={onDelete}>
					<DeleteIcon sx={styles.icons} fontSize="small" />
				</IconButton>
			)}
			{/* 	{onEdit && <IconButton onClick={onEdit}>
				<ModeEditIcon sx={styles.icons} fontSize="small" />
			</IconButton>} */}
			{onPlay && (
				<IconButton onClick={onPlay}>
					<PlayCircleIcon sx={styles.icons} fontSize="small" />
				</IconButton>
			)}
		</div>
	);
};

export default AttachedButtons;

import { Grid, Card, CardContent, Typography, useTheme, Box, Chip } from "@mui/material"; // Assicurati di importare i componenti di Material-UI necessari
import "./ExpectedConnection.css"; // Importa il file CSS esterno
import { useTranslation } from "react-i18next";
import { availableConnection } from "../../../constants/artplace.constant";

interface props {
	state: string | undefined;
	setState: (field: string, connectionType: string) => void;
	isvalid: boolean;
	error: boolean;
}

function ExpectedConnection({ state, setState, isvalid, error }: props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const redColor = theme.palette.error.light;

	//handle change generico
	const handleChange = (field: string, value: any) => {
		setState(field, value);
	};

	return (
		<Card className="cardContainer" sx={!isvalid && error ? { border: "1px solid" + redColor } : null}>
			<CardContent sx={{ mt: 0 }}>
				<Typography color={!isvalid && error ? redColor : "black"} variant="h6" component="div" mb={2}>
					{t("POI.DETAIL.CONNECTION")}
				</Typography>
				<Box>
					{availableConnection.map((el: string) => (
						<Chip
							key={el}
							onClick={() => handleChange("connection", el.toLowerCase())}
							label={
								<Typography variant="body1" fontWeight={"bold"} style={{ fontSize: "0.9rem", padding: 2 }}>
									{t(`CONNECTION.${el}`)}
								</Typography>
							}
							color="primary"
							variant={state?.toUpperCase() === el ? "filled" : "outlined"}
							style={{
								justifyContent: "center",
								marginLeft: 2,
								marginRight: 2,
								marginBottom: 2,
								borderRadius: 10,
								width: "fit - content",
								backgroundColor: state?.toUpperCase() === el ? "#000" : "transparent",
							}}
						/>
					))}
				</Box>
			</CardContent>
		</Card>
	);
}

export default ExpectedConnection;

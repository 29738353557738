import { createContext, useEffect, useState } from "react";


//TODO-RIVEDERE TUTTO
export interface Type {
  _id: string;
  code: string;
}

export interface ContactIds {
  contactIdEmailCode: string;
  contactIdPhoneCode: string;
  contactIdFacebookCode?: string;
  contactIdInstagramCode?: string;
  contactIdTwitterCode?: string;
  contactIdLinkedinCode?: string;
  contactIdWebsiteCode?: string;

  // ADDRESS
  contactIdAddressCode?: string;
}

export interface ContactTypeState {
  emailCode: string;
  phoneCode: string;
  facebookCode: string;
  instagramCode: string;
  twitterCode: string;
  linkedinCode: string;
  websiteCode: string;
}

export interface RegistryTypeState {
  registryTypeDipendente: string;
  registryTypeSede: string;
  registryTypeAzienda: string;
}

export interface AddressTypeState {
  addressResidenza: string;
  addressResidenzaPrincipale: string;
  addressLegale: string;
  addressLegalePrincipale: string;
}

export interface MyContextProps {
  contactsCodes: ContactTypeState;
  registryCodes: RegistryTypeState;
  addressCodes: AddressTypeState;
  contactIds: ContactIds;
  loading: boolean;
}

export const MyContext = createContext<MyContextProps | null>(null);

export const MyContextProvider = ({ children }: { children: React.ReactNode }) => {
  const initialContactTypeState: ContactTypeState = {
    emailCode: "",
    phoneCode: "",
    facebookCode: "",
    instagramCode: "",
    twitterCode: "",
    linkedinCode: "",
    websiteCode: "",
  };

  const initialRegistryTypeState: RegistryTypeState = {
    registryTypeDipendente: "",
    registryTypeSede: "",
    registryTypeAzienda: "",
  };

  const initialAddressTypeState: AddressTypeState = {
    addressResidenza: "",
    addressResidenzaPrincipale: "",
    addressLegale: "",
    addressLegalePrincipale: "",
  };

  const [loading, setLoading] = useState(true);

  const [contactTypeState, setContactTypeState] = useState<ContactTypeState>(initialContactTypeState);
  const [registryTypeState, setRegistryTypeState] = useState<RegistryTypeState>(initialRegistryTypeState);
  const [addressTypeState, setAddressTypeState] = useState<AddressTypeState>(initialAddressTypeState);

  const [contactIds, setContactIds] = useState<ContactIds>({
    contactIdEmailCode: "",
    contactIdPhoneCode: "",
    contactIdFacebookCode: "",
    contactIdInstagramCode: "",
    contactIdTwitterCode: "",
    contactIdLinkedinCode: "",
    contactIdWebsiteCode: "",

    // ADDRESS
    contactIdAddressCode: "",
  });

  return (
    <MyContext.Provider
      value={{ loading, contactsCodes: contactTypeState, registryCodes: registryTypeState, addressCodes: addressTypeState, contactIds }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;

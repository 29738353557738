import twitterLogo from "../img/socials/x-logo.png";
import facebookLogo from "../img/socials/facebook-logo.png";
import instagramLogo from "../img/socials/instagram-logo.png";
import youtubeLogo from "../img/socials/youtube-logo.png";
import linkedinLogo from "../img/socials/linkedin-logo.png";
import websiteLogo from "../img/socials/website-logo.png";
import pinterestLogo from "../img/socials/pinterest-logo.png";
//per questo probabilmente servirà un servizio rest che restituisca i dati dei servizi disponibili

export const PAGESIZE: number = 10;
export const ORDERBY: string = "createdAt";
export const SORTASC: number = -1;
//questo probabilmente non servirà il servizio REST

export enum AvailableConnection {
	HIGH = "HIGH",
	LOW = "LOW",
	ABSENT = "ABSENT",
}

export const availableConnection: AvailableConnection[] = [AvailableConnection.HIGH, AvailableConnection.LOW, AvailableConnection.ABSENT];
//questo probabilmente non servirà il servizio REST
export const availableSocials: { [key: string]: { staticIcon?: string; muiIcon: string } } = {
	facebook: {
		staticIcon: facebookLogo,
		muiIcon: "Facebook",
	},
	instagram: {
		staticIcon: instagramLogo,
		muiIcon: "Instagram",
	},
	linkedin: {
		staticIcon: linkedinLogo,
		muiIcon: "LinkedIn",
	},
	twitter: {
		staticIcon: twitterLogo,
		muiIcon: "X",
	},
	youtube: {
		staticIcon: youtubeLogo,
		muiIcon: "YouTube",
	},
	pinterest: {
		staticIcon: pinterestLogo,
		muiIcon: "Pinterest",
	},
};

export const libraries: ["places"] = ["places"];

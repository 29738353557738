import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { toast_error } from "../../utils/custom-toast";
import axios from "axios";
import Image from "react-bootstrap/Image";
// Icons
import * as Icons from "@mui/icons-material";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";

import artplaceLogoWithText from "../../img/logo_artpalce_bianco.png";
import artplaceLogo from "../../img/artplace-logo.png";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

// Styles
import styles from "./Sidebar.module.css";

import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { TokenKeys } from "../../type/commons-artplace";

type IconType = keyof typeof Icons;

const IconComponent: React.FC<{ iconName: IconType }> = ({ iconName }) => {
	const Icon = Icons[iconName];
	return <Icon />;
};

// Component
const SideMenu: React.FC = () => {
	const { t } = useTranslation();
	const [collapsed, setCollapsed] = useState(false);
	const [toggled, setToggled] = useState(false);
	const [menuItems, setMenuItems] = useState<any[]>([]);
	const [broken, setBroken] = useState(false);
	const [activeItem, setActiveItem] = useState("poi/view");
	const userStore: UserStore = useContext(UserStoreContext);
	// const [selectedSubMenu, setSelectedSubMenu] = useState<string|null>(null);

	const aToken = localStorage.getItem(TokenKeys.ACCESS_TOKEN);
	const role = localStorage.getItem("role");
	const handleCollapsedChange = () => {
		setCollapsed(!collapsed);
	};

	const handleBreakPointChange = (isBroken: boolean) => {
		if (isBroken) {
			setCollapsed(true);
		}
		setBroken(isBroken);
	};

	const handleMenuItemClick = (itemName: string) => {
		setActiveItem(itemName);
	};

	// const handleSubMenuClick = (submenuName:string) => {
	//   setSelectedSubMenu((prevSubMenu:string|null) =>
	//     prevSubMenu === submenuName ? null : submenuName
	//   );
	// };
	useEffect(() => {
		//TODO - SCOMMENTARE SE SI VUOLE APRIRE IL SUB-MENU
		//document.getElementById('POI_SUB_MENU')?.click();
		// if (aToken) {
		// 	const fetchData = async () => {
		// 		try {
		// 			console.log(API_GET_MENU);
		// 			const response = await axios.get(API_GET_MENU, {
		// 				headers: {
		// 					"x-access-token": aToken,
		// 				},
		// 			});
		// 			if (response.data.code === 0) {
		// 				setMenuItems(response.data.data);
		// 			} else {
		// 				toast_error(response.data.message);
		// 			}
		// 		} catch (error) {
		// 			toast_error("Errore nel caricamento del menu");
		// 		}
		// 	};
		// 	fetchData();
	}, []);

	return (
		<div>
			<Sidebar
				backgroundColor="black"
				style={{ height: "100%", color: "white", fontSize: "1rem" }}
				onBreakPoint={handleBreakPointChange}
				onBackdropClick={() => setToggled(false)}
				toggled={toggled}
				breakPoint="sm"
				collapsed={broken ? true : collapsed}
			>
				<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
					<div className={styles.headerContainer} style={{ height: 90 }}>
						{collapsed ? (
							<Image src={artplaceLogo} style={{ margin: "auto", maxHeight: "80%", maxWidth: "80%" }} />
						) : (
							<Image src={artplaceLogoWithText} style={{ width: "60%", margin: "auto", maxHeight: 90 }} />
						)}
					</div>

					<Divider
						variant={"middle"}
						sx={{ marginTop: "15px", marginBottom: "15px", backgroundColor: "#FFFFFF", width: collapsed ? "50%" : "80%" }}
					/>

					<div style={{ flex: 1 }} className="sidebarContent">
						<Menu>
							{/* TODO: RIMETTERE
            {menuItems
              .filter((item) => item.role === role)
              .map((item) => (
                <MenuItem
                  icon={<IconComponent iconName={item.icon} />}
                  component={<Link to={item.link} />}
                  className={`${activeItem === item.title ? styles.boldText : ""} ${styles.menuItem}`}
                  onClick={() => handleMenuItemClick(item.title)}
                  key={item._id}
                >
                  {t(item.title)}
                </MenuItem>
              ))} */}

							{/* <MenuItem
								component={<Link to="/dashboard" />}
								icon={<IconComponent iconName="Home" />}
								onClick={() => handleMenuItemClick("dashboard")}
								className={`${activeItem === "dashboard" ? styles.boldText : ""} ${styles.menuItem}`}
							>
								{t("SIDEBAR.DASHBOARD")}
							</MenuItem> */}

							<SubMenu
								label={t("SIDEBAR.ITINERARIES")}
								title={`Submenu ${t("SIDEBAR.ITINERARIES")}`}
								icon={<IconComponent iconName="DirectionsWalk" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
							>
								<MenuItem
									component={<Link to="/itineraries/view" />}
									onClick={() => handleMenuItemClick("itineraries/view")}
									className={`${activeItem === "itineraries/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
								<MenuItem
									component={<Link to="/itineraries/add" />}
									onClick={() => handleMenuItemClick("itineraries/add")}
									className={`${activeItem === "itineraries/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.ADD")}
								</MenuItem>
							</SubMenu>

							<SubMenu
								label={t("SIDEBAR.STAGES")}
								title={`Submenu ${t("SIDEBAR.STAGES")}`}
								icon={<IconComponent iconName="PushPin" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
							>
								<MenuItem
									component={<Link to="/stages/view" />}
									onClick={() => handleMenuItemClick("stages/view")}
									className={`${activeItem === "stages/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
							</SubMenu>

							<SubMenu
								label={t("SIDEBAR.POI")}
								title={`Submenu ${t("SIDEBAR.POI")}`}
								icon={<IconComponent iconName="PinDrop" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
								id="POI_SUB_MENU"
							>
								<MenuItem
									component={<Link to="/poi/view" />}
									onClick={() => handleMenuItemClick("poi/view")}
									className={`${activeItem === "poi/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
								<MenuItem
									component={<Link to="/poi/add" />}
									onClick={() => handleMenuItemClick("poi/add")}
									className={`${activeItem === "poi/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.ADD")}
								</MenuItem>
							</SubMenu>

							<SubMenu
								label={t("SIDEBAR.INSIGHTS")}
								title={`Submenu ${t("SIDEBAR.INSIGHTS")}`}
								icon={<IconComponent iconName="Collections" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
							>
								<MenuItem
									component={<Link to="/insights/view" />}
									onClick={() => handleMenuItemClick("insights/view")}
									className={`${activeItem === "insights/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
								<MenuItem
									component={<Link to="/insights/add" />}
									onClick={() => handleMenuItemClick("insights/add")}
									className={`${activeItem === "insights/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.ADD")}
								</MenuItem>
							</SubMenu>

							{/* 	<SubMenu
								label={t("SIDEBAR.CATEGORIES")}
								title="Submenu Categories"
								icon={<IconComponent iconName="Category" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
								//  open={selectedSubMenu ==="categories" }
								//  onClick={() => handleSubMenuClick("categories")}
								//  active={true}
							>
								<MenuItem
									component={<Link to="/categories/view" />}
									onClick={() => handleMenuItemClick("categories/view")}
									className={`${activeItem === "categories/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
								<MenuItem
									component={<Link to="/categories/add" />}
									onClick={() => handleMenuItemClick("categories/add")}
									className={`${activeItem === "categories/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.ADD")}
								</MenuItem>
							</SubMenu> */}

							<SubMenu
								label={t("SIDEBAR.BEACONS")}
								title={`Submenu ${t("SIDEBAR.BEACONS")}`}
								icon={<IconComponent iconName="WifiTethering" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
							>
								<MenuItem
									component={<Link to="/beacons/view" />}
									onClick={() => handleMenuItemClick("beacons/view")}
									className={`${activeItem === "beacons/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
								<MenuItem
									component={<Link to="/beacons/add" />}
									onClick={() => handleMenuItemClick("beacons/add")}
									className={`${activeItem === "beacons/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.ADD")}
								</MenuItem>
							</SubMenu>

							{userStore.getUserRole() === RegistryRoleEnum.SUPERADMIN.toUpperCase() ? (
								<>
									<SubMenu
										label={t("SIDEBAR.CUSTOMERS")}
										title={`Submenu ${t("SIDEBAR.CUSTOMERS")}`}
										icon={<IconComponent iconName="Group" />}
										className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
									>
										<MenuItem
											component={<Link to="/customers/view" />}
											onClick={() => handleMenuItemClick("customers/view")}
											className={`${activeItem === "customers/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
										>
											{t("SIDEBAR.SHOW")}
										</MenuItem>
										<MenuItem
											component={<Link to="/customers/add" />}
											onClick={() => handleMenuItemClick("customers/add")}
											className={`${activeItem === "customers/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
										>
											{t("SIDEBAR.ADD")}
										</MenuItem>
									</SubMenu>
									<SubMenu
										label={t("SIDEBAR.NEWS")}
										title={`Submenu ${t("SIDEBAR.NEWS")}`}
										icon={<IconComponent iconName="ChatBubble" />}
										className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
									>
										<MenuItem
											component={<Link to="/news/view" />}
											onClick={() => handleMenuItemClick("news/view")}
											className={`${activeItem === "news/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
										>
											{t("SIDEBAR.SHOW")}
										</MenuItem>
										<MenuItem
											component={<Link to="/news/add" />}
											onClick={() => handleMenuItemClick("news/add")}
											className={`${activeItem === "news/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
										>
											{t("SIDEBAR.ADD")}
										</MenuItem>
									</SubMenu>
								</>
							) : null}

							{/* <Divider
								variant={"middle"}
								sx={{ marginTop: "15px", marginBottom: "15px", backgroundColor: "#FFFFFF", width: collapsed ? "50%" : "80%" }}
							/> */}

							{/* <SubMenu
								label={t("SIDEBAR.NEWS")}
								icon={<IconComponent iconName="ChatBubble" />}
								className={`${activeItem === "/xxx" ? styles.boldText : ""} ${styles.subMenu}`}
							>
								<MenuItem
									component={<Link to="/news/view" />}
									onClick={() => handleMenuItemClick("news/view")}
									className={`${activeItem === "news/view" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.SHOW")}
								</MenuItem>
								<MenuItem
									component={<Link to="/news/add" />}
									onClick={() => handleMenuItemClick("news/add")}
									className={`${activeItem === "news/add" ? styles.boldText : ""} ${styles.subMenuItem}`}
								>
									{t("SIDEBAR.ADD")}
								</MenuItem>
							</SubMenu> */}

							{/* <Divider
								variant={"middle"}
								sx={{ marginTop: "15px", marginBottom: "15px", backgroundColor: "#FFFFFF", width: collapsed ? "50%" : "80%" }}
							/>
 */}
							{/* ESEMPI PER FORMIK-DATAGRID-ETC */}
							{/* {role === "superadmin" && (
            <>
              <MenuItem
                component={<Link to="/new-request" />}
                icon={<IconComponent iconName="BugReport" />}
                onClick={() => handleMenuItemClick("new-request")}
                className={`${activeItem === "new-request" ? styles.boldText : ""} ${styles.menuItem}`} 
                style={{color:'red'}}
              
              >
                {'ESEMPIO DATAGRID REQUEST'}
              </MenuItem>
              <MenuItem
                component={<Link to="/user/edit-profile" />}
                icon={<IconComponent iconName="BugReport" />}
                onClick={() => handleMenuItemClick("user/edit-profile")}
                className={`${activeItem === "user/edit-profile" ? styles.boldText : ""} ${styles.menuItem}`}
                style={{color:'red'}}

              >
                {'ESEMPIO EDIT PROFILE - FORMIK'}

              </MenuItem>
            </>
          )} */}
						</Menu>
					</div>

					<div className="sidebarFooter">
						{!broken && (
							<Menu>
								<MenuItem icon={<MenuIcon />} onClick={handleCollapsedChange} className={styles.menuItem}>
									{t("SIDEBAR.REDUCE")}
								</MenuItem>
							</Menu>
						)}
					</div>
				</div>
			</Sidebar>

			<Box style={{ position: "absolute", top: 18, left: 18 }}>
				<div>{broken && <MenuIcon onClick={() => setToggled(!toggled)} />}</div>
			</Box>
		</div>
	);
};

export default SideMenu;

import React, { useContext, useEffect, useState } from "react";
import styles from "./Topbar.module.css";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BE_LINKS } from "../../api/api";
import { toast_error } from "../../utils/custom-toast";
import Avatar from "@mui/material/Avatar";
import Image from "react-bootstrap/Image";
import { Grid, IconButton, LinearProgress, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { PersonInterface } from "../../type/rest/person-rest-interface";
import { mockyCollection } from "../../data/apiCall.mockup";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { toast } from "react-toastify";
import i18n from "../../i18n";
import { del, get, post } from "../../utils/call-api";
import { observer } from "mobx-react-lite";
import GlobalLanguageSelector from "../globalLanguageSelector/GlobalLanguageSelector";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import { t } from "i18next";
import { set } from "mobx";
import { logout } from "../../utils/util";
import { TypologicStoreContext } from "../../stores/TypologicStore";
import { ClearAllStores } from "../../stores/ClearAllStores";

const Topbar: React.FC = () => {
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const navigate = useNavigate();
	const userStore: UserStore = useContext(UserStoreContext);
	const [person, setPerson] = useState<PersonInterface | null>(null);
	const role = localStorage.getItem("role");
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const resetAllStores = ClearAllStores();

	const handleLogout = async () => {
		try {
			await logout();
			userStore.setIsAuthenticated(false);
			localStorage.clear();
			resetAllStores();

			navigate("/login");
		} catch (error: any) {
			localStorage.clear();
			userStore.setIsAuthenticated(false);
			resetAllStores();
			navigate("/login");
			toast.error(t("LOGIN.SUBMIT_ERROR"));
		}
	};

	const fetchIdRegistryOwner = async () => {
		setLoading(true);
		try {
			const response = (await get(BE_LINKS.COMMONS.USER_INFO)).data;
			if (response) {
				// console.log("Info User: ", response);
				userStore.setUser(response);
				setPerson(() => userStore.getUser());
				if (role === "superadmin" || role === "admin") {
					localStorage.setItem("business_name", response.business);
				}
			} else {
				toast.error("ERRORE: Non è stato possibile recuperare le informazioni dello user");
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchIdRegistryOwner();
	}, []);

	return (
		<div className={styles.containerTopbar}>
			<Grid padding={2}>
				<GlobalLanguageSelector />
			</Grid>
			<div className={styles.userInfoContainer} onClick={() => navigate("/profile")} style={{ cursor: "pointer" }}>
				<div className={styles.userText} style={{ display: smallScreen ? "none" : "block" }}>
					{person?.firstName ? (
						<Grid container alignItems={"center"} flexDirection={"column"} sx={{ textTransform: "capitalize" }}>
							<Typography variant={"h6"}>
								{person.firstName} {person.lastName}
							</Typography>
							<Typography>{person.role}</Typography>
						</Grid>
					) : (
						<Grid container alignItems={"center"} flexDirection={"column"} sx={{ textTransform: "capitalize" }}>
							<Typography variant={"h6"}>{person?.businessName}</Typography>
							<Typography>{person?.role}</Typography>
						</Grid>
					)}
					{/* <div className={styles.userCompany}>Microsoft</div> */}
				</div>

				{person?.image ? (
					<Avatar sx={{ bgcolor: "#E5E4E2", marginRight: "10px" }} variant="rounded">
						<Image src={person.image} className={styles.userImage} />
					</Avatar>
				) : (
					<Avatar sx={{ bgcolor: "#2A627D", marginRight: "10px" }} variant="rounded">
						{person?.firstName?.substring(0, 1)}
						{person?.lastName?.substring(0, 1)}
					</Avatar>
				)}
			</div>
			<Tooltip title="Esci">
				<IconButton onClick={() => setOpenModal(true)}>
					{/* <BiLogOut  onClick={handleLogout} /> */}
					<LogoutIcon className={styles.logoutIcon} />
				</IconButton>
			</Tooltip>
			<ConfirmationModal open={openModal} onClose={() => setOpenModal(false)} onConfirm={handleLogout} text1={t("MODALS.LOGOUT")} />
		</div>
	);
};
export default observer(Topbar);

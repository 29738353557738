import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEn from "./locales/en/translation.json";
import translationsIt from "./locales/it/translation.json";
import translationsDe from "./locales/de/translation.json";
import translationsFr from "./locales/fr/translation.json";
import translationsEs from "./locales/es/translation.json";
import translationsJa from "./locales/ja/translation.json";
import translationsRu from "./locales/ru/translation.json";
import translationsZh from "./locales/zh/translation.json";
import translationsUk from "./locales/uk/translation.json";
import typologicalIt from "./locales/it/typologicals.json";
import typologicalEn from "./locales/en/typologicals.json";
import typologicalDe from "./locales/de/typologicals.json";
import typologicalFr from "./locales/fr/typologicals.json";
import typologicalEs from "./locales/es/typologicals.json";
import typologicalJa from "./locales/ja/typologicals.json";
import typologicalRu from "./locales/ru/typologicals.json";
import typologicalZh from "./locales/zh/typologicals.json";
import typologicalUk from "./locales/uk/typologicals.json";

const lng = localStorage.getItem("lang");

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: { translation: translationsEn, typological: typologicalEn },
			it: { translation: translationsIt, typological: typologicalIt },
			de: { translation: translationsDe, typological: typologicalDe },
			fr: { translation: translationsFr, typological: typologicalFr },
			es: { translation: translationsEs, typological: typologicalEs },
			ja: { translation: translationsJa, typological: typologicalJa },
			ru: { translation: translationsRu, typological: typologicalRu },
			zh: { translation: translationsZh, typological: typologicalZh },
			uk: { translation: translationsUk, typological: typologicalUk },
		},
		lng: lng as string,
		fallbackLng: "en",
		//NOTE - RICORDARE DI RIATTIVARE IL DEBUG
		debug: false,
		ns: ["translation", "typological"],
		nsSeparator: ".",
		detection: {
			order: ["navigator"],
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;

import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { LangField } from "../../../type/commons-artplace";
import { t } from "i18next";

interface props {
	id: string | undefined;
	formik: any;
	lang: string;
}

function IdName({ id, formik, lang }: props) {
	const { handleNameChange, getIndexFromLangFields } = EntityUtilities();
	const [name, setName] = useState<string>("");
	const [showError, setShowError] = useState<boolean>(false);
	//console.log("formik.errors: ", formik.errors);
	//console.log("formik.touched: ", formik.touched);
	useEffect(() => {
		// Aggiorna il nome locale quando cambia il formik.values.langFields
		const index = getIndexFromLangFields(formik.values.langFields, lang);
		if (formik.values.langFields && index !== -1) {
			const langField = formik.values.langFields[index].name;
			setName(langField);
		}
	}, [formik.values.langFields, lang]);

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		handleNameChange(lang, event, formik.values.langFields, formik.setFieldValue);
		// Mostra l'errore solo se il campo è stato toccato e il nome è vuoto
		setShowError(!event.target.value.trim());
	};

	useEffect(() => {
		if (formik.errors.langFields && formik.touched.langFields) {
			setShowError(true);
		} else {
			setShowError(false);
		}
	}, [formik.errors.langFields, formik.touched.langFields]);

	return (
		<>
			<Grid container item spacing={2}>
				{id && (
					<Grid item xs={12} sm={4}>
						<TextField fullWidth label="ID" disabled variant="outlined" defaultValue={id} />
					</Grid>
				)}

				<Grid item xs={12} sm={id ? 8 : 12}>
					<TextField
						fullWidth
						required
						id="name"
						name={`langFields.${getIndexFromLangFields(formik.values.langFields, lang)}.name`}
						label={t("FORM.NAME")}
						error={showError}
						helperText={showError ? t("ERRORS.FORM.REQUIRED") : ""}
						value={name}
						onChange={(event) => setName(event.target.value)}
						onBlur={handleBlur}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default IdName;

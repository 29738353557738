import { toast, ToastOptions } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const default_options: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  className: "custom-toast",
};

export function toast_warn(message: string, options?: ToastOptions) {
  toast.warning(message, { ...default_options, ...options });
}

export function toast_error(message: string, options?: ToastOptions) {
  toast.error(message, { ...default_options, ...options });
}

export function toast_success(message: string, options?: ToastOptions) {
  toast.success(message, { ...default_options, ...options });
}

export function toast_info(message: string, options?: ToastOptions) {
  toast.info(message, { ...default_options, ...options });
}

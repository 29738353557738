import React, { useState, useEffect, useContext } from "react";
import styles from "./Login.module.css";
import { toast_error } from "../../utils/custom-toast";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import LinkMaterial from "@mui/material/Link";
import { API_LOGIN, API_TYPOLOGICS, BE_LINKS } from "../../api/api";
import { get, post } from "../../utils/call-api";
import { ToastContainer, toast } from "react-toastify";
import { Alert, Button, Container, IconButton, InputAdornment, TextField, Typography, Grid, Box } from "@mui/material";
import LadingPage from "../landingPage/LandingPage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IAlert } from "../../type/type";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { TypologicStoreContext } from "../../stores/TypologicStore";
import { observer } from "mobx-react-lite";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import GlobalLanguageSelector from "../../componets/globalLanguageSelector/GlobalLanguageSelector";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import { TokenKeys } from "../../type/commons-artplace";

function Login(): React.JSX.Element {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [mostraPwd, setMostraPwd] = useState(false);
	const [displayLoad, setDisplayLoad] = useState<string>("d-none");
	const [displayLog, setDisplayLog] = useState<string>("");
	const [redirect, setRedirect] = useState<boolean>(false);
	const typologicStore = useContext(TypologicStoreContext);

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "LOGIN.SUBMIT_ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({ ...alert, show: false });
		},
	});

	const [loading, setLoading] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean>(false);
	const userStore: UserStore = useContext(UserStoreContext);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const successVerification = searchParams.get("successVerification");

	useEffect(() => {
		let msg = localStorage.getItem("expiredSession");
		if (msg) {
			toast_error(msg);
			localStorage.removeItem("expiredSession");
		}
	}, []);

	useEffect(() => {
		if (successVerification) {
			setAlert({ ...alert, show: true, message: "LOGIN.SUCCESS_EMAIL_VERIFICATION", severity: "success" });
		}
	}, []);

	const onClickEnter = async (event: any) => {
		if (event.key === "Enter") {
			await login();
		}
	};
	useEffect(() => {
		if (userStore.getLogoutModal()) {
			toast.error(t("ERRORS.REFRESH_ERROR"));
			userStore.setLogoutModal(false);
		}
	}, []);

	async function login(): Promise<void> {
		const payload = {
			email: username,
			pwd: password,
			applicationName: "artplace",
			roles: [RegistryRoleEnum.SUPERADMIN, RegistryRoleEnum.ADMIN],
		};
		// callApi(API_LOGIN, API_METHOD.POST, payload)
		await axios
			.post(API_LOGIN, payload)
			.then(async (response) => {
				// console.log("login response: ", response.data);
				if (response.data.code === 0) {
					localStorage.setItem(TokenKeys.ACCESS_TOKEN, response.data.data.aToken);
					localStorage.setItem(TokenKeys.REFRESH_TOKEN, response.data.data.rToken);
					localStorage.setItem("userEmail", username);
					//localStorage.setItem("auth", response.data.success);
					localStorage.setItem("role", response.data.data.role);
					userStore.setUserRole(response.data.data.role);
					navigate("/poi/view");
					if (localStorage.getItem(TokenKeys.ACCESS_TOKEN)) {
						const typologics = (await get(BE_LINKS[userStore.getUserRole()].API_TYPOLOGICS)).data;
						if (typologics) {
							typologicStore.setTypologics(typologics);
							typologicStore.setTypologicsReady();
						}
					}
				} else {
					localStorage.clear(); //per pulire storage se qualcosa dovesse andare storto
					setDisplayLog("");
					setDisplayLoad("d-none");
					// console.log("response.data.message", response.data.message);
					setAlert({ ...alert, show: true, message: response.data.message });
					userStore.setIsAuthenticated(false);
				}
			})

			.catch((error) => {
				// console.log("Catch di login: ", error);
				setDisplayLog("");
				setDisplayLoad("d-none");
				console.error(error);
				setAlert({ ...alert, show: true });
				userStore.setIsAuthenticated(false);
			});
	}

	function _renderInput() {
		return (
			<>
				<ToastContainer position="top-center" hideProgressBar autoClose={3000} closeOnClick theme="colored" />
				<div style={{ position: "absolute", top: 30, right: 30 }}>
					<GlobalLanguageSelector />
				</div>
				<Container>
					<Typography variant="h4" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
						Login
					</Typography>
					<Typography variant="subtitle1" sx={{ marginBottom: "25px", fontWeight: "400", color: "#8c8c8c" }}>
						{t("LOGIN.WELCOME_BACK")}
					</Typography>
					{alert.show && (
						<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
							{t(alert.message)}
						</Alert>
					)}

					<Box marginBottom={"15px"}>
						<TextField
							id="usrnm"
							type="text"
							className={styles.textFields}
							variant={"outlined"}
							label={"Email"}
							onChange={(evt) => {
								setUsername(evt.target.value);
							}}
							fullWidth
						/>
					</Box>

					<Box marginBottom={"10px"}>
						<TextField
							id="psw"
							type={mostraPwd ? "text" : "password"}
							className={styles.textFields}
							variant={"outlined"}
							label={"Password"}
							onChange={(evt) => {
								setPassword(evt.target.value);
							}}
							onClick={() => onClickEnter}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>

					<Box display={"flex"} justifyContent={"space-between"}>
						{/*<FormGroup>
              <FormControlLabel control={<Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                placeholder={"Pippo"}
              />} label={t("LOGIN.REMEMBER_ME")} />
            </FormGroup>*/}

						<LinkMaterial href="/recover-password" underline="none" marginTop={"8px"} color={"black"} marginLeft={"5px"}>
							<Typography
								sx={{
									textDecoration: "none",
									fontSize: "1rem",
								}}
							>
								{t("LOGIN.RECOVER_PASSWORD")}
							</Typography>
						</LinkMaterial>
					</Box>
					<Box marginTop={"20px"}>
						<Button onClick={() => login()} sx={{ textTransform: "Capitalize" }} variant={"contained"} fullWidth size={"large"}>
							Login
						</Button>
					</Box>
					<Grid container className={"mt-5"}>
						{/* <Grid item xs={12} className={"mb-3"}>
              {t("LOGIN.NEW_USER")}
              <Link to={"/registrazione"} style={{ color: "black", marginLeft: "5px" }}>
                {t("LOGIN.REGISTER_NOW")}
              </Link>
            </Grid>*/}
					</Grid>
				</Container>
			</>
		);
	}

	return (
		<LadingPage>
			<div style={{ width: "100%", height: "100%" }}>
				<div>{_renderInput()}</div>
			</div>
		</LadingPage>
	);
}

export default observer(Login);

import React, { useState } from "react";
import "../../../colors.css";
import CustomDataGrid from "../../../componets/dataGrid/CustomDataGrid";
import { NAVIGATION_DATA, DATATYPE, CUSTOMER_FILTERS } from "../../../type/commons-artplace";

const CustomerList: React.FC = () => {
	const type = NAVIGATION_DATA.CUSTOMER;
	const [searchField, setSearchField] = useState<string>(CUSTOMER_FILTERS.name);

	return (
		<>
			<CustomDataGrid
				type={type}
				numberRows={DATATYPE.CUSTOMER}
				searchField={searchField}
				formatRows={(item) => ({
					...item,
					id: item._id,
					email: item.id_contact[0].contact, //TODO: andrà sistemata l'email
				})}
			/>
		</>
	);
};

export default CustomerList;

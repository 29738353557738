import { AvailableConnection } from "../constants/artplace.constant";
import { BeaconDetailInterface } from "./rest/beacon-rest-interface";
import { CustomerDetailInterface } from "./rest/customer-rest-interface";
import { InsightDetailInterface } from "./rest/insight-rest-interfaces";
import { ItineraryDetailInterface } from "./rest/itinerary-rest-interfaces";
import { NewsDetailInterface } from "./rest/news-rest-interface";
import { PoiDetailInterface } from "./rest/poi-rest-interfaces";
import { StageDetailInterface } from "./rest/stage-rest-interface";

export type ConnectionType = "high" | "medium" | "low" | "none";

export interface SocialInterface {
	type: string;
	//name: string;
	url: string;
}

/* export interface OpeningsInterface {
	monday?: string;
	tuesday?: string;
	wednesday?: string;
	thursday?: string;
	friday?: string;
	saturday?: string;
	sunday?: string;
} */

export interface OpeningHour {
	hourOpening: string;
	hourClosed: string;
	statusActive: boolean;
}

export interface OpeningsInterface {
	[day: string]: OpeningHour;
}

export interface SocialMapInterface {
	[key: string]: {
		staticIcon?: string;
		muiIcon: string;
		url?: string;
	};
}
export enum SupportedDynamicLanguageEnum {
	ENGLISH = "en",
	ITALIAN = "it",
	FRENCH = "fr",
	GERMAN = "de",
	SPANISH = "es",
	JAPANESE = "ja",
	RUSSIAN = "ru",
	CHINESE = "zh",
	UKRAINIAN = "uk",
}

export interface Coordinates {
	lat: number;
	lng: number;
	elevation?: number;
}

export const emptyPoi: PoiDetailInterface = {
	langFields: [
		{
			lang: "",
			name: "",
			subtitle: "",
			description: "",
		},
	],
	disableOpenings: false,
	name: "",
	cover: "",
	photos: [],
	category: undefined /* { key: "", label: "" } */,
	latlng: {
		lat: 0,
		lng: 0,
	},
	tenant: "",
	address: "",
	statusActive: false,
	durationSeconds: 0,
	subcategory: undefined /* { key: "", label: "" } */,
	price: 0,
	description: "",
	//itinerary: undefined,
	connection: AvailableConnection.HIGH.toLowerCase(), //ABSENT
	contacts: {
		phone: undefined,
		phone2: undefined,
		email: undefined,
	},
	insights: [],
	services: [],
	relatedPois: [],
	socials: [],
	website: undefined,
	openings: {
		monday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
		tuesday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
		wednesday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
		thursday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
		friday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
		saturday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
		sunday: {
			hourOpening: "",
			hourClosed: "",
			statusActive: false,
		},
	},
};

export const emptyItinerary: ItineraryDetailInterface = {
	_id: "",
	name: "",
	image: "",
	langFields: [
		{
			lang: "",
			name: "",
			subtitle: "",
			description: "",
		},
	],
	category: undefined /* { key: "", label: "" } */,
	tenant: "",
	shortAddress: "",
	statusActive: false,
	photos: [],
	gpxFile: undefined,
	subcategory: undefined /* { key: "", label: "" } */,
	distance: undefined,
	difference: undefined,
	description: "",
	stages: [],
	unstableConnection: false,
	website: undefined,
};

// export const emptyInsight: InsightDetailInterface = {
// 	_id: '0',
// 	cover: "",
// 	name: "",
// 	category: "",
// 	statusActive: false,
// 	code: 0,
// 	photos: [],
// 	customer: "",
// 	description: "",
// 	latlng: { lat: 0, lng: 0 },
// 	address: "",
// 	poi: {
// 		_id: 0,
// 		name: "",
// 		cover: "",
// 		category: "",
// 		latlng: { lat: 0, lng: 0 },
// 		customer: "",
// 		address: "",
// 		statusActive: false,
// 		durationSeconds: 0,
// 	},
// 	services: [],
// 	connection: "",
// 	audio: { mime: "", url: "", filename: "", dimension: 0, lang: "" },
// 	beacon: [],
// 	video: { mime: "", url: "", filename: "", dimension: 0, lang: "" },
// };

export const emptyStage: StageDetailInterface = {
	favorites: [],
	_id: "",
	tenant: "",
	langFields: [
		{
			lang: "",
			name: "",
			subtitle: "",
			description: "",
		},
	],
	distance: 0,
	travelTime: 0,
	maxElevation: 0,
	minElevation: 0,
	coordinates: [
		{
			lat: 0,
			lng: 0,
			elevation: 0,
		},
	],
	itinerary: undefined,
	order: 0,
	pois: [],
	photos: [],
	gpxFile: undefined,
	category: {
		key: "",
		label: "",
	},
};

export const emptyCustomer: CustomerDetailInterface = {
	id_contact: {},
	business_name: "",
	photos: [],
	email: "",
};
export const emptyInsight: InsightDetailInterface = {
	langFields: [
		{
			lang: "",
			name: "",
			subtitle: "",
			description: "",
		},
	],
	photos: [],
	tenant: "",
	category: undefined,
	latlng: {
		lat: 0,
		lng: 0,
	},
	audioTracks: [],
	videos: [],
	_id: "",
	address: undefined,
	poi: null,
	statusActive: false,
	order: undefined,
	group: undefined,
	onlyBeacon: false,
};

export const emptyNews: NewsDetailInterface = {
	_id: "",
	title: "",
	body: "",
	status: undefined,
	link: "",
	imageUrl: "",
	category: "",
	global: false,
	sentTo: [],
};

export enum BEACON_FILTERS {
	name = "name",
	uuid = "uuid",
	identification_code = "identification_code",
}

export enum INSIGHT_FILTERS {
	name = "langFields.name",
	id = "_id",
}

export enum STAGE_FILTERS {
	name = "langFields.name",
	id = "_id",
}

export enum POI_FILTERS {
	name = "langFields.name",
	id = "_id",
}
export enum USERS_FILTERS {
	name = "firstname",
	id = "_id",
	surname = "surname",
}

export enum ITINERARY_FILTERS {
	name = "langFields.name",
	id = "_id",
}

export enum CUSTOMER_FILTERS {
	name = "business_name",
	id = "_id",
}

export enum NEWS_FILTERS {
	name = "title",
	id = "_id",
}

export const emptyBeacon: BeaconDetailInterface = {
	identificationCode: "",
	name: "",
	uuid: "",
	major: 0,
	minor: 0,
	photos: [],
	latlng: {
		lat: 0,
		lng: 0,
	},
	address: "",
	status: false,
	insights: [],
	description: "",
	poi: [],
	supplier: "",
	model: "",
	tenant: undefined,
};

export enum DATATYPE {
	POI = "POI",

	POI_LIST = "POI_LIST",
	INSIGHT = "INSIGHT",
	INSIGHTS = "INSIGHTS",
	INSIGHT_LIST = "INSIGHT_LIST",
	ITINERARY = "ITINERARY",
	BEACON = "BEACON",
	BEACONS = "BEACONS",
	BEACON_LIST = "BEACON_LIST",
	STAGE = "STAGE",
	CUSTOMER = "CUSTOMER",
	NEWS = "NEWS",
	USERS = "USERS",
}

export enum NAVIGATION_DATA {
	POI = "POI",
	INSIGHT = "INSIGHTS",
	ITINERARY = "ITINERARIES",
	BEACON = "BEACONS",
	STAGE = "STAGES",
	CUSTOMER = "CUSTOMERS",
	NEWS = "NEWS",
	USERS = "USERS",
}

// export enum NAVIGATION_DATA {
// 	POI='POI',
// 	INSIGHT='INSIGHTS',
// 	ITINERARY='ITINERARIES',
// 	BEACON='BEACONS',
// 	STAGE='STAGES',
// }

export enum USER_ROLE {
	SUPERADMIN,
	ADMIN,
}

export enum poiType {
	POI = "Punto d'interesse",
	AC = "Attività commerciale",
}

export enum TypologicalEnum {
	POI_CATEGORY = "POI_CAT",
	POI_SUB_CATEGORY = "POI_SUB_CAT",
	POI_SERVICES = "POI_SERV",

	//ITINERARY
	ITINERARY_CATEGORY = "ITINER_CAT",
	ITINERARY_SUB_CATEGORY = "ITINER_SUB_CAT",

	//INSIGHT
	INSIGHT_CATEGORY = "INSIGHT_CAT",

	//INSIGHT
	STAGE_CATEGORY = "TRK_CAT",
}

export type TypologicType = {
	key: string;
	label: string;
};

export type LangField = {
	lang: string;
	name: string;
	subtitle: string | undefined;
	description: string | undefined;
};

export const langField: LangField = {
	lang: "",
	name: "",
	subtitle: "",
	description: "",
};

export enum ApiMethod {
	POST = "post",
	PATCH = "patch",
	PUT = "put",
	DELETE = "delete",
	GET = "get",
}

export enum TokenKeys {
	ACCESS_TOKEN = "aToken",
	REFRESH_TOKEN = "rToken",
	//..OTHER, e.g. expiration
}

import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";

import { FlagImage } from "react-international-phone";

import { LangField, SupportedDynamicLanguageEnum } from "../../type/commons-artplace";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import EditIcon from "@mui/icons-material/Edit";

interface LanguageSelectorInterface {
	currentLanguage: string;
	setCurrentLanguage: any;
	formik: any;
}
const EntityLanguageSelector = ({ currentLanguage, setCurrentLanguage, formik }: LanguageSelectorInterface) => {
	const { handleLanguageChange, correctLanguageValue } = EntityUtilities();

	return (
		<FormControl fullWidth variant="outlined">
			<InputLabel>{t("FORM.LANGUAGE")}</InputLabel>
			<Select
				label={t("FORM.LANGUAGE")}
				name="language"
				value={currentLanguage}
				onChange={(e) => handleLanguageChange(e, formik.values.langFields, setCurrentLanguage)}
				// style={{ borderRadius: 8 }}
			>
				{Object.entries(SupportedDynamicLanguageEnum).map(([key, value]) => {
					const isLanguagePresent = formik.values.langFields?.some((langField: LangField) => langField.lang === value);
					return (
						<MenuItem key={key} value={value}>
							{/* <div style={{ display: "flex", alignItems: "center" }}> */}
							<FlagImage iso2={correctLanguageValue(value)} style={{ marginRight: 10 }} />
							{t(`SUPPORTED_LANGUAGES.${key}`)} {isLanguagePresent && <EditIcon fontSize="small" />}
							{/* </div> */}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default EntityLanguageSelector;

import Topbar from "./componets/global/Topbar";
import Sidebar from "./componets/global/Sidebar";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styles from "./App.module.css";
import Login from "./modules/login/Login";

import { Box, Button, Card, Modal, ThemeProvider } from "@mui/material";
import theme from "./theme";
import NuovaPassword from "./modules/login/reset-password/nuova-password/NuovaPassword";

import { PrivateRoute, RoleBasedRoute } from "./componets/private-route/Private-route";
import RecoverPassword from "./modules/login/recover-password/RecoverPassword";
import { RegistryRoleEnum } from "./type/enum/registry-role.enum";
import PoiList from "./modules/artplace-poi/poi-list/PoiList";
import PoiDetail from "./modules/artplace-poi/poi-detail/PoiDetail";
import PoiAdd from "./modules/artplace-poi/poi-add/PoiAdd";
import InsightList from "./modules/artplace-insight/insight-list/InsightList";
import InsightDetail from "./modules/artplace-insight/insight-detail/InsightDetail";
import CustomerList from "./modules/artplace-customer/customer-list/CustomerList";
import StagesDetailAdd from "./modules/artplace-stages/stages-detail-add/StagesDetailAdd";
import StagesList from "./modules/artplace-stages/stages-list/StagesList";
import BeaconView from "./modules/artplace-beacon/BeaconView";
import BeaconAdd from "./modules/artplace-beacon/BeaconAdd";
import BeaconDetail from "./modules/artplace-beacon/BeaconDetail";

import ItineraryAdd from "./modules/artplace-itineraries/itinerary-add/ItineraryAdd";
import ItineraryDetail from "./modules/artplace-itineraries/itinerary-detail/ItineraryDetail";
import GoogleWrapper from "./componets/googleWrapper/GoogleWrapper";
import CustomerDetail from "./modules/artplace-customer/customer-add/CustomerAddDetail";
import ItineraryList from "./modules/artplace-itineraries/itinerary-list/ItineraryList";
import { useContext, useEffect } from "react";
import { UserStore, UserStoreContext } from "./stores/UserStore";
import { TypologicStoreContext } from "./stores/TypologicStore";
import { fetchTypologics } from "./utils/call-api";
import ConfirmPassword from "./modules/login/reset-password/ResetPassword/ConfirmPassword";
import ConfirmRegistration from "./modules/login/confirm-email/ConfirmRegistration";
import ConfirmationEmail from "./modules/login/confirm-email/ConfirmationEmail";
import News from "./modules/news/News";
import { BE_LINKS } from "./api/api";
import NewsList from "./modules/news/news-list/NewsList";
import InfoMe from "./componets/info-me/InfoMe";
import { TokenKeys } from "./type/commons-artplace";
import { observer } from "mobx-react-lite";
import { logout } from "./utils/util";
import Footer from "./componets/legalFooter/LegalFooter";
import axiosInterceptor from "./api/axios-interceptor";
import { ToastContainer } from "react-toastify";
//TODO: Modificare le route in inglese?

/**
 * App is the main functional component in the codebase.
 *
 * This function defines the routes and navigation paths used within the application.
 *
 * The ThemeProvider wraps the entire application and provides a unified theme throughout.
 *
 * The Router component is responsible for managing and rendering routes.
 * For each path, it defines a corresponding DOM element to be rendered.
 * For example, a path of '/login' will render the Login element and a path of '/registrazione' will render the SignUp element.
 *
 * It's important to notice that the path '/' is redirected to '/login' using the Navigate component.
 * Also, all undefined paths are directed towards the MainLayout component.
 *
 * Please keep in mind that the routes and paths presented represent the main structure of the application and are vital for its performance.
 * Any changes should be carefully considered and tested thoroughly to avoid disruption of the navigation functionalities.
 */

function App() {
	const userStore: UserStore = useContext(UserStoreContext);
	const typologicStore = useContext(TypologicStoreContext);
	console.log("process.env.NODE_ENV", process.env.NODE_ENV);

	useEffect(() => {
		const fetchData = async () => {
			if (localStorage.getItem(TokenKeys.ACCESS_TOKEN)) {
				userStore.setIsAuthenticated(true);
				const typologics = await fetchTypologics(BE_LINKS[userStore.getUserRole()].API_TYPOLOGICS); //fetch tipologiche

				if (typologics) {
					typologicStore.setTypologics(typologics); //setta nello store le tipologiche
					typologicStore.setTypologicsReady(); // flag che segnala che le tipologiche sono state impostate
				}
			}
		};

		fetchData();
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<GoogleWrapper>
				<Routes>
					<Route path="/" element={localStorage.getItem(TokenKeys.ACCESS_TOKEN) ? <Navigate to="/poi/view" /> : <Navigate to="/login" />} />
					<Route path="/login" element={<Login />} />
					<Route path="/*" element={<MainLayout />} />
					{/* verificare */}
					<Route path="/confirm-registration" element={<ConfirmRegistration />} />
					<Route path="/reset-password/:token" element={<NuovaPassword />} />
					<Route path="/recover-password" element={<RecoverPassword />} />
					{/* <Route path="/confirm-password" element={<ConfirmPassword />} /> */}
					<Route path="/confirm-password/:validToken" element={<ConfirmPassword />} />
					<Route path="/confirm-email" element={<ConfirmationEmail />} />
				</Routes>
			</GoogleWrapper>
		</ThemeProvider>
	);
}

function MainLayout() {
	const userStore: UserStore = useContext(UserStoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	axiosInterceptor(userStore, navigate);
	const showSidebarAndTopbar = location.pathname !== "/page-not-found";

	return (
		<div className={styles.app}>
			{showSidebarAndTopbar && <Sidebar />}
			<main className={styles.content}>
				<Box
					// height="100vh"
					sx={{ minHeight: "calc(100vh - 35px)" }}
				>
					<Topbar />
					<Routes>
						{/* ROTTE SUPERADMIN */}
						<Route element={<PrivateRoute />}>
							{/*  ROTTE ADMIN */}

							{/*  ROTTE SUPERADMIN-ADMIN */}
							<Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.SUPERADMIN, RegistryRoleEnum.ADMIN]} />}>
								{/*  Dashboard*/}
								{/* <Route path="/dashboard" element={<div>Dashboard</div>} /> */}
								{/* Itinerari */}
								<Route path="/itineraries/view" element={<ItineraryList />} />
								<Route path="/itineraries/detail/:id" element={<ItineraryDetail />} />
								<Route path="/itineraries/add" element={<ItineraryAdd />} />
								{/* Tappe */}
								<Route path="/stages/view" element={<StagesList />} />
								<Route path="/stages/detail/:id" element={<StagesDetailAdd />} />
								{/* POI */}
								<Route path="/poi/view" element={<PoiList />} />
								<Route path="/poi/detail/:id" element={<PoiDetail />} />
								<Route path="/poi/add" element={<PoiAdd />} /> {/* TODO: Dovrà essere unico componente PoiDetail */}
								{/* APPROFONDIMENTI */}
								<Route path="/insights/view" element={<InsightList />} />
								<Route path="/insights/detail/:id" element={<InsightDetail />} />
								<Route path="/insights/add" element={<InsightDetail />} />
								{/* BEACONS */}
								<Route path="/beacons/view" element={<BeaconView />} />
								<Route path="/beacons/detail/:id" element={<BeaconDetail />} />
								<Route path="/beacons/add" element={<BeaconAdd />} />
								{/* CUSTOMERS */}
								{/* <Route path="/customers/view" element={<CustomerList />} />
							<Route path="/customers/add" element={<CustomerDetail />} />
							<Route path="/customers/detail/:id" element={<CustomerDetail />} /> */}
								{/* NEWS */}
								{/* 	<Route path="/news/view" element={<NewsList />} />
							<Route path="/news/add" element={<News />} />
							<Route path="/news/detail/:id" element={<News />} /> */}
								{/* ESEMPIO DATAGRID*/}
								<Route path="/profile" element={<InfoMe />} />
							</Route>

							{/*  ROTTE ADMIN  */}
							<Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.SUPERADMIN]} />}>
								{/* NEWS */}
								<Route path="/news/view" element={<NewsList />} />
								<Route path="/news/add" element={<News />} />
								<Route path="/news/detail/:id" element={<News />} />
								{/* CUSTOMERS */}
								<Route path="/customers/view" element={<CustomerList />} />
								<Route path="/customers/add" element={<CustomerDetail />} />
								<Route path="/customers/detail/:id" element={<CustomerDetail />} />
							</Route>
						</Route>
					</Routes>
				</Box>
				<Footer />
				{/* <Modal
					open={userStore.getLogoutModal}
					//onClose={(reason) => userStore.setLogoutModal(false)}
					aria-labelledby="modal-title"
					aria-describedby="modal-description"
				>
					<Card
						sx={{
							position: "absolute",
							width: 400,
							p: 4,
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mt: 2 }}>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									logout()
										.then(() => {
											userStore.setLogoutModal(false);
											navigate("/login");
											localStorage.clear();
										})
										.catch((error) => {
											userStore.setLogoutModal(false);
											navigate("/login");
											localStorage.clear();

										});
								}}
							>
								Ok, riportami al login
							</Button>
						</Box>
					</Card>
					
				</Modal> */}
			</main>
		</div>
	);
}
export default observer(App);

import { FormikErrors } from "formik";
import { ChangeEvent } from "react";
import { LangField, SocialInterface, langField } from "../../type/commons-artplace";
import { PoiDetailInterface } from "../../type/rest/poi-rest-interfaces";
import { Chip, SelectChangeEvent } from "@mui/material";
import { attachedFile, attachedFileInfo, formattedAttachedFile, formattedLanguageFields } from "../../type/rest/insight-rest-interfaces";
import { NotificationStatus } from "../../type/rest/news-rest-interface";
import { useTranslation } from "react-i18next";
import no_pic from "../../img/no_pic.jpg";

const EntityUtilities = () => {
	const { t } = useTranslation();

	const statusLabels = {
		draft: t("COMMONS.DRAFT"),
		published: t("COMMONS.PUBLISHED"),
		scheduled: t("COMMONS.SCHEDULED"),
		now: t("COMMONS.NOW"),
		error: t("COMMONS.ERROR"),
	};

	const handleNameChange = (
		currentLanguage: string,
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		values: LangField[] | undefined,
		callBack: (field: string, value: any) => Promise<void | FormikErrors<Partial<PoiDetailInterface>>>,
	) => {
		values?.forEach((element: LangField, index: number) => {
			if (element.lang === currentLanguage) {
				callBack(`langFields[${index}].name`, event.target.value);
			}
		});
	};
	const handleSubtitleChange = (
		currentLanguage: string,
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		values: LangField[] | undefined,
		callBack: (field: string, value: any) => Promise<void | FormikErrors<Partial<PoiDetailInterface>>>,
	) => {
		values?.forEach((element: LangField, index: number) => {
			if (element.lang === currentLanguage) {
				callBack(`langFields[${index}].subtitle`, event.target.value);
			}
		});
	};

	const handleDescriptionChange = (
		currentLanguage: string,
		value: string,
		values: LangField[] | undefined,
		callBack: (field: string, value: any) => Promise<void | FormikErrors<Partial<PoiDetailInterface>>>,
	) => {
		values?.forEach((element: LangField, index: number) => {
			if (element.lang === currentLanguage) {
				callBack(`langFields[${index}].description`, value);
			}
		});
	};
	const correctLanguageValue = (value: string) => {
		switch (value) {
			case "ja":
				return "jp";
			case "zh":
				return "cn";
			case "uk":
				return "ua";
			case "en":
				return "gb";
			default:
				return value;
		}
	};
	const handleLanguageChange = (e: SelectChangeEvent, langFields: LangField[] | undefined, setCurrentLanguage: (newValue: string) => void) => {
		setCurrentLanguage(e.target.value);
		const result = langFields?.find((element) => element.lang === e.target.value);
		//Se non lo trova, lo crea con il nome del primo elemento (o il trattino) e lo mette nell'array di langFields di Formik
		if (!result) langFields?.push({ ...langField, name: langFields[0].name !== "" ? langFields[0].name : "-", lang: e.target.value });

		setCurrentLanguage(e.target.value);
	};

	const getIndexFromLangFields = (langFields: LangField[] | undefined, currentLanguage: string): number => {
		if (!langFields) return 0; // Handle the case when langFields is undefined
		const index = langFields.findIndex((element: LangField) => element.lang === currentLanguage);
		return index !== -1 ? index : 0;
	};

	const getIndexFromSocialsFields = (socials: SocialInterface[] | undefined, type: string): number => {
		if (!socials) return -1; // Handle the case when langFields is undefined
		const index = socials.findIndex((element: SocialInterface) => {
			return element.type === type;
		});
		return index !== -1 ? index : -1;
	};

	const formatLangFields = (langFields: LangField[]) => {
		const result: formattedLanguageFields = {};
		langFields.forEach((field: LangField) => {
			result[field.lang] = {
				name: field.name ? field.name : t("COMMONS.NO_VALUE"),
				subtitle: field.subtitle ?? "",
				description: field.description ?? "",
			};
		});
		return result;
	};

	const formatLangFieldFile = (langField: attachedFile[]) => {
		const result: any = {};
		langField.forEach((field: attachedFile) => {
			result[field.lang] = {
				...field.file,
				name: field.name,
			};
		});

		return result;
	};

	const ChipStatusSwitch = (status: any) => {
		switch (status) {
			case NotificationStatus.DRAFT:
				return <Chip label={statusLabels.draft} color="warning" variant="filled" style={{ width: "auto" }} />;
			case NotificationStatus.PUBLISHED:
				return <Chip label={statusLabels.published} color="success" variant="filled" style={{ width: "auto" }} />;
			case NotificationStatus.SCHEDULED:
				return <Chip label={statusLabels.scheduled} color="info" variant="filled" style={{ width: "auto" }} />;
			case NotificationStatus.NOW:
				return <Chip label={statusLabels.now} color="info" variant="filled" style={{ width: "auto" }} />;
			case NotificationStatus.ERROR:
				return <Chip label={statusLabels.error} color="error" variant="filled" style={{ width: "auto" }} />;
			default:
				return "";
		}
	};

	function convertToAttachedFiles(formattedFiles: any): attachedFile[] {
		return Object.keys(formattedFiles).map((lang) => ({
			lang: lang,
			file: formattedFiles[lang],
		}));
	}

	const createUrlFile = (fileUrl: string | undefined) => {
		// console.log("FileUrl dentro createUrlFile_ ", fileUrl);
		if (fileUrl) {
			if (fileUrl.startsWith("https") || fileUrl.startsWith("blob")) return fileUrl;
			else return process.env.REACT_APP_CLOUD_PHOTO_URL ? process.env.REACT_APP_CLOUD_PHOTO_URL + fileUrl : fileUrl;
		} else {
			return no_pic;
		}
	};

	return {
		handleNameChange,
		handleSubtitleChange,
		handleDescriptionChange,
		handleLanguageChange,
		correctLanguageValue,
		getIndexFromLangFields,
		formatLangFields,
		getIndexFromSocialsFields,
		ChipStatusSwitch,
		formatLangFieldFile,
		convertToAttachedFiles,
		createUrlFile,
	};
};

export default EntityUtilities;

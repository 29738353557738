import React, { useContext, useEffect, useState } from "react";
import { Box, Chip } from "@mui/material";
// import styles from "./PoiList.module.css";
import "../../../colors.css";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CenteredImageStyle from "../../../componets/centeredImage/CenteredImage.module.css";
import { PoiDetailInterface } from "../../../type/rest/poi-rest-interfaces";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import GridColumns from "../../../componets/data-table/gridColumns/GridColumns";
import { DATATYPE } from "../../../type/commons-artplace";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import "../../../componets/dataGrid/DataGridStyles.css";

export const PoiRelatedPoiList: React.FC<{ relatedPois: PoiDetailInterface[] }> = ({ relatedPois }) => {
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const currentLanguage: string = languageStore.getGlobalLanguage;
	const { formatLangFields,createUrlFile } = EntityUtilities();
	const [loading, setLoading] = useState<boolean>(false);
	const [rows, setRows] = useState<any[]>([]);
	const columns = GridColumns(DATATYPE.POI, currentLanguage, createUrlFile);
	const navigate = useNavigate();
	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			try {
				/* console.log("insisghts:", insights); */

				const formattedRows = relatedPois.map((item: PoiDetailInterface) => ({
					...item,
					id: item._id,
					name: formatLangFields(item.langFields),
				}));
				setRows(formattedRows);
			} catch (error) {
				/* 	toast.error(t("MESSAGES.CALL_ERROR")); */
				console.error("Error fetching data:", error);
				setRows([]);
			} finally {
				setLoading(false);
			}
		};
		getData();
	}, [relatedPois]);

	return (
		<>
			<Box
				sx={{
					minHeight: "150px",
					width: "100%",
					"& .MuiDataGrid-columnHeaders": { backgroundColor: "#EFF5F8" },
					"& .MuiDataGrid-cell:focus-within": { outline: 0 },
				}}
			>
				<DataGrid
					/* getRowId={(row) => row._id} */
					autoHeight={rows && rows.length > 0 ? false : true}
					getRowHeight={() => "auto"}
					style={{border: "none", maxHeight: "500px"}}
					localeText={{ noRowsLabel: t("TABLE.NO_RESULTS") }}
					rows={rows}
					// rowHeight={120}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					// disableRowSelectionOnClick
				/>
			</Box>
		</>
	);
};

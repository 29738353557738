import { PathItem } from "../../../data/stages.mockup";
import SingleCard from "../../../componets/pageComponents/ImageCard/SingleCard";
import { t } from "i18next";
import { Box, Grid, Typography } from "@mui/material";
import "./StagePath.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState } from "react";
import AttachedButtons from "../../artplace-insight/insight-add/AttachedFileButtons";
import GeneralModal from "../../../componets/generalModal/GeneralModal";
import { PoiFormattedInterface, PoiInterface } from "../../../type/rest/poi-rest-interfaces";
import { formattedLanguageFields } from "../../../type/rest/insight-rest-interfaces";
import { TypologicType, Coordinates } from "../../../type/commons-artplace";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";

interface props {
	pois: PoiFormattedInterface[];
	setPois: any;
	navigation: (id: string) => void;
	currentLng: string;
}

function StagePath({ pois, setPois, navigation, currentLng }: props) {
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [poiToDelete, setPoiToDelete] = useState<string>("");
	const [newPath, setNewPath] = useState(pois);
	const { createUrlFile } = EntityUtilities();
	const onDelete = (id: string) => {
		const filteredList = pois.filter((item) => item._id !== id);
		setPois(filteredList);
	};

	return (
		<>
			<GeneralModal
				open={openDeleteModal}
				onclickConfirm={() => {
					onDelete(poiToDelete);
					setOpenDeleteModal(false);
				}}
				onClose={() => setOpenDeleteModal(false)}
				onClickCancel={() => setOpenDeleteModal(false)}
			>
				<Typography>{`${t("COMMONS.DELETE_ENTITY_MESSAGE_M")} ${t("SIDEBAR.POI")}?`} </Typography>
			</GeneralModal>
			<Box sx={{ pt: 2 }}>
				<Grid container item spacing={2}>
					<Grid item xs={1} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<FiberManualRecordIcon fontSize="small" />
						<div style={{ borderRight: 2, borderRightStyle: "dotted", width: 0, height: "90%" }}></div>
						<FiberManualRecordIcon fontSize="small" sx={{ color: "gray" }} />
					</Grid>
					<Grid item xs={11}>
						<Typography fontWeight={"bold"}>{t("STAGES.DETAIL.START")}</Typography>
						<Box sx={{ pb: 1 }}>
							{pois.map((el) => (
								<>
									<SingleCard
										options={
											<AttachedButtons
												onDelete={() => {
													setPoiToDelete(el._id);
													setOpenDeleteModal(true);
												}}
											/>
										}
										id={el._id}
										// cover={el.photos[0]}
										description={el.category ? t(el.category.label) : ""}
										name={el.name?.[currentLng]?.name ?? el.name?.["en"]?.name ?? t("COMMONS.NO_VALUE")}
										chip={el.subcategory ? t(el.subcategory.label) : ""}
										navigation={() => navigation(el._id)}
										cover={createUrlFile(el.photos[0])}
									/>
								</>
							))}
						</Box>
						<Typography color={"gray"} fontWeight={"bold"}>
							{t("STAGES.DETAIL.END")}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default StagePath;

import React, { useState } from "react";
import { Typography, List, ListItem, ListItemText, Box, Grid, Icon, IconButton, TextField, Switch, Divider } from "@mui/material";
import { OpeningsInterface } from "../../../type/commons-artplace";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Edit } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";

interface OpeningHoursProps {
	openingHours: OpeningsInterface;
	setOpeningHours: (field: string, value: any, shouldValidate?: boolean) => void;
	onRequest: boolean;
}

interface FormattedInput {
	[key: string]: { hourOpening: Date; hourClosed: Date };
}

export const OpeningHours: React.FC<OpeningHoursProps> = ({ openingHours, setOpeningHours, onRequest }) => {
	const { t } = useTranslation();
	const [opened, setOpened] = useState<{ [key: string]: boolean }>(() => {
		const initialCheckedItems: { [key: string]: boolean } = {};
		Object.keys(openingHours).forEach((item) => {
			initialCheckedItems[item] = !!openingHours[item].hourOpening && !!openingHours[item].hourClosed;
		});
		return initialCheckedItems;
	});

	const handleSwitch = (day: string) => {
		setOpened((prevOpened) => ({
			...prevOpened,
			[day]: !prevOpened[day],
		}));
	};

	const formattedInput: FormattedInput = Object.keys(openingHours).reduce(
		(result, day) => {
			const from: string | undefined = openingHours[day].hourOpening;
			const to: string | undefined = openingHours[day].hourClosed;

			if (from && to) {
				const [hoursFrom, minutesFrom] = from.split(":").map(Number);
				const [hoursTo, minutesTo] = to.split(":").map(Number);

				const dateFrom = new Date();
				dateFrom.setHours(hoursFrom, minutesFrom, 0, 0);

				const dateTo = new Date();
				dateTo.setHours(hoursTo, minutesTo, 0, 0);

				(result as any)[day] = {
					hourOpening: dateFrom,
					hourClosed: dateTo,
				};
			} else {
				(result as any)[day] = {
					hourOpening: null,
					hourClosed: null,
				};
			}

			return result;
		},
		{} as { [key: string]: { hourOpening: Date; hourClosed: Date } },
	);

	const handleTimeChange = (day: string, field: string, value: Date | null) => {
		const stringValue = value ? `${value.getHours()}:${value.getMinutes()}` : "";
		setOpeningHours(`openings.${day}.${field}`, stringValue);
	};
	const handleResetHours = (day: string) => {
		setOpeningHours(`openings.${day}.hourOpening`, "");
		setOpeningHours(`openings.${day}.hourClosed`, "");
	};

	return (
		<Grid container item xs={12} sm={12} alignItems={"center"}>
			{!onRequest ? (
				Object.entries(openingHours).map(([day, hours], index) => (
					<React.Fragment key={index}>
						{/* <Grid flexDirection={"row"} xs={6} sm={5}> */}
						<Grid container>
							<Grid item xs={6} md={6} xl={3} sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
								{t(day).charAt(0).toUpperCase() + t(day).slice(1)}
							</Grid>
							<Grid item xs={6} md={6} xl={3} sx={{ fontWeight: "bold" }} pt={1}>
								<Switch
									checked={opened[day]}
									onChange={() => {
										handleSwitch(day);
										handleResetHours(day);
									}}
								/>
							</Grid>

							{opened[day] ? (
								<>
									<Grid item xs={6} md={6} xl={3} /* spacing={2} */ pt={1}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<TimePicker /* onChange={(newValue: any) => {
											setDuration(newValue);
											formik.setFieldValue("durationSeconds", formatTimeData(newValue));
										}} */
												name={`openings.${day}.hourOpening`}
												ampm={false}
												views={["hours", "minutes"]}
												format="HH:mm"
												value={formattedInput[day].hourOpening}
												onChange={(value) => handleTimeChange(day, "hourOpening", value)}
												sx={{ marginRight: 1 }}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={6} md={6} xl={3} /* spacing={2} */ pt={1}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<TimePicker
												/* onChange={(newValue: any) => {
											setDuration(newValue);
											formik.setFieldValue("durationSeconds", formatTimeData(newValue));
										}} */
												name={`openings.${day}.hourClosed`}
												ampm={false}
												views={["hours", "minutes"]}
												format="HH:mm"
												value={formattedInput[day].hourClosed}
												onChange={(value) => handleTimeChange(day, "hourClosed", value)}
												sx={{ marginLeft: 1 }}
											/>
										</LocalizationProvider>
									</Grid>
								</>
							) : (
								<Grid item xs={12} md={12} xl={6} spacing={2} sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
									<Box>{t("COMMONS.CLOSED")}</Box>
								</Grid>
							)}
						</Grid>
						{/* </Grid> */}
						{/* 						<Divider sx={{ backgroundColor: "#525252", marginTop: 1 }} />
						 */}{" "}
					</React.Fragment>
				))
			) : (
				<Typography padding={2}>{t("POI.DETAIL.REQUEST_DESCRIPTION")}</Typography>
			)}
		</Grid>
	);
};

import React, { useState, useEffect, ReactNode } from "react";
import { Autocomplete, GoogleMap, InfoWindow, LoadScript, Marker, Polyline, StandaloneSearchBox } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

// type GoogleMapsCardProps = {
//       address?: string;
//       lat?: never;
//       lng?: never;
// } | {
//       address?: string;
//       lat: number;
//       lng: number;
//     } ;

type GoogleMapsCardProps = {
	lat?: number;
	lng?: number;
	address?: string;
	setCoordinates?: any;
	stagesList?: any;
	children?: ReactNode;
};

const mapContainerStyle = {
	width: "100%",
	height: "280px",
	borderRadius: 8,
};




const GoogleMapsCard: React.FC<GoogleMapsCardProps> = ({ lat, lng, address, setCoordinates, stagesList, children }) => {
	// console.log(lat,lng,address)
	const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: lat ?? 41.9027835, lng: lng ?? 12.4963655 });
	const [showInfoWindow, setShowInfoWindow] = useState(false);

	const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

	const handleMarkerClick = () => {
		setShowInfoWindow(true);
	};

	const handleInfoWindowClose = () => {
		setShowInfoWindow(false);
	};

	const handlePolylineMarkerOpen = (index: number) => {
		//setShowInfoWindow(true);
		setSelectedMarker(index);
	};

	const handlePolylineMarkerClose = () => {
		//setShowInfoWindow(false);
		setSelectedMarker(null);
	};
	/* 	console.log("dati dentro google maps card: ", lat, lng, address, setCoordinates, stagesList, children);
	 */ useEffect(() => {
		const getLatLngFromAddress = async () => {
			/* if (!address && !lat && !lng) {
				//TODO: Non funziona al 100%, refactorizzare
				if (stagesList) {
					setCenter({
						lat: stagesList[0][0].lat,
						lng: stagesList[0][0].lng,
					});
				}
			} */
			/* if (!address ||  ((lat ?? false) && (lng ?? false))) {
			   if(lat && lng)  setCenter({ lat: lat, lng: lng });
			   else setCenter({ lat: 41.9027835, lng: 12.4963655 });    //centro di roma
			   return;
			 } */

			if (!address) return;

			try {
				//   const baseUrl = process.env.REACT_APP_GOOGLE_MAPS_URL;
				/* 				console.log("Attempt geocoding:", address);
				 */ const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);

				if (response.ok) {
					const data = await response.json();

					const location = data.results[0].geometry.location;
					setCenter({ lat: location.lat, lng: location.lng });
					setCoordinates("latlng.lat", location.lat);
					setCoordinates("latlng.lng", location.lng);
				}
			} catch (error) {
				console.error("Errore nella richiesta di geocodifica:", error);
			}
		};

		getLatLngFromAddress();
	}, [address]);

	const handleMapClick = () => {
		const googleMapsUrl = `https://www.google.com/maps/?q=${center.lat},${center.lng}`;
		//	window.open(googleMapsUrl, "_blank");
	};
	return (
		<Box mt={2} borderRadius={5}>
			{/* <h5 className="card-title">Indirizzo</h5>
			<p className="card-text">{address}</p>
			<p className="card-text">{`latitudine: ${center.lat} - longitudine: ${center.lng}`}</p>
			<p className="card-text">{`latlng: { lat: ${center.lat}, lng: ${center.lng}}`}</p> */}
			<div style={mapContainerStyle}>
				<GoogleMap mapContainerStyle={mapContainerStyle} zoom={15} center={center} onClick={handleMapClick}>
					{stagesList ? (
						<>
							<Box mt={2} borderRadius={5}>
								<div style={mapContainerStyle}>
									{stagesList &&
										stagesList.map((stage: any, index: any) => (
											<React.Fragment key={index}>
												{Array.isArray(stage) ? (
													<>
														<Marker position={stage[0]} label={(index + 1).toString()} onClick={() => handlePolylineMarkerOpen(index)}>
															{selectedMarker === index && (
																<InfoWindow onCloseClick={handlePolylineMarkerClose}>
																	<div>
																		<h6>Inizio tappa {index + 1}</h6>
																		<p>Latitudine: {stage[0].lat}</p>
																		<p>Longitudine: {stage[0].lng}</p>
																	</div>
																</InfoWindow>
															)}
														</Marker>
														<Polyline
															path={stage}
															options={{
																strokeColor: "#FF0000",
																strokeOpacity: 1.0,
																strokeWeight: 2,
															}}
														/>
													</>
												) : (
													<Polyline
														path={stagesList}
														options={{
															strokeColor: "#FF0000",
															strokeOpacity: 1.0,
															strokeWeight: 2,
														}}
													/>
												)}
											</React.Fragment>
										))}
								</div>
							</Box>
						</>
					) : (
						<Marker position={center} onClick={handleMarkerClick}>
							{showInfoWindow && (
								<InfoWindow onCloseClick={handleInfoWindowClose}>
									<div>
										<p>
											<h4>Coordinate:</h4>
										</p>
										<p>Latitudine: {center.lat}</p>
										<p>Longitudine: {center.lng}</p>
									</div>
								</InfoWindow>
							)}
						</Marker>
					)}
					{children && children}
				</GoogleMap>
			</div>
		</Box>
	);
};

export default GoogleMapsCard;

import { Box, Card, CardContent, Modal, IconButton, Typography, Grid, Paper, CircularProgress } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
// import "./InsightAdd.css";
import SectionContainer from "./sectionContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import AddButton from "../../../componets/buttons/AddButton";
import Carousel from "react-material-ui-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import GeneralModal from "../../../componets/generalModal/GeneralModal";
import theme from "../../../theme";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { deletePhoto, uploadPhoto } from "../../../utils/call-api";
import { useParams } from "react-router-dom";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { NAVIGATION_DATA } from "../../../type/commons-artplace";
import { toast } from "react-toastify";
const styles = {
	scrollBarStyleHidden: {
		"&::-webkit-scrollbar": {
			width: "0.2em",
			height: "0.4em",
		},
		"&::-webkit-scrollbar-track": {
			width: 10,
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: 8,
			backgroundColor: "#b6bcc2",
			minHeight: 24,
		},
	},
	imagesContainer: { overflowX: "auto", width: "100%", display: "inline-flex" },
	imageStyle: { width: "23%", aspectRatio: "1/1", objectFit: "cover", padding: 2, borderRadius: 12, margin: "4% 0.8%" },
	coverImage: { width: "100%", position: "relative", aspectRatio: "9/6", objectFit: "cover", marginBottom: "2%" },
};

interface photoProps {
	photos: string[] | [];
	setPhotoList: any; // Funzione di callback senza parametri
	resetFiles: (action: any) => void;
	navigationData: NAVIGATION_DATA;
	setEntity?: any;
	error?: boolean;
	setIsUploading?: any;
}

const PhotoDropZone = ({ photos, setPhotoList, error, resetFiles, navigationData, setEntity, setIsUploading }: photoProps) => {
	const { t } = useTranslation();
	const { createUrlFile } = EntityUtilities();
	const [fileList, setFileList] = useState<File[]>([]);
	const [urlList, setUrlList] = useState<string[] | []>(photos.map((photo) => createUrlFile(photo) || ""));
	const [bigImage, setBigImage] = useState<{ url: string; index: number }>({ url: urlList[0], index: 0 } || "");
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [visible, setVisible] = useState<boolean>(false);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const redColor = theme.palette.error.light;
	const { id } = useParams();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			const newUrlList = acceptedFiles.map((file) => URL.createObjectURL(file));
			setUrlList((prevUrlList) => newUrlList.concat(prevUrlList));
			const filesList = acceptedFiles.concat(fileList);
			setFileList(() => filesList);
			setPhotoList(() => filesList);
			if (id) {
				setIsUploading(() => true);
				await uploadPhoto(filesList, id, navigationData, userRole)
					.then((response) => {
						//setEntity(response); //aggiorna l'entità appena modificata
						setUrlList(response.photos);
						setBigImage({ url: createUrlFile(response.photos[0])!, index: 0 });
						toast.success(t("MESSAGES.UPLOAD_OK"));
						setFileList([]);
					})
					.catch((error): void => {
						toast.error(t("MESSAGES.UPLOAD_ERROR"));
						console.error("Errore nella richiesta:", error);
					})
					.finally(() => {
						setIsUploading(() => false);
					});
			} else {
				if (newUrlList.length > 0) {
					setBigImage({ url: newUrlList[0], index: 0 });
				}
			}
		},
		[setPhotoList, fileList],
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/*": [".png", ".jpg", ".jpeg"],
		},
	});

	function rearrangeArray(array: string[], indice: number) {
		const newArray = array.slice(indice).concat(array.slice(0, indice));
		return newArray;
	}

	function removeItem<T>(arr: Array<T>, index: number): Array<T> {
		if (index > -1) {
			arr.splice(index, 1);
		}
		return arr;
	}

	return (
		<>
			<GeneralModal
				open={openDeleteModal}
				onclickConfirm={async () => {
					if (id) {
						await deletePhoto(bigImage.url, id, navigationData, userRole)
							.then((response: any) => {
								//setEntity(response); //aggiorna l'entità appena modificata
								setUrlList(removeItem(urlList, bigImage.index));
								setBigImage({ url: urlList[0], index: 0 });
								setOpenDeleteModal(false);
								toast.success(t("MESSAGES.ELIMINATION_OK"));
								setIsUploading(() => false);
							})
							.catch((error) => {
								setOpenDeleteModal(false);
								toast.error(t("MESSAGES.ELIMINATION_ERROR"));
								console.error("Errore nella richiesta:", error);
							})
							.finally(() => {
								setIsUploading(() => false);
							});
					} else {
						const updatedPhotos: File[] = fileList.slice(0, bigImage.index).concat(fileList.slice(bigImage.index + 1)); //rimuove l'immagine eliminata dall'array delle foto da caricare
						setPhotoList(updatedPhotos);
						setFileList(updatedPhotos);
						setUrlList(removeItem(urlList, bigImage.index));
						setBigImage({ url: urlList[0], index: 0 });
						setOpenDeleteModal(false);
					}
				}}
				onClose={() => setOpenDeleteModal(false)}
				onClickCancel={() => setOpenDeleteModal(false)}
			>
				<Typography>{`${t("COMMONS.DELETE_ENTITY_MESSAGE_F")} ${t("POI.DETAIL.PHOTO")?.toLowerCase()}?`} </Typography>
			</GeneralModal>
			<Modal
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						width: "50vw",
						top: `50%`,
						margin: "auto",
					}}
				>
					<Carousel
						navButtonsAlwaysVisible={true}
						indicatorContainerProps={{ style: { marginTop: 0, color: "green" } }}
						sx={{
							marginTop: "0px !important",
							color: "red",
							width: "100%",
						}}
						autoPlay={false}
						NextIcon={<ArrowForwardIosIcon />}
						PrevIcon={<ArrowBackIosNewIcon />}
					>
						{rearrangeArray(urlList, bigImage.index).map((url: string, index: number) => (
							<img
								key={index}
								style={{ objectFit: "contain", maxHeight: "700px" }}
								width={"100%"}
								src={createUrlFile(url)}
								id={index.toString()}
								alt="Uploaded"
							/>
						))}
					</Carousel>
				</Box>
			</Modal>
			{urlList.length ? (
				<Card sx={{ height: "100%" }}>
					<CardContent sx={{ mt: 0 }}>
						<SectionContainer name={`${t("INSIGHTS.DETAIL.PHOTO")}`}>
							<Box {...getRootProps()}>
								<input {...getInputProps()} name="foto" />
								{<AddButton onClick={() => null} component={undefined} />}
							</Box>
						</SectionContainer>

						{urlList.length >= 1 && (
							<div style={styles.coverImage as any} onMouseOver={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
								<div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
									<IconButton
										onClick={() => setOpenDeleteModal(true)}
										sx={{
											position: "absolute",
											backgroundColor: "#ffffffa1",
											marginTop: 1,
											zIndex: 1,
											marginRight: 1,
											visibility: visible ? "visible" : "hidden",
										}}
									>
										<DeleteIcon fontSize="medium" color="primary" />
									</IconButton>
								</div>
								{bigImage.url ? (
									<img
										onClick={() => setModalOpen(true)}
										src={createUrlFile(bigImage.url)}
										alt="uploaded"
										style={{ width: "100%", height: "100%", objectFit: "cover" }}
									/>
								) : (
									<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
										<CircularProgress size={50} />
									</div>
								)}
							</div>
						)}
						<Box sx={[styles.imagesContainer, styles.scrollBarStyleHidden]}>
							{urlList &&
								urlList.map((url: string, index: number) => (
									<img
										key={index}
										style={styles.imageStyle as any}
										src={createUrlFile(url)}
										alt="Uploaded"
										onClick={() => {
											setBigImage({ url: urlList[index], index: index });
										}}
									/>
								))}
						</Box>
					</CardContent>
				</Card>
			) : (
				<Card sx={{ height: "100%" }}>
					<CardContent sx={{ mt: 0 }}>
						<SectionContainer name={`${t("INSIGHTS.DETAIL.PHOTO")}`}>
							<Box {...getRootProps()}>
								<input {...getInputProps()} name="foto" />
								{<AddButton onClick={() => null} component={undefined} />}
							</Box>
						</SectionContainer>

						<Box
							{...getRootProps()}
							sx={{
								height: 200,
								border: "2px dashed rgba(0, 0, 0, 0.2)",
								borderRadius: 2,
								backgroundColor: "#d6d6d6",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								"&:hover": {
									borderColor: "primary.main",
								},
							}}
						>
							<input {...getInputProps()} />
							<Grid container direction="column" alignItems="center">
								<Grid item pb={2}>
									<AddPhotoAlternateIcon fontSize="large" />
								</Grid>
								<Grid item container direction="row" justifyContent="center" spacing={1}>
									<Grid item>
										<Typography color="textSecondary">{t("COMMONS.UPLOAD")}</Typography>
									</Grid>
									<Grid item>
										<FileUploadIcon fontSize="small" />
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
				</Card>
			)}
			<Typography color={redColor} sx={error && !!!urlList.length ? { visibility: "visible" } : { visibility: "hidden" }}>
				{t("ERRORS.PHOTOS")}
			</Typography>
		</>
	);
};

export default PhotoDropZone;

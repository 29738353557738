import React from "react";
import { Container, Typography } from "@mui/material";
import "../../../../colors.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export default function ConfirmPassword() {
	const { t } = useTranslation();
	const { validToken } = useParams();
	// console.log(validToken);
	return validToken === "ok" ? (
		<Container
			maxWidth="sm"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				minHeight: "100vh",
				width: "100%",
				textAlign: "center",
			}}
		>
			<CheckCircleOutlineIcon style={{ color: "var(--greenAccent-500)", fontSize: "150px", marginBottom: 5 }} />
			<Typography variant="h2" mb={2} style={{ color: "var(--black)" }}>
				{t("LOGIN.RESET_PASSWORD_CONFIRM_TITLE")}
			</Typography>
			<Typography variant="h6" style={{ color: "var(--black)" }}>
				{t("LOGIN.RESET_PASSWORD_CONFIRM_DESCRIPTION")}
			</Typography>
			{/* <Link to="/login">Torna al Login</Link> */}
		</Container>
	) : (
		<>
			<Container
				maxWidth="sm"
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					width: "100%",
					textAlign: "center",
				}}
			>
				<HighlightOffIcon style={{ color: "var(--red-500)", fontSize: "150px", marginBottom: 5 }} />
				<Typography variant="h2" mb={2} style={{ color: "var(--black)" }}>
					{t("LOGIN.RESET_PASSWORD_EXPIRED_TITLE")}
				</Typography>
				<Typography variant="h6" style={{ color: "var(--black)" }}>
					{t("LOGIN.RESET_PASSWORD_EXPIRED_DESCRIPTION")}
				</Typography>
				{/* <Link to="/recover-password">Riprova</Link> */}
			</Container>
		</>
	);
}

import {
	Card,
	Grid,
	CircularProgress,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Divider,
	useTheme,
	duration,
} from "@mui/material";
import { Marker } from "@react-google-maps/api";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ClientInterface, PoiFormattedInterface } from "../../../type/rest/poi-rest-interfaces";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextEditor } from "../../../componets/textField/textEditor/TextEditor";
import { PathItem } from "../../../data/stages.mockup";
import StagePath from "./StagePath";
import GoogleMapsCard from "../../../componets/googleMapsCard/GoogleMapsCard";
import GPXUploader from "../../artplace-itineraries/GPXUploader";
import AddButton from "../../../componets/buttons/AddButton";
import { Coordinates, DATATYPE, LangField, NAVIGATION_DATA, TypologicType, emptyStage, langField } from "../../../type/commons-artplace";
import AddEntityModal from "../../../componets/modals/addEntityModal/AddEntityModal";
import { get, patch } from "../../../utils/call-api";
import { TypologicStoreContext } from "../../../stores/TypologicStore";
import { BE_LINKS } from "../../../api/api";
import { StageDetailInterface, chipColors } from "../../../type/rest/stage-rest-interface";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import IdName from "../../../componets/pageComponents/IdName/IdName";
import ConnectedItineraries from "../../../componets/pageComponents/connectedItineraries/connectedItineraries";
import PhotoDropZone from "../../artplace-insight/insight-add/photoDropZone";
import { ToastContainer, toast } from "react-toastify";
import { toast_error } from "../../../utils/custom-toast";
import { altitudeDifference, formatTimeData, getEnumKeyByValue, orderCustomersByBusinessName } from "../../../utils/util";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import Ratings from "../../../componets/ratings/Ratings";
import EntityLanguageSelector from "../../../componets/entityLanguageSelector/EntityLanguageSelector";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import { Spinner } from "react-bootstrap";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const StagesDetailAdd = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const [stagesDetail, setStagesDetail] = useState<StageDetailInterface | null>(null);
	const [customers, setCustomers] = useState<ClientInterface[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [relatedPoiList, setRelatedPoiList] = useState<PoiFormattedInterface[]>([]);
	//console.log("relatedPoiList=: ", relatedPoiList);
	const [open, setOpen] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<PoiFormattedInterface[] | PathItem[] | undefined>();
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const navigate = useNavigate();
	const [gpxFileFormData, setGpxFileFormData] = useState<any>();
	const typologicStore = useContext(TypologicStoreContext);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage);
	const { handleDescriptionChange } = EntityUtilities();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const theme = useTheme();
	const [photos, setPhotos] = useState<File[]>([]);
	const { formatLangFields } = EntityUtilities();
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);
	const [categories, setCategories] = useState<TypologicType[]>([]);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);

	const [isUploading, setIsUploading] = useState(false);

	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);
			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};

	const fetchCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getCategories(DATATYPE.STAGE);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchOptions = useCallback(async () => {
		try {
			// Fetch dati per tipologiche POI
			const [categories, clients] = await Promise.all([fetchCategories(), fetchClients()]);

			// Inizializzo le tipologiche del POI
			setCustomers(clients);
			setCategories(categories);

			// Handle other fetched data as needed
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	}, [typologicStore]);

	const fetchStagetDetail = useCallback(async () => {
		setStagesDetail(null);
		// setPoiList(undefined);

		try {
			if (id) {
				let data: any = (await get(BE_LINKS[userRole][NAVIGATION_DATA.STAGE].ALL, id, true)).data;
				if (!data) {
					setStagesDetail(null);
					return;
				}
				//data.langFields = data.langFields.map((field: any) => ({ ...field, name: field.name || t("COMMONS.NO_VALUE") }));
				if (data.langFields.find((langField: LangField) => langField.lang === currentLanguage)) {
					//se esiste già un langFields della lingua corrente OK
					setStagesDetail(data || null);
				} else {
					if (data.langFields.find((langField: LangField) => langField.lang === languageStore.defaultLanguage)) {
						//altrimenti visualizza l'entità in inglese
						setCurrentLanguage(languageStore.defaultLanguage);
						setStagesDetail(data || null);
					} else {
						//altrimenti la prima lingua disponibile nei langFields
						setCurrentLanguage(data.langFields[0].lang);
						setStagesDetail(data || null);
					}
				}
				setStagesDetail(data);
				const dataPois = data.pois || [];
				const formattedRows =
					dataPois &&
					dataPois.map((item: any) => ({
						...item,
						id: item._id,
						name: item.langFields ? formatLangFields(item.langFields) : item.name,
					}));
				setRelatedPoiList(formattedRows || []);
			} else {
				setStagesDetail({
					...emptyStage,
					langFields: [{ ...langField, lang: currentLanguage }],
				});
			}
		} catch (error: any) {
			console.error("Error fetching data:", error);
			return error;
		} finally {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const updateGPX = async (file: string | File) => {
		// if (id) UpdateEntity({ file: file as unknown as Blob }, NAVIGATION_DATA.STAGE, id);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			try {
				await fetchOptions(); // Try to fetch options
				await fetchStagetDetail();
			} catch (error) {
				console.error("Error fetching data:", error); // Handle and log any errors
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [fetchOptions, fetchStagetDetail, id]);

	const validationSchema = Yup.object({
		langFields: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().trim().required("ERRORS.FORM.REQUIRED").min(3, "ERRORS.FORM.MIN_LENGTH"),
			}),
		),
	});

	const customSymbol = (isPoi: boolean) => ({
		path: google.maps.SymbolPath.CIRCLE, // Tipo di simbolo (cerchio)
		fillColor: isPoi ? chipColors.poi.primary : chipColors.commercialActivity.primary, // Colore di riempimento del simbolo
		fillOpacity: 1, // Opacità del colore di riempimento
		scale: 14, // Dimensioni del simbolo
		strokeWeight: 1,
		strokeColor: isPoi ? chipColors.poi.dark : chipColors.commercialActivity.dark,
	});

	const handleModal = () => {
		setOpen((prev) => !prev);
	};

	const endSymbol = (coordinates: Coordinates) => ({
		path: "M5.75 1C6.16421 1 6.5 1.33579 6.5 1.75V3.6L8.22067 3.25587C9.8712 2.92576 11.5821 3.08284 13.1449 3.70797L13.5582 3.87329C14.9831 4.44323 16.5513 4.54967 18.0401 4.17746C18.6711 4.01972 19.1778 4.7036 18.8432 5.26132L17.5647 7.39221C17.2232 7.96137 17.0524 8.24595 17.0119 8.55549C16.9951 8.68461 16.9951 8.81539 17.0119 8.94451C17.0524 9.25405 17.2232 9.53863 17.5647 10.1078L19.1253 12.7089C19.4361 13.2269 19.1582 13.898 18.5721 14.0445L18.472 14.0695C16.7024 14.5119 14.8385 14.3854 13.1449 13.708C11.5821 13.0828 9.8712 12.9258 8.22067 13.2559L6.5 13.6V21.75C6.5 22.1642 6.16421 22.5 5.75 22.5C5.33579 22.5 5 22.1642 5 21.75V1.75C5 1.33579 5.33579 1 5.75 1Z",
		fillColor: theme.palette.error.light, // Colore di riempimento del simbolo
		fillOpacity: 1, // Opacità del colore di riempimento
		scale: 1.7, // Dimensioni del simbolo
		strokeWeight: 2,
		coordinates: coordinates,
		anchor: new google.maps.Point(5, 23),
		strokeColor: theme.palette.error.dark,
	});

	const startEndMarkers = () => {
		if (
			!relatedPoiList ||
			relatedPoiList?.length === 0 ||
			(relatedPoiList[0].latlng !== stagesDetail?.coordinates[0] &&
				relatedPoiList[relatedPoiList.length - 1].latlng !== stagesDetail?.coordinates[stagesDetail?.coordinates.length - 1])
		) {
			return (
				<>
					<Marker position={stagesDetail?.coordinates[0] as { lat: number; lng: number }}></Marker>
					<Marker
						icon={endSymbol(stagesDetail?.coordinates[0] as { lat: number; lng: number })}
						position={stagesDetail?.coordinates[stagesDetail?.coordinates.length - 1] as { lat: number; lng: number }}
					></Marker>
				</>
			);
		} else if (relatedPoiList && relatedPoiList?.length > 0 && relatedPoiList[0].latlng !== stagesDetail?.coordinates[0]) {
			return <Marker position={stagesDetail?.coordinates[0] as { lat: number; lng: number }} label={"Inizio"}></Marker>;
		} else
			return (
				<Marker
					icon={endSymbol(stagesDetail?.coordinates[0] as { lat: number; lng: number })}
					position={stagesDetail?.coordinates[stagesDetail?.coordinates.length - 1] as { lat: number; lng: number }}
					label={"Fine"}
				></Marker>
			);
	};

	const langFieldSchema = Yup.object().shape({
		name: Yup.string().required("ERRORS.FORM.REQUIRED"),
	});

	return (
		<>
			{/* {loadingInsightDetail || !insightDetail  ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
          <CircularProgress size={100} />
        </div>
      ) 
      :
       (  */}

			{loading || !stagesDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<Formik<Partial<StageDetailInterface>>
					enableReinitialize
					initialValues={stagesDetail}
					validationSchema={Yup.object({
						langFields: Yup.array().of(langFieldSchema).required("ERRORS.FORM.REQUIRED"),
						category: Yup.object().shape({
							key: Yup.string().required("ERRORS.FORM.REQUIRED"),
							label: Yup.string().required("ERRORS.FORM.REQUIRED"),
						}),
					})}
					onSubmit={async (values, { setSubmitting }) => {
						setIsRequestSubmitted(true);
						// Filtra i langFields vuoti
						const filteredLangFields = values.langFields?.filter((langField) => langField.name.trim() !== "");

						// console.log("VALUES: ", { ...values, langFields: filteredLangFields });
						try {
							const formData = new FormData();
							formData.append("file", gpxFileFormData as unknown as Blob);
							formData.append("langFields", JSON.stringify(filteredLangFields));
							if (relatedPoiList && relatedPoiList.length > 0) {
								formData.append("pois", JSON.stringify(relatedPoiList.map((poi) => poi._id)));
							} else {
								formData.append("pois", JSON.stringify([])); // Pass an empty array if no POIs
							}
							// if (values.distance) formData.append("distance", JSON.stringify(values.distance));
							if (values.category) formData.append("category", JSON.stringify(values.category));
							// if (values.travelTime) formData.append("travelTime", JSON.stringify(values.travelTime));
							// if (values.pois) formData.append("customer", JSON.stringify(values.pois));
							// if (values.tenant) formData.append("tenant", JSON.stringify(values.tenant));
							const response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.STAGE].ALL, id, formData, true);
							// console.log("BE RESPONSE FROM STAGE ADD:", response.data);
							toast.success(t("MESSAGES.UPDATE_OK"));
							// setTimeout(() => {
							setStagesDetail(response.data);
							setDeletedPhoto([]);
							setPhotos([]);
							if (resetFiles) resetFiles();
							// navigate(`/stages/detail/${response.data.data._id}`);
							// }, 1000);
						} catch (error: any) {
							// console.log("ERROR StageDetail: ", error);
							toast.error(error.message);
						} finally {
							setSubmitting(false);
							setTimeout(() => {
								setIsRequestSubmitted(false);
							}, 500);
						}
					}}
				>
					{(formik) => (
						<Form onSubmit={formik.handleSubmit}>
							<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
							<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
								<Grid flexDirection={"column"}>
									<Typography variant={"h1"}>
										{id
											? stagesDetail?.langFields?.find((field) => field.lang === currentLanguage)?.name || t("STAGE.DETAIL.TITLE")
											: t("STAGE.DETAIL.TITLE")}
									</Typography>
									<CustomBreadcrumbs
										dataType={NAVIGATION_DATA.STAGE}
										currentPage={
											id
												? stagesDetail?.langFields?.find((element) => element.lang === currentLanguage)?.name || t("POI.ADD.TITLE").split(" ")[0]
												: t("POI.ADD.TITLE").split(" ")[0]
										}
									/>
								</Grid>
								{/* <SaveButton
									onClick={() => {
										formik.handleSubmit();
									}}
									disabled={false}
								/> */}
								<Button
									disabled={isRequestSubmitted || isUploading}
									variant="contained"
									color="secondary"
									startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
									onClick={() => {
										if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
											toast.warn(t("MESSAGES.WARNING_FORM"), {
												className: "yellowToastWarning",
											});
											if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
												const emptyLangs =
													formik.values.langFields?.filter((value) => value.name === undefined || value.name === "").map((value) => value.lang) ?? [];
												const keyValues = emptyLangs?.map((element) => getEnumKeyByValue(element));
												const translatedKeys = keyValues
													.filter((key): key is string => key !== undefined)
													.map((key: string) => t(`SUPPORTED_LANGUAGES.${key}`))
													.join(",\n");
												toast.warn(t("MESSAGES.CHECK_LANGUAGES") + translatedKeys);
											}
											formik.setFieldTouched("langFields", true);
											formik.setFieldError("langFields", undefined);
										} else {
											formik.handleSubmit();
										}
									}}
									size="large"
								>
									{t("COMMONS.SAVE_CHANGES")}
								</Button>
							</Grid>

							<Grid container item spacing={2}>
								<Grid container item id="container-left" xs={12} sm={12} md={8} mb={2} spacing={2} alignContent={"flex-start"}>
									<Grid item xs={4} sm={4}>
										<EntityLanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} formik={formik} />
									</Grid>
									<IdName id={id} formik={formik} lang={currentLanguage} />

									<Grid item xs={12} sm={6}>
										<FormControl fullWidth variant="outlined">
											<InputLabel error={formik.touched.category && !!formik.errors.category}>{t("FORM.CATEGORY")}</InputLabel>
											<Select
												label={t("FORM.CATEGORY")}
												name="category"
												value={formik.values.category?.key || ""}
												onChange={(event) => {
													const selectedCategory = categories && categories.find((el) => el.key === event.target.value);
													formik.setFieldValue("category", selectedCategory); // Set the entire category object
												}}
												style={{ borderRadius: 8 }}
												error={formik.touched.category && !!formik.errors.category}
												/* helperText={formik.touched.category && t(formik.errors.category ?? "")} */
											>
												{categories &&
													categories.map((el: TypologicType) => (
														<MenuItem key={el.key} value={el.key}>
															{t(el.label)}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
									{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() ? (
										<Grid item xs={12} sm={6}>
											<FormControl fullWidth variant="outlined">
												<InputLabel error={formik.touched.tenant && !!formik.errors.tenant} color="primary">
													{t("FORM.CUSTOMER")}
												</InputLabel>
												<Select
													disabled
													label={t("FORM.CUSTOMER")}
													name="tenant"
													value={formik.values.tenant || userStore.getTenant}
													onChange={formik.handleChange}
													style={{ borderRadius: 8 }}
													error={formik.touched.tenant && !!formik.errors.tenant}
												>
													{customers.map((el: ClientInterface) => (
														<MenuItem key={el._id} value={el.tenant}>
															{el.business_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={12} sm={6} />
									)}
									<Grid item xs={12} sm={4}>
										<TextField
											fullWidth
											id="distance"
											name="distance"
											label="Distanza(km)"
											//value={formik.values.name}
											defaultValue={formik.values.distance?.toFixed(2)}
											// defaultValue={formik.values.distance}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.distance && Boolean(formik.errors.distance)}
											helperText={formik.touched.distance && formik.errors.distance}
											disabled
											inputProps={{ readOnly: true }}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<FormControl fullWidth variant="outlined">
											<InputLabel></InputLabel>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePicker
													disabled
													label={t("FORM.DURATION")}
													defaultValue={formatTimeData(formik.values.travelTime)}
													views={["hours", "minutes"]}
													format="HH:mm"
													ampm={false}
													//	onChange={formik.handleChange}
												/>
											</LocalizationProvider>

											{/* <div>{`Initialized Hours: ${valueDuration?.getHours()}, Minutes: ${valueDuration?.getMinutes()}, Seconds: ${valueDuration?.getSeconds()}`}</div> */}
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											fullWidth
											disabled
											label={t("ITINERARIES.DETAIL.ALTITUDE_DIFFERENCE")}
											name="difference"
											variant="outlined"
											value={altitudeDifference(formik.values.minElevation, formik.values.maxElevation)}
											InputProps={{ sx: { borderRadius: 2 } }}
										/>
									</Grid>
									{/* <Grid item xs={12} sm={4}>
										<DetailSelect
											completeList={customers}
											error={formik.errors.customer}
											touched={formik.touched.customer}
											value={formik.values.customer}
											setState={(value) => formik.setFieldValue("customer", value)}
											label={"Clienti"}
											name={"customer"}
										/>
									</Grid> */}

									<Grid item xs={12}>
										<Typography variant="h6" component="div" mb={1}>
											{t("INSIGHTS.DETAIL.DESCRIPTION")}
										</Typography>
										<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
											<TextEditor
												setFieldValue={(value) => handleDescriptionChange(currentLanguage, value, formik.values.langFields, formik.setFieldValue)}
												defaultValue={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.description}
											/>
										</Card>
									</Grid>
									<Grid item xs={12} justifyContent="center" alignItems="center">
										<GPXUploader
											setGpxFileFormData={setGpxFileFormData}
											setFormik={formik.setFieldValue}
											setNewData={(file) => {
												updateGPX(file);
											}}
											error={!!formik.errors.gpxFile && !!formik.touched.gpxFile}
										/>
									</Grid>

									{/* R4 */}
									<Divider sx={{ mt: 2 }} />
									<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
										<Grid item>
											<Typography variant="h6" component="div" mb={1}>
												{t("STAGES.DETAIL.STAGEPATH")}
											</Typography>
										</Grid>
										<Grid item>
											<AddButton
												length={relatedPoiList.length || 0}
												onClick={() => {
													setModalTitle(() => t("POI.ADD.TITLE"));
													setModalContent(() => relatedPoiList);
													setModalCallBack(() => setRelatedPoiList);
													setModalDataType(() => DATATYPE.POI);
													handleModal();
												}}
												component={undefined}
											/>
										</Grid>
										<Grid item xs={12}>
											<StagePath
												currentLng={currentLanguage}
												pois={relatedPoiList ? relatedPoiList : []}
												setPois={setRelatedPoiList}
												navigation={(id) => navigate(`/poi/detail/${id}`)}
											/>
										</Grid>
									</Grid>

									{/* R5 */}
									<Divider sx={{ mt: 2 }} />
									<Grid item xs={12}>
										{/* <input type="file" accept=".gpx" onChange={handleFileUpload} /> */}

										<Typography variant="h6" component="div" mb={1}>
											{t("POI.DETAIL.POSITION")}
										</Typography>
										<GoogleMapsCard
											lat={stagesDetail && stagesDetail.coordinates[0] ? stagesDetail.coordinates[0].lat : 0}
											lng={stagesDetail && stagesDetail.coordinates[0] ? stagesDetail.coordinates[0].lng : 0}
											stagesList={stagesDetail?.coordinates || []}
										>
											{/* <Polyline
												path={formik.values.map}
												options={{
													strokeColor: "#FF0000",
													strokeOpacity: 1.0,
													strokeWeight: 2,
												}}
											/> */}
											{relatedPoiList &&
												relatedPoiList.map((poi, index) => (
													<Marker
														label={(index + 1).toString()}
														icon={customSymbol(poi.type === "Punto d'interesse" ? true : false)}
														position={poi.latlng}
													></Marker>
												))}
											{startEndMarkers()}
										</GoogleMapsCard>
									</Grid>

									{/* R5 */}
									{/* <Grid item xs={12}>
										<AttachedFiles setFilesList={formik.setFieldValue} files={formik.values.files} />
									</Grid> */}
								</Grid>

								<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
									<Grid item xs={12}>
										<Grid item xs={12} pt={9}>
											<PhotoDropZone
												resetFiles={(action) => setResetFiles(action)}
												photos={stagesDetail.photos || []}
												setPhotoList={setPhotos}
												error={false}
												navigationData={NAVIGATION_DATA.STAGE}
												setEntity={setStagesDetail}
												setIsUploading={setIsUploading}
											/>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<ConnectedItineraries itineraries={formik.values.itinerary ? [formik.values.itinerary] : []} language={currentLanguage} />
									</Grid>
									{stagesDetail._id && (
										<Grid item xs={12}>
											<Ratings
												averageRate={stagesDetail.averageRate}
												numberOfRate={stagesDetail.numberOfRate}
												favorites={stagesDetail.favorites?.length}
											/>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			)}
			{open && modalDataType && (
				<Grid container xs={12}>
					<AddEntityModal
						open={open}
						title={modalTitle}
						onClose={() => handleModal()}
						callBack={modalCallBack}
						initialList={modalContent}
						dataType={modalDataType}
						tenant={stagesDetail?.tenant}
					/>
				</Grid>
			)}
		</>
	);
};

export default StagesDetailAdd;

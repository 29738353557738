import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { NewsDetailInterface, NotificationCategory, NotificationStatus } from "../../type/rest/news-rest-interface";
import { PoiDetailInterface } from "../../type/rest/poi-rest-interfaces";
import { Coordinates, DATATYPE, NAVIGATION_DATA, POI_FILTERS, USERS_FILTERS, emptyNews } from "../../type/commons-artplace";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { get, patch, post } from "../../utils/call-api";
import { BE_LINKS } from "../../api/api";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
	FormHelperText,
	Pagination,
	InputAdornment,
	IconButton,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik, getIn } from "formik";
import { ToastContainer, toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { DataGrid } from "@mui/x-data-grid";
import CustomDataGrid from "../../componets/dataGrid/CustomDataGrid";
import SearchBar from "../../componets/barraRicerca/SearchBar";
import ConfirmationModal from "../../componets/confirmationModal/ConfirmationModal";
import { UserDetailInterface } from "../../type/rest/user-rest-interface";
import GridColumns from "../../componets/data-table/gridColumns/GridColumns";
import { LanguageStore, LanguageStoreContext } from "../../stores/LanguageStore";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import { PAGESIZE } from "../../constants/artplace.constant";
import { set } from "mobx";
import "../../componets/dataGrid/DataGridStyles.css";

const News = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [newsDetail, setNewsDetail] = useState<Partial<NewsDetailInterface> | null>(null);
	const [loadingNewsDetail, setLoadingNewsDetail] = useState<boolean>(false);
	const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
	const [poi, setPoi] = useState<PoiDetailInterface | null>(null);
	const [latlng, setLatLng] = useState<Coordinates>();
	// Tutti gli user che arrivano dalla chiamata pagingata BE
	const [completeUsersList, setCompleteUsersList] = useState<UserDetailInterface[]>([]);
	// console.log("News ~ completeUsersList:", completeUsersList);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole = userStore.getUserRole();
	const [searchField, setSearchField] = useState<string>(USERS_FILTERS.surname);
	const [global, setGlobal] = useState<boolean>(false);
	const [searchTerm, setSearch] = useState<string>("");
	const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	//console.log("News ~ selectedRows:", selectedRows);
	const [rowCount, setRowCount] = useState<number | undefined>(undefined);
	//console.log("News ~ rowCount:", rowCount);
	const [rows, setRows] = useState<any[]>([]);
	const [selectionModel, setSelectionModel] = useState<string[]>([]);
	//console.log("News ~ selectionModel:", selectionModel);
	const [selectedRowIds, setSelectedRowIds] = useState(new Set());

	const [refreshTrigger, setRefreshTrigger] = useState(0);

	const { ChipStatusSwitch, createUrlFile } = EntityUtilities();
	const columns = GridColumns(DATATYPE.USERS, languageStore.getGlobalLanguage, createUrlFile);
	const [cachedFormikValues, setCachedFormikValues] = useState<Partial<NewsDetailInterface>>({});
	const [page, setPage] = useState<number>(1);
	const pageSize: number = PAGESIZE;

	const fetchNewsDetail = useCallback(async () => {
		setNewsDetail(null);
		setSelectedUsers([]);
		try {
			if (id) {
				const data: any = (await get(BE_LINKS[userRole][NAVIGATION_DATA.NEWS].ALL, id, true)).data;
				setNewsDetail(data || []);
				setGlobal(data.global);
				setSelectedUsers(() => data.sentTo.map((user: any) => user.authId));
				setSelectionModel(() => data.sentTo.map((user: any) => user.authId));
				//console.log("SelectedUser - Fase FETCH. ", selectedUsers);
			} else {
				setNewsDetail({
					...emptyNews,
				});
			}
		} catch (error: any) {
			console.error("Error fetching data:", error);
			return error;
		}
	}, [id]);

	const fetchUsersList = async () => {
		try {
			if (newsDetail?.status !== NotificationStatus.PUBLISHED) {
				//console.log("not newsDetail?.status", newsDetail?.status);
				//console.log("published");
				const response = (
					await get(BE_LINKS[userRole][NAVIGATION_DATA.USERS].ALL, undefined, true, undefined, searchTerm, searchField, page, pageSize)
				).data;
				setCompleteUsersList(() => response);
			} else {
				//console.log("no");
				const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.USERS].ALL, undefined, true, undefined)).data;
				setCompleteUsersList(() => response);
			}
		} catch (error) {
			toast.error("Errore nel fetch degli utenti");
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoadingNewsDetail(true);
			try {
				await fetchNewsDetail();
				// await fetchUsersList();
			} catch (error) {
				console.error("Error fetching data:", error); // Handle and log any errors
			} finally {
				setLoadingNewsDetail(false);
			}
		};

		fetchData();
	}, [id]);

	// const prevSearchTerm = useRef(searchTerm);
	// console.log("News ~ prevSearchTerm:", prevSearchTerm);

	useEffect(() => {
		if (newsDetail?.status === NotificationStatus.PUBLISHED) {
			//console.log("Skipping COUNT useEffect because newsDetail.status is 'published'");
			return;
		}

		// const shouldUpdate = searchTerm !== prevSearchTerm.current;
		// console.log("useEffect ~ shouldUpdate:", shouldUpdate);

		const getTotalNumber = async () => {
			try {
				const data = await get(
					BE_LINKS[userRole][NAVIGATION_DATA.USERS].COUNT,
					undefined,
					undefined,
					undefined,
					searchTerm,
					searchField,
					undefined,
					undefined,
				);
				setRowCount(data.data);
			} catch {
				setRowCount(0);
			}
		};

		getTotalNumber();

		// prevSearchTerm.current = searchTerm;
	}, [searchTerm, searchField, newsDetail?.status]);

	useEffect(() => {
		if (newsDetail?.status === NotificationStatus.PUBLISHED) {
			//console.log("Skipping USERS useEffect because newsDetail.status is 'published'");
			return;
		}

		const fetchDataUser = async () => {
			setLoadingUsers(true);
			try {
				fetchUsersList();
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoadingUsers(false);
			}
		};

		fetchDataUser();
	}, [searchTerm, searchField, page, newsDetail?.status]);

	const validationSchema = Yup.object({
		title: Yup.string().trim().required("ERRORS.FORM.REQUIRED").min(3),
		//body: Yup.string().trim().required("ERRORS.FORM.REQUIRED").min(3),
		category: Yup.string().trim().required("ERRORS.FORM.REQUIRED"),
		body: Yup.string().trim().required("ERRORS.FORM.REQUIRED").min(3),
		link: Yup.string().trim().url("ERRORS.FORM.INVALID_URL"),
		imageUrl: Yup.string().trim().url("ERRORS.FORM.INVALID_URL").nullable(),
	});

	//azzera selection model all'aperura della pagina se si tratta di una nuova notizia

	useEffect(() => {
		if (!id) {
			setSelectionModel([]);
			setGlobal(false);
		}
	}, [id]);

	const handleSearch = (searchTerm: string) => {
		if (searchTerm.length >= 3 || searchTerm.trim() === "") {
			setSearch(searchTerm);
			setPage(1);
		}
	};

	const sendCondition = (values: Partial<NewsDetailInterface>) => {
		return values.status === NotificationStatus.DRAFT &&
			values.title &&
			values.title.length >= 3 &&
			values.body &&
			values.body.length >= 3 &&
			values.category &&
			(selectionModel.length > 0 || values.global)
			? false
			: true;
	};
	const saveCondition = (values: Partial<NewsDetailInterface>) => {
		return values.title && values.title.length >= 3 && values.body && values.body.length >= 3 && values.category
			? //(selectedUsers.length > 0 || values.global)
			  false
			: true;
	};

	//SEND NOTIFICATION WITH STATUS NOW
	const handleSendNotification = async (values: any) => {
		setLoadingNewsDetail(true);
		try {
			const filteredSelectedRows = selectedRows.filter((row) => selectionModel.includes(row.id_auth_module));
			const formattedSelectedRows = filteredSelectedRows.map((row) => ({
				firstname: row.firstname,
				surname: row.surname,
				authId: row.id_auth_module,
			}));

			const result = { ...values, sentTo: formattedSelectedRows, status: NotificationStatus.NOW };
			let response: { data: NewsDetailInterface; message: string };
			if (id) {
				response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.NEWS].ALL, id, result);
				toast.success(t("MESSAGES.SEND_OK"));
				setNewsDetail(response.data);
			}
		} catch (error) {
			toast.error(t("MESSAGES.SEND_ERROR"));
		} finally {
			setModalConfirmationOpen(false);
			setLoadingNewsDetail(false);
		}
	};
	const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	return (
		<>
			{loadingNewsDetail || !newsDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<Formik<Partial<NewsDetailInterface>>
					enableReinitialize
					initialValues={newsDetail}
					validationSchema={validationSchema}
					onSubmit={async (values, { setSubmitting }) => {
						try {
							const filteredSelectedRows = selectedRows.filter((row) => selectionModel.includes(row.id_auth_module));
							const formattedSelectedRows = filteredSelectedRows.map((row) => ({
								firstname: row.firstname,
								surname: row.surname,
								authId: row.id_auth_module,
							}));

							const result = { ...values, sentTo: formattedSelectedRows };
							let response: { data: NewsDetailInterface; message: string };
							if (id) {
								response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.NEWS].ALL, id, result);
								setNewsDetail(response.data);
							} else {
								response = await post(BE_LINKS[userRole][NAVIGATION_DATA.NEWS].ALL, result, result.tenant, false);
							}
							toast.success(t("MESSAGES.SAVE_OK"));
							setTimeout(() => {
								navigate(`/news/detail/${response.data._id}`);
							}, 1000);
						} catch (error) {
							toast.error(t("MESSAGES.SAVE_ERROR"));
						}
						setSubmitting(false);
					}}
				>
					{(formik) => (
						<>
							<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
							<Form onSubmit={formik.handleSubmit}>
								<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
									<Grid container flexDirection={"row"} alignItems={"center"}>
										<Grid item pr={2}>
											<Typography variant={"h1"}>{id ? t("NEWS.DETAIL.TITLE") : t("NEWS.ADD.TITLE")}</Typography>
										</Grid>

										<Grid item>{ChipStatusSwitch(formik.values.status)}</Grid>
									</Grid>
								</Grid>
								<Grid container item spacing={2}>
									<Grid container item id="container-left" xs={12} sm={12} md={12} lg={12} xl={4} spacing={2} mb={5} alignContent={"flex-start"}>
										{/* R1 */}
										<Grid item xs={12} sm={12}>
											<TextField
												fullWidth
												required
												label={t("FORM.TITLE")}
												name="title"
												//helperText={formik.touched.title && t(formik.errors.title ?? "")}
												value={formik?.values?.title}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={formik.touched.title && !!formik.errors.title}
												helperText={formik.touched.title && t(formik.errors.title ?? "")}
												disabled={formik.values.status === NotificationStatus.PUBLISHED}
											/>
										</Grid>
										{/* R2 */}
										<Grid item xs={12} sm={12}>
											<FormControl fullWidth variant="outlined">
												<InputLabel required error={formik.touched.category && !!formik.errors.category} color="primary">
													{t("FORM.CATEGORY")}
												</InputLabel>
												<Select
													name="category"
													label={t("FORM.CATEGORY")}
													required
													value={formik.values.category}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={formik.touched.category && !!formik.errors.category}
													disabled={formik.values.status === NotificationStatus.PUBLISHED}
												>
													{Object.values(NotificationCategory).map((category) => (
														<MenuItem key={category} value={category}>
															{category}
														</MenuItem>
													))}
												</Select>
												{formik.touched.category && formik.errors.category && (
													<FormHelperText style={{ color: "#d32f2f" }}>{t(formik.errors.category)}</FormHelperText>
												)}
											</FormControl>
										</Grid>
										{/* R3 */}
										<Grid item xs={12} sm={12}>
											<TextField
												fullWidth
												label="Link"
												name="link"
												variant="outlined"
												onBlur={formik.handleBlur}
												error={formik.touched.link && !!formik.errors.link}
												helperText={formik.touched.link && formik.errors.link ? t(formik.errors.link) : ""}
												value={formik?.values?.link}
												onChange={(e) => {
													const trimmedValue = e.target.value.trim();
													formik.setFieldValue("link", trimmedValue);
												}}
												disabled={formik.values.status === NotificationStatus.PUBLISHED}
												InputProps={
													formik.values.status === NotificationStatus.PUBLISHED && formik.values.link
														? {
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton onClick={() => window.open(formik.values.imageUrl, "_blank")} edge="end">
																			<InsertLinkIcon />
																		</IconButton>
																	</InputAdornment>
																),
														  }
														: {}
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextField
												fullWidth
												label={t("FORM.IMAGE")}
												name="imageUrl"
												variant="outlined"
												onBlur={formik.handleBlur}
												value={formik?.values?.imageUrl}
												error={formik.touched.imageUrl && !!formik.errors.imageUrl}
												helperText={formik.touched.imageUrl && formik.errors.imageUrl ? t(formik.errors.imageUrl) : ""}
												onChange={(e) => {
													const trimmedValue = e.target.value.trim();
													formik.setFieldValue("imageUrl", trimmedValue);
												}}
												disabled={formik.values.status === NotificationStatus.PUBLISHED}
												InputProps={
													formik.values.status === NotificationStatus.PUBLISHED && formik.values.imageUrl
														? {
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton onClick={() => window.open(formik.values.imageUrl, "_blank")} edge="end">
																			<InsertLinkIcon />
																		</IconButton>
																	</InputAdornment>
																),
														  }
														: {}
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextField
												required
												fullWidth
												multiline
												rows={10}
												label={t("FORM.TEXT")}
												name="body"
												variant="outlined"
												//helperText={formik.touched.title && t(formik.errors.title ?? "")}
												value={formik?.values?.body}
												onChange={formik.handleChange}
												error={formik.touched.body && !!formik.errors.body}
												helperText={formik.touched.body && t(formik.errors.body ?? "")}
												onBlur={formik.handleBlur}
												disabled={formik.values.status === NotificationStatus.PUBLISHED}
											/>
										</Grid>
										{formik.values.status !== NotificationStatus.PUBLISHED && (
											<Grid container xs={12} sm={12} flexDirection={"row"} py={2} justifyContent={"space-evenly"}>
												<Grid item>
													<Button
														disabled={saveCondition(formik.values)}
														variant="contained"
														size="large"
														color="success"
														endIcon={<SaveIcon />}
														onClick={() => {
															if (!formik.values.title || !formik.values.category || !formik.values.body) {
																toast.warn(t("MESSAGES.WARNING_FORM"), {
																	className: "yellowToastWarning",
																});
																formik.setFieldTouched("title", true);
																formik.setFieldTouched("category", true);
																formik.setFieldTouched("body", true);
															} else {
																formik.handleSubmit();
															}
														}}
													>
														{`${t("COMMONS.SAVE")} ${t("COMMONS.DRAFT")}`}
													</Button>
												</Grid>
												<Grid item>
													<Button
														disabled={sendCondition(formik.values)}
														variant="contained"
														size="large"
														color="info"
														endIcon={<SendIcon />}
														onClick={() => {
															setCachedFormikValues(formik.values);
															setModalConfirmationOpen(true);
														}}
													>
														{`${t("COMMONS.SAVE")} ${t("COMMONS.AND")} ${t("COMMONS.SEND")}`}
													</Button>
												</Grid>
											</Grid>
										)}
									</Grid>
									<Grid container item id="container-right" xs={12} sm={12} md={12} lg={12} xl={8} mb={5} alignContent={"flex-start"}>
										<div className="d-flex align-items-center">
											<SearchBar onSearch={handleSearch} placeholder={t("COMMONS.NAME_SEARCH")} />
											<Switch
												checked={formik.values.global}
												onChange={(e) => {
													formik.setFieldValue("global", e.target.checked);
													setGlobal(e.target.checked);
												}}
												disabled={formik.values.status === NotificationStatus.PUBLISHED}
											/>
											<Typography>Invio Globale</Typography>
										</div>

										<Box
											sx={{
												height: "70vh",
												width: "100%",
												marginTop: "1.5%",
												"& .MuiDataGrid-columnHeaders": { backgroundColor: "#EFF5F8", textTransform: "uppercase" },
												"& .MuiDataGrid-cell:focus-within": { outline: 0 },
												"& .MuiDataGrid-columnHeaderTitle": { fontWeight: "700" },
											}}
										>
											{loadingUsers || !completeUsersList || !selectedUsers ? (
												<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
													<CircularProgress size={100} />
												</div>
											) : !global ? (
												newsDetail?.status !== NotificationStatus.PUBLISHED ? (
													<DataGrid
														// rowHeight={120}
														autoHeight={completeUsersList && completeUsersList.length > 0 ? false : true}
														getRowHeight={() => "auto"}
														style={{ border: "none" }}
														rowSelectionModel={selectionModel} //
														getRowId={(row) => row.id_auth_module}
														keepNonExistentRowsSelected
														onRowSelectionModelChange={(ids) => {
															setSelectionModel(ids as string[]);
															const selectedIDs = new Set(ids);
															const newSelectedRows = completeUsersList.filter((row) => selectedIDs.has(row.id_auth_module as string));
															const uniqueNewSelectedRows = newSelectedRows.filter(
																(newRow) => !selectedRows.some((prevRow) => prevRow._id === newRow._id),
															);
															setSelectedRows((prev) => [...prev, ...uniqueNewSelectedRows]);
														}}
														localeText={{ noRowsLabel: t("TABLE.NO_RESULTS") }}
														rows={completeUsersList}
														columns={columns}
														initialState={{
															pagination: {
																paginationModel: {
																	pageSize: pageSize,
																},
															},
														}}
														pageSizeOptions={[10]}
														checkboxSelection
														slots={{
															footer: () => (
																<Pagination
																	page={page}
																	size="small"
																	color="primary"
																	shape="rounded"
																	onChange={handlePageChange}
																	sx={{ alignSelf: "flex-end", padding: 2 }}
																	count={Math.ceil((rowCount as number) / pageSize)}
																/>
															),
														}}
													/>
												) : (
													<DataGrid
														autoHeight={completeUsersList && completeUsersList.length > 0 ? false : true}
														getRowHeight={() => "auto"}
														getRowId={(row) => row.id_auth_module}
														columns={columns}
														rows={completeUsersList.filter(
															(user) =>
																selectionModel.includes(user.id_auth_module) &&
																(searchTerm.trim() === "" ||
																	(searchTerm.length >= 3 && user.surname.toLowerCase().includes(searchTerm.toLowerCase()))),
														)}
														disableRowSelectionOnClick
														initialState={{
															pagination: {
																paginationModel: {
																	pageSize: 10,
																},
															},
														}}
													/>
												)
											) : (
												<Typography>{t("COMMONS.GLOBAL_SEND")}</Typography>
											)}
										</Box>
									</Grid>
								</Grid>
							</Form>
						</>
					)}
				</Formik>
			)}
			{modalConfirmationOpen && (
				<ConfirmationModal
					open={modalConfirmationOpen}
					onClose={() => setModalConfirmationOpen(false)}
					onConfirm={() => handleSendNotification(cachedFormikValues)}
					usersNumber={selectionModel.length}
					global={global}
					text1={t("MODALS.SEND_CONFIRM")}
					text2={t("MODALS.CONTINUE")}
				/>
			)}
		</>
	);
};

export default News;

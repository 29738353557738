import {
	Card,
	Grid,
	CircularProgress,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Checkbox,
	CardContent,
	Button,
	FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast_error, toast_success } from "../../../utils/custom-toast";
import i18n from "../../../i18n";
import GoogleMapsCard from "../../../componets/googleMapsCard/GoogleMapsCard";
import { ClientInterface, PoiCategoryInterface, PoiDetailInterface, PoiSubCategoryInterface } from "../../../type/rest/poi-rest-interfaces";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DATATYPE, LangField, NAVIGATION_DATA, TypologicType, emptyItinerary, langField } from "../../../type/commons-artplace";
import { Form, Formik, getIn } from "formik";
import SaveButton from "../../../componets/buttons/SaveButton";
import { mockyCollection } from "../../../data/apiCall.mockup";
import { TextEditor } from "../../../componets/textField/textEditor/TextEditor";
import PhotoDropZone from "../../artplace-insight/insight-add/photoDropZone";
import { ItineraryDetailInterface } from "../../../type/rest/itinerary-rest-interfaces";
import GPXUploader from "../GPXUploader";
import PoiInsightsList from "../../artplace-poi/poi-detail/PoiInsightsList";
import { TypologicStoreContext } from "../../../stores/TypologicStore";
import { clientMockup } from "../../../data/poi.mockup";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { BE_LINKS } from "../../../api/api";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { ToastContainer, toast } from "react-toastify";
import { get, post, uploadPhoto } from "../../../utils/call-api";
import EntityLanguageSelector from "../../../componets/entityLanguageSelector/EntityLanguageSelector";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { Spinner } from "react-bootstrap";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import IdName from "../../../componets/pageComponents/IdName/IdName";
import LangFieldText from "../../../componets/langFieldText/LangFieldText";
import { getEnumKeyByValue, orderCustomersByBusinessName } from "../../../utils/util";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";

const ItineraryAdd = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const typologicStore = useContext(TypologicStoreContext);

	const [itineraryDetail, setItineraryDetail] = useState<Partial<ItineraryDetailInterface>>({});
	const userStore: UserStore = useContext(UserStoreContext);
	const [enabledStringsArray, setEnabledStringsArray] = useState<string[]>([]);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const [stagesList, setStagesList] = useState<any>([]);
	const [loadingItineraryDetail, setLoadingItineraryDetail] = useState<boolean>(false);
	const [allStringsArray, setAllStringsArray] = useState<string[]>([]);
	const [elevation, setElevation] = useState<number | null>(null);
	//totale categorie presenti
	const [categories, setCategories] = useState<TypologicType[]>([]);
	const [gpxFileFormData, setGpxFileFormData] = useState<any>();
	//totale sottocategorie presenti
	const [subCategories, setSubCategories] = useState<TypologicType[]>([]);
	const [clients, setClients] = useState<ClientInterface[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [searchResult, setSearchResult] = useState<any>();
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage);
	const { handleNameChange, handleSubtitleChange, handleDescriptionChange, handleLanguageChange, correctLanguageValue, getIndexFromLangFields } =
		EntityUtilities();
	const userRole: string = userStore.getUserRole();
	const [photos, setPhotos] = useState<File[]>([]);
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);

	const [isUploading, setIsUploading] = useState(false);

	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);

			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};

	const fetchCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getCategories(DATATYPE.ITINERARY);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchSubCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getSubcategories(DATATYPE.ITINERARY);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchOptions = async () => {
		const [categories, clients, subcategories] = await Promise.all([fetchCategories(), fetchClients(), fetchSubCategories()]);

		setSubCategories(subcategories);
		setCategories(categories);
		setClients(clients);

		// Se c'è solo una categoria, selezionala automaticamente
		if (categories.length === 1) {
			setItineraryDetail((prevDetails) => ({
				...prevDetails,
				category: categories[0],
			}));
		}
	};

	useEffect(() => {
		fetchOptions();
		setItineraryDetail({
			...emptyItinerary,
			langFields: [{ ...langField, lang: currentLanguage }],
		});
	}, [typologicStore.getTypologicsReady]);

	const extractCoordinates = (stages: any[]) => {
		return stages.map((stage) => stage.coordinates);
	};
	const langFieldSchema = Yup.object().shape({
		name: Yup.string().required("ERRORS.FORM.REQUIRED"),
	});
	return (
		<>
			{loadingItineraryDetail || !itineraryDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<>
					<Formik<Partial<ItineraryDetailInterface>>
						enableReinitialize
						// initialValues={itineraryDetail}
						initialValues={{
							...itineraryDetail,
							category: itineraryDetail?.category || (categories?.length === 1 ? categories[0] : undefined),
						}}
						validationSchema={Yup.object({
							langFields: Yup.array().of(langFieldSchema).required("ERRORS.FORM.REQUIRED"),
							category: Yup.object().shape({
								key: Yup.string().required("ERRORS.FORM.REQUIRED"),
								label: Yup.string().required("ERRORS.FORM.REQUIRED"),
							}),
							gpxFile: Yup.mixed().required("ERRORS.ITINERARY.GPX_REQUIRED"),
						})}
						onSubmit={async (values: Partial<ItineraryDetailInterface>, { setSubmitting }) => {
							setIsRequestSubmitted(true);
							// Filtra i langFields vuoti
							const filteredLangFields = values.langFields?.filter((langField) => langField.name.trim() !== "");

							try {
								const formData = new FormData();
								formData.append("file", gpxFileFormData as unknown as Blob);
								formData.append("langFields", JSON.stringify(filteredLangFields));
								if (values.photos) formData.append("photos", JSON.stringify(values.photos));
								if (values.category) formData.append("category", JSON.stringify(values.category));
								if (values.subcategory) formData.append("subcategory", JSON.stringify(values.subcategory));
								if (values.website) formData.append("website", JSON.stringify(values.website));
								//if (values.tenant) formData.append("tenant", JSON.stringify(values.tenant));
								if (values.unstableConnection) formData.append("unstableConnection", JSON.stringify(values.unstableConnection));
								const response = await post(BE_LINKS[userRole][NAVIGATION_DATA.ITINERARY].ALL, formData, values.tenant, true);
								//ADD: Le foto vanno aggiunte dopo la creazione dell'entità
								if (photos.length) {
									try {
										await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.ITINERARY, userRole);
									} catch (error) {
										toast.error(t("Errore upload photo"));
									}
								}
								// console.log("BE RESPONSE FROM ITINERARY ADD:", response);
								toast.success(t("MESSAGES.CREATION_OK"));
								setTimeout(() => {
									setDeletedPhoto([]);
									setPhotos([]);
									if (resetFiles) resetFiles();
									navigate(`/itineraries/detail/${response.data._id}`);
								}, 1000);
							} catch (error: any) {
								// console.log("ERROR ItineraryAdd: ", error);
								toast_error(error.message);
							} finally {
								setSubmitting(false);
								setTimeout(() => {
									setIsRequestSubmitted(false);
								}, 500);
							}
						}}
					>
						{(formik) => {
							return (
								<>
									<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
									<Grid container item xs={12} mb={5} justifyContent={"space-between"} alignItems={"center"}>
										<Grid flexDirection={"column"}>
											<Typography variant={"h1"}>{itineraryDetail?.name || t("ITINERARIES.ADD.TITLE")}</Typography>
											<CustomBreadcrumbs dataType={NAVIGATION_DATA.ITINERARY} currentPage={t("POI.ADD.TITLE").split(" ")[0]} />{" "}
										</Grid>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												if (
													!formik.values.gpxFile ||
													!formik.values.category?.key ||
													formik.values.langFields?.some((value) => value.name === undefined || value.name === "")
												) {
													toast.warn(t("MESSAGES.WARNING_FORM"));
													if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
														const emptyLangs =
															formik.values.langFields?.filter((value) => value.name === undefined || value.name === "").map((value) => value.lang) ??
															[];
														const keyValues = emptyLangs?.map((element) => getEnumKeyByValue(element));
														const translatedKeys = keyValues
															.filter((key): key is string => key !== undefined)
															.map((key: string) => t(`SUPPORTED_LANGUAGES.${key}`))
															.join(",\n");
														toast.warn(t("MESSAGES.CHECK_LANGUAGES") + translatedKeys);
													}
													formik.setFieldTouched("gpxFile", true);
													formik.setFieldTouched("category", true);
													formik.setFieldTouched("langFields", true);
													formik.setFieldError("langFields", undefined);
												} else {
													formik.handleSubmit();
												}
											}}
											startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
											size="large"
											disabled={isRequestSubmitted || isUploading}
										>
											{t("COMMONS.SAVE_CHANGES")}
										</Button>
									</Grid>
									<Form onSubmit={formik.handleSubmit}>
										<Grid container spacing={2}>
											{/* LEFT */}
											<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
												{/* R1*/}
												<Grid item xs={4} sm={4}>
													<EntityLanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} formik={formik} />
												</Grid>
												<IdName id={undefined} formik={formik} lang={currentLanguage} />
												{/* <Grid item xs={12} sm={12}>
													<TextField
														fullWidth
														label={t("FORM.NAME")}
														name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.name`}
														variant="outlined"
														required
														onBlur={formik.handleBlur}
														error={
															((formik.touched.langFields as unknown as LangField[])?.[
																getIndexFromLangFields(formik.values.langFields, currentLanguage)
															] ??
																false) &&
															(!!formik.errors.langFields?.[getIndexFromLangFields(formik.values.langFields, currentLanguage)] ?? false)
														}
														helperText={
															formik.errors.langFields &&
															(formik.touched.langFields as unknown as LangField[])?.[
																getIndexFromLangFields(formik.values.langFields, currentLanguage)
															] &&
															!!formik.errors.langFields &&
															!!formik.errors.langFields[getIndexFromLangFields(formik.values.langFields, currentLanguage)]
																? t(getIn(formik.errors.langFields[getIndexFromLangFields(formik.values.langFields, currentLanguage)], "name"))
																: ""
														}
														value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.name}
														onChange={(event) => handleNameChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
														InputProps={{ sx: { borderRadius: 2 } }}
													/>
													{/* 	<ErrorMessage name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.name`} /> */}

												{/* 												<Input type="file" onChange={handleFileChange} />
												 */}
												<Grid item xs={12} sm={12}>
													<LangFieldText formik={formik} fieldName={"subtitle"} callBack={handleSubtitleChange} lang={currentLanguage} />
												</Grid>
												{/* 	<Grid item xs={12} sm={12}>
													<TextField
														fullWidth
														label={t("FORM.SUBTITLE")}
														name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.subtitle`}
														variant="outlined"
														//helperText={formik.touched.title && t(formik.errors.title ?? "")}
														value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.subtitle}
														onChange={(event) => handleSubtitleChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
														InputProps={{ sx: { borderRadius: 2 } }}
													/>
												</Grid> */}
												{/* R2 */}
												<Grid item xs={12} sm={6}>
													<FormControl fullWidth variant="outlined">
														<InputLabel required error={formik.touched.category && !!formik.errors.category}>
															{t("FORM.CATEGORY")}
														</InputLabel>
														<Select
															label={t("FORM.CATEGORY")}
															name="category"
															required
															value={formik.values.category?.key || ""}
															onBlur={formik.handleBlur}
															onChange={(event) => {
																const selectedCategory = categories && categories.find((el) => el.key === event.target.value);
																formik.setFieldValue("category", selectedCategory); // Set the entire category object
															}}
															error={formik.touched.category && !!formik.errors.category}
														>
															{categories &&
																categories.map((el: TypologicType) => (
																	<MenuItem key={el.key} value={el.key}>
																		{t(el.label)}
																	</MenuItem>
																))}
														</Select>
														{formik.touched.category && !!formik.errors.category && (
															<FormHelperText style={{ color: "#d32f2f" }}>{t(getIn(formik.errors.category, "key"))}</FormHelperText>
														)}
													</FormControl>
												</Grid>

												{/* <Grid item xs={12} sm={4}>
													<FormControl fullWidth variant="outlined">
														<InputLabel>{t("FORM.SUBCATEGORY")}</InputLabel>
														<Select
															disabled
															label={t("FORM.SUBCATEGORY")}
															name="subcategory"
															value={formik.values.subcategory?.key || ""}
															onChange={(event) => {
																const selectedCategory = subCategories && subCategories.find((el) => el.key === event.target.value);
																formik.setFieldValue("subcategory", selectedCategory); // Set the entire category object
															}}
															style={{ borderRadius: 8 }}
															error={formik.touched.subcategory && !!formik.errors.subcategory}
														>
															{subCategories &&
																subCategories.map((el: TypologicType) => (
																	<MenuItem key={el.key} value={el.key}>
																		{t(el.label)}
																	</MenuItem>
																))}
														</Select>
													</FormControl>
												</Grid> */}

												{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
													<Grid item xs={12} sm={6}>
														<FormControl fullWidth variant="outlined">
															<InputLabel error={formik.touched.tenant && !!formik.errors.tenant} color="primary">
																{t("FORM.CUSTOMER")}
															</InputLabel>
															<Select
																label={t("FORM.CUSTOMER")}
																name="tenant"
																value={formik.values.tenant || userStore.getTenant}
																onChange={formik.handleChange}
																style={{ borderRadius: 8 }}
																error={formik.touched.tenant && !!formik.errors.tenant}
															>
																{clients.map((el: ClientInterface) => (
																	<MenuItem key={el._id} value={el.tenant}>
																		{el.business_name}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
												)}

												{/* R3 */}
												{/* 	<Grid item xs={12} sm={4}>
													<TextField
														fullWidth
														label={t("ITINERARIES.DETAIL.LENGTH")}
														name="length"
														variant="outlined"
														error={formik.touched.distance && !!formik.errors.distance}
														//helperText={formik.touched.name && t(formik.errors.name ?? "")}
														value={formik.values.distance}
														onChange={formik.handleChange}
														InputProps={{ sx: { borderRadius: 2 } }}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														fullWidth
														label={t("ITINERARIES.DETAIL.ALTITUDE_DIFFERENCE")}
														name="difference"
														variant="outlined"
														error={formik.touched.difference && !!formik.errors.difference}
														//helperText={formik.touched.name && t(formik.errors.name ?? "")}
														value={formik.values.difference}
														onChange={formik.handleChange}
														InputProps={{ sx: { borderRadius: 2 } }}
													/>
												</Grid> */}

												{/* R4 */}
												<Grid item xs={12}>
													<Typography variant="h6" component="div" mb={1}>
														{t("POI.DETAIL.DESCRIPTION")}
													</Typography>
													<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
														<TextEditor
															setFieldValue={(value) =>
																handleDescriptionChange(currentLanguage, value, formik.values.langFields, formik.setFieldValue)
															}
															defaultValue={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.description}
														/>
													</Card>
												</Grid>
												<Grid item xs={12} justifyContent="center" alignItems="center" py={2}>
													<Typography variant="h6" component="div" mb={1} mt={2}>
														{`${t("ITINERARIES.ADD.FILE")} ${"*"}`}
													</Typography>

													<GPXUploader
														setGpxFileFormData={setGpxFileFormData}
														setFormik={formik.setFieldValue}
														error={!!formik.errors.gpxFile && !!formik.touched.gpxFile}
													/>
												</Grid>

												{/*R5 */}
												{formik.values.stages && formik.values.stages.length > 0 ? (
													<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"}>
														<Grid item>
															<Typography variant="h6" component="div" mb={1} mt={2}>
																{t("ITINERARIES.DETAIL.STAGES")}
															</Typography>
														</Grid>

														<Grid item xs={12}>
															<PoiInsightsList insights={stagesList ?? []} dataType={DATATYPE.STAGE} />
														</Grid>

														<Grid item xs={12} pb={2}>
															{/* 		<GoogleWrapper> */}
															<GoogleMapsCard stagesList={extractCoordinates(formik.values.stages)} />
															{/* 	</GoogleWrapper> */}
														</Grid>
													</Grid>
												) : null}
											</Grid>
											{/*R6 */}
											{/* RIGHT*/}
											{/* R1*/}
											<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
												<Grid item xs={12}>
													<Grid item xs={12} pt={9}>
														<PhotoDropZone
															resetFiles={(action) => setResetFiles(action)}
															photos={formik.values.photos ?? []}
															setPhotoList={setPhotos}
															navigationData={NAVIGATION_DATA.ITINERARY}
															setIsUploading={setIsUploading}
														/>
													</Grid>
												</Grid>
												{/* R2*/}
												<Grid item xs={12}>
													<TextField
														fullWidth
														label="Website"
														name="website"
														id="website"
														type="website"
														variant="outlined"
														value={formik.values.website || ""}
														onChange={formik.handleChange}
													/>
												</Grid>
												{/* <Grid alignItems="center" item xs={12}>
													<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
														<CardContent style={{ display: "flex", alignItems: "center" }}>
															<Typography variant={"h6"}>{t("ITINERARIES.ADD.UNSTABLE_CONNECTION")}</Typography>
															<Checkbox
																name="unstableConnection"
																checked={formik.values.unstableConnection}
																onChange={formik.handleChange}
																disableRipple
																sx={{
																	"&.Mui-checked": {
																		color: "#0A17A7",
																	},
																}}
															/>
														</CardContent>
													</Card>
												</Grid> */}
											</Grid>
										</Grid>
									</Form>
								</>
							);
						}}
					</Formik>
				</>
			)}
		</>
	);
};

export default ItineraryAdd;

import { NAVIGATION_DATA } from "../type/commons-artplace";

//--------------------- Base urls modules ---------------------
export const ARTPLACE_BE_URL = process.env.REACT_APP_API_URL; //"http://localhost:3010"
export const AUTH_URL = process.env.REACT_APP_AUTH_SERVER_URL;
export const REGISTER_URL = process.env.REACT_APP_REG_SERVER_URL; //tutte wrappate in artplace
//export const MENU_URL = process.env.REACT_APP_MENU_SERVER_URL;

//AUTH_URL
export const API_LOGIN = AUTH_URL + "/auth/login";
export const API_REFRESH = AUTH_URL + "/auth/refresh";
export const API_AUTH_FORGOT_PASSWORD = AUTH_URL + "/auth/forgotPassword";
export const API_AUTH_RECOVERY_PASSWORD = AUTH_URL + "/auth/recoveryPassword";
export const API_AUTH_CHANGE_PASSWORD = AUTH_URL + "/auth/changePassword";
export const API_AUTH_NEW_PASSWORD = AUTH_URL + "/auth/newPassword";
export const API_AUTH_VERIFYMAIL = AUTH_URL + "/auth/verifyMail";

//COMMONS
const LIST_COUNT = "list/count";
export const API_TYPOLOGICS = ARTPLACE_BE_URL + "/superadmin/typologicals";

export const BE_LINKS: any = {
	COMMONS: {
		USER_INFO: ARTPLACE_BE_URL + "/authenticated/auth/me",
		API_LOGOUT: ARTPLACE_BE_URL + "/authenticated/auth/logout",
	},
	ADMIN: {
		[NAVIGATION_DATA.BEACON]: {
			COUNT: ARTPLACE_BE_URL + "/customer/beacons/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/customer/beacons/",
			POIS: ARTPLACE_BE_URL + "/customer/beacons/{id}/poi",
		},
		[NAVIGATION_DATA.INSIGHT]: {
			COUNT: ARTPLACE_BE_URL + "/customer/insights/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/customer/insights/",
			LINKABLE_INSIGHTS: ARTPLACE_BE_URL + "/customer/insights/list/linkableInsights/",
			LINKABLE_COUNT: ARTPLACE_BE_URL + "/customer/insights/list/linkableInsights/count/",
		},
		[NAVIGATION_DATA.POI]: {
			COUNT: ARTPLACE_BE_URL + "/customer/pois/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/customer/pois/",
			ITINERARIES: ARTPLACE_BE_URL + "/customer/pois/{id}/itineraries",
			UNLINKED_INSIGHTS: ARTPLACE_BE_URL + "/customer/pois/{id}/linkableInsights",
			UNLINKED_COUNT: ARTPLACE_BE_URL + "/customer/pois/{id}/linkableInsights/count",
		},
		[NAVIGATION_DATA.ITINERARY]: {
			COUNT: ARTPLACE_BE_URL + "/customer/itineraries/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/customer/itineraries/",
		},
		[NAVIGATION_DATA.STAGE]: {
			COUNT: ARTPLACE_BE_URL + "/customer/stages/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/customer/stages/",
		},
		[NAVIGATION_DATA.CUSTOMER]: {
			COUNT: ARTPLACE_BE_URL + "/customer/customers/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/customer/customers/",
		},
		API_TYPOLOGICS: ARTPLACE_BE_URL + "/customer/typologicals",
	},
	SUPERADMIN: {
		[NAVIGATION_DATA.BEACON]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/beacons/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/beacons/",
			POIS: ARTPLACE_BE_URL + "/superadmin/beacons/{id}/poi",
		},
		[NAVIGATION_DATA.INSIGHT]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/insights/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/insights/",
			LINKABLE_INSIGHTS: ARTPLACE_BE_URL + "/superadmin/insights/list/linkableInsights/",
			LINKABLE_COUNT: ARTPLACE_BE_URL + "/superadmin/insights/list/linkableInsights/count/",
		},
		[NAVIGATION_DATA.POI]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/pois/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/pois/",
			ITINERARIES: ARTPLACE_BE_URL + "/superadmin/pois/{id}/itineraries",
			UNLINKED_INSIGHTS: ARTPLACE_BE_URL + "/superadmin/pois/{id}/linkableInsights",
			UNLINKED_COUNT: ARTPLACE_BE_URL + "/superadmin/pois/{id}/linkableInsights/count",
		},
		[NAVIGATION_DATA.ITINERARY]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/itineraries/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/itineraries/",
		},
		[NAVIGATION_DATA.STAGE]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/stages/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/stages/",
		},
		[NAVIGATION_DATA.CUSTOMER]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/customers/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/customers/",
		},
		[NAVIGATION_DATA.NEWS]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/news/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/news/",
			PUBLISH: ARTPLACE_BE_URL + "/superadmin/news/{id}/publish",
		},
		[NAVIGATION_DATA.USERS]: {
			COUNT: ARTPLACE_BE_URL + "/superadmin/users/" + LIST_COUNT,
			ALL: ARTPLACE_BE_URL + "/superadmin/users",
		},
		API_TYPOLOGICS: ARTPLACE_BE_URL + "/superadmin/typologicals",
	},
};

export const API_UPLOAD_PHOTO = (role: string, id: string, userRole: string) => `${BE_LINKS[userRole][role].ALL}${id}/photo`;
export const API_UPLOAD_FILE = (role: string, id: string, isAudio: boolean, userRole: string) =>
	`${BE_LINKS[userRole][role].ALL}${id}/${isAudio ? "audio" : "video"}`;

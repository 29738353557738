import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import { TokenKeys } from "../../type/commons-artplace";

export function PrivateRoute() {
	const hasToken = localStorage.getItem(TokenKeys.ACCESS_TOKEN);
	const hasRefreshToken = localStorage.getItem(TokenKeys.REFRESH_TOKEN);

	if (hasToken && hasRefreshToken) {
		return <Outlet />;
	}

	return <Navigate to="/" replace />;
}

export function RoleBasedRoute({ allowedRoles }: { allowedRoles: RegistryRoleEnum[] }) {
	const userRole = localStorage.getItem("role") as RegistryRoleEnum;
	// console.log("userRole", userRole);

	if (allowedRoles.includes(userRole)) {
		return <Outlet />;
	} else {
		return <Navigate to="/unauthorized" replace />;
	}
}

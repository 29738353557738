import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { getIn } from "formik";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import { LangField } from "../../type/commons-artplace";

interface Props {
	formik?: any;
	fieldName: string;
	callBack: any;
	lang: string;
}

const LangFieldText = ({ formik, lang, fieldName, callBack }: Props) => {
	const { getIndexFromLangFields } = EntityUtilities();
	const [field, setField] = useState<string>("");

	useEffect(() => {
		if (formik && formik.values.langFields) {
			const langField = formik.values.langFields.find((element: { lang: string }) => element.lang === lang);
			setField(langField ? langField[fieldName] : "");
		}
	}, [formik, lang, fieldName]);

	const index = formik && formik.values.langFields ? getIndexFromLangFields(formik.values.langFields, lang) : -1;

	return (
		<>
			<Grid container item spacing={2}>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						id={fieldName}
						name={index !== -1 ? `langFields.${index}.${fieldName}` : ""}
						label={t(`FORM.${fieldName.toUpperCase()}`)}
						// error={formik && formik.touched.langFields && index !== -1 && !!formik.errors.langFields && !!formik.errors.langFields[index]}
						// helperText={
						// 	formik && formik.errors.langFields && index !== -1 && formik.touched.langFields?.[index] && formik.errors.langFields[index]?.[fieldName]
						// 		? t(getIn(formik.errors.langFields[index], fieldName))
						// 		: ""
						// }
						value={field}
						onChange={(event) => setField(event.target.value)}
						onBlur={(event) => callBack(lang, event, formik?.values.langFields, formik?.setFieldValue)}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default LangFieldText;

import {
	Card,
	Grid,
	CircularProgress,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	CardContent,
	Switch,
	Button,
	Divider,
	FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getEnumKeyByValue, handleModal, orderCustomersByBusinessName } from "../../../utils/util";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CheckBoxList from "./CheckBoxList";
import { OpeningHours } from "./OpeningHours";
import PoiInsightsList from "./PoiInsightsList";
import { PoiRelatedPoiList } from "./PoiRelatedPoiList";
import { AvailableConnection, availableSocials } from "../../../constants/artplace.constant";
import { ClientInterface, PoiDetailInterface, PoiInterface } from "../../../type/rest/poi-rest-interfaces";
import { Form, Formik, FormikErrors, getIn } from "formik";
import { TextEditor } from "../../../componets/textField/textEditor/TextEditor";
import { MuiPhone } from "../../../componets/muiPhone/muiphone";
import { DATATYPE, LangField, NAVIGATION_DATA, TypologicType, emptyPoi, langField } from "../../../type/commons-artplace";
import { intlPhoneRegex, urlRegex } from "../../../utils/regex";

import AddButton from "../../../componets/buttons/AddButton";
import PhotoDropZone from "../../artplace-insight/insight-add/photoDropZone";
import ConnectedItineraries from "../../../componets/pageComponents/connectedItineraries/connectedItineraries";
import SocialInputGrid from "../../../componets/socialGrid/SocialInputGrid";
import GoogleMapsCard from "../../../componets/googleMapsCard/GoogleMapsCard";
import { Autocomplete } from "@react-google-maps/api";
import { InsightDetailInterface } from "../../../type/rest/insight-rest-interfaces";
import ExpectedConnection from "../../../componets/pageComponents/expectedConnection/ExpectedConnection";
import { BE_LINKS } from "../../../api/api";
import AddEntityModal from "../../../componets/modals/addEntityModal/AddEntityModal";
import { TypologicStoreContext } from "../../../stores/TypologicStore";
import { ToastContainer, toast } from "react-toastify";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { get, patch, post, uploadPhoto } from "../../../utils/call-api";
import Ratings from "../../../componets/ratings/Ratings";
import EntityLanguageSelector from "../../../componets/entityLanguageSelector/EntityLanguageSelector";
import StatusSelector from "../../../componets/statusSelector/StatusSelector";
import { ItineraryDetailInterface } from "../../../type/rest/itinerary-rest-interfaces";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";
import IdName from "../../../componets/pageComponents/IdName/IdName";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import PhoneInputComponent from "../../../componets/muiPhone/PhoneInput";
import { Spinner } from "react-bootstrap";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import { MuiPhoneOld } from "../../../componets/muiPhone/MuiPhoneOld";
import LangFieldText from "../../../componets/langFieldText/LangFieldText";

const PoiDetail = memo(() => {
	const { id } = useParams();
	const params = useParams();
	const typologicStore = useContext(TypologicStoreContext);
	const [address, setAddress] = useState<string>("");
	//TODO globaleapiKey
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage);

	// const [setAddressDebounce, setAddressDebounce] = useState("");
	const { t } = useTranslation();
	const navigate = useNavigate();
	//contanitore data ottenuti da BE (POI totale)
	const [poiDetail, setPoiDetail] = useState<Partial<PoiDetailInterface> | null>(null);
	//loading TODO: stato globale
	const [loadingPoiDetail, setLoadingPoiDetail] = useState<boolean>(false);
	//durata evento
	const [duration, setDuration] = useState<Date | null>(null);
	//itinerari connessi
	const [connectedItineraries, setConnectedItineraries] = useState<ItineraryDetailInterface[]>([]);
	//tutti i servizi disponibili
	const [allStringsArray, setAllStringsArray] = useState<TypologicType[] | undefined>([]);
	//totale categorie presenti
	const [categories, setCategories] = useState<TypologicType[] | undefined>([]);
	//totale sottocategorie presenti
	const [subCategories, setSubCategories] = useState<TypologicType[]>([]);
	//Lista approfondimenti appartenenti al POI
	const [insightsList, setInsightsList] = useState<InsightDetailInterface[]>([]);
	//lista POI correlati a quello corrente
	const [poiList, setPoiList] = useState<PoiDetailInterface[]>();
	//Lista risultati per autocomplete Indirizzo
	const [searchResult, setSearchResult] = useState<any>();
	//gestisce switch dell'orario. true = visite solo su richiesta, false = orari fissi
	const [onRequest, setOnRequest] = useState<boolean>(false);
	//serve a formik per capire se effettuare la chiamata (devo settare in maniera asincrona dentro l'onSubmit alcuni campi)
	//totale clienti
	const [clients, setClients] = useState<ClientInterface[]>([]);
	const [formikCallback, setFormikCallback] = useState<any>();
	const [formikLabel, setFormikLabel] = useState<string>("");
	//stato modale
	const [open, setOpen] = useState<boolean>(false);
	//useState per modale
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<InsightDetailInterface[] | PoiInterface[] | undefined>();
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const { handleNameChange, handleSubtitleChange, handleDescriptionChange, handleLanguageChange, correctLanguageValue, getIndexFromLangFields } =
		EntityUtilities();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const [photos, setPhotos] = useState<File[]>([]);
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);

	const [isUploading, setIsUploading] = useState(false);

	const fetchPoiDetail = useCallback(async () => {
		setLoadingPoiDetail(true);
		try {
			setPoiDetail(null);
			setInsightsList([]);
			setAddress("");
			setPoiList([]);
			setOnRequest(false);
			//Chiamata centralizzata
			if (id) {
				const response: PoiDetailInterface = (await get(BE_LINKS[userStore.getUserRole()][NAVIGATION_DATA.POI].ALL, id, true)).data;
				if (response.langFields.find((langField: LangField) => langField.lang === currentLanguage)) {
					//se esiste già un langFields della lingua corrente OK
					setPoiDetail(response || null);
				} else {
					if (response.langFields.find((langField: LangField) => langField.lang === languageStore.defaultLanguage)) {
						//altrimenti visualizza l'entità in inglese
						setCurrentLanguage(languageStore.defaultLanguage);
						setPoiDetail(response || null);
					} else {
						//altrimenti la prima lingua disponibile nei langFields
						setCurrentLanguage(response.langFields[0].lang);
						setPoiDetail(response || null);
					}
				}
				setAddress(response.address);
				setInsightsList(response.insights || []);
				setPoiList(response.relatedPois);
				setOnRequest(response.disableOpenings);
				if (response.durationSeconds) {
					const hours = Math.floor(response.durationSeconds / 3600);
					const minutes = Math.floor((response.durationSeconds % 3600) / 60);
					setDuration(new Date(0, 0, 0, hours, minutes, 0));
				}
			} else {
				setPoiDetail({
					...emptyPoi,
					langFields: [{ ...langField, lang: currentLanguage }],
				});
			}
			//fetch itinerari collegati
		} catch (error) {
			console.error("Error fetching data:", error);
			return error;
		} finally {
			setLoadingPoiDetail(false);
		}
	}, [currentLanguage, id]);

	useEffect(() => {
		const fetchItineraries = async () => {
			const itineraries = (await get(BE_LINKS[userRole][NAVIGATION_DATA.POI][NAVIGATION_DATA.ITINERARY].replace("{id}", poiDetail?._id))).data;
			setConnectedItineraries(() => itineraries);
		};
		if (poiDetail?._id) fetchItineraries();
	}, [poiDetail]);

	const fetchServices = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getServices(DATATYPE.POI);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching services:", error);
			return [];
		}
	};

	const formatTimeData = (data: Date) => {
		const hours = data.getHours();
		const minutes = data.getMinutes();
		//console.log("Duration seconds value: ", hours * 3600 + minutes * 60);
		return hours * 3600 + minutes * 60;
	};
	const fetchCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getCategories(DATATYPE.POI);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchSubCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getSubcategories(DATATYPE.POI);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};
	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);
			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};

	const fetchOptions = async () => {
		const [services, categories, clients, subcategories] = await Promise.all([
			fetchServices(),
			fetchCategories(),
			fetchClients(),
			fetchSubCategories(),
		]);

		setSubCategories(subcategories);
		//}
		// Fetch other options as needed
		setAllStringsArray(services.sort((a, b) => t(a.label)?.localeCompare(t(b.label))));
		setCategories(categories);
		setClients(clients);

		// Handle other fetched data as needed
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoadingPoiDetail(true);
			try {
				await fetchOptions(); // Try to fetch options
				await fetchPoiDetail(); // Try to fetch PoiDetail
			} catch (error) {
				console.error("Error fetching data:", error); // Handle and log any errors
			} finally {
				setLoadingPoiDetail(false);
			}
		};

		fetchData();
	}, [id, typologicStore.getTypologicsReady]);

	function onLoad(autocomplete: any) {
		setSearchResult(autocomplete);
	}

	const onPlaceChanged = async (formikCallback: any) => {
		if (searchResult != null) {
			//variable to store the result
			const place = await searchResult.getPlace();
			setAddress(place.formatted_address); //per aggiornare agilmente il TextField
			formikCallback("address", place.formatted_address); //per aggiornare il formik
		}
	};
	const langFieldSchema = Yup.object().shape({
		name: Yup.string().required("ERRORS.FORM.REQUIRED"),
	});
	return (
		<>
			{loadingPoiDetail || !poiDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				// : !poiDetail ? (
				//   <>
				//    <EmptySection description={t("GENERAL.EMPTY_SECTION_DETAIL")} />
				//   </>
				// )
				<>
					<Formik<Partial<PoiDetailInterface>>
						enableReinitialize
						initialValues={poiDetail}
						validationSchema={Yup.object({
							langFields: Yup.array().of(langFieldSchema).required("ERRORS.FORM.REQUIRED"),
							category: Yup.object().shape({
								key: Yup.string().required("ERRORS.FORM.REQUIRED"),
								label: Yup.string().required("ERRORS.FORM.REQUIRED"),
							}),

							contacts: Yup.object().shape({
								email: Yup.string().trim().email("ERRORS.FORM.INVALID_EMAIL"),
								phone: Yup.string().trim().matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
								phone2: Yup.string().trim().matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
							}),
							socials: Yup.array().of(
								Yup.object().shape({
									url: Yup.string().notRequired().matches(urlRegex, "ERRORS.FORM.INVALID_URL"),
								}),
							),
							address: Yup.string().required("ERRORS.FORM.REQUIRED"),
						})}
						onSubmit={async (values, { setValues, setFieldValue, setSubmitting }) => {
							setIsRequestSubmitted(true);

							const filteredLangFields = values.langFields?.filter((langField) => langField.name.trim() !== "");

							const { _id, favorites, updatedAt, numberOfRate, averageRate, photos, ...newValues } = values;

							newValues.langFields = filteredLangFields;

							try {
								const relatedPoisIds = poiList?.map((el) => el._id) || [];
								const insightsIds = insightsList?.map((el) => el._id) || [];

								const result = {
									...newValues,
									relatedPois: relatedPoisIds,
									insights: insightsIds,
									socials: values.socials
										?.map((element) => ({ type: element.type, url: element.url }))
										.filter((element) => (element.type.trim() && element.url.trim()) !== ""),
									contacts: {
										...values.contacts,
										email: values.contacts && values.contacts.email?.trim() !== "" ? values.contacts.email : undefined,
									},
									address: values.address,
								};
								// console.log("Trying to submit: ", result);
								let response: { data: InsightDetailInterface; message: string };
								/* let response: { data: InsightDetailInterface; message: string };
								const response1 = await patch(BE_LINKS[userRole][NAVIGATION_DATA.POI].ALL, id, result);
								toast.success(t("MESSAGES.UPDATE_OK"));
								setPoiDetail(response.data);
								setSubmitting(false); */
								if (id) {
									response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.POI].ALL, id, result);
									toast.success(t("MESSAGES.UPDATE_OK"));
									setPoiDetail(response.data);
									setSubmitting(false);
									//fetchPoiDetail();
								} else {
									response = await post(BE_LINKS[userRole][NAVIGATION_DATA.POI].ALL, result, result.tenant);
									toast.success(t("MESSAGES.CREATION_OK"));
									setTimeout(() => {
										navigate(`/poi/detail/${response.data._id}`);
									}, 1000);
								}
								//EDIT: Le foto non vanno caricate al salvataggio modifiche ma direttamente. Logica spostata in PhotoDropZone
								/* if (result.photos) {
									try {
										await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.POI, userRole);
									} catch (error) {
										toast.error(t("Errore upload photo"));
									}
								} */
							} catch (error: any) {
								// console.log("Error submitting poi: ", error.response);
								toast.error(t("MESSAGES.UPDATE_ERROR"));
							} finally {
								setSubmitting(false);
								setDeletedPhoto([]);
								setPhotos([]);
								if (resetFiles) resetFiles();
								setTimeout(() => {
									setIsRequestSubmitted(false);
								}, 500);
							}
						}}
					>
						{(formik) => (
							<>
								<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
								<Grid container item xs={12} mb={5} justifyContent={"space-between"} alignItems={"center"}>
									<Grid xs={8}>
										<Typography variant={"h1"}>
											{poiDetail?.langFields?.find((field) => field.lang === currentLanguage)?.name || t("POI.ADD.TITLE")}
										</Typography>
										<CustomBreadcrumbs
											dataType={NAVIGATION_DATA.POI}
											currentPage={
												poiDetail?.langFields?.find((element) => element.lang === currentLanguage)?.name || t("POI.ADD.TITLE").split(" ")[0]
											}
										/>
									</Grid>
									<Button
										variant="contained"
										color="secondary"
										disabled={isRequestSubmitted || isUploading}
										onClick={() => {
											if (
												formik.errors.address ||
												formik.errors.category ||
												formik.errors.socials ||
												formik.errors.contacts ||
												formik.values.langFields?.some((value) => value.name === undefined || value.name === "")
											) {
												toast.warn(t("MESSAGES.WARNING_FORM"));
												if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
													const emptyLangs =
														formik.values.langFields?.filter((value) => value.name === undefined || value.name === "").map((value) => value.lang) ??
														[];
													const keyValues = emptyLangs?.map((element) => getEnumKeyByValue(element));
													const translatedKeys = keyValues
														.filter((key): key is string => key !== undefined)
														.map((key: string) => t(`SUPPORTED_LANGUAGES.${key}`))
														.join(",\n");
													toast.warn(t("MESSAGES.CHECK_LANGUAGES") + translatedKeys);
												}
												formik.setFieldTouched("address", true);
												formik.setFieldTouched("category", true);
												formik.setFieldTouched("langFields", true);
												formik.setFieldError("langFields", undefined);
											} else {
												formik.handleSubmit();
											}
										}}
										startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
										size="large"
									>
										{t("COMMONS.SAVE_CHANGES")}
									</Button>
								</Grid>

								<Form onSubmit={formik.handleSubmit} noValidate>
									<Grid container item spacing={2}>
										<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
											{/*R1*/}
											<Grid item xs={4} sm={4}>
												<EntityLanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} formik={formik} />
											</Grid>
											{/* TODO: NON RIMUOVERE, ANDRA' REINTRODOTTO */}
											{/* <Grid item xs={1} sm={1}>
												<StatusSelector value={formik?.values?.statusActive || true} callBack={formik.setFieldValue} insightsList={insightsList} />
											</Grid> */}
											<IdName id={id} formik={formik} lang={currentLanguage} />

											{/* <Grid xs={9} sm={9}></Grid> */}
											{/* <Grid item xs={12} sm={4}>
												<TextField
													fullWidth
													label="ID"
													name="_id"
													disabled
													variant="outlined"
													value={formik.values._id}
													InputProps={{ sx: { borderRadius: 2 } }}
												/>
											</Grid>

											<Grid item xs={12} sm={8}>
												<TextField
													fullWidth
													label={t("FORM.NAME")}
													name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.name`}
													required
													variant="outlined"
													error={
														((formik.touched.langFields as unknown as LangField[])?.[
															getIndexFromLangFields(formik.values.langFields, currentLanguage)
														] ??
															false) &&
														(!!formik.errors.langFields?.[getIndexFromLangFields(formik.values.langFields, currentLanguage)] ?? false)
													}
													helperText={
														formik.errors.langFields &&
														(formik.touched.langFields as unknown as LangField[])?.[
															getIndexFromLangFields(formik.values.langFields, currentLanguage)
														] &&
														!!formik.errors.langFields &&
														!!formik.errors.langFields[getIndexFromLangFields(formik.values.langFields, currentLanguage)]
															? t(getIn(formik.errors.langFields[getIndexFromLangFields(formik.values.langFields, currentLanguage)], "name"))
															: ""
													}
													value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.name}
													onChange={(event) => handleNameChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
												/>
											</Grid> */}
											{/* 	<Grid item xs={12} sm={12}>
												<TextField
													fullWidth
													label={t("FORM.SUBTITLE")}
													name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.subtitle`}
													variant="outlined"
													//helperText={formik.touched.title && t(formik.errors.title ?? "")}
													value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.subtitle}
													onChange={(event) => handleSubtitleChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
												/>
											</Grid> */}
											<Grid item xs={12} sm={12}>
												<LangFieldText formik={formik} fieldName={"subtitle"} callBack={handleSubtitleChange} lang={currentLanguage} />
											</Grid>
											{/*R2*/}
											<Grid item xs={12} sm={6}>
												<FormControl fullWidth variant="outlined">
													<InputLabel>{t("FORM.CATEGORY")}</InputLabel>
													<Select
														label={t("FORM.CATEGORY")}
														name="category"
														value={formik.values.category?.key || ""}
														onChange={(event) => {
															const selectedCategory = categories && categories.find((el) => el.key === event.target.value);
															formik.setFieldValue("category", selectedCategory); // Set the entire category object
														}}
														style={{ borderRadius: 8 }}
														error={formik.touched.category && !!formik.errors.category}
														/* helperText={formik.touched.category && t(formik.errors.category ?? "")} */
													>
														{categories &&
															categories.map((el: TypologicType) => (
																<MenuItem key={el.key} value={el.key}>
																	{t(el.label)}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Grid>

											{/* <Grid item xs={12} sm={4}>
												<FormControl fullWidth variant="outlined">
													<InputLabel>{t("FORM.SUBCATEGORY")}</InputLabel>
													<Select
														disabled
														label={t("FORM.SUBCATEGORY")}
														name="subcategory"
														value={formik.values.subcategory?.key || ""}
														onChange={(event) => {
															const selectedSubcategory = subCategories && subCategories.find((el) => el.key === event.target.value);
															formik.setFieldValue("subcategory", selectedSubcategory); // Set the entire category object
														}}
													>
														{subCategories &&
															subCategories.map((el: TypologicType) => (
																<MenuItem key={el.key} value={el.key}>
																	{t(el.label)}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Grid> */}

											<Grid item xs={12} sm={6}>
												<FormControl fullWidth variant="outlined">
													<InputLabel></InputLabel>
													<LocalizationProvider dateAdapter={AdapterDateFns}>
														<TimePicker
															label={t("FORM.DURATION")}
															value={duration}
															onChange={(newValue: any) => {
																setDuration(newValue);
																formik.setFieldValue("durationSeconds", formatTimeData(newValue));
															}}
															views={["hours", "minutes"]}
															format="HH:mm"
															ampm={false}
															//	onChange={formik.handleChange}
														/>
													</LocalizationProvider>

													{/* <div>{`Initialized Hours: ${valueDuration?.getHours()}, Minutes: ${valueDuration?.getMinutes()}, Seconds: ${valueDuration?.getSeconds()}`}</div> */}
												</FormControl>
											</Grid>
											{/*R3*/}
											{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
												<Grid item xs={12} sm={12}>
													<FormControl fullWidth variant="outlined">
														<InputLabel>{t("FORM.CUSTOMER")}</InputLabel>
														<Select
															disabled={params.id ? true : false}
															label={t("FORM.CUSTOMER")}
															name="tenant"
															value={formik.values.tenant || userStore.getTenant}
															onChange={formik.handleChange}
															style={{ borderRadius: 8 }}
															error={formik.touched.tenant && !!formik.errors.tenant}
														>
															{clients.map((el: ClientInterface) => (
																<MenuItem key={el._id} value={el.tenant}>
																	{el.business_name}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
											)}

											{/* {userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
												<Grid item xs={12} sm={12}>
													<FormControl fullWidth variant="outlined">
														<InputLabel>{t("FORM.CUSTOMER")}</InputLabel>
														<Select
															disabled={params.id ? true : false}
															label={t("FORM.CUSTOMER")}
															name="tenant"
															value={formik.values.tenantData?.businessName || userStore.getBusinessName}
															onChange={formik.handleChange}
															style={{ borderRadius: 8 }}
															error={formik.touched.tenant && !!formik.errors.tenant}
														>
															<MenuItem value={formik.values.tenantData?.businessName || userStore.getBusinessName}>
																{formik.values.tenantData?.businessName || userStore.getBusinessName}
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											)} */}

											{/* R4*/}
											<Grid item xs={12}>
												<Typography variant="h6" component="div" mb={1}>
													{t("POI.DETAIL.DESCRIPTION")}
												</Typography>
												<Card>
													<TextEditor
														setFieldValue={(value) => handleDescriptionChange(currentLanguage, value, formik.values.langFields, formik.setFieldValue)}
														defaultValue={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.description}
													/>
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h6" component="div" mb={1}>
													{t("POI.DETAIL.POSITION")}
												</Typography>
												{/* 		<GoogleWrapper> */}
												<Autocomplete onLoad={onLoad} onPlaceChanged={() => onPlaceChanged(formik.setFieldValue)}>
													<TextField
														fullWidth
														label={t("FORM.ADDRESS")}
														required
														variant="outlined"
														onBlur={() => formik.setFieldTouched("address", true)}
														value={address}
														error={formik.touched.address && !!formik.errors.address}
														onChange={(e) => {
															if (!formik.touched.address) {
																formik.setFieldTouched("address", true);
															}
															setAddress(e.target.value);
															//formik.setFieldValue("address", e.target.value);
														}}
													/>
												</Autocomplete>
												{!!formik.errors.address && <FormHelperText style={{ color: "#d32f2f" }}>{t(formik.errors.address)}</FormHelperText>}
												{formik.values.address && formik.values.address !== "initialize your address" ? (
													<GoogleMapsCard
														lat={formik.values?.latlng?.lat}
														lng={formik.values?.latlng?.lng}
														address={formik.values.address}
														setCoordinates={formik.setFieldValue}
													/>
												) : (
													<Grid
														container
														mt={2}
														height={"280px"}
														width={"100%"}
														alignItems={"center"}
														justifyContent={"center"}
														borderRadius={2}
														style={{ backgroundColor: "grey" }}
													>
														<Typography color={"white"}>{t("COMMONS.NO_ADDRESS")}</Typography>
													</Grid>
												)}
												{/* 	</GoogleWrapper> */}
											</Grid>
											{/* <Grid item xs={12} spacing={2}>
												<DataTable
													columns={columns}
													paginationUrl={API_REGISTRY_PAGINATION}
													selectionEnabled={false}
													onRowSelectionChange={setRowSelectionModel}
													rowSelected={rowSelectionModel}
													enabledSearch={false}
													onlyCustomFilter={false}
												/>
											</Grid> */}
											<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
												<Grid item>
													<Typography variant="h6" component="div" mb={1} mt={2}>
														{t("POI.DETAIL.INSIGHTS")}
													</Typography>
												</Grid>
												<Grid item>
													<AddButton
														length={insightsList?.length || 0}
														onClick={() => {
															setModalTitle(() => t("INSIGHTS.ADD.TITLE"));
															setModalContent(() => insightsList);
															setModalCallBack(() => setInsightsList);
															setFormikCallback(() => formik.setFieldValue);
															setFormikLabel("insights");
															setModalDataType(() => DATATYPE.INSIGHTS);
															handleModal(open, setOpen);
														}}
														component={undefined}
													/>
												</Grid>
												<Grid item xs={12}>
													<PoiInsightsList insights={insightsList ?? []} dataType={DATATYPE.INSIGHTS} />
												</Grid>
											</Grid>
											<Divider />

											<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
												<Grid item>
													<Typography variant="h6" component="div" mb={1} mt={2}>
														{t("POI.DETAIL.RELATED")}
													</Typography>
												</Grid>
												<Grid item>
													<AddButton
														length={poiList?.length || 0}
														onClick={() => {
															setModalTitle(() => t("POI.ADD.TITLE"));
															setModalContent(() => poiList);
															setModalCallBack(() => setPoiList);
															setFormikCallback(() => formik.setFieldValue);
															setFormikLabel("relatedPois");
															setModalDataType(() => DATATYPE.POI);
															handleModal(open, setOpen);
														}}
														component={undefined}
													/>
												</Grid>
												<Grid item xs={12}>
													<PoiRelatedPoiList relatedPois={poiList ?? []} />
												</Grid>
											</Grid>
											<Divider />
											<Grid container item xs={12} spacing={2}>
												<Grid item xs={12} sm={12}>
													<Typography variant="h6" component="div" mt={2}>
														{t("POI.DETAIL.CONTACTS")}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={12} md={6}>
													<Grid container item>
														<MuiPhoneOld
															value={formik.values.contacts?.phone}
															onChange={(phone: any) => {
																if (phone === "" || phone.length < 5) {
																	formik.setFieldValue("contacts.phone", undefined);
																} else {
																	formik.setFieldValue("contacts.phone", phone);
																}
															}}
															onBlur={() => formik.setFieldTouched("contacts.phone")}
															error={getIn(formik.touched.contacts, "phone") && !!getIn(formik.errors.contacts, "phone")}
															helperText={getIn(formik.touched.contacts, "phone") && t(getIn(formik.errors.contacts, "phone") ?? "")}
															label={t("SIGN_UP.PHONE")}
															disabled={loadingPoiDetail}
														/>
													</Grid>
													<Grid container item pt={2}>
														<MuiPhoneOld
															value={formik.values.contacts?.phone2}
															onChange={(phone2: any) => {
																if (phone2 === "" || phone2.length < 5) {
																	formik.setFieldValue("contacts.phone2", undefined);
																} else {
																	formik.setFieldValue("contacts.phone2", phone2);
																}
															}}
															onBlur={() => formik.setFieldTouched("contacts.phone2")}
															error={getIn(formik.touched.contacts, "phone2") && !!getIn(formik.errors.contacts, "phone2")}
															helperText={getIn(formik.touched.contacts, "phone2") && t(getIn(formik.errors.contacts, "phone2") ?? "")}
															label={t("SIGN_UP.PHONE2")}
															disabled={loadingPoiDetail}
														/>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={12} md={6}>
													<TextField
														fullWidth
														label="Email"
														name="contacts.email"
														id="contacts.email"
														type="contacts.email"
														variant="outlined"
														error={getIn(formik.touched.contacts, "email") && !!getIn(formik.errors.contacts, "email")}
														helperText={
															getIn(formik.touched.contacts, "email") && getIn(formik.errors.contacts, "email")
																? t(getIn(formik.errors.contacts, "email"))
																: ""
														}
														//error={!!formik.errors.email}
														value={formik.values.contacts?.email}
														onBlur={() => formik.setFieldTouched("contacts.email")}
														onChange={(e) => {
															if (e.target.value === "") {
																formik.setFieldValue("contacts.email", undefined);
															} else {
																formik.setFieldValue("contacts.email", e.target.value);
															}
														}}
														InputProps={{ sx: { borderRadius: 2 } }}
													/>

													<Grid container item pt={2}>
														<TextField
															fullWidth
															label="Website"
															name="website"
															id="website"
															type="website"
															variant="outlined"
															value={formik.values.website || ""}
															onChange={formik.handleChange}
														/>
													</Grid>
												</Grid>
											</Grid>

											<Grid container item xs={12} spacing={2}>
												<SocialInputGrid availableSocials={availableSocials} formik={formik} />
											</Grid>
										</Grid>

										<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
											<Grid item xs={12}>
												{/* TODO-METTERE FOR */}
												<Grid item xs={12} pt={9}>
													<PhotoDropZone
														photos={poiDetail.photos || []}
														setPhotoList={setPhotos}
														resetFiles={(action) => setResetFiles(action)}
														navigationData={NAVIGATION_DATA.POI}
														setEntity={setPoiDetail}
														setIsUploading={setIsUploading}
													/>
												</Grid>
												{/* <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions> */}
											</Grid>
											<Grid item xs={12} style={{ height: "30rem", overflow: "scroll", overflowX: "hidden" }}>
												<Card style={{ border: "1px solid rgba(0,0,0,0.2)", borderRadius: 8 }}>
													<CardContent sx={{ mt: 0 }}>
														<Typography variant="h6" component="div" mb={2}>
															{t("POI.DETAIL.AVAILABLE_SERVICES")}
														</Typography>
														<CheckBoxList
															allStrings={allStringsArray}
															enabledStrings={formik.values.services || []}
															onChange={formik.setFieldValue}
														/>
													</CardContent>
													{/* <CardActions>
                    <Button size="small">Learn More</Button>
                  </CardActions> */}
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
													<CardContent sx={{ mt: 0 }}>
														<Grid container xs={12} alignItems={"space-between"}>
															<Grid item xs={6}>
																<Typography variant="h6" component="div" mb={2}>
																	{t("POI.DETAIL.OPENINGS")}
																</Typography>
															</Grid>
															<Grid container item xs={6} alignItems={"baseline"}>
																<Grid item xs={5}>
																	<Typography variant="subtitle2" component="div" mb={2}>
																		{t("POI.DETAIL.REQUEST_OPENING")}
																	</Typography>
																</Grid>
																<Grid item xs={1}>
																	<Switch
																		checked={onRequest}
																		onChange={() => {
																			setOnRequest(!onRequest);
																			formik.setFieldValue("disableOpenings", !onRequest);
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														<OpeningHours
															openingHours={poiDetail?.openings ?? {}}
															setOpeningHours={formik.setFieldValue}
															onRequest={formik.values.disableOpenings || false}
														/>
													</CardContent>
												</Card>
											</Grid>
											<ConnectedItineraries itineraries={connectedItineraries || []} language={currentLanguage} />

											<Grid item xs={12}>
												<ExpectedConnection
													state={formik.values.connection || AvailableConnection.HIGH.toLowerCase()}
													setState={formik.setFieldValue}
													isvalid={formik.isValid}
													error={!!formik.touched.connection && Boolean(formik.errors.connection)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Ratings averageRate={poiDetail.averageRate} numberOfRate={poiDetail.numberOfRate} favorites={poiDetail.favorites?.length} />
											</Grid>
										</Grid>
									</Grid>
									<br />
								</Form>
							</>
						)}
					</Formik>
				</>
			)}
			{open && modalDataType && (
				<Grid container xs={12}>
					<AddEntityModal
						open={open}
						title={modalTitle}
						onClose={() => handleModal(open, setOpen)}
						callBack={modalCallBack}
						formikCallback={formikCallback}
						formikLabel={formikLabel}
						initialList={modalContent}
						dataType={modalDataType}
						tenant={poiDetail?.tenant}
					/>
				</Grid>
			)}
		</>
	);
});

export default PoiDetail;

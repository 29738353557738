import React, { useState } from "react";
import "../../../colors.css";
import { formatInsight } from "../commons";
import CustomDataGrid from "../../../componets/dataGrid/CustomDataGrid";
import { INSIGHT_FILTERS, DATATYPE, NAVIGATION_DATA } from "../../../type/commons-artplace";

const InsightList: React.FC = () => {
	const type = NAVIGATION_DATA.INSIGHT;
	const [searchField, setSearchField] = useState<string>(INSIGHT_FILTERS.name);

	// useEffect(() => {
	// 	const getData = async () => {
	// 		setLoadingInsightList(true);
	// 		try {
	// 			const response = await fetchData(JSON_BACK.SUPERADMIN.INSIGHTS.ALL , searchTerm, aToken, searchField,page, pageSize);
	// 			const formattedRows = response.data.map((item: InsightDetailInterface) => formatInsight(item));
	// 			setInsightList(formattedRows);
	// 		} catch (error) {
	// 			console.error("Error fetching data:", error);
	// 		} finally {
	// 			setLoadingInsightList(false);
	// 		}
	// 	};
	// 	getData();
	// }, [aToken, page, pageSize, searchField, searchTerm]);

	// const getTotalNumber= async ()=>{
	// 	const data = await fetchData(JSON_BACK.SUPERADMIN[type].COUNT,searchTerm,aToken,searchField);
	// 	return data.data
	// }

	return (
		<>
			<CustomDataGrid type={type} numberRows={DATATYPE.INSIGHT_LIST} searchField={searchField} formatRows={formatInsight} />
		</>
	);
};

export default InsightList;

import { createContext } from "react";
import { makeAutoObservable, toJS } from "mobx";
import { DATATYPE, TypologicalEnum, USER_ROLE } from "../type/commons-artplace";

interface TypologicItem {
	key: string;
	label: string;
}

export class TypologicStore {
	public typologicArray: { [key: string]: TypologicItem[] } = {};
	private services: TypologicItem[] = [];
	private categories: TypologicItem[] = [];
	private subcategories: TypologicItem[] = [];
	private typologicsReady: boolean = false;
	constructor() {
		makeAutoObservable(this);
	}
	setTypologics = (typologicArray: any) => {
		for (let element of typologicArray) {
			if (!this.typologicArray[element.type]) {
				this.typologicArray[element.type] = [{ key: element.key, label: element.label }];
			} else {
				this.typologicArray[element.type].push({ key: element.key, label: element.label });
			}
		}
	};
	/* 	getCategories = (dataType: DATATYPE) => {
		const label = dataType + "_CATEGORY";
		const enumLabel = TypologicalEnum[label as keyof typeof TypologicalEnum];
		if (this.typologicArray && Object.keys(this.typologicArray).includes(enumLabel)) {
			return toJS(this.typologicArray[enumLabel]);
		} else {
			console.log("Tipologica non presente");
			return [];
		}
	};
	getSubcategories = (dataType: DATATYPE) => {
		const label = dataType + "_SUB_CATEGORY";
		const enumLabel = TypologicalEnum[label as keyof typeof TypologicalEnum];
		if (this.typologicArray && Object.keys(this.typologicArray).includes(enumLabel)) {
			return toJS(this.typologicArray[enumLabel]);
		} else {
			console.log("Tipologica non presente");
			return [];
		}
	};
	getServices = (dataType: DATATYPE) => {
		const label = dataType + "_SERVICES";
		const enumLabel = TypologicalEnum[label as keyof typeof TypologicalEnum];
		if (this.typologicArray && Object.keys(this.typologicArray).includes(enumLabel)) {
			return toJS(this.typologicArray[enumLabel]);
		} else {
			console.log("Tipologica non presente");
			return [];
		}
	}; */
	private getTypologicItems = (dataType: DATATYPE, itemType: string) => {
		const enumLabel = TypologicalEnum[(dataType + "_" + itemType) as keyof typeof TypologicalEnum];
		return toJS(this.typologicArray[enumLabel] || []);
	};

	getCategories = (dataType: DATATYPE) => {
		return this.getTypologicItems(dataType, "CATEGORY");
	};

	getSubcategories = (dataType: DATATYPE) => {
		return this.getTypologicItems(dataType, "SUB_CATEGORY");
	};

	getServices = (dataType: DATATYPE) => {
		return this.getTypologicItems(dataType, "SERVICES");
	};
	getAllTypoligics = () => {
		if (this.typologicArray) return toJS(this.typologicArray);
		else return [];
	};
	public get getTypologicsReady() {
		return this.typologicsReady;
	}
	setTypologicsReady = () => {
		// console.log("Tipologiche pronte");
		this.typologicsReady = true;
	};

	clearTypologicStore = () => {
		this.typologicArray = {};
		this.typologicsReady = false;
	};
}

export const TypologicStoreContext = createContext(new TypologicStore());

import { createContext } from "react";
import { makeAutoObservable, toJS } from "mobx";
import i18next from "i18next";
export class LanguageStore {
	public defaultLanguage: string = "en";
	public globalLanguage: string = localStorage.getItem("lang") || this.defaultLanguage;

	constructor() {
		makeAutoObservable(this);
	}

	public get getGlobalLanguage(): string {
		return this.globalLanguage || this.defaultLanguage;
	}
	public get getDefaultLanguage(): string {
		return this.defaultLanguage;
	}

	public set setGlobalLanguage(languageCode: string) {
		this.globalLanguage = languageCode;
	}

	clearLanguageStore = () => {
		this.globalLanguage = this.defaultLanguage;

		localStorage.removeItem("lang");

		i18next.changeLanguage(this.defaultLanguage);
	};
}

export const LanguageStoreContext = createContext(new LanguageStore());

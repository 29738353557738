import axios, { AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults } from "axios";
import { API_REFRESH, ARTPLACE_BE_URL } from "./api";

import { TokenKeys } from "../type/commons-artplace";
import { UserStore } from "../stores/UserStore";
import { ClearAllStores } from "../stores/ClearAllStores";

const axiosConfig: CreateAxiosDefaults = {
	baseURL: ARTPLACE_BE_URL || undefined,
	headers: {
		"Content-Type": "application/json",
	},
	//withCredentials: true,
	timeout: 99000,
};

let isRefreshing = false;
let retryQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];

// per eventuali chiamate che nono devono passare per il tipico flusso di interceptor
export const standardAxios = axios.create(axiosConfig);

// TODO - Axios interceptor for automatic token refresh
export const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(
	(config: any) => {
		//Add the token to the request headers
		const aToken = localStorage.getItem(TokenKeys.ACCESS_TOKEN);
		if (aToken) {
			config.headers["x-access-token"] = aToken;
		} else {
			console.log("accesToken not found");
		}
		return config;
	},

	(error) => {
		console.log("Error request: ", error);
	},
);

const axiosInterceptor = (store: UserStore, navigate: any) => {
	const resetAllStores = ClearAllStores();
	//esegue la coda di request "sospese". Le risolve e rejecta, a seconda della situazione
	const processQueue = (error: any) => {
		retryQueue.forEach((prom: any) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve();
			}
		});

		retryQueue = [];
	};
	//effettua refresh del token. Eventuali errori sono intercettati dall'interceptor delle response
	const refreshAccessToken = async (rToken: string): Promise<any> => {
		const response = await axiosInstance.post(API_REFRESH, { refreshToken: rToken });
		return response.data;
	};

	axiosInstance.interceptors.response.use(
		(response: AxiosResponse) => {
			return response;
		},
		async (error) => {
			const originalRequest: AxiosRequestConfig & { _retry: boolean } = error.config;
			if (error.response?.status === 401 && !originalRequest._retry) {
				//Se la chiamata di refresh va in errore, slogga forzatamente l'utente loggato
				if (error.config.url.startsWith(API_REFRESH)) {
					localStorage.clear();
					resetAllStores();
					store.setLogoutModal(true);
					navigate("/login");
				}
				if (isRefreshing) {
					return new Promise(function (resolve, reject) {
						retryQueue.push({ resolve, reject });
					}).then(() => {
						return axiosInstance(originalRequest);
					});
					/* 	.catch((err) => {
							console.log("Catch di isRefreshing");
							return Promise.reject(err);
						});*/
				}
				// Set a flag to indicate that the token is being refreshed
				isRefreshing = true;
				originalRequest._retry = true;
				const rToken = localStorage.getItem(TokenKeys.REFRESH_TOKEN);
				// Refresh the token and retry the original request
				return new Promise<AxiosResponse>((resolve, reject) => {
					if (!rToken) {
						isRefreshing = false;
						console.log("No rToken stored");
						reject("No rToken stored");
					} else
						refreshAccessToken(rToken)
							.then(async (res) => {
								if (!(res?.data?.aToken && res?.data?.rToken)) {
									console.log("No token found in response");
									reject("No token found in response");
									localStorage.clear();
									resetAllStores();
									store.setLogoutModal(true);
									navigate("/login");
								} else {
									localStorage.setItem(TokenKeys.ACCESS_TOKEN, res.data.aToken);
									localStorage.setItem(TokenKeys.REFRESH_TOKEN, res.data.rToken);
									console.log("Nuovi token acquisiti");
									processQueue(null);
									originalRequest._retry = true;
									resolve(axiosInstance(originalRequest));
								}
							})
							.catch(async (error) => {
								processQueue(error);
								console.log("Refresh error: ", JSON.stringify(error));
								reject(error);
								localStorage.clear();
								resetAllStores();
								store.setLogoutModal(true);
								navigate("/login");
							})
							.finally(() => {
								isRefreshing = false;
							});
				});
			}
			return Promise.reject(error);
		},
	);
};

export default axiosInterceptor;

import sharedStyles from "../../../landingPage/Shared.module.css";
import { Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import { t } from "i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, FormikTouched } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import LandingPage from "../../../landingPage/LandingPage";
import { toast_error } from "../../../../utils/custom-toast";
import i18n from "../../../../i18n";
import YupPassword from "yup-password";
import { API_AUTH_NEW_PASSWORD, API_AUTH_RECOVERY_PASSWORD } from "../../../../api/api";
import { Alert, Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IAlert } from "../../../../type/type";

YupPassword(Yup);
const ResetPasswordSchema = Yup.object().shape({
	newPassword: Yup.string()
		.trim()
		.required("ERRORS.FORM.REQUIRED")
		.min(8, "ERRORS.FORM.SHOULD_BE_AT_LEAST_8_CHARACTERS")
		.max(24, "ERRORS.FORM.SHOULD_BE_AT_MOST_24_CHARACTERS")
		.minLowercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
		.minUppercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
		.minNumbers(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
		.minSymbols(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT"),
	newPasswordConfirm: Yup.string()
		.oneOf([Yup.ref("newPassword")], "ERRORS.FORM.INVALID_PASSWORD_MATCH")
		.required("ERRORS.FORM.REQUIRED"),
});

const NewPassword = () => {
	const [mostraPwd, setMostraPwd] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const { token } = useParams();
	const navigate = useNavigate();
	const fullLanguageCode = i18n.language;
	const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const change = searchParams.get("change");

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "LOGIN.SUBMIT_ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({ ...alert, show: false });
		},
	});

	// const authApiService = new AuthApiService();

	const recoverPassword = async (values: { newPassword: string; newPasswordConfirm: string }) => {
		setLoading(true);
		const payload = { pwd: values.newPassword, confirmPwd: values.newPasswordConfirm };
		const header = {
			headers: {
				"x-access-token": token,
				"Accept-Language": languageCode,
			},
		};
		const apiEndPoint = change === "true" ? API_AUTH_NEW_PASSWORD : API_AUTH_RECOVERY_PASSWORD;
		try {
			const response = await axios.post(apiEndPoint, payload, header);
			if (response.data.code === 0) {
				navigate("/confirm-password/ok");
			} else {
				console.log(response.data.message);
			}
		} catch (error: any) {
			if (error.response.data.code === 62) {
				toast_error(t("LOGIN.PASSWORD_EXPIRED"));
				navigate("/confirm-password/expired");
			} else {
				setAlert({
					...alert,
					show: true,
					message: error.response.data.message,
					dismissible: true,
					onClose: () => {
						setAlert({ ...alert, show: false });
					},
				});
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<LandingPage>
			{/* <ToastContainer /> */}
			<Formik initialValues={{ newPassword: "", newPasswordConfirm: "" }} validationSchema={ResetPasswordSchema} onSubmit={recoverPassword}>
				{({ handleSubmit, values, errors, touched, handleChange, handleBlur, isValid }) => {
					const formIsTouched = Object.keys(touched).some(
						(field) => touched[field as keyof FormikTouched<{ newPassword: string; newPasswordConfirm: string }>],
					);
					const isSubmitDisabled = !formIsTouched || !isValid;
					return (
						<Form onSubmit={handleSubmit}>
							{change === "true" ? (
								<Box>
									<Typography variant="h4" sx={{ marginBottom: "5px" }}>
										{t("COMMONS.NEW_PASSWORD")}
									</Typography>
									<Typography variant="subtitle1" sx={{ fontWeight: "400", color: "#8c8c8c" }}>
										{t("LOGIN.RESET_PASSWORD_DESCRIPTION")}
									</Typography>
								</Box>
							) : (
								<Box>
									<Typography variant="h4" sx={{ marginBottom: "5px" }}>
										{t("COMMONS.RESET_PASSWORD")}
									</Typography>
									<Typography variant="subtitle1" sx={{ fontWeight: "400", color: "#8c8c8c" }}>
										{t("LOGIN.RESET_PASSWORD_DESCRIPTION")}
									</Typography>
								</Box>
							)}
							{alert.show ? (
								<Box sx={{ height: "70px" }}>
									<Alert
										className="mb-3"
										severity={alert.severity}
										action={
											<IconButton aria-label="close" color="inherit" size="small" onClick={alert.onClose}>
												<CloseIcon fontSize="inherit" />
											</IconButton>
										}
									>
										{t(alert.message)}
									</Alert>
								</Box>
							) : (
								<Box sx={{ height: "70px" }}></Box>
							)}
							<div className={sharedStyles.container}>
								{/* <InputGroup className={sharedStyles.inputGroupWithButton}> */}
								<Box marginBottom={"15px"}>
									<TextField
										id="newPassword"
										fullWidth
										name="newPassword"
										type={mostraPwd ? "text" : "password"}
										label={t("COMMONS.NEW_PASSWORD")}
										value={values.newPassword}
										className={sharedStyles.inputGroupWithButton}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.newPassword && !!errors.newPassword}
										helperText={touched.newPassword && errors.newPassword && t(errors.newPassword)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Box>
								{/* </InputGroup> */}
								<Box marginBottom={"10px"}>
									<TextField
										id="newPasswordConfirm"
										fullWidth
										name="newPasswordConfirm"
										type={mostraPwd ? "text" : "password"}
										label={t("COMMONS.CONFIRM_PASSWORD")}
										value={values.newPasswordConfirm}
										className={sharedStyles.inputGroupWithButton}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.newPasswordConfirm && !!errors.newPasswordConfirm}
										helperText={touched.newPasswordConfirm && errors.newPasswordConfirm && t(errors.newPasswordConfirm)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</div>
							<div style={{ display: "flex", justifyContent: "end" }}>
								<Button type="submit" sx={{ textTransform: "Capitalize" }} variant={"contained"} fullWidth disabled={isSubmitDisabled} size={"large"}>
									{!loading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LandingPage>
	);
};

export default NewPassword;

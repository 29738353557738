import { Grid, CircularProgress, Typography, TextField, Button } from "@mui/material";
import { SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../api/axios-interceptor";
import { mockyCollection } from "../../../data/apiCall.mockup";
import { PoiDetailInterface, PoiInterface } from "../../../type/rest/poi-rest-interfaces";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import IdName from "../../../componets/pageComponents/IdName/IdName";
import SaveButton from "../../../componets/buttons/SaveButton";
import PhotoDropZone from "../../artplace-insight/insight-add/photoDropZone";
import { CustomerDetailInterface } from "../../../type/rest/customer-rest-interface";
import { PoiRelatedPoiList } from "../../artplace-poi/poi-detail/PoiRelatedPoiList";
import SectionContainer from "../../artplace-insight/insight-add/sectionContainer";
import AddButton from "../../../componets/buttons/AddButton";
import AddEntityModal from "../../../componets/modals/addEntityModal/AddEntityModal";
import { DATATYPE, NAVIGATION_DATA, emptyCustomer } from "../../../type/commons-artplace";
import { ItineraryDetailInterface, ItineraryListInterface } from "../../../type/rest/itinerary-rest-interfaces";
import { ItineraryRelatedPoiList } from "./RelatedItineraryList";
import { get, patch, post, uploadPhoto } from "../../../utils/call-api";
import { BE_LINKS } from "../../../api/api";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const CustomerDetail = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [customerDetail, setCustomerDetail] = useState<Partial<CustomerDetailInterface> | null>(null);
	const [loadingCustomerDetail, setLoadingCustomerDetail] = useState<boolean>(false);
	//const [itineraryList, setItineraryList] = useState<ItineraryDetailInterface[] | null>([]);
	const [poiList, setPoiList] = useState<PoiDetailInterface[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<ItineraryDetailInterface[] | PoiInterface[] | undefined>();
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);

	const handleModal = () => {
		setOpen((prev) => !prev);
	};

	const fetchCustomerData = useCallback(async () => {
		setCustomerDetail(null);
		setPoiList([]);
		//setItineraryList([]);
		setLoadingCustomerDetail(true);
		try {
			if (id) {
				const data: CustomerDetailInterface = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, id, true)).data;

				setCustomerDetail(data || []);
				//setItineraryList(data.itineraryList || []);
				const poiData: PoiDetailInterface[] = (await get(BE_LINKS[userRole][NAVIGATION_DATA.POI].ALL, undefined, true, data.tenant)).data;
				// console.log("POIs del cliente: ", poiData);
				setPoiList(poiData || []);
			} else {
				setCustomerDetail({
					...emptyCustomer,
				});
			}
			setLoadingCustomerDetail(false);
		} catch (error: any) {
			// console.log(error, "error");
		} finally {
			setLoadingCustomerDetail(false);
		}
	}, [id]);

	useEffect(() => {
		const fetchData = async () => {
			setLoadingCustomerDetail(true);
			try {
				await fetchCustomerData();
			} catch (error) {
				console.error("Error fetching data:", error); // Handle and log any errors
			} finally {
				setLoadingCustomerDetail(false);
			}
		};

		fetchData();
	}, [id]);
	const validationSchema = id
		? Yup.object({
				business_name: Yup.string().required("ERRORS.FORM.REQUIRED").min(3),
		  })
		: Yup.object({
				business_name: Yup.string().required("ERRORS.FORM.REQUIRED").min(3),
				email: Yup.string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
		  });
	return (
		<>
			{loadingCustomerDetail || !customerDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<Formik<Partial<CustomerDetailInterface>>
					enableReinitialize
					initialValues={customerDetail}
					validationSchema={validationSchema}
					onSubmit={async (values, { setSubmitting }) => {
						setIsRequestSubmitted(true);
						// console.log("Trying to submit: ", values);
						let response: any;
						const result = { ...values };

						if (id) {
							try {
								response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, id, values, undefined);

								setCustomerDetail(response.data);
								//console.log("Response from PATCH: ", response.data);
								toast.success(t("MESSAGES.UPDATE_OK"));
							} catch (error) {
								// console.log("Error PATCH CUSTOMER: ", error);
								toast.error(t("MESSAGES.UPDATE_ERROR"));
							}
						} else {
							try {
								response = (await post(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, values, undefined, false)).data;
								// console.log("BE response POST Customer: ", response);
								toast.success(t("MESSAGES.CREATION_OK"));
								setTimeout(() => {
									navigate(`/customers/detail/${response._id}`);
								}, 1000);
							} catch (error:any) {
								// console.log("Error POST CUSTOMER: ", error);

								
								if(error.response?.data?.code === "REG_046") 								
									toast.error(t("MESSAGES.EMAIL_EXISTS"));
								else toast.error(t("MESSAGES.CREATION_ERROR"));
								
							}
						}
						setSubmitting(false);
						setTimeout(() => {
							setIsRequestSubmitted(false);
						}, 500);
					}}
				>
					{(formik) => (
						<>
							<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
							<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
								<Grid flexDirection={"column"}>
									<Typography variant={"h1"}>{customerDetail?.business_name || t("CUSTOMERS.ADD.TITLE")} </Typography>
									<CustomBreadcrumbs
										dataType={NAVIGATION_DATA.CUSTOMER}
										currentPage={customerDetail?.business_name || t("POI.ADD.TITLE").split(" ")[0]}
									/>
								</Grid>
								<Button
									variant="contained"
									color="secondary"
									onClick={() => {
										if (!formik.values.business_name || (!id && !formik.values.email)) {
											toast.warn(t("MESSAGES.WARNING_FORM"), {
												className: "yellowToastWarning",
											});
											formik.setFieldTouched("business_name", true);
											if (!id) {
												formik.setFieldTouched("email", true);
											}
										} else {
											formik.handleSubmit();
										}
									}}
									startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
									size="large"
									disabled={isRequestSubmitted /* || !formik.isValid || !formik.dirty */}
								>
									{/* {!id ? t("COMMONS.CONFIRM") : t("COMMONS.SAVE_CHANGES")} */}
									{ t("COMMONS.SAVE_CHANGES")}
									</Button>
							</Grid>
							<Form onSubmit={formik.handleSubmit}>
								<Grid container item spacing={2}>
									<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
										{/* <IdName id={id} formik={formik} /> */}
										{id && (
											<Grid item xs={12} sm={4}>
												<TextField fullWidth label="ID" name="_id" disabled variant="outlined" value={formik.values._id} />
											</Grid>
										)}
										<Grid item xs={id ? 8 : 12} sm={id ? 8 : 12}>
											<TextField
												fullWidth
												label={t("FORM.NAME")}
												required
												name="business_name"
												variant="outlined"
												onBlur={formik.handleBlur}
												error={formik.touched.business_name && !!formik.errors.business_name}
												helperText={formik.touched.business_name && t(formik.errors.business_name ?? "")}
												value={formik?.values?.business_name}
												onChange={formik.handleChange}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<TextField
												fullWidth
												required
												disabled={id ? true : false}
												label="Email"
												name="email"
												id="email"
												type="email"
												variant="outlined"
												error={formik.touched.email && !!formik.errors.email}
												helperText={formik.touched.email && t(formik.errors.email ?? "")}
												value={id ? formik.values.id_contact[0]?.contact : formik.values.email}
												onBlur={formik.handleBlur}
												onChange={(e) => {
													const trimmedValue = e.target.value.trim();
													formik.setFieldValue("email", trimmedValue);
												}}
											/>
										</Grid>

										{id && (
											<>
												<Grid item xs={12}>
													<SectionContainer name={t("POI.LIST.TITLE")}>
														{/* <AddButton
															onClick={() => {
																setModalTitle(() => t("POI.ADD.TITLE"));
																setModalContent(() => poiList);
																setModalCallBack(() => setPoiList);
																setModalDataType(() => DATATYPE.POI);
																handleModal();
															}}
															component={undefined}
														/> */}
													</SectionContainer>
													<PoiRelatedPoiList relatedPois={poiList} />
												</Grid>
											</>
										)}
									</Grid>

									<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
										{/* <Grid item xs={12}>
											<PhotoDropZone photos={formik.values.photos || []} setPhotoList={formik.setFieldValue} />
										</Grid> */}
									</Grid>
								</Grid>
							</Form>
						</>
					)}
				</Formik>
			)}
			{open && modalDataType && (
				<AddEntityModal
					open={open}
					title={modalTitle}
					onClose={handleModal}
					callBack={modalCallBack}
					initialList={modalContent}
					dataType={modalDataType}
				/>
			)}
		</>
	);
};

export default CustomerDetail;

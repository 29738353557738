import { FormControl, FormHelperText, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { TypologicType } from "../../../type/commons-artplace";
import { useTranslation } from "react-i18next";

interface DetailSelectProps {
	completeList:TypologicType[] | undefined ;
	error:any;
	touched: boolean | undefined;
	value: string;
	setState: (value: string) => void;
	label: string;
	name: string;
	onBlur:any;	
	required?: boolean;
}

function DetailSelect({ completeList, error, touched, value, setState, label, name,onBlur, required }: DetailSelectProps) {
	const { t } = useTranslation();
	const theme = useTheme();
	const redColor = theme.palette.error.light;

	const handleChange = (value: any) => {
		setState(value);
	};

	return (
		<FormControl fullWidth variant="outlined">
			<InputLabel  id="Label" error={touched && error} required={required}>
				{label}
			</InputLabel>
			<Select
				// sx={touched && error ? { border: "1px solid" + redColor } : null}
				label={label}
				name={name}
				value={value}
				onChange={(event) => handleChange(event.target.value)}
				onBlur={onBlur}
				error={touched && error}
			>
				{completeList && completeList.map((el) => (
					<MenuItem key={el.key} value={el.key}>
						{t(el.label)}
					</MenuItem>
				))}
			</Select>
			<FormHelperText sx={{ color: redColor }}>{touched && t(error?.key)}</FormHelperText>
		</FormControl>
	);
}

export default DetailSelect;

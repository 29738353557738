import { stringify } from "qs";
import { BeaconInterface } from "../type/rest/beacon-rest-interface";
import { PoiDetailInterface } from "../type/rest/poi-rest-interfaces";
import { BeaconDetailInterface } from "../type/rest/beacon-rest-interface";
import { t } from "i18next";
import EntityUtilities from "./entityUtilities/EntityUtilities";
import { SupportedDynamicLanguageEnum, TokenKeys } from "../type/commons-artplace";
import { post } from "./call-api";
import { BE_LINKS } from "../api/api";

export class Util {
	// DA TOGLIERE
	public static getContact(row: any, contactType: string) {
		if (row?.id_contact) {
			const contact = row?.id_contact.filter((c: any) => c.id_contact_type.code === contactType);
			return contact[0].contact;
		} else {
			return "";
		}
	}

	public static getSearchParams(params: any, suffix?: string) {
		const copyParams = params;
		suffix = "?";
		Object.entries(copyParams).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				suffix = suffix + value.map((keyValuePair) => new URLSearchParams({ [key]: keyValuePair })).join("&") + "&";
				delete params[key];
			}
		});

		return suffix + stringify(params);
	}

	public static formatStripePrice = (price: string): string => {
		return (parseInt(price, 10) / 100).toFixed(2);
	};
}

export const reworkData = (objects: BeaconDetailInterface[] | PoiDetailInterface | PoiDetailInterface[], language: string) => {
	const { formatLangFields, createUrlFile } = EntityUtilities();
	if (Array.isArray(objects)) {
		// It's an array
		return objects.map((item: BeaconInterface | PoiDetailInterface) => {
			if ("major" in item && "minor" in item) {
				// It's a BeaconInterface
				const beacon = item as BeaconDetailInterface;
				return {
					name: beacon.name,
					id: beacon._id,
					description: `${beacon.minor} - ${beacon.major}`,
					cover: createUrlFile(beacon.photos[0]),
				};
			} else {
				// It's a PoiInterface
				const poi = item as PoiDetailInterface;
				return {
					name: formatLangFields(poi.langFields)[language]
						? formatLangFields(poi.langFields)[language].name
						: formatLangFields(poi.langFields)["en"]
						  ? formatLangFields(poi.langFields)["en"]?.name
						  : formatLangFields(poi.langFields)[0]
						    ? formatLangFields(poi.langFields)[0].name
						    : t("COMMONS.NO_VALUE"),
					id: poi._id,
					description: poi.category ? poi.category.label : t("COMMONS.NO_CATEGORY"),
					cover: createUrlFile(poi.photos[0]),
				};
			}
		});
	} else {
		// It's a single object
		if ("major" in objects && "minor" in objects) {
			// It's a BeaconInterface
			const beacon = objects as unknown as BeaconDetailInterface;
			return [
				{
					name: beacon.name,
					id: beacon._id,
					description: `${beacon.minor} - ${beacon.major}`,
					cover: createUrlFile(beacon.photos[0]),
				},
			];
		} else {
			// It's a PoiInterface
			const poi = objects;

			const formattedLangField = formatLangFields(poi.langFields);
			const langFieldKeys = Object.keys(formattedLangField);
			return [
				{
					name: formattedLangField[language]
						? formattedLangField[language].name
						: formattedLangField["en"]
						  ? formattedLangField["en"].name
						  : formattedLangField[langFieldKeys[0]]
						    ? formattedLangField[langFieldKeys[0]].name
						    : t("COMMONS.NO_VALUE"),
					id: poi._id,
					description: poi.category ? poi.category.label : t("COMMONS.NO_CATEGORY"),
					cover: createUrlFile(poi.photos[0]),
				},
			];
		}
	}
};

export const handleModal = (prev: boolean, setOpen: any) => {
	setOpen(!prev);
};

export const getRowId = (row: any) => {
	return row._id || 0;
};

export const altitudeDifference = (value1: number | undefined, value2: number | undefined): number => {
	if (value1 && value2) return parseFloat((value2 - value1).toFixed(2));
	else return 0;
};

export const formatTimeData = (seconds: number | undefined) => {
	if (seconds !== undefined) {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);

		//const formattedSeconds = String(remainingSeconds).padStart(2, "0");

		return new Date(0, 0, 0, hours, minutes);
	}
};

export const logout = async () => {
	try {
		const refreshToken = localStorage.getItem(TokenKeys.REFRESH_TOKEN);
		let response = await post(BE_LINKS.COMMONS.API_LOGOUT, {
			refreshToken: refreshToken,
		});

		return response;
	} catch (error) {
		console.log("Errore logout: ", error);
		throw error;
	}
};

export function orderCustomersByBusinessName(customersResponse: any) {
	return customersResponse.sort((a: any, b: any) => {
		const nameA = a.business_name?.trim().toLowerCase();
		const nameB = b.business_name?.trim().toLowerCase();

		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});
}

export function getEnumKeyByValue(value: string): string | undefined {
	return Object.keys(SupportedDynamicLanguageEnum).find(
		(key) => SupportedDynamicLanguageEnum[key as keyof typeof SupportedDynamicLanguageEnum] === value,
	);
}

import { Coordinates } from "../commons-artplace";
import { PoiDetailInterface } from "./poi-rest-interfaces";

export interface NewsDetailInterface {
	_id: string;
	title: string;
	body: string;
	status?: string;
	category: string;
	link?: string;
	imageUrl?: string;
	global: boolean;
	sentTo: any[];
	publishedAt?: Date;
	poi?: PoiDetailInterface;
	latlng?: Coordinates;
	tenant?: string;
}

export enum NotificationStatus {
	DRAFT = "draft",
	PUBLISHED = "published",
	SCHEDULED = "scheduled",
	NOW = "now",
	ERROR = "error",
}

export enum NotificationCategory {
	GENERAL = "general",
	CULTURAL = "cultural",
	COMMERCIAL = "commercial",
}

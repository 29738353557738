import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MyContextProvider } from "./context/MyContext";
import Version from "./componets/global/Version";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <MyContextProvider>
      <App />
      {/* <Version/> */}
    </MyContextProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

import React, { useState } from "react";
import CustomDataGrid from "../../componets/dataGrid/CustomDataGrid";
import { observer } from "mobx-react-lite";
import { BEACON_FILTERS, DATATYPE, NAVIGATION_DATA } from "../../type/commons-artplace";

const BeaconView: React.FC = () => {
	const type = NAVIGATION_DATA.BEACON;
	const [searchField, setSearchField] = useState<string>(BEACON_FILTERS.name);

	return (
		<>
			<CustomDataGrid
				type={type}
				numberRows={DATATYPE.BEACON_LIST}
				searchField={searchField}
				formatRows={(item) => ({
					...item,
					id: item._id,
					identificationCode: item.identificationCode,
					uuid: item.uuid,
					major: item.major,
					minor: item.minor,
					status: item.status,
					insights: item.insights,
				})}
			/>
		</>
	);
};

export default observer(BeaconView);

import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, TextField, CircularProgress, Paper, FormControl, Select, InputLabel, MenuItem, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import { ClientInterface, PoiDetailInterface, PoiInterface } from "../../type/rest/poi-rest-interfaces";
import GoogleMapsCard from "../../componets/googleMapsCard/GoogleMapsCard";
import { InsightDetailInterface } from "../../type/rest/insight-rest-interfaces";
import PhotoDropZone from "../artplace-insight/insight-add/photoDropZone";
import { BeaconDetailInterface } from "../../type/rest/beacon-rest-interface";
import PoiInsightsList from "../artplace-poi/poi-detail/PoiInsightsList";
import AddButton from "../../componets/buttons/AddButton";
import { DATATYPE, NAVIGATION_DATA } from "../../type/commons-artplace";
import AddEntityModal from "../../componets/modals/addEntityModal/AddEntityModal";
import ImageCard from "../../componets/pageComponents/ImageCard/ImageCard";
import { BE_LINKS } from "../../api/api";
import { useParams } from "react-router";
import SaveButton from "../../componets/buttons/SaveButton";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { observer } from "mobx-react-lite";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import { get, patch, uploadPhoto } from "../../utils/call-api";
import CustomBreadcrumbs from "../../componets/breadCrumbs/CustomBreadcrumbs";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import { LanguageStore, LanguageStoreContext } from "../../stores/LanguageStore";
import { Spinner } from "react-bootstrap";
import { orderCustomersByBusinessName } from "../../utils/util";

const BeaconDetail: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const params = useParams();
	const [insightsList, setInsightsList] = useState<InsightDetailInterface[]>([]);
	const [address, setAddress] = useState("");
	const [poiDetail, setPoiDetail] = useState<Partial<PoiDetailInterface> | null>(null);
	const imgBeacon = "https://img.economyup.it/wp-content/uploads/2022/04/word-image-6.jpeg";
	const [searchResult, setSearchResult] = useState<any>();
	const [poiList, setPoiList] = useState<PoiDetailInterface[]>();
	const [open, setOpen] = useState<boolean>(false);
	//useState per modale
	const [clients, setClients] = useState<ClientInterface[]>([]); //OK
	const [formikCallback, setFormikCallback] = useState<any>();
	const [formikLabel, setFormikLabel] = useState<string>("");
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<InsightDetailInterface[] | PoiInterface[]>();
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole = userStore.getUserRole();
	const [photos, setPhotos] = useState<File[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState(false);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const currentLanguage = languageStore.getGlobalLanguage;
	const navigate = useNavigate();
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);

	const handleModal = () => {
		setOpen((prev) => !prev);
	}; //controller apertura/chiusura modale

	function onLoad(autocomplete: any) {
		setSearchResult(autocomplete);
	}

	const [beaconDetail, setBeaconDetail] = useState<Partial<BeaconDetailInterface>>({});

	const fetchBeaconDetail = async () => {
		setLoading(true);
		try {
			//Chiamata centralizzata
			const response = await get(BE_LINKS[userRole].BEACONS.ALL, params.id, true, undefined);
			const data: BeaconDetailInterface = response.data;
			setBeaconDetail(data || {});
			setAddress(data.address);
			if (data.insights) {
				const poiData = (await get(BE_LINKS[userRole][NAVIGATION_DATA.BEACON].POIS.replace("{id}", params.id), undefined, true, beaconDetail.tenant))
					.data;
				setPoiList(poiData || []);
			}
			//fetch dei poi del beacon a lui associati (chiamata apposita)
			//const poiData = (await get(BE_LINKS[userRole][NAVIGATION_DATA.BEACON].POIS.replace("{id}", params.id), undefined, true, data.tenant)).data;
			//setPoiList(poiData || []);
			setInsightsList(data.insights || []);

			setLoading(false);
		} catch (error) {
			// console.log("Errore fetch Beacon detail: ", error);
			setLoading(false);
		}
	};
	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);

			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};
	const fetchOptions = async () => {
		//OK - PIù COMPLETO
		try {
			// Fetch dati per tipologiche POI
			const [clients] = await Promise.all([fetchClients()]);

			setClients(clients);

			// Handle other fetched data as needed
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	};
	useEffect(() => {
		fetchOptions();
		fetchBeaconDetail();
	}, [userStore.getUserRole()]);

	return (
		<>
			{loading ? (
				<>
					<Typography>Loading...</Typography>
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
						<CircularProgress size={100} />
					</div>
				</>
			) : (
				<>
					{/* <div className="d-flex justify-content-between align-items-center">
						<Typography variant="h1" mb={4}>
							{beaconDetail?.name}
						</Typography> 
						<Button
							variant="contained"
							color="primary"
							type="submit"
							onClick={() => saveDetailChanges(beaconDetail)}
							style={{ backgroundColor: "#0A17A7", color: "white", textTransform: "initial" }}
						>
							{" "}
							Salva modifiche
						</Button>
					</div> */}
					<Formik
						initialValues={beaconDetail || {}}
						validationSchema={Yup.object({
							// identificationCode: Yup.string().trim().required("ERRORS.FORM.REQUIRED"),
							name: Yup.string().trim().required("ERRORS.FORM.REQUIRED"),
							uuid: Yup.string().trim().required("ERRORS.FORM.REQUIRED"),
							major: Yup.number().min(1, "ERRORS.FORM.BEACONS.MIN_1").max(65535, "ERRORS.FORM.BEACONS.MAX_65535").required("ERRORS.FORM.REQUIRED"),
							minor: Yup.number().min(1, "ERRORS.FORM.BEACONS.MIN_1").max(65535, "ERRORS.FORM.BEACONS.MAX_65535").required("ERRORS.FORM.REQUIRED"),
							//address: Yup.string().required("ERRORS.FORM.REQUIRED"),
							//latlng: Yup.string().trim().required("ERRORS.FORM.REQUIRED"),
						})}
						onSubmit={async (values, { setFieldValue, setSubmitting }) => {
							setIsRequestSubmitted(true);
							try {
								const insightsIds = insightsList?.map((el) => el._id) || [];

								const result = { ...values, insights: insightsIds, photos: photos };

								// console.log("Trying to submit: ", result);

								const response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.BEACON].ALL, params.id, result);

								//EDIT: Le foto non vanno caricate al salvataggio modifiche ma direttamente. Logica spostata in PhotoDropZone
								/* if (photos) {
									try {
										await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.BEACON, userRole);
									} catch (error) {
										toast.error(t("Errore upload photo"));
									}
								} */
								// console.log("BE Response: ", response);
								toast.success(t("MESSAGES.UPDATE_OK"));
								setBeaconDetail(response.data);
								const poiData = (
									await get(BE_LINKS[userRole][NAVIGATION_DATA.BEACON].POIS.replace("{id}", params.id), undefined, true, beaconDetail.tenant)
								).data;
								setPoiList(poiData || []);
								setDeletedPhoto([]);
								setPhotos([]);
								if (resetFiles) resetFiles();
								setSubmitting(false);
							} catch (error: any) {
								// console.log("Error submitting beacon: ", error.response);
								toast.error(t("MESSAGES.UPDATE_ERROR"));

								setSubmitting(false);
							} finally {
								setSubmitting(false);
								setDeletedPhoto([]);
								setPhotos([]);
								if (resetFiles) resetFiles();
								setTimeout(() => {
									setIsRequestSubmitted(false);
								}, 500);
							}
						}}
					>
						{(formik) => (
							<>
								<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
								<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
									<Grid flexDirection={"column"}>
										<Typography variant={"h1"}> {beaconDetail?.name}</Typography>
										<CustomBreadcrumbs dataType={NAVIGATION_DATA.BEACON} currentPage={beaconDetail?.name || t("COMMONS.ADD").split(" ")[0]} />
									</Grid>
									<Button
										variant="contained"
										color="secondary"
										onClick={() => {
											if (!formik.values.name || !formik.values.uuid || !formik.values.major || !formik.values.minor) {
												toast.warn(t("MESSAGES.WARNING_FORM"), {
													className: "yellowToastWarning",
												});
												formik.setFieldTouched("name", true);
												formik.setFieldTouched("uuid", true);
												formik.setFieldTouched("major", true);
												formik.setFieldTouched("minor", true);
											} else {
												formik.handleSubmit();
											}
										}}
										startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
										size="large"
										disabled={isRequestSubmitted || isUploading}
									>
										{t("COMMONS.SAVE_CHANGES")}
									</Button>
								</Grid>
								<Form onSubmit={formik.handleSubmit}>
									<Grid container spacing={4}>
										{/* Form */}
										<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
											<Grid item xs={4}>
												<TextField fullWidth label="ID" name="_id" disabled variant="outlined" defaultValue={formik.values._id} />
											</Grid>
											<Grid item xs={8}>
												<Grid item xs={12} sm={12}>
													<TextField
														fullWidth
														label={t("FORM.NAME")}
														name="name"
														required
														variant="outlined"
														error={formik.touched.name && !!formik.errors.name}
														value={formik.values.name}
														onChange={formik.handleChange}
														InputProps={{ sx: { borderRadius: 2 } }}
														onBlur={formik.handleBlur}
														helperText={formik.touched.name && t(formik.errors.name ?? "")}
													/>
												</Grid>
											</Grid>
											<Grid item xs={6}>
												<TextField
													name="identificationCode"
													fullWidth
													required
													label={t("FORM.IDENTIFICATION_CODE")}
													variant="outlined"
													error={formik.touched.identificationCode && !!formik.errors.identificationCode}
													helperText={formik.touched.identificationCode && t(formik.errors.identificationCode ?? "")}
													value={formik.values.identificationCode}
													onChange={formik.handleChange}
													InputProps={{ sx: { borderRadius: 2 } }}
													disabled={true}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													name="uuid"
													fullWidth
													required
													label="UUID"
													variant="outlined"
													error={formik.touched.uuid && !!formik.errors.uuid}
													helperText={formik.touched.uuid && t(formik.errors.uuid ?? "")}
													value={formik.values.uuid}
													onChange={formik.handleChange}
													InputProps={{ sx: { borderRadius: 2 } }}
													onBlur={formik.handleBlur}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													name="major"
													fullWidth
													required
													label="Major"
													variant="outlined"
													type="number"
													error={formik.touched.major && !!formik.errors.major}
													value={formik.values.major}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													inputProps={{ min: 1, max: 65535 }}
													helperText={formik.touched.major && t(formik.errors.major ?? "")}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													name="minor"
													required
													fullWidth
													type="number"
													label="Minor"
													variant="outlined"
													error={formik.touched.minor && !!formik.errors.minor}
													value={formik.values.minor}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													inputProps={{ min: 1, max: 65535 }}
													helperText={formik.touched.minor && t(formik.errors.minor ?? "")}
												/>
											</Grid>
											{/* Clienti */}
											{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
												<Grid item xs={12} sm={12}>
													<FormControl fullWidth variant="outlined">
														<InputLabel>{t("FORM.CUSTOMER")}</InputLabel>
														<Select
															disabled={params.id ? true : false}
															label={t("FORM.CUSTOMER")}
															name="tenant"
															value={formik.values.tenant || userStore.getTenant}
															onChange={formik.handleChange}
															style={{ borderRadius: 8 }}
															error={formik.touched.tenant && !!formik.errors.tenant}
														>
															{clients.map((el: ClientInterface) => (
																<MenuItem key={el._id} value={el.tenant}>
																	{el.business_name}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
											)}
											{/* Point of Interest */}
											{/* LOCATION MAPS */}
											{poiList && poiList[0] && (
												<Grid item xs={12}>
													<Typography variant="h6" component="div" mb={1}>
														{t("POI.DETAIL.POSITION")}
													</Typography>

													<TextField
														fullWidth
														//Non deve essere modificabile
														disabled={true}
														label={t("FORM.ADDRESS")}
														name="address"
														variant="outlined"
														value={poiList[0].address}
														//error={formik.touched.address && !!formik.errors.address}
														//helperText={formik.touched.address && t(formik.errors.address ?? "")}
														InputProps={{ sx: { borderRadius: 2 } }}
													/>

													<GoogleMapsCard
														lat={poiList[0].latlng.lat}
														lng={poiList[0].latlng.lng}
														address={formik.values.address}
														//	setCoordinates={formik.setFieldValue}
													/>

													{/* </GoogleWrapper> */}
												</Grid>
											)}
											{poiList && poiList[0] && (
												<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
													<Grid item>
														<Typography variant="h6" component="div" mb={1} mt={2}>
															{t("INSIGHTS.DETAIL.CONNECTED_POI")}
														</Typography>
													</Grid>
													<Grid item xs={12}>
														<ImageCard
															navigation={(id: string | undefined) => navigate(`/poi/detail/${id}`)}
															currentLanguage={currentLanguage}
															state={poiList || []}
														/>
													</Grid>
												</Grid>
											)}
											{/* Assegna Approfondimenti MOCKUP*/}
											<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
												<Grid item>
													<Typography variant="h6" component="div" mb={1} mt={2}>
														{t("POI.DETAIL.INSIGHTS")}
													</Typography>
												</Grid>
												<Grid item>
													<AddButton
														length={insightsList?.length || 0}
														onClick={() => {
															// console.log("Clicked Insights");
															setModalTitle(() => t("INSIGHTS.ADD.TITLE"));
															setModalContent(() => insightsList);
															setModalCallBack(() => setInsightsList);
															setFormikCallback(() => formik.setFieldValue);
															setFormikLabel("insights");
															setModalDataType(() => DATATYPE.INSIGHTS);
															handleModal();
														}}
														component={undefined}
													/>
												</Grid>
												<Grid item xs={12}>
													<PoiInsightsList insights={insightsList || []} dataType={DATATYPE.INSIGHTS} />
												</Grid>
											</Grid>
										</Grid>
										{/* IMG */}
										<Grid item xs={12} md={4}>
											<Grid item xs={12}>
												<PhotoDropZone
													resetFiles={(action) => setResetFiles(action)}
													photos={beaconDetail.photos || []}
													setPhotoList={setPhotos}
													navigationData={NAVIGATION_DATA.BEACON}
													setEntity={setBeaconDetail}
													setIsUploading={setIsUploading}
												/>
											</Grid>
											{/* 	<Paper>
												<img src={imgBeacon} alt={t("COMMONS.IMAGE")} style={{ width: "100%" }} />
											</Paper> */}
										</Grid>
									</Grid>
								</Form>
							</>
						)}
					</Formik>
				</>
			)}
			{open && modalDataType && (
				<Grid container xs={12}>
					<AddEntityModal
						open={open}
						title={modalTitle}
						onClose={handleModal}
						callBack={modalCallBack}
						formikCallback={formikCallback}
						formikLabel={formikLabel}
						initialList={modalContent}
						dataType={modalDataType}
						tenant={beaconDetail?.tenant}
					/>
				</Grid>
			)}
		</>
	);
};

export default observer(BeaconDetail);

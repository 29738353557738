import { GridColDef } from "@mui/x-data-grid";
import { DATATYPE } from "../../../type/commons-artplace";
import { Box, Chip, Grid, Typography } from "@mui/material";
import CenteredImageStyle from "../../../componets/centeredImage/CenteredImage.module.css";
import { t } from "i18next";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useContext } from "react";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import { NotificationStatus } from "../../../type/rest/news-rest-interface";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import no_pic from "../../../img/no_pic.jpg";
import { format, parseISO } from "date-fns";
// Define columns for each data type group
const GridColumns = (rows: string, lang: string, createUrlFile?: any) => {
	const userStore: UserStore = useContext(UserStoreContext);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const userRole: string = userStore.getUserRole();
	const GridColumns: { [dataType: string]: GridColDef[] } = {
		//colonne da usare per oggetti POI
		[DATATYPE.POI]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: t("DATA_TABLE.NAME"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,

				renderCell: (params) => {
					const firstLanguage = Object.keys(params.value)[0];
					return (
						<Typography style={{ display: "inline-block", width: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value[lang]
								? params.value[lang].name
								: params.value[languageStore.getDefaultLanguage]
								  ? params.value[languageStore.getDefaultLanguage].name
								  : params.value[firstLanguage]
								    ? params.value[firstLanguage].name
								    : t("COMMONS.VALUE")}
						</Typography>
					);
				},
			},
			{
				field: "category",
				headerName: t("DATA_TABLE.CATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return <Chip label={t(params.value.label) || `${t("COMMONS.NO_CATEGORY")}`} color="default" variant="outlined" style={{ width: "80%" }} />;
				},
			},
			{
				field: "address",
				headerName: t("DATA_TABLE.ADDRESS"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
			},
			{ field: "id", headerName: "ID", headerAlign: "left", width: 200, sortable: false },
			// {
			//   field: 'statusActive',
			//   headerName: 'STATO',
			//   type: 'boolean',
			//   width: 110,
			//   editable: false,
			//   filterable: false,
			// },
			/* {
				field: "statusActive",
				align: "center",
				headerName: "STATO",
				headerAlign: "center",
				sortable: false,
				renderCell(params: any) {
					return params.value ? (
						<Chip label={t("COMMONS.ACTIVE")} color="success" variant="filled" style={{ width: 70 }} />
					) : (
						<Chip label={t("COMMONS.INACTIVE")} color="error" variant="filled" style={{ width: 70 }} />
					);
				},
			}, */
		],
		//colonne da usare per oggetti INSIGHTS
		[DATATYPE.INSIGHTS]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: t("DATA_TABLE.NAME"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					const firstLanguage = Object.keys(params.value)[0];
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value[lang]
								? params.value[lang].name
								: params.value[languageStore.getDefaultLanguage]
								  ? params.value[languageStore.getDefaultLanguage].name
								  : params.value[firstLanguage]
								    ? params.value[firstLanguage].name
								    : t("COMMONS.VALUE")}
						</Typography>
					);
				},
			},
			{
				field: "category",
				headerName: t("DATA_TABLE.CATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params?: any) {
					return (
						<Chip
							label={params?.value?.label ? t(params.value.label) : `${t("COMMONS.NO_CATEGORY")}`}
							color="default"
							variant="outlined"
							style={{ width: "80%" }}
						/>
					);
				},
			},
			{ field: "_id", headerName: `${t("DATA_TABLE.ID")}`, headerAlign: "left", width: 200 },
			/* {
				field: "subcategory",
				headerName: t("DATA_TABLE.SUBCATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params?: any) {
					return (
						<Chip
							label={params?.value?.label ? t(params.value.label) : `${t("COMMONS.NO_SUBCATEGORY")}`}
							color="default"
							variant="outlined"
							style={{ width: "80%" }}
						/>
					);
				},
			}, */
			/* {
				field: "statusActive",
				align: "center",
				headerName: "STATO",
				headerAlign: "center",
				sortable: false,
				renderCell(params: any) {
					return params.value ? (
						<Chip label="Attivo" color="success" variant="filled" style={{ width: 70 }} />
					) : (
						<Chip label="Disattivo" color="error" variant="filled" style={{ width: 70 }} />
					);
				},
			}, */
		],

		//colonne da usare per oggetti ITINERARY
		[DATATYPE.ITINERARY]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: t("DATA_TABLE.NAME"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					const firstLanguage = Object.keys(params.value)[0];
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value[lang]
								? params.value[lang].name
								: params.value[languageStore.getDefaultLanguage]
								  ? params.value[languageStore.getDefaultLanguage].name
								  : params.value[firstLanguage]
								    ? params.value[firstLanguage].name
								    : t("COMMONS.VALUE")}
						</Typography>
					);
				},
			},
			{
				field: "category",
				headerName: t("DATA_TABLE.CATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params?: any) {
					return (
						<Chip
							label={params?.value?.label ? t(params.value.label) : `${t("COMMONS.NO_CATEGORY")}`}
							color="default"
							variant="outlined"
							style={{ width: "80%" }}
						/>
					);
				},
			},
			/* {
				field: "subcategory",
				headerName: t("DATA_TABLE.SUBCATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return (
						<Chip label={t(params?.value?.label) || `${t("COMMONS.NO_SUBCATEGORY")}`} color="default" variant="outlined" style={{ width: "80%" }} />
					);
				},
			}, */
			{
				field: "distance",
				headerName: t("DATA_TABLE.LENGTH"),
				headerAlign: "left",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return <Chip label={Math.trunc(params.value) + " m"} color="default" variant="outlined" style={{ width: 150 }} />;
				},
			},
			{
				field: "averageRate",
				headerName: `${t("RATINGS.AVG_RATING")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params?.value?.toFixed(1) || "0.0"}</Typography>;
				},
			},
			{
				field: "numberOfRate",
				headerName: `${t("RATINGS.NUMBER_RATINGS")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value ?? 0}</Typography>;
				},
			},
			{
				field: "favorites",
				headerName: `${t("RATINGS.FAVORITES")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 100,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value?.length ?? 0}</Typography>;
				},
			},
			{ field: "id", headerName: "ID", headerAlign: "left", width: 200, sortable: false },
		],
		//colonne da usare per oggetti BEACON
		[DATATYPE.BEACONS]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: t("DATA_TABLE.NAME"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value}
						</Typography>
					);
				},
			},
			{
				field: "major",
				headerName: "MAJOR",
				headerAlign: "left",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
			},
			{
				field: "minor",
				headerName: "MINOR",
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
			},
			{ field: "id", headerName: "ID", headerAlign: "left", width: 200, sortable: false },
			/* {
				field: "statusActive",
				align: "center",
				headerName: t("DATA_TABLE.STATE"),
				headerAlign: "center",
				renderCell(params: any) {
					return params.value ? (
						<Chip label={t("COMMONS.ACTIVE")} color="success" variant="filled" style={{ width: 70 }} />
					) : (
						<Chip label={t("COMMONS.INACTIVE")} color="error" variant="filled" style={{ width: 70 }} />
					);
				},
			}, */
		],
		[DATATYPE.STAGE]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									//src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: `${t("DATA_TABLE.NAME")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					const firstLanguage = Object.keys(params.value)[0];
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value[lang]
								? params.value[lang].name
								: params.value[languageStore.getDefaultLanguage]
								  ? params.value[languageStore.getDefaultLanguage].name
								  : params.value[firstLanguage]
								    ? params.value[firstLanguage].name
								    : t("COMMONS.VALUE")}
						</Typography>
					);
				},
			},
			{
				field: "pois",
				headerName: "POI",
				headerAlign: "left",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return <>{params.value ? params.value.length + " " + t("POI.LIST.TITLE") : 0 + " " + t("POI.LIST.TITLE")}</>;
				},
			},
			{
				field: "distance",
				headerName: t("DATA_TABLE.LENGTH"),
				headerAlign: "left",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return <Chip label={Math.trunc(params.value) + " m"} color="default" variant="outlined" style={{ width: 150 }} />;
				},
			},
			/* {
				field: "itinerary",
				headerName: t("DATA_TABLE.ITINERARY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
			}, */
			{
				field: "averageRate",
				headerName: `${t("RATINGS.AVG_RATING")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params?.value?.toFixed(1) || "0.0"}</Typography>;
				},
			},
			{
				field: "numberOfRate",
				headerName: `${t("RATINGS.NUMBER_RATINGS")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value ?? 0}</Typography>;
				},
			},
			{
				field: "favorites",
				headerName: `${t("RATINGS.FAVORITES")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 100,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value?.length ?? 0}</Typography>;
				},
			},
			{ field: "id", headerName: "ID", headerAlign: "left", width: 200, sortable: false },
		],
		[DATATYPE.POI_LIST]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: t("DATA_TABLE.NAME"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					const firstLanguage = Object.keys(params.value)[0];
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value[lang]
								? params.value[lang].name
								: params.value[languageStore.getDefaultLanguage]
								  ? params.value[languageStore.getDefaultLanguage].name
								  : params.value[firstLanguage]
								    ? params.value[firstLanguage].name
								    : t("COMMONS.VALUE")}
						</Typography>
					);
				},
			},
			{
				field: "category",
				headerName: t("DATA_TABLE.CATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return <Chip label={t(params.value.label) || `${t("COMMONS.NO_CATEGORY")}`} color="default" variant="outlined" style={{ width: "80%" }} />;
				},
			},
			/* {
				field: "subcategory",
				headerName: t("DATA_TABLE.SUBCATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return (
						<Chip label={t(params?.value?.label) || `${t("COMMONS.NO_SUBCATEGORY")}`} color="default" variant="outlined" style={{ width: "80%" }} />
					);
				},
			}, */
			{
				field: "address",
				headerName: t("DATA_TABLE.ADDRESS"),
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
			},
			{
				field: "averageRate",
				headerName: `${t("RATINGS.AVG_RATING")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params?.value?.toFixed(1) || "0.0"}</Typography>;
				},
			},
			{
				field: "numberOfRate",
				headerName: `${t("RATINGS.NUMBER_RATINGS")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value ?? 0}</Typography>;
				},
			},
			{
				field: "favorites",
				headerName: `${t("RATINGS.FAVORITES")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 100,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value?.length ?? 0}</Typography>;
				},
			},
			{ field: "id", headerName: "ID", headerAlign: "left", width: 200, sortable: false },
			/* {
				field: "statusActive",
				align: "center",
				headerName: t("DATA_TABLE.STATE"),
				headerAlign: "center",
				sortable: false,
				renderCell(params: any) {
					return params.value ? (
						<Chip label={t("COMMONS.ACTIVE")} color="success" variant="filled" style={{ width: 70 }} />
					) : (
						<Chip label={t("COMMONS.INACTIVE")} color="error" variant="filled" style={{ width: 70 }} />
					);
				},
			}, */
		],
		[DATATYPE.BEACON_LIST]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: t("DATA_TABLE.NAME"),
				width: 300,
				sortable: false,
				renderCell: (params) => {
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value}
						</Typography>
					);
				},
			},
			{
				field: "identificationCode",
				headerName: t("DATA_TABLE.CODE"),
				width: 250,
				sortable: false,
			},
			{
				field: "uuid",
				headerName: "UUID",
				width: 350,
				sortable: false,
			},
			{
				field: "major",
				headerName: "MAJOR",
				width: 120,
				sortable: false,
			},
			{
				field: "minor",
				headerName: "MINOR",
				width: 120,
				sortable: false,
			},
			{ field: "_id", headerName: "ID", width: 200, sortable: false },
			/* {
				field: "stato",
				headerName: t("DATA_TABLE.STATE"),
				width: 160,
				renderCell: (params) => {
					return params.row.status ? (
						<Chip label={t("COMMONS.ACTIVE")} color="success" variant="filled" />
					) : (
						<Chip label={t("COMMONS.INACTIVE")} color="error" variant="filled" />
					);
				},
			}, */
		],
		[DATATYPE.INSIGHT_LIST]: [
			{
				field: "photos",
				headerName: "COVER",
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.row.photos[0] ? createUrlFile(params.row.photos[0]) : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "name",
				headerName: `${t("DATA_TABLE.NAME")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					const firstLanguage = Object.keys(params.value)[0];
					return (
						<Typography style={{ display: "inline-block", width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{params.value[lang]
								? params.value[lang].name
								: params.value[languageStore.getDefaultLanguage]
								  ? params.value[languageStore.getDefaultLanguage].name
								  : params.value[firstLanguage]
								    ? params.value[firstLanguage].name
								    : t("COMMONS.VALUE")}
						</Typography>
					);
				},
			},
			{
				field: "category",
				headerName: `${t("DATA_TABLE.CATEGORY")}`,
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return <Chip label={t(params.value.label) || `${t("COMMONS.NO_CATEGORY")}`} color="default" variant="outlined" style={{ width: "80%" }} />;
				},
			},
			/* {
				field: "poi",
				headerName: `${t("DATA_TABLE.POI")}`,
				headerAlign: "left",
				width: 200,
				editable: false,
				filterable: false,
				renderCell(params) {
					return params.value || `${t("COMMONS.NO_POI")}`;
				},
			}, */
			{
				field: "averageRate",
				headerName: `${t("RATINGS.AVG_RATING")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 150,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params?.value?.toFixed(1) || "0.0"}</Typography>;
				},
			},
			{
				field: "numberOfRate",
				headerName: `${t("RATINGS.NUMBER_RATINGS")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value ?? 0}</Typography>;
				},
			},
			{
				field: "favorites",
				headerName: `${t("RATINGS.FAVORITES")}`,
				headerAlign: "center",
				align: "center",
				type: "string",
				width: 100,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return <Typography>{params.value?.length ?? 0}</Typography>;
				},
			},
			{ field: "id", headerName: `${t("DATA_TABLE.ID")}`, headerAlign: "left", width: 200 },
			/* {
				field: "statusActive",
				align: "center",
				headerName: `${t("DATA_TABLE.STATE")}`,
				headerAlign: "center",
				renderCell(params) {
					return params.value ? (
						<Chip label={t("COMMONS.ACTIVE")} color="success" variant="filled" style={{ width: 70 }} />
					) : (
						<Chip label={t("COMMONS.INACTIVE")} color="error" variant="filled" style={{ width: 70 }} />
					);
				},
			}, */
			// Add more columns for INSIGHT data type
		],
		[DATATYPE.CUSTOMER]: [
			{
				field: "business_name",
				headerName: `${t("DATA_TABLE.NAME")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => (
					<>
						{/** TODO */}
						{/* <Box width={50} height={50} marginTop={2} marginBottom={2} marginRight={2}>
							<div className={CenteredImageStyle.squareImageContainer}>
								<img src={params.row.image} alt={"cover"} className={CenteredImageStyle.squareImage} />
							</div>
						</Box> */}
						<Typography>{params.value}</Typography>
					</>
				),
			},
			{
				field: "email",
				headerName: `${t("DATA_TABLE.EMAIL")}`,
				headerAlign: "left",
				type: "string",
				width: 500,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return (
						<>
							<MailOutlineIcon fontSize="small" sx={{ mr: 3 }}></MailOutlineIcon>
							<Typography>{params.value}</Typography>
						</>
					);
				},
			},
			{ field: "id", headerName: `${t("DATA_TABLE.ID")}`, headerAlign: "left", width: 200 },
		],
		[DATATYPE.USERS]: [
			{
				field: "firstname",
				headerName: `${t("DATA_TABLE.NAME")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => <Typography>{params.value}</Typography>,
			},
			{
				field: "surname",
				headerName: `${t("DATA_TABLE.SURNAME")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => <Typography>{params.value}</Typography>,
			},
			{
				field: "business_name",
				headerName: `${t("DATA_TABLE.BUSINESS_NAME")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => <Typography>{params.value}</Typography>,
			},
			{
				field: "email",
				headerName: `${t("DATA_TABLE.EMAIL")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return (
						<>
							<MailOutlineIcon fontSize="small" sx={{ mr: 3 }}></MailOutlineIcon>
							<Typography>{params.row.id_contact[0].contact}</Typography>
						</>
					);
				},
			},
			{
				field: "role",
				headerName: `${t("DATA_TABLE.ROLE")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => <Typography>{params.value}</Typography>,
			},
			{ field: "id_auth_module", headerName: `${t("DATA_TABLE.ID")}`, headerAlign: "left", width: 200 },
		],
		[DATATYPE.NEWS]: [
			{
				field: "imageUrl",
				headerName: "COVER", //eventualmente mettere image
				headerAlign: "center",
				type: "string",
				width: 120,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => {
					return (
						<Box style={{ height: "100%" }} padding={1}>
							<div className={CenteredImageStyle.squareImageContainer} style={{ width: "100%", aspectRatio: "1/1" }}>
								<img
									src={params.value && params.value.startsWith("http") ? params.value : no_pic}
									alt={"cover"}
									className={CenteredImageStyle.squareImage}
									style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container
								/>
							</div>
						</Box>
					);
				},
			},
			{
				field: "title",
				headerName: `${t("DATA_TABLE.TITLE")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell: (params) => (
					<>
						<Typography>{params.value}</Typography>
					</>
				),
			},
			{
				field: "body",
				headerName: `${t("DATA_TABLE.TEXT")}`,
				headerAlign: "left",
				type: "string",
				width: 300,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params) {
					return (
						<>
							<Typography>{params.value}</Typography>
						</>
					);
				},
			},
			{
				field: "status",
				headerName: `${t("DATA_TABLE.STATE")}`,
				headerAlign: "center",
				align: "center",
				sortable: false,
				filterable: false,
				width: 100,
				renderCell(params) {
					return params.value === NotificationStatus.DRAFT ? (
						<Chip label={t("COMMONS.DRAFT")} color="warning" variant="filled" style={{ width: "auto" }} />
					) : (
						<Chip label={t("COMMONS.PUBLISHED")} color="success" variant="filled" style={{ width: "auto" }} />
					);
				},
			},
			{
				field: "category",
				headerName: t("DATA_TABLE.CATEGORY"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return <Chip label={t(params.value) || `${t("COMMONS.NO_CATEGORY")}`} color="default" variant="outlined" style={{ width: "80%" }} />;
				},
			},
			{
				field: "numberOfSentTo",
				headerName: t("DATA_TABLE.SENT_TO"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return params.row.global ? <Typography>{`${t("COMMONS.NO_VALUE")}`}</Typography> : <Typography>{params.value}</Typography>;
				},
			},
			{
				field: "global",
				headerName: t("DATA_TABLE.GLOBAL"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return params.value ? (
						<Chip label={"INVIO GLOBALE"} color="success" variant="filled" style={{ width: "80%" }} />
					) : (
						<Typography>{`${t("COMMONS.NO_VALUE")}`}</Typography>
					);
				},
			},
			{
				field: "publishedAt",
				headerName: t("DATA_TABLE.PUBLISHED_AT"),
				headerAlign: "left",
				type: "string",
				width: 200,
				editable: false,
				filterable: false,
				sortable: false,
				renderCell(params: any) {
					return <Typography>{params.value ? format(parseISO(params.value), "dd/MM/yyyy") : `${t("COMMONS.NO_VALUE")}`}</Typography>;
				},
			},
			{ field: "_id", headerName: `${t("DATA_TABLE.ID")}`, headerAlign: "left", width: 200 },
		],
		// Define columns for other data type groups
	};

	return GridColumns[rows];
};
export default GridColumns;

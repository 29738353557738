import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { API_AUTH_FORGOT_PASSWORD } from "../../../api/api";
import { ToastContainer } from "react-toastify";
import { Alert, Box, Button, Container, Grid, IconButton, Link, TextField, Typography } from "@mui/material";
import LadingPage from "../../landingPage/LandingPage";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Form, Spinner } from "react-bootstrap";
import i18n from "../../../i18n";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { emailRegex } from "../../../utils/regex";

export default function RecoverPassword(): React.JSX.Element {
	const { t } = useTranslation();
	const [email, setEmail] = useState<string>("");
	const [touched, setTouched] = useState<boolean>(false);
	const [validated, setValidated] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [requestSended, setRequestSended] = useState<boolean>(false);
	const fullLanguageCode = i18n.language;
	const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const legacy = searchParams.get("legacy");
	// console.log(" RecoverPassword ~ legacy:", legacy);

	const [alert, setAlert] = useState<any>({
		show: false,
		severity: "error",
		message: "",
		dismissible: true,
		onClose: () => {
			setAlert({ ...alert, show: false });
		},
	});

	const handlePasswordRecovery = async (email: string) => {
		let requestBody;
		requestBody = {
			email: email,
		};
		try {
			setLoading(true);
			const response = await axios.post(API_AUTH_FORGOT_PASSWORD, requestBody, {
				headers: {
					"Accept-Language": languageCode,
				},
			});
			if (response.data.code === 0) {
				setAlert({ ...alert, show: true, severity: "success", message: response.data.message });
				setRequestSended(true);
			} else {
				setAlert({ ...alert, show: true, message: response.data.message });
			}
		} catch (error: any) {
			setAlert({ ...alert, show: true, message: error.response.data.message });
		} finally {
			setLoading(false);
		}
	};

	const handleBlur = () => {
		if (!touched) {
			setTouched(true);
		}
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		setValidated(true);
		if (email && emailRegex.test(email)) {
			handlePasswordRecovery(email);
			setEmail("");
		}
	};

	function _renderInput() {
		return (
			<>
				<ToastContainer />
				<Container>
					{!requestSended && (
						<>
							{legacy ? (
								<>
									<Typography variant="h4" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
										{t("COMMONS.WELCOME") + "!"}
									</Typography>
									<Typography variant="subtitle1" sx={{ marginBottom: "5px", fontWeight: "400", color: "#8c8c8c" }}>
										{t("LOGIN.RECOVER_PASSWORD_DESCRIPTION")}
									</Typography>
								</>
							) : (
								<>
									<Typography variant="h4" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
										{t("LOGIN.RECOVER_PASSWORD")}
									</Typography>
									<Typography variant="subtitle1" sx={{ marginBottom: "5px", fontWeight: "400", color: "#8c8c8c" }}>
										{t("LOGIN.RECOVER_PASSWORD_DESCRIPTION")}
									</Typography>
								</>
							)}
							<Form noValidate validated={validated} onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="exampleForm.email">
									{alert.show ? (
										<Box sx={{ height: "70px" }}>
											<Alert
												className="mb-3"
												severity={alert.severity}
												action={
													<IconButton aria-label="close" color="inherit" size="small" onClick={alert.onClose}>
														<CloseIcon fontSize="inherit" />
													</IconButton>
												}
											>
												{t(alert.message)}
											</Alert>
										</Box>
									) : (
										<Box sx={{ height: "70px" }}></Box>
									)}
									<TextField
										name="email"
										label={t("COMMONS.EMAIL")}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										onBlur={handleBlur}
										type="email"
										fullWidth
										required
										error={Boolean(touched && email && !emailRegex.test(email))}
										helperText={touched && email && !emailRegex.test(email) ? t("ERRORS.FORM.INVALID_EMAIL") : " "}
									/>
								</Form.Group>
								<Box marginTop={"20px"}>
									<Button
										type="submit"
										variant="contained"
										sx={{ borderRadius: "6px", textTransform: "Capitalize" }}
										fullWidth
										size={"large"}
										disabled={loading || !emailRegex.test(email)}
									>
										{!loading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
									</Button>
								</Box>
							</Form>

							{/* <Box marginTop={"20px"}> */}
							{/*<Button
              onClick={() => login()}
              sx={{ textTransform: "Capitalize" }}
              variant={"contained"}
              fullWidth
              size={"large"}
            >Login</Button>*/}
							{/* </Box> */}
							<Grid container className={"mt-5"}>
								<Grid item xs={12} className={"mb-3"} justifyContent={"center"} display={"flex"}>
									{t("LOGIN.USER_ALREADY_REGISTERED")}
									<Link href={"/login"} underline="none" color={"black"} marginLeft={"5px"}>
										{t("LOGIN.TITLE")}
									</Link>
								</Grid>
							</Grid>
						</>
					)}

					{requestSended && (
						<>
							<Typography variant="h4" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
								{t("LOGIN.RECOVER_PASSWORD_SUCCESSFUL")}
							</Typography>
							<Typography variant="subtitle1" sx={{ marginBottom: "25px", fontWeight: "400", color: "#8c8c8c" }}>
								{t("LOGIN.RECOVER_PASSWORD_SUCCESSFUL_DESCRIPTION")}
							</Typography>
							{/* <Box marginTop={"20px"}>
								<Button
									variant="contained"
									sx={{ borderRadius: "6px", textTransform: "Capitalize" }}
									fullWidth
									size={"large"}
									onClick={() => navigate("/login")}
								>
									{t("COMMONS.GO_TO_LOGIN_PAGE")}
								</Button>
							</Box> */}
						</>
					)}
				</Container>
			</>
		);
	}

	return (
		<LadingPage>
			<div style={{ width: "100%", height: "100%" }}>
				<div>{_renderInput()}</div>
			</div>
		</LadingPage>
	);
}

import { Box, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionContainer from "./sectionContainer";
import { useDropzone } from "react-dropzone";
import HeadsetIcon from "@mui/icons-material/Headset";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

import { attachedFile, attachedFileInfo, formattedAttachedFile } from "../../../type/rest/insight-rest-interfaces";
import SingleCard from "../../../componets/pageComponents/ImageCard/SingleCard";
import AttachedButtons from "./AttachedFileButtons";
import PlayerVideo from "./PlayerVideo";
import PlayerAudio from "./PlayerAudio";
import DoneIcon from "@mui/icons-material/Done";
import GeneralModal from "../../../componets/generalModal/GeneralModal";
import DropZone from "./DropZone";
import theme from "../../../theme";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { deleteFile, uploadFile } from "../../../utils/call-api";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { NAVIGATION_DATA } from "../../../type/commons-artplace";

interface Props {
	fileAudio: attachedFile[] | [];
	fileVideo: attachedFile[] | [];
	setFilesList: (type: string, acceptedFiles: attachedFile[] | undefined) => void; // Funzione di callback senza parametri
	errorAudio: boolean;
	errorVideo: boolean;
	lang: string;
	setDeletedVideo: (lang: string) => void;
	setDeletedAudio: (lang: string) => void;
	navigationData?: NAVIGATION_DATA;
}

const LoadingOverlay = ({ isLoading }: { isLoading: boolean }) => {
	if (!isLoading) return null;

	return (
		<div
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100vw",
				height: "100vh",
				backgroundColor: "rgba(0,0,0,0.5)",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				zIndex: 1000, // Assicurati che sia sopra tutto il resto
			}}
		>
			<CircularProgress size={80} />
		</div>
	);
};

const AttachedFiles = ({
	setFilesList,
	fileAudio,
	fileVideo,
	errorAudio,
	errorVideo,
	lang,
	setDeletedVideo,
	setDeletedAudio,
	navigationData,
}: Props) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const { formatLangFieldFile, convertToAttachedFiles, createUrlFile } = EntityUtilities();
	const [audio, setAudio] = useState<formattedAttachedFile | undefined>(fileAudio.length ? formatLangFieldFile(fileAudio) : undefined);
	const [video, setVideo] = useState<formattedAttachedFile | undefined>(fileVideo.length ? formatLangFieldFile(fileVideo) : undefined);
	const [audioFile, setAudioFile] = useState<attachedFileInfo[] | []>([]);
	const [videoFile, setVideoFile] = useState<attachedFileInfo[] | []>([]);
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	// const [audio, setAudio] = useState<attachedFile[] | []>(fileAudio);
	// const [video, setVideo] = useState<attachedFile[] | []>(fileVideo);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	console.log("isLoading", isLoading);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [isAudio, setIsAudio] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const redColor = theme.palette.error.light;

	const [uploadSuccess, setUploadSuccess] = useState({ audio: false, video: false });

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			acceptedFiles.forEach(async (file: File) => {
				if (file.type.startsWith("audio")) {
					const newAudio = {
						...audio,
						[lang]: {
							// url: URL.createObjectURL(file),
							key: URL.createObjectURL(file),
							name: file.name,
							sizeBytes: file.size,
							mime: file.type,
							duration: null,
						},
					};
					const formattedAudio = {
						...audioFile,
						[lang]: file,
					};
					//console.log("onDrop: formattedAudio:", formattedAudio);
					setAudio(newAudio);
					setAudioFile(formattedAudio);
					setFilesList("audioTracks", convertToAttachedFiles(formattedAudio));
					if (id) {
						setIsLoading(true);
						await uploadFile(convertToAttachedFiles(formattedAudio), navigationData!, id, true, userRole)
							.then(() => {
								toast.success(t("MESSAGES.UPLOAD_OK"));
								setUploadSuccess((prev) => ({ ...prev, audio: true }));
							})
							.catch((error): void => {
								toast.error(t("MESSAGES.UPLOAD_ERROR"));
								console.error("Errore nella richiesta:", error);
								setAudio(undefined); // Reset dello stato audio in caso di errore
								setAudioFile([]);
								setUploadSuccess((prev) => ({ ...prev, audio: false }));
							})
							.finally(() => {
								setIsLoading(false);
							});
					}
				} else if (file.type.startsWith("video")) {
					const newVideo = {
						...video,
						[lang]: {
							// url: URL.createObjectURL(file),
							key: URL.createObjectURL(file),
							name: file.name,
							sizeBytes: file.size,
							mime: file.type,
							duration: null,
						},
					};
					const formattedVideo = {
						...videoFile,
						[lang]: file,
					};
					//console.log("onDrop: formattedVideo:", formattedVideo);

					setVideo(newVideo);
					setVideoFile(formattedVideo);
					setFilesList("videos", convertToAttachedFiles(formattedVideo));
					if (id) {
						setIsLoading(true);
						await uploadFile(convertToAttachedFiles(formattedVideo), navigationData!, id, false, userRole)
							.then(() => {
								toast.success(t("MESSAGES.UPLOAD_OK"));
							})
							.catch((error): void => {
								toast.error(t("MESSAGES.UPLOAD_ERROR"));
								console.error("Errore nella richiesta:", error);
								setVideo(undefined);
								setVideoFile([]);
							})
							.finally(() => {
								setIsLoading(false);
							});
					}
				}
			});
		},
		[audio, lang, video],
	);

	const acceptedFiles = () => {
		let formati = {};

		if (!video && !audio) {
			formati = {
				"video/mp4": [".mp4"],
				"audio/mpeg": [".mp3"],
			};
		} else if (video) {
			formati = {
				"audio/mpeg": [".mp3"],
			};
		} else if (audio) {
			formati = {
				"video/mp4": [".mp4"],
			};
		}

		return formati;
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: acceptedFiles(),
		noClick: true,
	});

	const byteToMega = (byte: number | undefined) => {
		const megabyte = byte ? byte / 1000000 : 0;
		return Math.round(megabyte * 10) / 10;
	};

	const secToMin = (sec: number) => {
		const minutes = sec / 60;
		return Math.round(minutes * 10) / 10;
	};

	const onEdit = (isAudio: boolean) => {
		setIsAudio(isAudio);
		setEdit(true);
	};
	const onPlay = (isAudio: boolean) => {
		setIsAudio(isAudio);
		setModalOpen(true);
	};
	const onDelete = async (isAudio: boolean) => {
		if (isAudio && audio) {
			const newobj = {
				...audio,
				[lang]: undefined,
			};
			const formattedNewObj = {
				...audioFile,
				[lang]: undefined,
			};
			//console.log("onDelete: formattedNewObject AUDIO_ ", formattedNewObj);
			if (id) {
				await deleteFile(navigationData!, id, true, userRole, lang)
					.then(() => {
						setAudio(newobj);
						//setFilesList("audioTracks", convertToAttachedFiles(formattedNewObj));
						setDeletedAudio(lang);
						toast.success(t("MESSAGES.ELIMINATION_OK"));
					})
					.catch((error) => {
						toast.error(t("MESSAGES.ELIMINATION_ERROR"));
						console.error("Errore nella richiesta:", error);
					});
			} else {
				setAudio(newobj);
				setAudioFile(formattedNewObj);
				setFilesList("audioTracks", convertToAttachedFiles(formattedNewObj));
				setDeletedAudio(lang);
			}
		} else if (!isAudio && video) {
			const newobj = {
				...video,
				[lang]: undefined,
			};
			const formattedNewObj = {
				...videoFile,
				[lang]: undefined,
			};
			//console.log("onDelete: formattedNewObject VIDEO_ ", formattedNewObj);

			if (id) {
				await deleteFile(navigationData!, id, false, userRole, lang)
					.then(() => {
						setVideo(newobj);
						//setFilesList("videos", convertToAttachedFiles(newobj));
						setDeletedVideo(lang);
						toast.success(t("MESSAGES.ELIMINATION_OK"));
					})
					.catch((error) => {
						toast.error(t("MESSAGES.ELIMINATION_ERROR"));
						console.error("Errore nella richiesta:", error);
					});
			} else {
				setVideo(newobj);
				setVideoFile(formattedNewObj);
				setFilesList("videos", convertToAttachedFiles(formattedNewObj));
				setDeletedVideo(lang);
			}
		}
	};

	const iconStyle: any = {
		display: "flex",
		backgroundColor: "black",
		aspectRatio: "1/1",
		objectFit: "cover",
		height: "60px",
		borderRadius: "7px",
		justifyContent: "center",
		alignItems: "center",
		flexWrap: "wrap",
	};

	const noFileIcon: any = {
		opacity: 0.5,
	};

	const options = (isAudio: boolean) => (
	

		<div style={{ display: "flex", justifyContent: "space-between", float:"right" }}>
			<AttachedButtons
				onEdit={() => onEdit(isAudio)}
				onPlay={() => onPlay(isAudio)}
				onDelete={() => {
					setIsAudio(isAudio);
					setOpenDeleteModal(true);
				}}
			/>
		</div> 
	);

	//la possibilità di modificare il nome è stata rimossa
	const nameEdit = (name: string, isAudio: boolean) => {
		if (isAudio && audio) {
			const newobj = {
				...audio,
				[lang]: {
					...audio[lang],
					name: name,
				},
			};
			//setAudio(newobj);
			setFilesList("audioTracks", convertToAttachedFiles(newobj));
		} else if (!isAudio && video) {
			const newobj = {
				...video,
				[lang]: {
					...video[lang],
					name: name,
				},
			};
			//setAudio(newobj);
			setFilesList("videos", convertToAttachedFiles(newobj));
		}
	};

	const acceptEdit = (
		<IconButton
			onClick={() => {
				setEdit(false);
			}}
		>
			<DoneIcon></DoneIcon>
		</IconButton>
	);
	return (
		<>
			{/* {isLoading && <CircularProgress size={24} />} */}
			<LoadingOverlay isLoading={isLoading} />
			<GeneralModal
				open={openDeleteModal}
				onclickConfirm={() => {
					onDelete(isAudio);
					setOpenDeleteModal(false);
				}}
				onClose={() => setOpenDeleteModal(false)}
				onClickCancel={() => setOpenDeleteModal(false)}
			>
				<Typography>{`${t("COMMONS.DELETE_ENTITY_MESSAGE_M")} file?`} </Typography>
			</GeneralModal>
			<Modal
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div
					style={{
						padding: "0px !important",
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						borderRadius: 17,
						width: "57vw",
						aspectRatio: isAudio ? "" : "12/9",
						background: isAudio ? "transpaent" : "black",
					}}
				>
					{isAudio
						? audio && audio[lang] && <PlayerAudio url={createUrlFile(audio[lang]?.key) || ""} />
						: video && video[lang] && <PlayerVideo url={createUrlFile(video[lang]?.key) || ""} />}
				</div>
			</Modal>
			<SectionContainer name={`${t("INSIGHTS.DETAIL.ATTACHED_FILES")}`}>
				{/* <Box {...getRootProps()}>
					<input {...getInputProps()} name="foto" />
					{(!audio || !video) && <AddButton onClick={() => null} component={undefined} />}
				</Box> */}
			</SectionContainer>
			<Box>
				<DropZone isAudio={true} onDrop={onDrop} upload={audio ? !audio[lang] : true}>
					<SingleCard
						id={1}
						icon={
							audio && audio[lang] ? (
								<div style={iconStyle}>
									<HeadsetIcon sx={{ color: "white",  }} fontSize="large"></HeadsetIcon>
								</div>
							) : (
								<HeadsetIcon sx={{ color: "grey" }} fontSize="large"></HeadsetIcon>
							)
						}
						options={edit && isAudio ? acceptEdit : options(true)}
						description={
							byteToMega(audio && audio[lang] ? audio[lang]!.sizeBytes : 0) + " MB " // +
							//(audio && audio[lang] ? secToMin(audio[lang]!.duration || 0) + " min" : "")
						}
						name={audio && audio[lang] ? audio[lang]!.name : ""}
						nameEdit={edit && isAudio ? (name) => nameEdit(name, true) : undefined}
						upload={audio ? !audio[lang] : true}
					/>
				</DropZone>
				<Typography color={redColor} sx={errorAudio ? { visibility: "visible", mb: 1, mt: -1 } : { visibility: "hidden" }}>
					{t("ERRORS.INSIGHTS.URL")}
				</Typography>

				<DropZone isAudio={false} onDrop={onDrop} upload={video ? !video[lang] : true}>
					<SingleCard
						id={2}
						icon={
							video && video[lang] ? (
								<Box sx={video && video[lang] ? iconStyle : [iconStyle, noFileIcon]}>
									<OndemandVideoIcon sx={{ color: "white" }} fontSize="large"></OndemandVideoIcon>
								</Box>
							) : (
								<OndemandVideoIcon sx={{ color: "grey" }} fontSize="large"></OndemandVideoIcon>
							)
						}
						options={edit && !isAudio ? acceptEdit : options(false)}
						description={
							byteToMega(video && video[lang] ? video[lang]!.sizeBytes : 0) + " MB" //+
							//	(video && video[lang] ? secToMin(video[lang]!.duration || 0) + " min" : null)
						}
						name={video && video[lang] ? video[lang]!.name || video[lang]!.name : ""}
						nameEdit={edit && !isAudio ? (name) => nameEdit(name, false) : undefined}
						upload={video ? !video[lang] : true}
					/>
				</DropZone>
				<Typography color={redColor} sx={errorVideo ? { visibility: "visible", mb: 1, mt: -1 } : { visibility: "hidden" }}>
					{t("ERRORS.INSIGHTS.URL")}
				</Typography>
			</Box>
		</>
	);
};

export default AttachedFiles;

import SingleCard from "./SingleCard";
import { reworkData } from "../../../utils/util";
import { PoiDetailInterface } from "../../../type/rest/poi-rest-interfaces";
import { BeaconDetailInterface } from "../../../type/rest/beacon-rest-interface";
import { t } from "i18next";

interface props {
	state?: BeaconDetailInterface[] | PoiDetailInterface | PoiDetailInterface[];
	navigation: (id: string | undefined) => void;
	currentLanguage: string;
}

function ImageCard({ state, navigation, currentLanguage }: props) {
	// const [poiList, setPoiList] = useState< PoiDetailInterface[]|[]>(state ? state : []);

	const reworkPoi = state ? reworkData(state, currentLanguage) : null;
	return (
		<>
			{reworkPoi &&
				reworkPoi.map((el) => {
					return <SingleCard id={el.id || ""} cover={el.cover} description={t(el.description)} name={el.name} navigation={() => navigation(el.id)} />;
				})}
		</>
	);
}

export default ImageCard;

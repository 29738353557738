import { Box, Container, Grid } from "@mui/material";
import React, { ReactNode } from "react";
import styles from "./LandingPage.module.css";
import Image from "react-bootstrap/Image";
import artPlaceLogo from "../../img/logo_artpalce_scuro.png";
import Footer from "../../componets/legalFooter/LegalFooter";

type TProps = {
	children: ReactNode;
};
const LandingPage: React.FC<TProps> = ({ children }) => {
	return (
		<>
			<div>
				<Grid container className={styles.container}>
					<Grid item xs={12} sm={6} className={styles.primarySide}>
						<Container sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
							<Image src={artPlaceLogo} className={styles.logoStyle} />
						</Container>
					</Grid>
					<Grid item xs={12} sm={6} className={styles.whiteSide}>
						<Grid container direction="column" style={{ height: "100%" }}>
							<Grid item xs style={{ overflow: "auto", display: "flex", alignItems: "center" }}>
								<Container maxWidth="xs">{children}</Container>
							</Grid>
							<Grid item>
								<Footer />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default LandingPage;

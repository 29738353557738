import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";

interface Props {
	isAudio: boolean;
    onDrop:(acceptedFiles: File[]) => void;
    children:React.ReactNode;
    upload:boolean;
}

const DropZone = ({ isAudio,onDrop,children,upload }: Props) => {
    const acceptedFiles = () => {
		let formati = {};

		if (isAudio) {
			formati = {
				"audio/mpeg": [".mp3"],
			};
		} else {
			formati = {
				"video/mp4": [".mp4"],
			};}

		return formati;
	};

    const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: acceptedFiles(),
        noClick:!upload
	});
    
	return (
		<Box {...getRootProps()}>
			<input {...getInputProps()} name="foto" />
            {children}
		</Box>
	);
};

export default DropZone;

import React, { useState } from "react";
// import styles from "./PoiList.module.css";
import "./PoiList.module.css";
import "../../../colors.css";
import { DATATYPE, NAVIGATION_DATA, POI_FILTERS } from "../../../type/commons-artplace";
import CustomDataGrid from "../../../componets/dataGrid/CustomDataGrid";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { observer } from "mobx-react-lite";
const PoiList: React.FC = () => {
	const type = NAVIGATION_DATA.POI;
	const [searchField, setSearchField] = useState<string>(POI_FILTERS.name);
	const { formatLangFields } = EntityUtilities();

	return (
		<>
			<CustomDataGrid
				type={type}
				numberRows={DATATYPE.POI_LIST}
				searchField={searchField}
				formatRows={(item) => ({
					...item,
					id: item._id,
					name: formatLangFields(item.langFields),
				})}
			/>
		</>
	);
};

export default observer(PoiList);

import { useState } from "react";
import "../../../colors.css";
import { DATATYPE, NAVIGATION_DATA, NEWS_FILTERS } from "../../../type/commons-artplace";
import CustomDataGrid from "../../../componets/dataGrid/CustomDataGrid";

const NewsList = () => {
	const type = NAVIGATION_DATA.NEWS;
	const [searchField, setSearchField] = useState<string>(NEWS_FILTERS.name);

	return (
		<>
			<CustomDataGrid
				type={type}
				numberRows={DATATYPE.NEWS}
				searchField={searchField}
				formatRows={(item) => ({
					...item,
					id: item._id,
				})}
				labelAdd="COMMONS.ADD_NEW2"
			/>
		</>
	);
};

export default NewsList;

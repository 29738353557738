import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { USER_ROLE } from "../type/commons-artplace";
import { PersonInterface } from "../type/rest/person-rest-interface";
export class UserStore {
	private id: number = 0;
	//private id_registry_owner: { business_name: string } = { business_name: "" };
	private role: string = "";
	private image: string = "";
	private firstName: string = "";
	private lastName: string = "";
	private tenant: string = "";
	private vat_number: string = "";
	private businessName: string = "";
	private isAuthenticated: boolean = false;
	private logoutModal: boolean = false;
	constructor() {
		makeAutoObservable(this);
	}

	setUser = (user: any) => {
		// Creo un nuovo oggetto utente che rispetti l'interfaccia PersonInterface
		const userToSave: PersonInterface = {
			id: user._id,
			firstName: user.firstname,
			lastName: user.surname,
			role: user.role,
			tenant: user.tenant,
			vat_number: user.vat_number,
			businessName: user.business, // Supponendo che 'business' corrisponda a 'businessName'
		};

		// Imposto l'utente nel localStorage
		localStorage.setItem("user", JSON.stringify(userToSave));

		// Setto le label dell'utente nello store
		this.id = userToSave.id;
		this.firstName = userToSave.firstName;
		this.lastName = userToSave.lastName;
		this.businessName = userToSave.businessName;
		this.role = userToSave.role;
		this.tenant = userToSave.tenant;
		//this.id_registry_owner = userToSave.id_registry_role;
	};

	getUser = (): PersonInterface => {
		const storedData = localStorage.getItem("user");
		if (storedData) {
			const user: PersonInterface = JSON.parse(storedData);
			// Setto le label dell'utente nello store
			this.id = user.id;
			this.firstName = user.firstName;
			this.lastName = user.lastName;
			this.businessName = user.businessName;
			this.role = user.role;
			this.tenant = user.tenant;
			//this.id_registry_owner = user.id_registry_role;
		}
		return {
			id: this.id,
			firstName: this.firstName,
			lastName: this.lastName,
			businessName: this.businessName,
			role: this.role,
			tenant: this.tenant,
			vat_number: "", // Assumendo che non venga gestito da getUser
		};
	};

	getUserId = (): number => {
		return this.id;
	};
	getUserFirstName = (): string => {
		return this.firstName;
	};
	// getUserLastName = (): string => {
	// 	return this.lastName;
	// };
	getUserImage = (): string => {
		return this.image;
	};
	getUserRole = (): string => {
		if (this.role !== "") {
			return this.role.toUpperCase();
		} else {
			const newRole = localStorage.getItem("role");
			if (newRole) {
				this.role = newRole;
				return this.role.toUpperCase();
			} else {
				return "";
			}
		}
	};
	setUserRole = (role: string): void => {
		this.role = role;
	};
	/* getUserIdRegistryOwner = () => {
		return this.id_registry_owner;
	}; */

	public get getTenant(): string {
		return this.tenant;
	}

	public get getVatNumber(): string {
		return this.vat_number;
	}

	public get getBusinessName(): string {
		return this.businessName;
	}

	// public get getUserFirstName(): string {
	// 	return this.firstName;
	// }

	public get getUserLastName(): string {
		return this.lastName;
	}

	public setTenant(value: string): void {
		this.tenant = value;
	}

	public setVatNumber(value: string): void {
		this.vat_number = value;
	}

	public setBusinessName(value: string): void {
		this.businessName = value;
	}
	setIsAuthenticated(value: boolean) {
		this.isAuthenticated = value;
	}
	public get getIsAuthenticated(): boolean {
		return this.isAuthenticated;
	}

	public getLogoutModal = () => {
		return this.logoutModal;
	};

	public setLogoutModal = (value: boolean) => {
		this.logoutModal = value;
	};

	clearUserStore = () => {
		// Resetta gli attributi allo stato iniziale
		this.id = 0;
		this.role = "";
		this.image = "";
		this.firstName = "";
		this.lastName = "";
		this.tenant = "";
		this.vat_number = "";
		this.businessName = "";
		this.isAuthenticated = false;
		this.logoutModal = false;

		// Puoi anche resettare altri stati necessari qui
	};
}

export const UserStoreContext = createContext(new UserStore());

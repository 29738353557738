import React from "react";
import { Box, Container, Typography } from "@mui/material";
import "../../../colors.css";
import { t } from "i18next";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

export default function ConfirmRegistration() {
	return (
		<Container
			maxWidth="sm"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				minHeight: "100vh",
				width: "100%",
				textAlign: "center",
			}}
		>
			<EmailOutlinedIcon style={{ color: "var(--greenAccent-500)", fontSize: "150px", marginBottom: 5 }} />
			<Typography variant="h2" style={{ color: "var(--black)" }}>
				{t("COMMONS.THANK_YOU")}
			</Typography>
			<Typography variant="h6" style={{ color: "var(--black)" }}>
				{t("LOGIN.CONFIRM_REGISTRATION_EMAIL")}
			</Typography>
			{/* <Link to="/login">Torna al Login</Link> */}
		</Container>
	);
}

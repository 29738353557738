// File BeaconDeleteModal.tsx
import { Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import GeneralModal from "../generalModal/GeneralModal";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import { t } from "i18next";
import { LanguageStore, LanguageStoreContext } from "../../stores/LanguageStore";

interface BeaconDeleteModalProps {
	open: boolean;
	onClose: () => void;
	onDeleteConfirmed: (index: number) => void;
	rowIndex: number;
	rowData: any;
}

const DeleteModal: React.FC<BeaconDeleteModalProps> = ({ open, onClose, onDeleteConfirmed, rowIndex, rowData }) => {
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage);
	const { formatLangFields } = EntityUtilities();
	const handleDeleteConfirmed = () => {
		onDeleteConfirmed(rowIndex);
		onClose();
	};

	const firstLanguage = rowData.langFields ? Object.keys(formatLangFields(rowData.langFields))[0] : null;

	const formattedData =
		rowData.langFields && firstLanguage
			? formatLangFields(rowData.langFields)[currentLanguage]
				? formatLangFields(rowData.langFields)[currentLanguage]
				: formatLangFields(rowData.langFields)[languageStore.getDefaultLanguage]
				  ? formatLangFields(rowData.langFields)[languageStore.getDefaultLanguage]
				  : formatLangFields(rowData.langFields)[firstLanguage]
			: null;
	return (
		<GeneralModal open={open} onClose={onClose} onClickCancel={onClose} onclickConfirm={handleDeleteConfirmed}>
			<>
				<Typography variant="h6" id="modal-title" gutterBottom>
					{t("MODALS.DELETE")}
				</Typography>
				<Typography variant="body1" gutterBottom>
					{t("MODALS.NAME")}
					{rowData.langFields
						? formattedData
							? formattedData.name
							: t("COMMONS.NO_VALUE")
						: rowData.name
						  ? rowData.name
						  : rowData.title
						    ? rowData.title
						    : rowData.business_name}
				</Typography>
				<Typography variant="body1" gutterBottom>
					{t("MODALS.ID")} {rowData._id || rowData.id}
				</Typography>
			</>
		</GeneralModal>
	);
};

export default DeleteModal;

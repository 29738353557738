import { Button, Card, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import TaskIcon from "@mui/icons-material/Task";
import { ErrorMessage } from "formik";
interface GPXUploaderInterface {
	setGpxFileFormData?: any;
	setFormik: (field: string, value: File | null) => void;
	setNewData?: (file: any) => void;
	error?: boolean | undefined;
}
const GPXUploader = ({ setGpxFileFormData, setFormik, setNewData, error }: GPXUploaderInterface) => {
	const [gpxFile, setGpxFile] = useState<File | null>(null);
	const onDrop = useCallback((acceptedFiles: File[]) => {
		acceptedFiles.forEach(async (file: File) => {
			setGpxFile(file);
			addFileToFormik(file);
			setGpxFileFormData(file);
			//post
			if (setNewData) setNewData(file);
		});
	}, []);

	const addFileToFormik = (value: File | null) => {
		setFormik("gpxFile", value);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		noDrag: true,
		multiple: false,
		accept: {
			"text/xml": [".gpx"],
		},
	});

	return (
		<>
			<Grid item>
				<ErrorMessage name="gpxFile">{(msg) => <div style={{ color: "red" }}>{t(msg)}</div>}</ErrorMessage>
			</Grid>
			<Card
				className="gpx-card"
				elevation={0}
				style={{
					border: error === true ? "1px solid red" : 0,
					borderRadius: 8,
					backgroundColor: "#D8DAF1",
					height: 150,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				{gpxFile ? (
					<Grid container flexDirection={"column"} alignItems={"center"}>
						<TaskIcon style={{ width: 50, height: 50 }} />
						<Typography>
							{gpxFile.name}
							<ClearRoundedIcon
								onClick={() => {
									addFileToFormik(null);
									setGpxFile(null);
								}}
							/>
						</Typography>
					</Grid>
				) : (
					<>
						<Typography variant="subtitle1">File GPX</Typography>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<Button variant="contained" startIcon={<FileUploadRoundedIcon />} color="secondary">
								{t("COMMONS.UPLOAD")}
							</Button>
						</div>
					</>
				)}
			</Card>
		</>
	);
};

export default GPXUploader;

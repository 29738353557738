import React from "react";
import { Grid, Typography, TextField, InputAdornment } from "@mui/material";
import { SocialInterface, SocialMapInterface } from "../../type/commons-artplace";
import { FormikErrors, FormikProps, getIn } from "formik";
import { PoiDetailInterface } from "../../type/rest/poi-rest-interfaces";
import { t } from "i18next";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";

interface SocialInputGridProps {
	availableSocials: SocialMapInterface;
	formik: FormikProps<Partial<PoiDetailInterface>>;
}

const SocialInputGrid: React.FC<SocialInputGridProps> = ({ availableSocials, formik }) => {
	const { getIndexFromSocialsFields } = EntityUtilities();

	// Fa l'update del social corrispondente in formik.values.socials
	const handleSocialChange = (
		key: string,
		url: string,
		formik: FormikProps<Partial<PoiDetailInterface>>,
		setFieldValue: {
			(field: string, value: any, shouldValidate?: boolean | undefined): Promise<void | FormikErrors<Partial<PoiDetailInterface>>>;
			(arg0: string, arg1: any): void;
		},
	) => {
		//Verifica che esista già il social nella lista formik.values.formik
		const existsSocial = formik.values.socials?.find((social) => social.type === key);

		if (existsSocial) {
			const updatedSocials = formik.values.socials?.map((social: SocialInterface) => {
				if (social.type === key) {
					if (url === "") {
						return { /* name: key,  */ type: "", url: url };
					} else {
						return { type: key, /* name: key,  */ url: url };
					}
				}
				return social;
			});

			// Update the formik values with the modified socials array
			setFieldValue("socials", updatedSocials?.filter((element) => (element.type && element.url) !== ""));
		} else {
			// Social doesn't exist, add a new one
			const newSocial: SocialInterface = { /* name: key, */ type: key, url: url };
			const updatedSocials = formik.values.socials ? [...formik.values.socials, newSocial] : [newSocial];

			// Update the formik values with the modified socials array
			setFieldValue("socials", updatedSocials);
		}
	};
	return (
		<>
			<Grid item xs={12} sm={12}>
				<Typography variant="h6" component="div" mt={2}>
					{t("POI.DETAIL.SOCIAL")}
				</Typography>
			</Grid>

			{Object.keys(availableSocials).map((key) => {
				const social = formik.values.socials?.find((item) => item.type === key);
				const url = social ? social.url : "";
				const socialIndex = getIndexFromSocialsFields(formik.values.socials, key);
				const socialError = formik.errors.socials ? getIn(formik.errors.socials[socialIndex], "url") : "";
				return (
					<Grid item xs={12} sm={12} md={6} key={key}>
						<TextField
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<img src={availableSocials[key].staticIcon} alt={key} width={28} height={28} />
									</InputAdornment>
								),
								sx: { borderRadius: 2 },
							}}
							name={formik.values.socials ? `socials.${socialIndex}.type` : ""}
							error={!!formik.errors.socials?.[socialIndex] ?? false}
							helperText={socialError ? t(socialError) : " "}
							label={t(`SOCIAL.${key.toUpperCase()}`)}
							variant="outlined"
							defaultValue={url}
							onBlur={(e) => handleSocialChange(key, e.target.value, formik, formik.setFieldValue)}
						/>
					</Grid>
				);
			})}
		</>
	);
};

export default SocialInputGrid;

import i18n from "../../i18n";

const IUBENDA_URL = "https://www.iubenda.com/";

const urlsByLanguage: any = {
	it: [
		{ label: "COOKIE_POLICY", href: IUBENDA_URL + "it/privacy-policy/70710870/cookie-policy" },
		{ label: "PRIVACY_POLICY", href: IUBENDA_URL + "it/privacy-policy/70710870" },
		{ label: "TERMS_AND_CONDITIONS", href: IUBENDA_URL + "it/termini-e-condizioni/70710870" },
	],
	en: [
		{ label: "COOKIE_POLICY", href: IUBENDA_URL + "en/privacy-policy/20057989/cookie-policy" },
		{ label: "PRIVACY_POLICY", href: IUBENDA_URL + "en/privacy-policy/20057989" },
		{ label: "TERMS_AND_CONDITIONS", href: IUBENDA_URL + "en/termini-e-condizioni/20057989" },
	],
};

export function getLegalLinks(language: any) {
	return urlsByLanguage[language] || urlsByLanguage["en"]; // fallback to English if language is not found
}

// File BeaconDeleteModal.tsx
import { Typography } from "@mui/material";
import React, { useState } from "react";
import GeneralModal from "../generalModal/GeneralModal";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import { t } from "i18next";

interface ModalProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	usersNumber?: number;
	global?: boolean;
	text1?: string;
	text2?: string;
}

const ConfirmationModal: React.FC<ModalProps> = ({ open, onClose, onConfirm, usersNumber, global, text1, text2 }) => {
	const handleConfirm = () => {
		onConfirm();
		onClose();
	};

	return (
		<GeneralModal open={open} onClose={onClose} onClickCancel={onClose} onclickConfirm={onConfirm}>
			<>
				<Typography variant="h6" id="modal-title" gutterBottom>
					{text1}
					{(usersNumber || global) &&
						(global ? t("MODALS.ALL_USERS") : usersNumber === 1 ? `${usersNumber} ${t("MODALS.USER")}` : `${usersNumber} ${t("MODALS.USERS")}`)}
					{text2}
				</Typography>
			</>
		</GeneralModal>
	);
};

export default ConfirmationModal;

import { Card, Grid, CircularProgress, Typography, FormControl, InputLabel, MenuItem, Select, Divider, Checkbox, Button } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { InsightDetailInterface } from "../../../type/rest/insight-rest-interfaces";
import { ClientInterface, PoiDetailInterface, PoiInterface } from "../../../type/rest/poi-rest-interfaces";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import { TextEditor } from "../../../componets/textField/textEditor/TextEditor";
import AttachedFiles from "../insight-add/attachedFiles";
import PhotoDropZone from "../insight-add/photoDropZone";
import ImageCard from "../../../componets/pageComponents/ImageCard/ImageCard";
import IdName from "../../../componets/pageComponents/IdName/IdName";
import { BeaconDetailInterface, BeaconInterface } from "../../../type/rest/beacon-rest-interface";
import AddEntityModal from "../../../componets/modals/addEntityModal/AddEntityModal";
import { DATATYPE, LangField, NAVIGATION_DATA, TypologicType, emptyInsight, langField } from "../../../type/commons-artplace";
import AddButton from "../../../componets/buttons/AddButton";
import { BE_LINKS } from "../../../api/api";
import DetailSelect from "../../../componets/pageComponents/DetailSelect/DetailSelect";
import { TypologicStoreContext } from "../../../stores/TypologicStore";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { ToastContainer, toast } from "react-toastify";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { get, post, patch, uploadPhoto, uploadFile } from "../../../utils/call-api";
import Ratings from "../../../componets/ratings/Ratings";
import EntityLanguageSelector from "../../../componets/entityLanguageSelector/EntityLanguageSelector";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import LangFieldText from "../../../componets/langFieldText/LangFieldText";
import { Spinner } from "react-bootstrap";
import { getEnumKeyByValue, orderCustomersByBusinessName } from "../../../utils/util";

const InsightDetail = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const [insightDetail, setInsightDetail] = useState<Partial<InsightDetailInterface> | null>(null);
	const [loadingInsightDetail, setLoadingInsightDetail] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	//useState per modale
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<BeaconInterface[] | PoiInterface[]>();
	const [rowLimit, setRowLimit] = useState<boolean>(false);
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const [poi, setPoi] = useState<PoiDetailInterface | null>(null);
	const [photos, setPhotos] = useState<File[]>([]);
	const [beaconList, setBeaconList] = useState<BeaconDetailInterface[]>([]);
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole = userStore.getUserRole();
	const navigate = useNavigate();
	const typologicStore = useContext(TypologicStoreContext);
	const [clients, setClients] = useState<ClientInterface[]>([]);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
	const [categories, setCategories] = useState<TypologicType[] | undefined>([]);
	const [hasSubmitted, sethasSubmitted] = useState<boolean>(false);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage);
	const { handleSubtitleChange, handleDescriptionChange, handleLanguageChange, correctLanguageValue, getIndexFromLangFields } = EntityUtilities();
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [deletedAudio, setDeletedAudio] = useState<string[]>([]);
	const [deletedVideo, setDeletedVideo] = useState<string[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);

	const [isUploading, setIsUploading] = useState(false);

	function checkWordInUrl(word: string): boolean {
		// Ottiene l'URL corrente della pagina
		const currentUrl = window.location.href;
		// Verifica se la parola è presente nell'URL
		return currentUrl.includes(word);
	}

	const [selectedTenant, setSelectedTenant] = useState<string>("");

	useEffect(() => {
		// Funzione per controllare la parola "ADD" nell'URL
		const hasAddInUrl = checkWordInUrl("add");

		if (hasAddInUrl) {
			const fetchTenant = async () => {
				const tenant = userStore.getTenant; // Assicurati che getTenant sia una funzione o adatta questa chiamata
				if (tenant) {
					setSelectedTenant(tenant);
					// console.log("Tenant fetched and set:", tenant);
				} else {
					console.log("No tenant found or error in fetching tenant");
				}
			};

			fetchTenant();
		}
	}, []);

	useEffect(() => {
		// Questo codice viene eseguito ogni volta che `selectedTenant` cambia
		setPoi(null); // Svuota la lista degli POI
		setBeaconList([]); // Svuota la lista dei BEACON correlati
	}, [selectedTenant]);

	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);

			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};

	const fetchOptions = useCallback(async () => {
		const fetchCategories = async () => {
			try {
				if (typologicStore.getTypologicsReady) {
					//DEVE RIMANERE DATATYPE.INSIGHT_
					return typologicStore.getCategories(DATATYPE.INSIGHT);
				} else {
					return [];
				}
			} catch (error) {
				console.error("Error fetching categories:", error);
				return [];
			}
		};

		try {
			// Fetch dati per tipologiche POI
			const [categories, clients] = await Promise.all([fetchCategories(), fetchClients()]);
			//Per sicurezza, prima azzera tutto e re-inizializzo tutto
			// setCategories([]);
			// setClients([]);

			// Inizializzo le tipologiche del POI
			setCategories(() => categories);
			setClients(() => clients);

			if (categories.length === 1) {
				// Imposta automaticamente la categoria se ce n'è solo una
				setInsightDetail((currentDetails) => ({
					...currentDetails,
					category: categories[0],
				}));
			}

			// Handle other fetched data as needed
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	}, [typologicStore]);

	const fetchInsightDetail = useCallback(async () => {
		setInsightDetail(null);
		setPoi(null);
		setBeaconList([]);

		try {
			if (id) {
				const data: any = (await get(BE_LINKS[userRole][NAVIGATION_DATA.INSIGHT].ALL, id, true)).data;
				// console.log("fetch insightDetail: ", data);
				if (data.langFields.find((langField: LangField) => langField.lang === currentLanguage)) {
					//se esiste già un langFields della lingua corrente OK
					setInsightDetail(data || null);
				} else {
					if (data.langFields.find((langField: LangField) => langField.lang === languageStore.defaultLanguage)) {
						//altrimenti visualizza l'entità in inglese
						setCurrentLanguage(languageStore.defaultLanguage);
						setInsightDetail(data || null);
					} else {
						//altrimenti la prima lingua disponibile nei langFields
						setCurrentLanguage(data.langFields[0].lang);
						setInsightDetail(data || null);
					}
				}

				setBeaconList(() => data.beacons || []);
				setPoi(() => data.poi);
				/* fetchPoi();
				fetchBeacon(); */
			} else {
				setInsightDetail({
					...emptyInsight,
					langFields: [{ ...langField, lang: currentLanguage }],
				});
			}
		} catch (error: any) {
			console.error("Error fetching data:", error);
			return error;
		}
	}, [currentLanguage, id]);

	useEffect(() => {
		const fetchData = async () => {
			setLoadingInsightDetail(true);
			try {
				await fetchOptions(); // Try to fetch options
				await fetchInsightDetail();
			} catch (error) {
				console.error("Error fetching data:", error); // Handle and log any errors
			} finally {
				setTimeout(() => {
					setLoadingInsightDetail(false);
				}, 500);
			}
		};

		fetchData();
	}, [id, typologicStore.getTypologicsReady]);

	const langFieldSchema = Yup.object().shape({
		name: Yup.string().required("ERRORS.FORM.REQUIRED"),
	});

	const validationSchema = Yup.object({
		langFields: Yup.array().of(langFieldSchema).required("ERRORS.FORM.REQUIRED"),

		category: Yup.object().shape({
			key: Yup.string().required("ERRORS.FORM.REQUIRED"),
			label: Yup.string().required("ERRORS.FORM.REQUIRED"),
		}),
		//Non sono obbligatori
		/* video: Yup.object().shape({
			url: Yup.string().trim(),
			filename: Yup.string().trim(),
			dimension: Yup.string().trim(),
		}),
		audioTracks: Yup.object().shape({
			url: Yup.string().trim(),
			filename: Yup.string().trim(),
			dimension: Yup.string().trim(),
		}), */
		// photos: Yup.array().min(1, "ERRORS.INSIGHT.PHOTOS"),
	});

	const handleModal = () => {
		setOpen((prev) => !prev);
	}; //controller apertura/chiusura modale

	return (
		<>
			{loadingInsightDetail || !insightDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<Formik<Partial<InsightDetailInterface>>
					enableReinitialize
					initialValues={{
						...insightDetail,
						category: insightDetail?.category || (categories?.length === 1 ? categories[0] : undefined),
					}}
					validationSchema={validationSchema}
					onSubmit={async (values, { setSubmitting }) => {
						try {
							setIsRequestSubmitted(true);

							const filteredLangFields = values.langFields?.filter((langField) => langField.name.trim() !== "");

							// Aggiorna `values` con il nuovo array filtrato
							const { averageRate, favorites, numberOfRate, latlng, _id, ...newValues } = values;

							newValues.langFields = filteredLangFields;

							let response: { data: InsightDetailInterface; message: string };
							const poiId: any = poi ? poi?._id : null;
							const beaconIds: (string | undefined)[] = beaconList?.map((el) => el._id) || [];
							const result = { ...newValues, statusActive: true, poi: poiId, beacons: beaconIds };

							if (id) {
								//console.log("Trying to submit: ", result);
								response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.INSIGHT].ALL, id, result);
								setInsightDetail(response.data);
							} else {
								response = await post(BE_LINKS[userRole][NAVIGATION_DATA.INSIGHT].ALL, result, result.tenant, false);
								//Caricamento in bulk di tutte le foto selezionate al salvataggio (Solo in creazione)
								if (photos.length) {
									try {
										await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.INSIGHT, userRole);
									} catch (error) {
										toast.error(t("Errore upload photo"));
									}
								}
								if (result.audioTracks) {
									try {
										await uploadFile(result.audioTracks, NAVIGATION_DATA.INSIGHT, response.data._id, true, userRole);
									} catch (error) {
										toast.error(t("MESSAGES.UPLOAD_ERROR"));
									}
								}
								if (result.videos) {
									try {
										//console.log("Trying to upload - video: ", result.videos);
										await uploadFile(result.videos, NAVIGATION_DATA.INSIGHT, response.data._id, false, userRole);
									} catch (error) {
										toast.error(t("MESSAGES.UPLOAD_ERROR"));
									}
								}
							}

							toast.success(t("MESSAGES.UPDATE_OK"));
							//setInsightDetail(response.data);

							// console.log("response from insight ", response);

							setTimeout(() => {
								navigate(`/insights/detail/${response.data._id}`);
							}, 1000);
						} catch (error: any) {
							throw error;
							/* console.log("ERROR Insight: ", error);
							toast.error(t("MESSAGES.UPDATE_ERROR")); */
						} finally {
							setTimeout(() => {
								setIsRequestSubmitted(false);
							}, 500);
						}
						setSubmitting(false);
					}}
				>
					{(formik) => (
						<form onSubmit={formik.handleSubmit}>
							<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
							<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
								<Grid flexDirection={"column"}>
									<Typography variant={"h1"}>
										{id
											? insightDetail?.langFields?.find((field) => field.lang === currentLanguage)?.name || t("INSIGHTS.ADD.TITLE")
											: t("INSIGHTS.ADD.TITLE")}
									</Typography>
									<CustomBreadcrumbs
										dataType={NAVIGATION_DATA.INSIGHT}
										currentPage={
											id
												? insightDetail?.langFields?.find((element) => element.lang === currentLanguage)?.name ||
												  t("INSIGHTS.ADD.TITLE").split(" ")[0]
												: t("INSIGHTS.ADD.TITLE").split(" ")[0]
										}
									/>
								</Grid>
								<Button
									variant="contained"
									color="secondary"
									disabled={isRequestSubmitted || isUploading}
									onClick={() => {
										if (
											!formik.values.category ||
											/* formik.errors.videos ||
											formik.errors.audioTracks || */
											formik.values.langFields?.some((value) => value.name === undefined || value.name === "")
										) {
											toast.warn(t("MESSAGES.WARNING_FORM"), {
												className: "yellowToastWarning",
											});
											if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
												const emptyLangs =
													formik.values.langFields?.filter((value) => value.name === undefined || value.name === "").map((value) => value.lang) ?? [];
												const keyValues = emptyLangs?.map((element) => getEnumKeyByValue(element));
												const translatedKeys = keyValues
													.filter((key): key is string => key !== undefined)
													.map((key: string) => t(`SUPPORTED_LANGUAGES.${key}`))
													.join(",\n");
												toast.warn(t("MESSAGES.CHECK_LANGUAGES") + translatedKeys);
											}
											formik.setFieldTouched("langFields", true);
											formik.setFieldError("langFields", undefined);
											formik.setFieldTouched("videos", true);
											formik.setFieldTouched("audioTracks", true);
											formik.setFieldTouched("category", true);
										} else {
											formik.handleSubmit();
										}
									}}
									startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
									size="large"
								>
									{t("COMMONS.SAVE_CHANGES")}
								</Button>
							</Grid>

							<Grid container item spacing={2}>
								<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
									{/* R1 */}
									<Grid item xs={4} sm={4} alignItems={"baseline"} justifyContent={"center"}>
										<EntityLanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} formik={formik} />
									</Grid>
									{/* TODO: NON RIMUOVERE, ANDRA' REINTRODOTTO */}
									{/* <Grid item xs={1} sm={1}>
										<StatusSelector value={formik?.values?.statusActive || true} callBack={formik.setFieldValue} />
									</Grid> */}
									{/* R2 */}
									<IdName id={id} formik={formik} lang={currentLanguage} />
									{/* R3 */}
									{/* <Grid item xs={12} sm={12}>
										<TextField
											fullWidth
											label="Sottotitolo"
											name={`langFields.${getIndexFromLangFields(formik.values.langFields, currentLanguage)}.subtitle`}
											variant="outlined"
											//helperText={formik.touched.title && t(formik.errors.title ?? "")}
											value={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.subtitle}
											onChange={(event) => handleSubtitleChange(currentLanguage, event, formik.values.langFields, formik.setFieldValue)}
											InputProps={{ sx: { borderRadius: 2 } }}
										/>
									</Grid> */}
									<Grid item xs={12} sm={12}>
										<LangFieldText formik={formik} fieldName={"subtitle"} callBack={handleSubtitleChange} lang={currentLanguage} />
									</Grid>
									{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
										<Grid item xs={12} md={6} sm={6}>
											<FormControl fullWidth variant="outlined">
												<InputLabel error={formik.touched.tenant && !!formik.errors.tenant} color="primary">
													{t("FORM.CUSTOMER")}
												</InputLabel>
												<Select
													disabled={id ? true : false}
													label={t("FORM.CUSTOMER")}
													name="tenant"
													value={formik.values.tenant || userStore.getTenant}
													// onChange={formik.handleChange}
													onChange={(event) => {
														setSelectedTenant(event.target.value);
														formik.setFieldValue("tenant", event.target.value);
													}}
													style={{ borderRadius: 8 }}
													error={formik.touched.tenant && !!formik.errors.tenant}
												>
													{clients.map((el: ClientInterface) => (
														<MenuItem key={el._id} value={el.tenant}>
															{el.business_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									)}
									{/* R5 */}
									<Grid item xs={12} md={6} sm={6}>
										<DetailSelect
											completeList={categories}
											error={formik.errors.category}
											touched={formik.touched.category}
											value={formik.values.category?.key || ""}
											setState={(event) => {
												const selectedCategory = categories && categories.find((el) => el.key === event);
												formik.setFieldValue("category", selectedCategory); // Set the entire category object
											}}
											label={t("FORM.CATEGORY")}
											name={"category"}
											onBlur={formik.handleBlur}
											required={true}
										/>
										{/* {formik.touched.category && !!formik.errors.category && (
														<FormHelperText >{t(getIn(formik.errors.category, "key"))}</FormHelperText>
													)} */}
									</Grid>
									<Grid container item xs={12} md={12} sm={12} flexDirection={"row"} alignItems={"center"}>
										<Grid item>
											<Typography>{t("INSIGHTS.DETAIL.ONLY_BEACON")}</Typography>
										</Grid>
										<Grid item>
											<Checkbox name="onlyBeacon" checked={formik.values.onlyBeacon} onChange={formik.handleChange} />
										</Grid>
									</Grid>

									{/* R6 */}
									<Grid item xs={12}>
										<Typography variant="h6" component="div" mb={1}>
											{t("INSIGHTS.DETAIL.DESCRIPTION")}
										</Typography>
										<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
											<TextEditor
												setFieldValue={(value) => handleDescriptionChange(currentLanguage, value, formik.values.langFields, formik.setFieldValue)}
												defaultValue={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.description}
											/>
										</Card>
									</Grid>

									{/* R4 */}
									<Divider />
									<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
										<Grid item>
											<Typography variant="h6" component="div" mb={1} mt={2}>
												{t("INSIGHTS.DETAIL.CONNECTED_POI")}
											</Typography>
										</Grid>
										{
											<Grid item>
												<AddButton
													length={poi ? 1 : 0}
													onClick={() => {
														setModalTitle(() => t("POI.ADD.TITLE"));
														setModalContent(() => (poi ? [poi] : []));
														setModalCallBack(() => setPoi);
														setModalDataType(() => DATATYPE.POI);
														setRowLimit(true);
														handleModal();
														setRowLimit(true);
													}}
													component={undefined}
												/>
											</Grid>
										}
										<Grid item xs={12}>
											<ImageCard
												navigation={(id: string | undefined) => navigate(`/poi/detail/${id}`)}
												currentLanguage={currentLanguage}
												state={poi || []}
											/>
										</Grid>
									</Grid>
									<Divider />
									{/* R5 */}
									<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
										<Grid item>
											<Typography variant="h6" component="div" mb={1} mt={2}>
												{t("INSIGHTS.DETAIL.CONNECTED_BEACONS")}
											</Typography>
										</Grid>
										<Grid item>
											<AddButton
												length={beaconList?.length || 0}
												onClick={() => {
													setModalTitle(() => t("BEACONS.ADD.TITLE"));
													setModalContent(() => beaconList);
													setModalCallBack(() => setBeaconList);
													setModalDataType(() => DATATYPE.BEACONS);
													setRowLimit(false);
													handleModal();
												}}
												component={undefined}
											/>
										</Grid>
										<Grid item xs={12}>
											<ImageCard
												currentLanguage={currentLanguage}
												state={beaconList || []}
												navigation={(id: string | undefined) => navigate(`/beacons/detail/${id}`)}
											/>
										</Grid>
									</Grid>
									<Divider />
									{/* R5 */}
									<Grid item xs={12}>
										<AttachedFiles
											errorVideo={Boolean(formik.errors.videos) && hasSubmitted}
											errorAudio={Boolean(formik.errors.audioTracks) && hasSubmitted}
											setFilesList={formik.setFieldValue}
											fileAudio={formik.values.audioTracks ? formik.values.audioTracks : []}
											fileVideo={formik.values.videos ? formik.values.videos : []}
											lang={currentLanguage}
											setDeletedVideo={(lang: string) => setDeletedVideo(deletedVideo.concat([lang]))}
											setDeletedAudio={(lang: string) => setDeletedAudio(deletedVideo.concat([lang]))}
											navigationData={NAVIGATION_DATA.INSIGHT}
										/>
									</Grid>
								</Grid>
								<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
									<Grid item xs={12}>
										<Grid item xs={12} pt={9}>
											<PhotoDropZone
												photos={insightDetail.photos || []}
												setPhotoList={setPhotos}
												resetFiles={(action) => setResetFiles(action)}
												navigationData={NAVIGATION_DATA.INSIGHT}
												setEntity={setInsightDetail}
												setIsUploading={setIsUploading}
											/>
										</Grid>
									</Grid>
									{insightDetail._id && (
										<Grid item xs={12}>
											<Ratings
												averageRate={insightDetail.averageRate}
												numberOfRate={insightDetail.numberOfRate}
												favorites={insightDetail.favorites?.length}
											/>
										</Grid>
									)}
									{/* <Grid item xs={12}>
										<ExpectedConnection
											state={formik.values.conn || ""}
											setState={formik.setFieldValue}
											isvalid={formik.isValid}
											error={!!formik.touched.connection && Boolean(formik.errors.connection)}
										/>
										{!formik.isValid && formik.touched.connection && Boolean(formik.errors.connection) && (
											<Typography fontSize={"1.5vh"} margin={"1vh"} color={"red"}>
												{formik.errors.connection}
											</Typography>
										)}
									</Grid> */}
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			)}
			{open && modalDataType && (
				<Grid container xs={12}>
					<AddEntityModal
						open={open}
						title={modalTitle}
						onClose={() => handleModal()}
						callBack={modalCallBack}
						initialList={modalContent}
						dataType={modalDataType}
						rowLimit={rowLimit}
						tenant={id ? insightDetail?.tenant : selectedTenant}
					/>
				</Grid>
			)}
		</>
	);
};

export default InsightDetail;

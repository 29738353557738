import { useContext, useEffect, useState } from "react";
import { PersonInterface } from "../../type/rest/person-rest-interface";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { Form, Formik, FormikHelpers, FormikTouched } from "formik";
import { Alert, Button, Card, CircularProgress, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../breadCrumbs/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import { NAVIGATION_DATA } from "../../type/commons-artplace";
import SaveButton from "../buttons/SaveButton";
import YupPassword from "yup-password";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { API_AUTH_CHANGE_PASSWORD, BE_LINKS } from "../../api/api";
import { get, post } from "../../utils/call-api";
import { toast } from "react-toastify";
import { IAlert } from "../../type/type";
import { ISearchParams } from "./../../type/data-table";

interface FormValues {
	pwd: string;
	confirmPwd: string;
}

const ChangePassword = () => {
	const { t } = useTranslation();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const [mostraPwd, setMostraPwd] = useState(false);
	const [mostraPwd2, setMostraPwd2] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "COMMONS.ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({ ...alert, show: false });
		},
	});

	YupPassword(Yup);
	const ResetPasswordSchema = Yup.object().shape({
		pwd: Yup.string()
			.trim()
			.required("ERRORS.FORM.REQUIRED")
			.min(8, "ERRORS.FORM.SHOULD_BE_AT_LEAST_8_CHARACTERS")
			.max(24, "ERRORS.FORM.SHOULD_BE_AT_MOST_24_CHARACTERS")
			.minLowercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
			.minUppercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
			.minNumbers(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
			.minSymbols(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT"),
		confirmPwd: Yup.string()
			.oneOf([Yup.ref("pwd")], "ERRORS.FORM.INVALID_PASSWORD_MATCH")
			.required("ERRORS.FORM.REQUIRED"),
	});

	const handlePasswordChange = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
		setIsLoading(true);
		const payload = { pwd: values.pwd, confirmPwd: values.confirmPwd };
		try {
			const response = await post(API_AUTH_CHANGE_PASSWORD, payload);
			// console.log("response from fetch clients", response);
			resetForm();
			toast.success(t("TOAST.PASSWORD_CHANGED_SUCCESS"));
			// setAlert({ ...alert, show: true, message: "COMMONS.SUCCESS", severity: "success" });
		} catch (error) {
			console.error(`Error fetching clients`, error);
			toast.success(t("TOAST.PASSWORD_CHANGED_ERROR"));
			return [];
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Formik initialValues={{ pwd: "", confirmPwd: "" }} validationSchema={ResetPasswordSchema} onSubmit={handlePasswordChange}>
			{({ handleSubmit, values, errors, touched, handleChange, handleBlur, isValid, resetForm }) => {
				const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<{ pwd: string; confirmPwd: string }>]);
				const isSubmitDisabled = !formIsTouched || !isValid;
				return (
					<Form noValidate>
						<Card sx={{ padding: 2 }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12}>
									<Typography variant={"h6"}>{t("CUSTOMERS.CHANGE_PASSWORD")}</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									{alert.show && (
										<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
											{t(alert.message)}
										</Alert>
									)}
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										id="pwd"
										fullWidth
										name="pwd"
										type={mostraPwd ? "text" : "password"}
										label={t("COMMONS.NEW_PASSWORD")}
										value={values.pwd}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.pwd && !!errors.pwd}
										helperText={touched.pwd && errors.pwd && t(errors.pwd)}
										inputProps={{ autoComplete: "new-password" }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										id="confirmPwd"
										fullWidth
										name="confirmPwd"
										type={mostraPwd2 ? "text" : "password"}
										label={t("COMMONS.CONFIRM_PASSWORD")}
										value={values.confirmPwd}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.confirmPwd && !!errors.confirmPwd}
										helperText={touched.confirmPwd && errors.confirmPwd && t(errors.confirmPwd)}
										autoComplete="new-password"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setMostraPwd2(!mostraPwd2)}>{mostraPwd2 ? <VisibilityOff /> : <Visibility />}</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid container justifyContent="flex-end" item xs={12} sm={12}>
									<Grid item xs={5} sm={3}>
										<Button
											type="submit"
											sx={{ textTransform: "Capitalize" }}
											variant={"contained"}
											fullWidth
											disabled={isSubmitDisabled || isLoading}
											size={"medium"}
											startIcon={isLoading ? <CircularProgress size={20} /> : ""}
										>
											{t("COMMONS.CONFIRM")}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Card>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ChangePassword;

import React, { ReactNode } from "react";
import { Typography, Box } from "@mui/material";

interface CustomCardProps {
	children?: ReactNode;
	name: string;
}

const SectionContainer: React.FC<CustomCardProps> = ({ children, name }) => {
	const button = children ? React.Children.toArray(children)[0] : null;
	return (
		<Box sx={{ display: "inline-flex", width: "100%", justifyContent: "space-between" , marginTop:2,marginBottom:2}}>
			<Typography variant="h6" component="div">
				{name}
			</Typography>
			{button}
		</Box>
	);
};

export default SectionContainer;

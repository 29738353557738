import { BorderAll } from "@mui/icons-material";
import { createTheme } from "@mui/material";

export const colors = {
	primary400: "#f7f7f7",
	primary500: "#F5F8F9",
	primary600: "#E7EEF0",
	primary700: "#D9E5E9",

	red100: "#f3b6b6",
	red200: "#ee9c9c",
	red300: "#e88282",
	red400: "#e36868",
	red500: "#de4e4e",
	red600: "#d43434",

	orangeAccent100: "#FFEFE2",
	orangeAccent200: "#FCEBCF",
	orangeAccent300: "#FFB388",
	orangeAccent400: "#f39259",
	orangeAccent500: "#EF8144",

	greenAccent100: "#EFFCEF",
	greenAccent200: "#CDF59A",

	gray200: "#E0E0E0",
	gray300: "#D8DBDF",
	gray400: "#BDBDBD",
	gray500: "#969696",
	gray600: "#828B8F",
	gray700: "#4c4c4c",
	gray800: "#3A3A3A",
	gray900: "#292929",

	blue100: "#EFF5F8",
	blue200: "#D8DAF1",
	blue300: "#C2C5EB",
	blue400: "#ABB0E5",
	blue500: "#0A17A7",
	blue600: "#091593",
	blue700: "#081280",

	white: "#ffffff",
	black: "#0A0A0A",
	divider: "#bdbdbd",
};

const heading = {
	fontWeight: 700,
	color: colors.black,
};

const theme = createTheme({
	palette: {
		primary: {
			main: colors.black,
			contrastText: "#ffffff",
			light: "#F9A94C",
			dark: "#F9AF4C",
		},
		secondary: {
			main: colors.blue500,
			contrastText: colors.white,
			light: colors.blue400,
			dark: colors.blue700,
		},

		text: {
			primary: colors.black,
		},
	},
	typography: {
		// fontFamily: ["'Ubuntu', sans-serif"].join(","),
		h1: {
			...heading,
			fontSize: "2.28rem",
		},
		h2: {
			...heading,
			fontSize: "1.8rem",
		},
		h3: {
			...heading,
		},
		h4: {
			...heading,
		},
		h5: {
			...heading,
		},
		h6: {
			...heading,
		},
		subtitle1: {
			...heading,
		},
		subtitle2: {
			...heading,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "10px",
					boxShadow: "none",
					textTransform: "capitalize",
				},
				containedPrimary: {
					backgroundColor: colors.black,
					color: colors.white,
					"&:hover": {
						backgroundColor: colors.gray800,
					},
				},
				containedSecondary: {
					backgroundColor: colors.blue500,
					borderColor: colors.blue500,
					color: colors.white,
					"&:hover": {
						backgroundColor: colors.blue700,
					},
				},
				containedError: {
					backgroundColor: colors.red600,
					color: colors.black,
					"&:hover": {
						backgroundColor: colors.red600,
					},
				},
				outlinedPrimary: {
					borderColor: colors.black,
					color: colors.black,
					"&:hover": {
						backgroundColor: colors.gray400,
					},
				},
				sizeLarge: {
					fontSize: "1.2rem",
					height: 40,
					fontWeight: 700,
				},
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					color: colors.gray500,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: colors.gray600,
					"&.Mui-error": {
						color: "red",
					},
				},
			},
		},

		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontWeight: 700,
					color: colors.black,
					fontSize: "14px",
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					boxShadow: "none",
					border: "1px solid #D8DBDF",
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: colors.divider,
				},
			},
		},

		//MUI INPUT CON VARIANT OUTLINED
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					// Default border color
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: colors.gray300,
					},
					// Hover border color
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: colors.gray500,
					},
					// Focused border color
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: colors.black,
					},
					// Error border color
					"&.Mui-error .MuiOutlinedInput-notchedOutline": {
						borderColor: "red",
					},
					// Disabled INPUT outlined style
					"&.Mui-disabled": {
						backgroundColor: "#EFF5F8",
						// Border color
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor: "transparent !important",
						},
					},
				},
			},
		},

		//MUI DATAGRID

		//MUI CHECKBOX
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: colors.black,
					"&:hover": {
						backgroundColor: "transparent",
						color: colors.blue500,
					},
					"&.Mui-checked": {
						color: colors.blue500,
					},
				},
			},
		},
	},
});

export default theme;

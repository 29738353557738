import { useContext } from "react";
import { UserStoreContext } from "./UserStore";
import { TypologicStoreContext } from "./TypologicStore";
import { LanguageStoreContext } from "./LanguageStore";

export function ClearAllStores() {
	// Typing the useContext to ensure TypeScript knows the type of store being returned
	const userStore = useContext(UserStoreContext);
	const typologicStore = useContext(TypologicStoreContext);
	const languageStore = useContext(LanguageStoreContext);

	const resetAllStores = () => {
		userStore.clearUserStore();
		typologicStore.clearTypologicStore();
		// languageStore.clearLanguageStore();

		console.log("All stores have been reset to their default states.");
	};

	return resetAllStores;
}

import { LoadScript } from "@react-google-maps/api";
import React, { ReactNode } from "react";
import { libraries } from "../../constants/artplace.constant";

interface GoogleWrapperProps {
	children: ReactNode;
}

const GoogleWrapper: React.FC<GoogleWrapperProps> = ({ children }) => {
	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
	/* const libraries: ["places"] = ["places"]; // Add the required libraries */

	if (!apiKey) {
		// If apiKey is not available, you might want to return something else or null.
		return null;
	}

	return (
		<LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
			{children}
		</LoadScript>
	);
};

export default GoogleWrapper;

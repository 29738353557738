import { useContext, useEffect, useState } from "react";
import { PersonInterface } from "../../type/rest/person-rest-interface";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { Form, Formik, FormikHelpers, FormikTouched } from "formik";
import { Button, Card, CircularProgress, Divider, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BE_LINKS } from "../../api/api";
import { get, patch } from "../../utils/call-api";
import YupPassword from "yup-password";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";

const UserEdit = ({ setBusinessNameProp, businessNameProp }: any) => {
	const { t } = useTranslation();
	const userStore: UserStore = useContext(UserStoreContext);
	const [person, setPerson] = useState<any>({});
	const [initialValues, setInitialValues] = useState<any>({});
	const email = localStorage.getItem("userEmail");
	const [patched, setPatched] = useState(false);
	const [loading, setLoading] = useState(false);

	YupPassword(Yup);
	const userDataSchema = Yup.object().shape({
		business_name: Yup.string().trim().min(3, "ERRORS.FORM.MIN_LENGTH"),
	});

	const fetchIdRegistryOwner = async () => {
		setLoading(true);
		try {
			const response = (await get(BE_LINKS.COMMONS.USER_INFO)).data;
			if (response) {
				// console.log("Info User: ", response);
				setPerson(response);
			} else {
				toast.error("ERRORE: Non è stato possibile recuperare le informazioni dello user");
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchIdRegistryOwner();
	}, []);

	useEffect(() => {
		if (person) {
			const mappedValues = {
				birth_date: "",
				business_name: person.business || "",
				email: email || "",
				firstname: person.firstname || "",
				image: person.image || "",
				phone: "",
				sex_code: "",
				surname: person.surname || "",
			};
			setInitialValues(mappedValues);
		}
	}, [person, email]);

	const handleSubmit = async (values: any) => {
		const payload = { business_name: values.business_name };
		setPatched(true);
		try {
			const response = await patch(BE_LINKS.COMMONS.USER_INFO, " ", payload);
			if (response) {
				toast.success(t("TOAST.USER_DATA_UPDATED_SUCCESS"));
				localStorage.setItem("business_name", response.data.business_name);
				setBusinessNameProp(response.data.business_name);
				// setPerson((prev: any) => ({ ...prev, business: response.data.business_name }));
			}
		} catch (error) {
			console.error(`Error fetching clients`);
			toast.success(t("TOAST.USER_DATA_UPDATED_ERROR"));
			return [];
		} finally {
			setPatched(false);
		}
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize validationSchema={userDataSchema}>
			{({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
				const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<Partial<PersonInterface>>]);
				const isSubmitDisabled = !formIsTouched || !isValid;
				return (
					<>
						<Form noValidate>
							<Card sx={{ padding: 2 }}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12}>
										<Typography variant={"h6"}>{t("CUSTOMERS.PROFILE_DETAIL")}</Typography>
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextField
											fullWidth
											label={t("FORM.BUSINESS_NAME")}
											required
											name="business_name"
											variant="outlined"
											onBlur={handleBlur}
											error={touched.business_name && !!errors.business_name}
											helperText={touched.business_name ? (typeof errors.business_name === "string" ? t(errors.business_name, "") : "") : ""}
											value={values?.business_name}
											onChange={handleChange}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextField
											fullWidth
											label={t("FORM.EMAIL")}
											// required
											// name="businessName"
											// variant="outlined"
											// onBlur={handleBlur}
											// error={touched.email && !!errors.businessName}
											// helperText={touched.businessName && t(errors.businessName ?? "")}
											value={email}
											// onChange={handleChange}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid container justifyContent="flex-end" item xs={12} sm={12}>
										<Grid item xs={5} sm={3}>
											<Button
												type="submit"
												sx={{ textTransform: "Capitalize" }}
												variant={"contained"}
												fullWidth
												disabled={isSubmitDisabled || patched}
												size={"medium"}
												// startIcon={patched ? <Spinner animation="border" /> : ""}
												startIcon={patched ? <CircularProgress size={20} /> : ""}
											>
												{t("COMMONS.CONFIRM")}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Card>
						</Form>
					</>
				);
			}}
		</Formik>
	);
};

export default UserEdit;

import React, { useEffect } from "react";
import { CircularProgress, Container, Typography } from "@mui/material";
import "../../../colors.css";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_AUTH_VERIFYMAIL } from "../../../api/api";
import { useLocation, useParams } from "react-router-dom";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export default function ConfirmationEmail() {
	// const { token } = useParams();
	const [loading, setLoading] = React.useState<boolean>(true);
	const { t } = useTranslation();
	const [tokenValidity, setTokenValidity] = React.useState<string>("");
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");

	const handleAccountConfirmation = async () => {
		setLoading(true);
		try {
			const response = await axios.get(API_AUTH_VERIFYMAIL, {
				params: {
					token,
				},
			});
			if (response.data.code === 0) {
				setTokenValidity("valid");
			} else {
				setTokenValidity("invalid");
			}
		} catch (error: any) {
			switch (error.response.data.code) {
				case 62:
					setTokenValidity("expired");
					break;
				case 63:
					setTokenValidity("invalid");
					break;
				default:
					console.error(error.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleAccountConfirmation();
	}, []);

	if (loading) {
		return (
			<Container
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					width: "100%",
					textAlign: "center",
				}}
			>
				<CircularProgress size={100} />
			</Container>
		);
	}

	if (tokenValidity === "expired") {
		return (
			<Container
				maxWidth="sm"
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					width: "100%",
					textAlign: "center",
				}}
			>
				<HighlightOffIcon style={{ color: "var(--red-500)", fontSize: "150px", marginBottom: 5 }} />
				<Typography variant="h2" mb={2} style={{ color: "var(--black)", textTransform: "uppercase" }}>
					{t("LOGIN.CONFIRMATION_EMAIL_TITLE_EXPIRED")}
				</Typography>
				<Typography variant="h6" style={{ color: "var(--black)" }}>
					{t("LOGIN.CONFIRMATION_EMAIL_DESCRIPTION_EXPIRED")}
				</Typography>
			</Container>
		);
	}

	if (tokenValidity === "invalid") {
		return (
			<Container
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					width: "100%",
					textAlign: "center",
				}}
			>
				<ErrorOutlineOutlinedIcon style={{ color: "var(--red-500)", fontSize: "150px", marginBottom: 5 }} />
				<Typography variant="h2" style={{ color: "var(--black)", textTransform: "uppercase" }}>
					{t("LOGIN.CONFIRMATION_EMAIL_TITLE_ERROR")}
				</Typography>
				<Typography variant="h6" style={{ color: "var(--black)" }}>
					{t("LOGIN.CONFIRMATION_EMAIL_DESCRIPTION_ERROR")}
				</Typography>
			</Container>
		);
	}
	return (
		<Container
			maxWidth="sm"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				minHeight: "100vh",
				width: "100%",
				textAlign: "center",
			}}
		>
			<MarkEmailReadOutlinedIcon style={{ color: "var(--greenAccent-500)", fontSize: "150px", marginBottom: 5 }} />
			<Typography variant="h2" mb={2} style={{ color: "var(--black)", textTransform: "uppercase" }}>
				{t("LOGIN.CONFIRMATION_EMAIL_TITLE")}
			</Typography>
			<Typography variant="h6" style={{ color: "var(--black)" }}>
				{t("LOGIN.CONFIRMATION_EMAIL_DESCRIPTION")}
			</Typography>
		</Container>
	);
}

//<Container
// 	maxWidth="sm"
// 	sx={{
// 		display: "flex",
// 		justifyContent: "center",
// 		alignItems: "center",
// 		flexDirection: "column",
// 		minHeight: "100vh",
// 		width: "100%",
// 		textAlign: "center",
// 	}}
// >
// 	<MarkEmailReadOutlinedIcon style={{ color: "var(--greenAccent-500)", fontSize: "150px", marginBottom: 5 }} />
// 	<Typography variant="h2" mb={2} style={{ color: "var(--black)", textTransform: "uppercase" }}>
// 		{t("LOGIN.CONFIRMATION_EMAIL_TITLE")}
// 	</Typography>
// 	<Typography variant="h6" style={{ color: "var(--black)" }}>
// 		{t("LOGIN.CONFIRMATION_EMAIL_DESCRIPTION")}
// 	</Typography>
// 	{/* <Link to="/login">Torna al Login</Link> */}
// </Container>
// 	);
// }

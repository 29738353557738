import { t } from "i18next";
import { LangField } from "../../type/commons-artplace";
import { InsightDetailInterface, InsightInterface, formattedLanguageFields } from "../../type/rest/insight-rest-interfaces";
import { StageDetailInterface } from "../../type/rest/stage-rest-interface";

export const formatInsight = (response: InsightDetailInterface) => {
	return {
		name: transformLanguageArrayToObject(response.langFields),
		id: response._id,
		cover: response.photos[0],
		category: response.category,
		...response,
	};
};

export const formatStage = (response: StageDetailInterface) => {
	const { _id, langFields, photos, ...a } = response;
	return {
		name: transformLanguageArrayToObject(response.langFields),
		id: response._id,
		langFields: response.langFields,
		//cover: response.photos[0],
		photos,
		...a,
	};
};

export function transformLanguageArrayToObject(arr: LangField[]) {
	const result: formattedLanguageFields = {};
	try {
		arr.forEach((field) => {
			result[field.lang] = {
				name: field.name ? field.name : t("COMMONS.NO_VALUE"),
				subtitle: field.subtitle,
				description: field.description,
			};
		});
		return result;
	} catch {
		return {
			it: {
				name: "",
			},
		};
	}
}

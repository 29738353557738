import React from "react";
import { Button } from "@mui/material";
import AddRounded from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { AddButtonProps } from "../../type/type";
import { useTranslation } from "react-i18next";
import { textTransform } from "@mui/system";

const ConfirmButton: React.FC<AddButtonProps> = ({ onClick }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Button
			sx={{
				backgroundColor: "#0A17A7",
				borderColor: "#0A17A7",
				color: "white",
				fontSize: "16px",
				textTransform: "Capitalize",

				"&:hover": {
					borderColor: "var(--black)",
					color: "var(--white)",
					backgroundColor: "var( --gray-700)",
				},
			}}
			style={{ fontWeight: "bold", color: "white" }}
			onClick={onClick}
			variant="contained"
		>
			{`${t("COMMONS.INSERT")}`}
		</Button>
	);
};

export default ConfirmButton;

import { useContext, useEffect, useState } from "react";
import { PersonInterface } from "../../type/rest/person-rest-interface";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { Form, Formik, FormikHelpers, FormikTouched } from "formik";
import { Card, Divider, Grid, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../breadCrumbs/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import { NAVIGATION_DATA } from "../../type/commons-artplace";
import UserEdit from "./UserEdit";
import ChangePassword from "./ChangePassword";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "../legalFooter/PrivacyPolicy";
import Footer from "../legalFooter/LegalFooter";

const InfoMe = () => {
	const { t } = useTranslation();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const businessName = localStorage.getItem("business_name");
	const [businessNameProp, setBusinessNameProp] = useState("");
	// Dynamically setting the currentPage based on businessNameProp's content
	const [currentPage, setCurrentPage] = useState("");

	useEffect(() => {
		// When businessNameProp changes, update currentPage accordingly
		setCurrentPage(businessNameProp || businessName || "");
	}, [businessNameProp, businessName]);

	return (
		<>
			<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
			<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
				<Grid flexDirection={"column"} item>
					<Typography variant={"h1"}>{t("CUSTOMERS.PROFILE")} </Typography>
					<CustomBreadcrumbs dataType={NAVIGATION_DATA.CUSTOMER} currentPage={currentPage} />
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={2}>
				<Grid item xs={12} md={6}>
					<UserEdit setBusinessNameProp={setBusinessNameProp} businessNameProp={businessNameProp} />
				</Grid>
				{/* <Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2}>
					<Grid item xs={12} sm={12}>
						<Divider sx={{ marginTop: 3, marginBottom: 3 }} />
					</Grid>
				</Grid> */}
				<Grid item xs={12} md={6}>
					<ChangePassword />
				</Grid>
			</Grid>
		</>
	);
};

export default InfoMe;

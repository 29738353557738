import React, { useState } from "react";
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import { t } from "i18next";
import { TypologicType } from "../../../type/commons-artplace";

interface CheckBoxListProps {
	allStrings: TypologicType[] | undefined;
	enabledStrings: TypologicType[];
	onChange: any;
}

const CheckBoxList: React.FC<CheckBoxListProps> = ({ allStrings, enabledStrings, onChange }) => {
	const handleChange = (itemName: TypologicType) => {
		if (enabledStrings.some((enabledItem) => enabledItem.key === itemName.key)) {
			onChange(
				"services",
				enabledStrings.filter((element) => element.key !== itemName.key),
			);
		} else {
			onChange("services", [...enabledStrings, itemName]);
		}
	};

	return (
		<FormGroup>
			{allStrings &&
				allStrings.map((item, index) => (
					<FormControlLabel
						key={index}
						control={
							<Checkbox
								disableRipple
								checked={enabledStrings.some((enabledItem) => enabledItem.key === item.key)}
								onChange={() => handleChange(item)}
							/>
						}
						label={t(item.label)}
					/>
				))}
		</FormGroup>
	);
};

export default CheckBoxList;

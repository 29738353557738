import React, { useContext, useEffect, useState } from "react";
import { Box, Chip } from "@mui/material";
// import styles from "./PoiList.module.css";
import "../../../colors.css";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CenteredImageStyle from "../../../componets/centeredImage/CenteredImage.module.css";
import { useTranslation } from "react-i18next";
import { InsightDetailInterface, InsightInterface } from "../../../type/rest/insight-rest-interfaces";
import { getRowId } from "../../../utils/util";
import GridColumns from "../../../componets/data-table/gridColumns/GridColumns";
import { DATATYPE } from "../../../type/commons-artplace";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import "../../../componets/dataGrid/DataGridStyles.css";

const PoiInsightsList: React.FC<{ insights: InsightDetailInterface[]; dataType?: DATATYPE }> = ({ insights, dataType }) => {
	//const [filteredData, setFilteredData] = useState<Person[]>(mockDataPeople);
	//const [searchTerm, setSearchTerm] = useState<string>("");
	//Page e Limit Server call
	const { t } = useTranslation();
	const { formatLangFields } = EntityUtilities();

	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const currentLanguage: string = languageStore.getGlobalLanguage;
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [rows, setRows] = useState<any[]>([]);
	const { createUrlFile } = EntityUtilities();

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			try {
				/* console.log("insisghts:", insights); */

				const formattedRows = insights.map((item: InsightDetailInterface) => ({
					...item,
					id: item._id,
					name: formatLangFields(item.langFields),
				}));
				setRows(formattedRows);
			} catch (error) {
				/* 	toast.error(t("MESSAGES.CALL_ERROR")); */
				console.error("Error fetching data:", error);
				setRows([]);
			} finally {
				setLoading(false);
			}
		};
		getData();
	}, [insights]);

	const columns = GridColumns(dataType ? dataType : DATATYPE.INSIGHTS, currentLanguage, createUrlFile);

	return (
		<>
			<Box
				sx={{
					minHeight: "150px",
					width: "100%",
					"& .MuiDataGrid-columnHeaders": { backgroundColor: "#EFF5F8" },
					"& .MuiDataGrid-cell:focus-within": { outline: 0 },
				}}
			>
				<DataGrid
					/* 	getRowId={(row) => row._id} */
					autoHeight={rows && rows.length > 0 ? false : true}
					style={{border: "none", maxHeight: "500px"}}
					getRowHeight={() => "auto"}
					disableRowSelectionOnClick
					rows={rows}
					// rowHeight={120}
					localeText={{ noRowsLabel: t("TABLE.NO_RESULTS") }}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					// disableRowSelectionOnClick
				/>
			</Box>
		</>
	);
};

export default PoiInsightsList;

import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Pagination, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AddEntityModalProps } from "../../../type/props";
import { BEACON_FILTERS, DATATYPE, NAVIGATION_DATA, POI_FILTERS } from "../../../type/commons-artplace";
import { PoiDetailInterface, PoiInterface } from "../../../type/rest/poi-rest-interfaces";
import { InsightDetailInterface, InsightInterface } from "../../../type/rest/insight-rest-interfaces";
import { ItineraryDetailInterface, ItineraryListInterface } from "../../../type/rest/itinerary-rest-interfaces";
import { BeaconDetailInterface } from "../../../type/rest/beacon-rest-interface";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SearchBar from "../../barraRicerca/SearchBar";
import GridColumns from "../../data-table/gridColumns/GridColumns";
import CancelButton from "../../buttons/CancelButton";
import ConfirmButton from "../../buttons/ConfirmButton";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { observer } from "mobx-react-lite";
import { BE_LINKS } from "../../../api/api";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { useLocation, useParams } from "react-router-dom";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { get } from "../../../utils/call-api";
import { ORDERBY, PAGESIZE, SORTASC } from "../../../constants/artplace.constant";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import "../../../componets/dataGrid/DataGridStyles.css";

const AddEntityModal: React.FC<AddEntityModalProps> = ({
	open,
	title,
	onClose,
	callBack,
	formikCallback,
	formikLabel,
	initialList,
	dataType,
	rowLimit,
	tenant,
}) => {
	const theme = useTheme();
	const fullScreenModal = useMediaQuery(theme.breakpoints.down("md"));
	const { t } = useTranslation();
	const location = useLocation();
	const currentEntity = location.pathname.split("/")[1].toUpperCase();
	const params = useParams();
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const currentLanguage: string = languageStore.getGlobalLanguage;
	const [completeList, setCompleteList] = useState<
		(PoiDetailInterface | InsightDetailInterface | ItineraryDetailInterface | BeaconDetailInterface)[] | undefined
	>();
	//console.log("completeList:", completeList);
	const [rows, setRows] = useState<any[]>([]);
	const pageSize: number = PAGESIZE;
	const orderBy: string = ORDERBY;
	const sort: number = SORTASC;
	const [rowCount, setRowCount] = useState<number | undefined>(undefined);
	const [searchTerm, setSearch] = useState<string>("");
	const [searchField, setSearchField] = useState<string>(dataType === DATATYPE.BEACONS ? BEACON_FILTERS.name : POI_FILTERS.name);
	const [page, setPage] = useState<number>(1);
	const { formatLangFields, createUrlFile } = EntityUtilities();
	/* 	const [selectionModel, setSelectionModel] = React.useState(() => completeList?.filter((r) => r.age > 40).map((r) => r.id)); */
	const [modalLoading, setModalLoading] = useState<boolean>(false);
	/* const [initialContent, setInitialContent] = useState(initialList); */
	//selectionmodel rappresenta le righe checkate nel datagrid. Inizializzato inizialmente a la lista iniziale di entità già associate a quella visualizzata
	const [selectionModel, setSelectionModel] = useState<any[]>(initialList?.map((element) => element._id) || []);
	//console.log("selectionModel:", selectionModel);
	const [selectedRows, setSelectedRows] = useState<any[]>(initialList || []);
	//console.log("selectedRows", selectedRows);

	//Initialize selectionModel

	useEffect(() => {
		// Cleanup

		return () => {
			setCompleteList([]);
			setSelectionModel([]);
			setRows([]);
			setRowCount(undefined);
			setSearch("");
			setPage(1);
			setModalLoading(false);
			setSelectedRows([]);
			//
		};
	}, [tenant]);
	useEffect(() => {
		const fetchData = async (url: string, tenant: string | undefined): Promise<any> => {
			try {
				// console.log("Fetching data:", tenant);
				const response = await get(url, undefined, undefined, tenant, searchTerm, searchField, page, pageSize, orderBy, sort);
				// console.log("response fetch list data:", response);
				//esclude se stesso dai risultati (evita che l'entità si associ con se stessa)
				return response.data.filter((element: any) => element._id !== params.id);
			} catch (error) {
				console.error("Errore nel recupero dei dati:", error);
				setModalLoading(false);
				return;
				//throw error;
			}
		};

		let delayDebounceFn: NodeJS.Timeout | null = null;
		//Viene fatta la chiamata giusta a seconda del tipo di entità richiesta
		const fetchList = async () => {
			setModalLoading(true);
			try {
				if (delayDebounceFn) {
					clearTimeout(delayDebounceFn);
				}
				delayDebounceFn = setTimeout(async () => {
					let data: PoiInterface[] | InsightInterface[] | ItineraryListInterface[] | BeaconDetailInterface[] | undefined;
					switch (dataType) {
						case DATATYPE.POI:
							// console.log("POI");
							data = await fetchData(BE_LINKS[userRole][NAVIGATION_DATA.POI].ALL, tenant);
							break;
						case DATATYPE.INSIGHTS:
							if (currentEntity === DATATYPE.POI) {
								if (params.id) {
									// console.log("UNLINKED_INSIGHT");
									//EDIT POI: unlinked insight + insights già collegati al POI
									data = await fetchData(BE_LINKS[userRole][NAVIGATION_DATA.POI].UNLINKED_INSIGHTS.replace("{id}", params.id), tenant);
								} else {
									//CREATE POI: unlinked insights collegabili al POI
									// console.log("LINKABLE_INSIGHT");
									// console.log("LINK: ", BE_LINKS[userRole][NAVIGATION_DATA.INSIGHT]);
									data = await fetchData(BE_LINKS[userRole][NAVIGATION_DATA.INSIGHT].LINKABLE_INSIGHTS, tenant);
								}
								break;
							} else {
								// console.log("INSIGHT");
								data = await fetchData(BE_LINKS[userRole][NAVIGATION_DATA.INSIGHT].ALL, tenant);
								break;
							}
						case DATATYPE.ITINERARY:
							// console.log("ITINERARY");
							data = await fetchData(BE_LINKS[userRole].ITINERARIES.ALL, tenant);
							break;
						case DATATYPE.BEACONS:
							// console.log("BEACON");
							data = await fetchData(BE_LINKS[userRole].BEACONS.ALL, tenant);
							break;
						default:
							break;
					}
					// console.log(data);
					const formattedRows =
						data &&
						data.map((item: any) => ({
							...item,
							id: item._id,
							name: item.langFields ? formatLangFields(item.langFields) : item.name,
						}));
					setCompleteList(formattedRows);

					setModalLoading(false);
				}, 500);
			} catch (error) {
				// console.log("Error: ", error);
				setModalLoading(false);

				return;
				/* toast_error(error.message); */
				// L'errore è già stato gestito nella funzione fetchData
			}
		};

		fetchList();
	}, [userRole, page, pageSize, searchField, searchTerm, dataType]);

	useEffect(() => {
		const getTotalNumber = async () => {
			try {
				if (currentEntity === DATATYPE.POI && dataType === DATATYPE.INSIGHTS) {
					if (params.id) {
						//EDIT POI: Count di insight unlinked + insight già collegati al POI
						const data = await get(BE_LINKS[userRole][DATATYPE.POI].UNLINKED_COUNT.replace("{id}", params.id), undefined, undefined, tenant);
						setRowCount(data.data);
					} else {
						//CREATE POI: Count di insight unlinked
						const data = await get(BE_LINKS[userRole][DATATYPE.INSIGHTS].LINKABLE_COUNT, undefined, undefined, tenant);
						setRowCount(data.data);
					}
				} else {
					const data = await get(BE_LINKS[userRole][dataType].COUNT, undefined, undefined, tenant);
					setRowCount(data.data);
				}
			} catch (error) {
				setRowCount(0);
				throw error;
			}
		};
		getTotalNumber();
	}, [dataType, searchTerm, searchField, userRole]);

	//A seconda del ruolo dell'utente aggiunge il tenant alla richiesta o meno (SUPERADMIN: si, ADMIN: no)
	const filterByCustomer = (userRole: string, tenant: string | undefined): string | undefined => {
		return userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() ? tenant : undefined;
	};
	const handleConfirm = () => {
		const filteredSelectedRows = selectedRows.filter((row) => selectionModel.includes(row._id));
		callBack(
			rowLimit
				? filteredSelectedRows.length
					? {
							...filteredSelectedRows[0],
							id: filteredSelectedRows[0]._id,
							name: filteredSelectedRows[0].langFields ? formatLangFields(filteredSelectedRows[0].langFields) : filteredSelectedRows[0].name,
					  }
					: null
				: filteredSelectedRows?.map((item: any) => ({
						...item,
						id: item._id,
						name: item.langFields ? formatLangFields(item.langFields) : item.name,
				  })),
		);
		if (formikLabel && formikCallback) {
			// const indexes = selectedRows?.map((element) => {
			// 	return element._id;
			// });
			formikCallback(formikLabel, filteredSelectedRows);
		}
		onClose();
	};
	const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	//Searchbar
	const handleSearch = (searchTerm: string) => {
		if (searchTerm.length >= 3 || searchTerm.trim() === "") {
			setSearch(searchTerm);
			setPage(1); //fix riporta alla prima pagina ora ogni volta che cerca
		}
	};

	return (
		<Dialog
			maxWidth={"xl"}
			fullWidth
			fullScreen={fullScreenModal}
			open={open}
			onClose={onClose}
			style={{ height: "100%", maxHeight: "100%", minHeight: "100%" }}
		>
			<DialogTitle>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>{title}</Grid>
					<Grid>
						<SearchBar onSearch={handleSearch} placeholder={t("COMMONS.NAME_SEARCH")} />
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 5, display: "flex", flexDirection: "column", paddingBottom: 5 }}>
				{modalLoading || !completeList ? (
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
						<CircularProgress size={100} />
					</div>
				) : (
					<>
						<Box
							sx={{
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								overflow: "auto",
								minHeight: "200px",
								width: "100%",
								"& .MuiDataGrid-columnHeaders": { backgroundColor: "#EFF5F8" },
								"& .MuiDataGrid-cell:focus-within": { outline: 0 },
							}}
						>
							<DataGrid
								autoHeight={false} //false dovuto a flex sopra, in altri Datagrid, mettere a true quando non ci sono rows
								getRowHeight={() => "auto"}
								style={{ border: "none", paddingBottom: "0px", flexGrow: 1, minHeight: "200px", maxHeight: "600px" }}
								// rowHeight={120}
								rowSelectionModel={selectionModel} //
								keepNonExistentRowsSelected
								onRowSelectionModelChange={(ids) => {
									//se rowLimit = true ( POI  1:N  Insight): si DEVE solo ultimo id selezionato nel selectionModel
									//se rowLimit = false:  vengono presi tutti gli id selezionati
									setSelectionModel(rowLimit && ids.length > 1 ? (ids[ids.length - 1] as unknown as string[]) : (ids as string[]));
									const selectedIDs = new Set(ids);
									const newSelectedRows = completeList.filter((row) => selectedIDs.has(row._id as string));
									// Filter out newSelectedRows that already exist in prev
									const uniqueNewSelectedRows = newSelectedRows.filter((newRow) => !selectedRows.some((prevRow) => prevRow._id === newRow._id));
									setSelectedRows((prev) => [...prev, ...uniqueNewSelectedRows]);
								}}
								localeText={{ noRowsLabel: t("TABLE.NO_RESULTS") }}
								rows={completeList}
								columns={GridColumns(dataType, currentLanguage, createUrlFile)}
								initialState={{
									pagination: {
										paginationModel: {
											pageSize: pageSize,
										},
									},
								}}
								pageSizeOptions={[10]}
								checkboxSelection
								slots={{
									footer: () => (
										<Pagination
											page={page}
											size="small"
											color="primary"
											shape="rounded"
											onChange={handlePageChange}
											sx={{ alignSelf: "flex-end", padding: 2 }}
											count={Math.ceil((rowCount as number) / pageSize)}
										/>
									),
								}}
							/>
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions style={{ justifyContent: "space-between", paddingRight: 25, paddingLeft: 25, paddingTop: 0, paddingBottom: 20 }}>
				<CancelButton onClick={onClose} component={undefined} />
				<ConfirmButton onClick={handleConfirm} component={undefined} length={selectionModel && selectionModel.length} />
			</DialogActions>
		</Dialog>
	);
};
export default observer(AddEntityModal);

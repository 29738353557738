import React from "react";
import { Button } from "@mui/material";
import AddRounded from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { AddButtonProps } from "../../type/type";
import { useTranslation } from "react-i18next";
import { textTransform } from "@mui/system";

const AddButton: React.FC<AddButtonProps> = ({ onClick, length }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleAddDipendente = () => {
		//navigate(path);
	};

	return (
		<Button
			style={{ fontWeight: "bold", color: "#0A17A7" }}
			onClick={onClick}
			variant="text"
			startIcon={<AddRounded sx={{ color: "#0A17A7" }} />}
			sx={{ textTransform: "Capitalize" }}
		>
			{length && length > 0 ? `${t("COMMONS.EDIT")}` : `${t("COMMONS.ADD")}`}
		</Button>
	);
};

export default AddButton;

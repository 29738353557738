import { Grid, Card, Typography, Avatar, Chip, Box, TextField } from "@mui/material"; // Assicurati di importare i componenti di Material-UI necessari
import no_pic from "../../../img/no_pic.jpg";
import React, { ReactNode, useEffect, useState } from "react";
import { poiType } from "../../../type/commons-artplace";
import UploadIcon from "@mui/icons-material/Upload";
import { chipColors } from "../../../type/rest/stage-rest-interface";
import Image from "react-bootstrap/Image";

interface props {
	id: string | number;
	description: string;
	name: string;
	chip?: string; //label del chip
	options?: ReactNode; //componente dopo il chip
	cover?: string; //immagine url
	icon?: ReactNode; //icona se non c'è immagine
	nameEdit?: (name: string) => void; //funzione per settare il nome cambiato
	navigation?: () => void;
	upload?: boolean;
}

function SingleCard({ cover, description, name, id, chip, options, icon, nameEdit, navigation, upload }: props) {
	const [nameField, setName] = useState<string>(name);
	const border = !upload ? "1px solid rgba(0,0,0,0.2)" : "2px dashed rgba(0, 0, 0, 0.2)";

	/* 	useEffect(() => {
		setName(name);
	}, [name]); */

	return (
		<Card
			key={id}
			sx={[
				{ border: border, padding: "12px", marginBottom: "10px", minHeight: 70 },
				upload ? { backgroundColor: "#80808052", cursor: "pointer" } : {},
			]}
		>
			{!upload ? (
				<Grid container alignItems="center" spacing={2}>
					{/* <Grid item xs={12} sm={3} md={2} lg={2} xl={1}> */}
					<Grid item xs={12} sm={2}>
						{cover ? (
							<Avatar
								sx={{
									bgcolor: "#E5E4E2",
									aspectRatio: "1/1",
									objectFit: "cover",
									width: "60px",
									height: "60px",
								}}
								variant="rounded"
								src={cover /* || no_pic */}
							/>
						) : (
							<Box sx={{ display: "flex", justifyContent: "left", width: "60px", height: "60px" }}>{icon}</Box>
						)}
					</Grid>
					{/* <Grid item xs={12} sm={7} md={8} lg={8} xl={8}> */}
					<Grid item xs={12} sm={5}>
						<div>
							{nameEdit ? (
								<TextField
									fullWidth
									value={nameField}
									variant="standard"
									onChange={(event) => {
										setName(event.target.value);
										nameEdit(event.target.value);
									}}
								/>
							) : (
								<div onClick={() => (navigation ? navigation() : null)} style={navigation ? { cursor: "pointer" } : {}}>
									<Typography variant="h6">{name}</Typography>
								</div>
							)}
							<Typography variant="body1" color="textSecondary">
								{description}
							</Typography>
						</div>
					</Grid>
					{chip && (
						<Grid item xs={12} sm={3}>
							<Chip
								label={chip}
								sx={{
									backgroundColor: chip === poiType.AC ? chipColors.commercialActivity.primary : chipColors.poi.primary,
									color: "black",
								}}
							/>
						</Grid>
					)}
					{options && (
						<Grid item xs={12} sm={chip ? 1 : 5} sx={{ marginTop: !options ? "16px" : "0px" }}>
							{options}
						</Grid>
					)}
				</Grid>
			) : (
				<Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
					{icon}
					<Box display="flex" sx={{ mt: 1, alignContent: "center", flexWrap: "wrap" }}>
						<Typography color="grey">Upload</Typography>
						<UploadIcon fontSize="small" sx={{ ml: 0.5, color: "grey" }} />
					</Box>
				</Box>
			)}
		</Card>
	);
}

export default SingleCard;

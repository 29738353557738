import { useState } from "react";
import { DATATYPE, ITINERARY_FILTERS, NAVIGATION_DATA } from "../../../type/commons-artplace";
import CustomDataGrid from "../../../componets/dataGrid/CustomDataGrid";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
const ItinerariesList = () => {
	const type = NAVIGATION_DATA.ITINERARY;
	const { formatLangFields } = EntityUtilities();
	const [searchField, setSearchField] = useState<string>(ITINERARY_FILTERS.name);

	return (
		<>
			<CustomDataGrid
				type={type}
				numberRows={DATATYPE.ITINERARY}
				searchField={searchField}
				formatRows={(item) => ({
					...item,
					id: item._id,
					name: formatLangFields(item.langFields),
				})}
			/>
		</>
	);
};
export default ItinerariesList;

import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { ClientInterface, PoiDetailInterface, PoiInterface } from "../../type/rest/poi-rest-interfaces";
import { Formik, Form } from "formik";
import PhotoDropZone from "../artplace-insight/insight-add/photoDropZone";
import { BeaconDetailInterface } from "../../type/rest/beacon-rest-interface";
import * as Yup from "yup";
import SaveButton from "../../componets/buttons/SaveButton";
import AddEntityModal from "../../componets/modals/addEntityModal/AddEntityModal";
import { InsightDetailInterface } from "../../type/rest/insight-rest-interfaces";
import { DATATYPE, NAVIGATION_DATA, emptyBeacon } from "../../type/commons-artplace";
import { handleModal, orderCustomersByBusinessName } from "../../utils/util";
import AddButton from "../../componets/buttons/AddButton";
import PoiInsightsList from "../artplace-poi/poi-detail/PoiInsightsList";
import { BE_LINKS } from "../../api/api";
import { toast_error } from "../../utils/custom-toast";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { get, post, uploadPhoto } from "../../utils/call-api";
import CustomBreadcrumbs from "../../componets/breadCrumbs/CustomBreadcrumbs";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import { mockyCollection } from "../../data/apiCall.mockup";
import { clientMockup } from "../../data/poi.mockup";
import { UUIDRegex } from "../../utils/regex";
import { Spinner } from "react-bootstrap";

const BeaconAdd: React.FC = () => {
	const [searchTerm] = useState<string>("");
	const [artworks] = useState<any[]>([]);
	const [poiDetail] = useState<Partial<PoiDetailInterface> | null>(null);
	const [searchResult, setSearchResult] = useState<any>();
	const [beaconDetail] = useState<Partial<BeaconDetailInterface>>({});
	const [savedData, setSavedData] = useState({});
	const [open, setOpen] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>("");
	const [modalContent, setModalContent] = useState<InsightDetailInterface[] | PoiInterface[]>();
	const [modalDataType, setModalDataType] = useState<DATATYPE | undefined>();
	const [modalCallBack, setModalCallBack] = useState<any>();
	const [photos, setPhotos] = useState<File[]>([]);
	const [formikCallback, setFormikCallback] = useState<any>();
	const [formikLabel, setFormikLabel] = useState<string>("");
	const [insightData, setInsightData] = useState<InsightDetailInterface[]>();
	const [clients, setClients] = useState<ClientInterface[]>([]); //OK
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole = userStore.getUserRole();
	const navigate = useNavigate();
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);

	const [selectedTenant, setSelectedTenant] = useState<string>("");

	const filteredArtworks = artworks.filter((artwork) => artwork.name.toLowerCase().includes(searchTerm.toLowerCase()));

	const [isUploading, setIsUploading] = useState(false);
	console.log("isUploading", isUploading);

	const saveAddChanges = () => {
		alert(JSON.stringify(beaconDetail, null, 2));
	};

	const updateSavedData = (values: any) => {
		setSavedData(values);
	};

	function onLoad(autocomplete: any) {
		setSearchResult(autocomplete);
	}

	useEffect(() => {
		//console.log("userStore on init:", userStore);
		//console.log("getTenant method on init:", userStore.getTenant);

		function fetchTenant() {
			const tenant = userStore.getTenant;
			//console.log("Fetched tenant:", tenant);
			setSelectedTenant(tenant);
		}

		fetchTenant();
	}, []);
	//TODO: Da rimuovere definitivamente
	/* 	const onPlaceChanged = async (formikCallback: {
		(field: string, value: any): Promise<void | FormikErrors<Partial<BeaconDetailInterface>>>;
		(arg0: string, arg1: any): void;
	}) => {
		if (searchResult != null) {
			const place = await searchResult.getPlace();
			setAddress(place.formatted_address);
			formikCallback("address", place.formatted_address);
		}
	}; */

	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);

			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
			// return response;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};
	const fetchOptions = async () => {
		//OK - PIù COMPLETO
		try {
			// Fetch dati per tipologiche POI
			const [clients] = await Promise.all([fetchClients()]);

			setClients(clients);

			// Handle other fetched data as needed
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	};

	useEffect(() => {
		try {
			fetchOptions(); // Try to fetch options
			//await fetchPoiDetail(); // Try to fetch PoiDetail
		} catch (error) {
			console.error("Error fetching data:", error); // Handle and log any errors
			/* } finally {
			setLoadingBeaconDetail(false);
		} */
		}
	}, []);

	useEffect(() => {
		// Questo codice viene eseguito ogni volta che `selectedTenant` cambia
		setInsightData([]); // Svuota la lista dei APPRODONDIMENTI correlati
	}, [selectedTenant]);

	return (
		<>
			<Formik<Partial<BeaconDetailInterface>>
				enableReinitialize
				initialValues={emptyBeacon}
				validationSchema={Yup.object({
					// identificationCode: Yup.string().required("ERRORS.FORM.REQUIRED").min(6, t("MESSAGES.LENGTH_MIN")).max(6, t("MESSAGES.LENGTH_MAX")),
					name: Yup.string().trim().required("ERRORS.FORM.REQUIRED").min(3),
					uuid: Yup.string().trim().required("ERRORS.FORM.REQUIRED").matches(UUIDRegex, "ERRORS.FORM.UUID"),
					major: Yup.number().min(1, "ERRORS.FORM.BEACONS.MIN_1").max(65535, "ERRORS.FORM.BEACONS.MAX_65535").required("ERRORS.FORM.REQUIRED"),
					minor: Yup.number().min(1, "ERRORS.FORM.BEACONS.MIN_1").max(65535, "ERRORS.FORM.BEACONS.MAX_65535").required("ERRORS.FORM.REQUIRED"),
					//address: Yup.string().required("ERRORS.FORM.REQUIRED"),
					//latlng: Yup.string().trim().required("ERRORS.FORM.REQUIRED"),
				})}
				onSubmit={async (values, { setFieldValue, setSubmitting }) => {
					setIsRequestSubmitted(true);
					try {
						const insightsIds = insightData?.map((el) => el._id);

						const result = { ...values, insights: insightsIds, photos: photos };
						// console.log("LINK CALLING: ", BE_LINKS[userRole][NAVIGATION_DATA.BEACON].ALL);
						const response = await post(BE_LINKS[userRole][NAVIGATION_DATA.BEACON].ALL, result, result.tenant);
						if (result.photos) {
							try {
								await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.BEACON, userRole);
							} catch (error) {
								toast.error(t("Errore upload photo"));
							}
						}
						/* alert(JSON.stringify(values, null, 2)); */
						toast.success(t("MESSAGES.CREATION_OK"));
						setTimeout(() => {
							setDeletedPhoto([]);
							setPhotos([]);
							if (resetFiles) resetFiles();
							navigate(`/beacons/detail/${response.data._id}`);
						}, 1000);
						setSubmitting(false);
					} catch (error: any) {
						// console.log("Error submitting beacon: ", error.response);
						toast_error(error.message);

						setSubmitting(false);
					} finally {
						setTimeout(() => {
							setIsRequestSubmitted(false);
						}, 500);
						setSubmitting(false);
					}
				}}
			>
				{(formik) => (
					<>
						<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
						<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
							<Grid flexDirection={"column"}>
								<Typography variant={"h1"}>{poiDetail?.name || t("BEACONS.ADD.TITLE")}</Typography>
								<CustomBreadcrumbs dataType={NAVIGATION_DATA.BEACON} currentPage={beaconDetail?.name || t("BEACONS.ADD.TITLE").split(" ")[0]} />
							</Grid>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									if (!formik.values.name || !formik.values.uuid || !formik.values.major || !formik.values.minor) {
										toast.warn(t("MESSAGES.WARNING_FORM"), {
											className: "yellowToastWarning",
										});
										formik.setFieldTouched("name", true);
										formik.setFieldTouched("uuid", true);
										formik.setFieldTouched("major", true);
										formik.setFieldTouched("minor", true);
									} else {
										formik.handleSubmit();
									}
								}}
								startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
								size="large"
								disabled={isRequestSubmitted || isUploading}
							>
								{t("COMMONS.SAVE_CHANGES")}
							</Button>
						</Grid>
						<Form onSubmit={formik.handleSubmit}>
							<Grid container spacing={4}>
								<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
									<Grid item xs={12}>
										<TextField
											name="name"
											required
											fullWidth
											label={t("FORM.NAME")}
											variant="outlined"
											error={formik.touched.name && !!formik.errors.name}
											helperText={formik.touched.name && t(formik.errors.name ?? "")}
											value={formik.values.name}
											onChange={formik.handleChange}
											InputProps={{ sx: { borderRadius: 2 } }}
											onBlur={formik.handleBlur}
										/>
									</Grid>
									{/* <Grid item xs={6}> */}
									{/* <TextField
											name="identificationCode"
											required
											fullWidth
											label={t("FORM.IDENTIFICATION_CODE")}
											variant="outlined"
											error={formik.touched.identificationCode && !!formik.errors.identificationCode}
											helperText={formik.touched.identificationCode && t(formik.errors.identificationCode ?? "")}
											value={formik.values.identificationCode}
											onChange={formik.handleChange}
											InputProps={{ sx: { borderRadius: 2 } }}
										/> */}
									{/* </Grid> */}
									<Grid item xs={12}>
										<TextField
											name="uuid"
											fullWidth
											required
											label="UUID"
											variant="outlined"
											error={formik.touched.uuid && !!formik.errors.uuid}
											value={formik.values.uuid}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											helperText={formik.touched.uuid && t(formik.errors.uuid ?? "")}
											InputProps={{ sx: { borderRadius: 2 } }}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											name="major"
											onBlur={formik.handleBlur}
											required
											fullWidth
											label="Major"
											variant="outlined"
											type="number"
											error={formik.touched.major && !!formik.errors.major}
											value={formik.values.major}
											onChange={formik.handleChange}
											inputProps={{ min: 1, max: 65535 }}
											helperText={formik.touched.major && t(formik.errors.major ?? "")}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											name="minor"
											fullWidth
											required
											type="number"
											label="Minor"
											variant="outlined"
											error={formik.touched.minor && !!formik.errors.minor}
											value={formik.values.minor}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											inputProps={{ min: 1, max: 65535 }}
											helperText={formik.touched.minor && t(formik.errors.minor ?? "")}
										/>
									</Grid>
									{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && (
										<Grid item xs={12} sm={12}>
											<FormControl fullWidth variant="outlined">
												<InputLabel>{t("FORM.CUSTOMER")}</InputLabel>
												<Select
													label={t("FORM.CUSTOMER")}
													name="tenant"
													value={formik.values.tenant || userStore.getTenant}
													onChange={(event) => {
														setSelectedTenant(event.target.value);
														formik.setFieldValue("tenant", event.target.value);
													}}
													style={{ borderRadius: 8 }}
													error={formik.touched.tenant && !!formik.errors.tenant}
												>
													{clients.map((el: ClientInterface) => (
														<MenuItem key={el._id} value={el.tenant}>
															{el.business_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									)}
									{/* Assegna Approfondimenti */}
									<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"} spacing={2}>
										<Grid item>
											<Typography variant="h6" component="div" mb={1} mt={2}>
												{t("POI.DETAIL.INSIGHTS")}
											</Typography>
										</Grid>
										<Grid item>
											<AddButton
												length={insightData?.length || 0}
												onClick={() => {
													console.log("Clicked Insights");
													setModalTitle(() => t("INSIGHTS.ADD.TITLE"));
													setModalContent(() => insightData);
													setModalCallBack(() => setInsightData);
													setFormikCallback(() => formik.setFieldValue);
													setFormikLabel("insights");
													setModalDataType(() => DATATYPE.INSIGHTS);
													handleModal(open, setOpen);
												}}
												component={undefined}
											/>
										</Grid>
										<Grid item xs={12}>
											<PoiInsightsList insights={insightData || []} dataType={DATATYPE.INSIGHTS} />
										</Grid>
									</Grid>
								</Grid>
								{/* IMG */}
								<Grid item xs={12} md={4}>
									<Grid item xs={12}>
										<PhotoDropZone
											resetFiles={(action) => setResetFiles(action)}
											photos={formik.values.photos || []}
											setPhotoList={setPhotos}
											navigationData={NAVIGATION_DATA.BEACON}
											setIsUploading={setIsUploading}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>

			{open && modalDataType && (
				<Grid container xs={12}>
					<AddEntityModal
						open={open}
						title={modalTitle}
						onClose={() => handleModal(open, setOpen)}
						callBack={modalCallBack}
						formikCallback={formikCallback}
						formikLabel={formikLabel}
						initialList={modalContent}
						dataType={modalDataType}
						tenant={selectedTenant}
					/>
				</Grid>
			)}
		</>
	);
};

export default BeaconAdd;

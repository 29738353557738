import {
	Card,
	Grid,
	CircularProgress,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Checkbox,
	CardContent,
	Button,
	FormHelperText,
} from "@mui/material";
import omit from "lodash/omit";
import * as Yup from "yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../i18n";
import GoogleMapsCard from "../../../componets/googleMapsCard/GoogleMapsCard";
import { ClientInterface } from "../../../type/rest/poi-rest-interfaces";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DATATYPE, LangField, NAVIGATION_DATA, TypologicType, langField } from "../../../type/commons-artplace";
import { Form, Formik, getIn } from "formik";
import SaveButton from "../../../componets/buttons/SaveButton";
import { TextEditor } from "../../../componets/textField/textEditor/TextEditor";
import PhotoDropZone from "../../artplace-insight/insight-add/photoDropZone";
import { ItineraryDetailInterface } from "../../../type/rest/itinerary-rest-interfaces";
import PoiInsightsList from "../../artplace-poi/poi-detail/PoiInsightsList";
import { ToastContainer, toast } from "react-toastify";
import EntityUtilities from "../../../utils/entityUtilities/EntityUtilities";
import { TypologicStoreContext } from "../../../stores/TypologicStore";
import { BE_LINKS } from "../../../api/api";
import { UserStore, UserStoreContext } from "../../../stores/UserStore";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { get, patch, uploadPhoto } from "../../../utils/call-api";
import { altitudeDifference, getEnumKeyByValue, orderCustomersByBusinessName } from "../../../utils/util";
import Ratings from "../../../componets/ratings/Ratings";
import EntityLanguageSelector from "../../../componets/entityLanguageSelector/EntityLanguageSelector";
import CustomBreadcrumbs from "../../../componets/breadCrumbs/CustomBreadcrumbs";
import { RegistryRoleEnum } from "../../../type/enum/registry-role.enum";
import { LanguageStore, LanguageStoreContext } from "../../../stores/LanguageStore";
import IdName from "../../../componets/pageComponents/IdName/IdName";
import LangFieldText from "../../../componets/langFieldText/LangFieldText";
import { Spinner } from "react-bootstrap";
import { format } from "date-fns";

const ItineraryDetail = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const [itineraryDetail, setItineraryDetail] = useState<Partial<ItineraryDetailInterface | null>>(null);
	const [currentLanguage, setCurrentLanguage] = useState<string>(languageStore.getGlobalLanguage);
	const userStore: UserStore = useContext(UserStoreContext);
	const userRole: string = userStore.getUserRole();
	const [stagesList, setStagesList] = useState<any>([]);
	const [loadingItineraryDetail, setLoadingItineraryDetail] = useState<boolean>(false);
	const typologicStore = useContext(TypologicStoreContext);
	const [duration, setDuration] = useState<Date | null>(null);
	const [photos, setPhotos] = useState<File[]>([]);
	//totale categorie presenti
	const [categories, setCategories] = useState<TypologicType[]>([]);
	//totale sottocategorie presenti
	const [subCategories, setSubCategories] = useState<TypologicType[]>([]);
	const [clients, setClients] = useState<ClientInterface[]>([]);
	const [deletedPhoto, setDeletedPhoto] = useState<string[]>([]);
	const [resetFiles, setResetFiles] = useState<(() => void) | null>(null);
	const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);

	const [isUploading, setIsUploading] = useState(false);

	const { handleNameChange, handleSubtitleChange, handleDescriptionChange, getIndexFromLangFields } = EntityUtilities();

	const fetchClients = async () => {
		if (userRole !== RegistryRoleEnum.SUPERADMIN.toUpperCase()) {
			return [];
		}
		try {
			const response = (await get(BE_LINKS[userRole][NAVIGATION_DATA.CUSTOMER].ALL, undefined, false, undefined, undefined, undefined, 1, 9999)).data;
			let orderedByName = orderCustomersByBusinessName(response);
			const myself = { _id: "fake", business_name: userStore.getBusinessName, tenant: userStore.getTenant };
			const updatedClients = [myself, ...orderedByName];
			return updatedClients;
		} catch (error) {
			console.error(`Error fetching clients`, error);
			return [];
		}
	};

	const fetchCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getCategories(DATATYPE.ITINERARY);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchSubCategories = async () => {
		try {
			if (typologicStore.getTypologicsReady) {
				return typologicStore.getSubcategories(DATATYPE.ITINERARY);
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	const fetchOptions = async () => {
		const [categories, clients, subcategories] = await Promise.all([fetchCategories(), fetchClients(), fetchSubCategories()]);

		setSubCategories(subcategories);
		setCategories(categories);
		setClients(clients);
	};

	const fetchItineraryDetail = useCallback(async () => {
		try {
			const response: ItineraryDetailInterface = (await get(BE_LINKS[userRole][NAVIGATION_DATA.ITINERARY].ALL, id, true)).data;
			// console.log("BE response fetch itineraryDetail: ", response);
			if (response.langFields.find((langField: LangField) => langField.lang === currentLanguage)) {
				//se esiste già un langFields della lingua corrente OK
				setItineraryDetail(response || null);
			} else {
				if (response.langFields.find((langField: LangField) => langField.lang === languageStore.defaultLanguage)) {
					//altrimenti visualizza l'entità in inglese
					setCurrentLanguage(languageStore.defaultLanguage);
					setItineraryDetail(response || null);
				} else {
					//altrimenti la prima lingua disponibile nei langFields
					setCurrentLanguage(response.langFields[0].lang);
					setItineraryDetail(response || null);
				}
			}
			formatTimeData(response.travelTime);
			setStagesList(response.stages);
		} catch (error) {
			console.error("Error fetching data:", error);
			toast.error(t("MESSAGES.CALL_ERROR"));
		}
	}, [currentLanguage, id]);

	useEffect(() => {
		const fetchData = async () => {
			setLoadingItineraryDetail(true);
			try {
				await fetchOptions(); // Try to fetch options
				await fetchItineraryDetail(); // Try to fetch PoiDetail
			} catch (error) {
				console.error("Error fetching data:", error); // Handle and log any errors
			} finally {
				setLoadingItineraryDetail(false);
			}
		};

		fetchData();
	}, [id, typologicStore.getTypologicsReady]);

	const extractCoordinates = (stages: any[]) => {
		return stages.map((stage) => stage.coordinates);
	};

	const formatTimeData = (seconds: number | undefined) => {
		if (seconds !== undefined) {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			//let remainingSeconds = seconds % 60;

			//const formattedSeconds = String(remainingSeconds).padStart(2, "0");

			setDuration(new Date(0, 0, 0, hours, minutes /*  +formattedSeconds */));
		}
	};
	const langFieldSchema = Yup.object().shape({
		name: Yup.string().required("ERRORS.FORM.REQUIRED"),
	});
	return (
		<>
			{loadingItineraryDetail || !itineraryDetail ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
					<CircularProgress size={100} />
				</div>
			) : (
				<>
					<Formik<Partial<ItineraryDetailInterface>>
						enableReinitialize
						initialValues={itineraryDetail}
						validationSchema={Yup.object({
							langFields: Yup.array().of(langFieldSchema).required("ERRORS.FORM.REQUIRED"),
							category: Yup.object().shape({
								key: Yup.string().required("ERRORS.FORM.REQUIRED"),
								label: Yup.string().required("ERRORS.FORM.REQUIRED"),
							}),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							setIsRequestSubmitted(true);
							// Filtra i langFields vuoti
							const filteredLangFields = values.langFields?.filter((langField) => langField.name.trim() !== "");
							// let keysToDelete = [
							// 	"_id",
							// 	"tenant",
							// 	"distance",
							// 	"travelTime",
							// 	"maxElevation",
							// 	"minElevation",
							// 	"stages",
							// 	"favorites",
							// 	"createdAt",
							// 	"updatedAt",
							// ];
							// const newValues: any = { ...values, langFields: filteredLangFields };
							// keysToDelete.forEach((key: any) => {
							// 	delete newValues[key];
							// });

							const { _id, tenant, distance, travelTime, maxElevation, minElevation, stages, favorites, createdAt, updatedAt, photos, ...newValues } =
								values;

							newValues.langFields = filteredLangFields;

							try {
								// console.log("Trying to submit:", newValues);
								//Chiamata di update
								const response = await patch(BE_LINKS[userRole][NAVIGATION_DATA.ITINERARY].ALL, id, newValues);
								// console.log("BE Response: ", response);

								//set manuale delle nuove info dell'itinerario (aggiornerà il formik)
								//setItineraryDetail(response.data);
								toast.success(t("MESSAGES.UPDATE_OK"));

								//EDIT: Le foto non vanno caricate al salvataggio modifiche ma direttamente. Logica spostata in PhotoDropZone
								/* 	if (photos.length) {
									try {
										await uploadPhoto(photos, response.data._id, NAVIGATION_DATA.ITINERARY, userRole);
									} catch (error) {
										toast.error(t("Errore upload photo"));
									}
								} */
								setItineraryDetail(response.data);
							} catch (error) {
								// Handle API error
								console.error("Error fetching data:", error);
								toast.error(t("MESSAGES.UPDATE_ERROR"));
							} finally {
								setDeletedPhoto([]);
								setPhotos([]);
								if (resetFiles) resetFiles();
								setSubmitting(false);
								setTimeout(() => {
									setIsRequestSubmitted(false);
								}, 500);
							}
						}}
					>
						{(formik) => {
							return (
								<>
									<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />
									<Grid container item xs={12} mb={4} justifyContent={"space-between"}>
										<Grid xs={8}>
											<Typography variant={"h1"}>
												{itineraryDetail?.langFields?.find((field) => field.lang === currentLanguage)?.name || t("POI.ADD.TITLE")}
											</Typography>
											<CustomBreadcrumbs
												dataType={NAVIGATION_DATA.ITINERARY}
												currentPage={
													itineraryDetail?.langFields?.find((element) => element.lang === currentLanguage)?.name || t("POI.ADD.TITLE").split(" ")[0]
												}
											/>
										</Grid>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												if (
													!formik.values.category?.key ||
													formik.values.langFields?.some((value) => value.name === undefined || value.name === "")
												) {
													toast.warn(t("MESSAGES.WARNING_FORM"));
													if (formik.values.langFields?.some((value) => value.name === undefined || value.name === "")) {
														const emptyLangs =
															formik.values.langFields?.filter((value) => value.name === undefined || value.name === "").map((value) => value.lang) ??
															[];
														const keyValues = emptyLangs?.map((element) => getEnumKeyByValue(element));
														const translatedKeys = keyValues
															.filter((key): key is string => key !== undefined)
															.map((key: string) => t(`SUPPORTED_LANGUAGES.${key}`))
															.join(",\n");
														toast.warn(t("MESSAGES.CHECK_LANGUAGES") + translatedKeys);
													}
													formik.setFieldTouched("gpxFile", true);
													formik.setFieldTouched("category", true);
													formik.setFieldTouched("langFields", true);
													formik.setFieldError("langFields", undefined);
												} else {
													formik.handleSubmit();
												}
											}}
											startIcon={isRequestSubmitted ? <Spinner animation="border" /> : ""}
											size="large"
											disabled={isRequestSubmitted || isUploading}
										>
											{t("COMMONS.SAVE_CHANGES")}
										</Button>
									</Grid>
									<Form onSubmit={formik.handleSubmit}>
										<Grid container spacing={2}>
											{/* LEFT */}
											<Grid container item id="container-left" xs={12} sm={12} md={8} spacing={2} alignContent={"flex-start"}>
												{/* R1*/}
												<Grid item xs={4} sm={4}>
													<EntityLanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} formik={formik} />
												</Grid>
												<Grid xs={9} sm={9}></Grid>
												<IdName id={id} formik={formik} lang={currentLanguage} />

												<Grid item xs={12} sm={12}>
													<LangFieldText formik={formik} fieldName={"subtitle"} callBack={handleSubtitleChange} lang={currentLanguage} />
												</Grid>

												{/* R2 */}
												<Grid item xs={12} sm={6}>
													<FormControl fullWidth variant="outlined">
														<InputLabel required error={formik.touched.category && !!formik.errors.category}>
															{t("FORM.CATEGORY")}
														</InputLabel>
														<Select
															label={t("FORM.CATEGORY")}
															name="category"
															required
															value={formik.values.category?.key || ""}
															onBlur={formik.handleBlur}
															onChange={(event) => {
																const selectedCategory = categories && categories.find((el) => el.key === event.target.value);
																formik.setFieldValue("category", selectedCategory); // Set the entire category object
															}}
															error={formik.touched.category && !!formik.errors.category}
														>
															{categories &&
																categories.map((el: TypologicType) => (
																	<MenuItem key={el.key} value={el.key}>
																		{t(el.label)}
																	</MenuItem>
																))}
														</Select>
														{formik.touched.category && !!formik.errors.category && (
															<FormHelperText style={{ color: "#d32f2f" }}>{t(getIn(formik.errors.category, "key"))}</FormHelperText>
														)}
													</FormControl>
												</Grid>

												{/* <Grid item xs={12} sm={4}>
													<FormControl fullWidth variant="outlined">
														<InputLabel error={formik.touched.subcategory && !!formik.errors.subcategory}>{t("FORM.SUBCATEGORY")}</InputLabel>
														<Select
															disabled
															label={t("FORM.SUBCATEGORY")}
															name="subcategory"
															value={formik.values.subcategory?.key || ""}
															onChange={(event) => {
																const selectedCategory = subCategories && subCategories.find((el) => el.key === event.target.value);
																formik.setFieldValue("subcategory", selectedCategory); // Set the entire category object
															}}
															style={{ borderRadius: 8 }}
															error={formik.touched.subcategory && !!formik.errors.subcategory}
														>
															{subCategories &&
																subCategories.map((el: TypologicType) => (
																	<MenuItem key={el.key} value={el.key}>
																		{t(el.label)}
																	</MenuItem>
																))}
														</Select>
													</FormControl>
												</Grid> */}
												{userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() ? (
													<Grid item xs={12} sm={6}>
														<FormControl fullWidth variant="outlined">
															<InputLabel error={formik.touched.tenant && !!formik.errors.tenant} color="primary">
																{t("FORM.CUSTOMER")}
															</InputLabel>
															<Select
																disabled={id ? true : false}
																label={t("FORM.CUSTOMER")}
																name="tenant"
																value={formik.values.tenant || userStore.getTenant}
																onChange={formik.handleChange}
																style={{ borderRadius: 8 }}
																error={formik.touched.tenant && !!formik.errors.tenant}
															>
																{clients.map((el: ClientInterface) => (
																	<MenuItem key={el._id} value={el.tenant}>
																		{el.business_name}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
												) : (
													<Grid item xs={12} sm={4} />
												)}

												{/* R3 */}
												<Grid item xs={12} sm={4}>
													<TextField
														fullWidth
														disabled
														label={t("ITINERARIES.DETAIL.LENGTH")}
														name="length"
														variant="outlined"
														//helperText={formik.touched.name && t(formik.errors.name ?? "")}
														value={formik.values.distance?.toFixed(2)}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														fullWidth
														disabled
														label={t("ITINERARIES.DETAIL.ALTITUDE_DIFFERENCE")}
														name="difference"
														variant="outlined"
														value={altitudeDifference(formik.values.minElevation, formik.values.maxElevation)}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<FormControl fullWidth variant="outlined">
														<InputLabel></InputLabel>
														<LocalizationProvider dateAdapter={AdapterDateFns}>
															<TimePicker
																disabled
																label={t("FORM.DURATION")}
																value={duration}
																views={["hours", "minutes"]}
																format="HH:mm"
																ampm={false}
																//	onChange={formik.handleChange}
															/>
														</LocalizationProvider>

														{/* <div>{`Initialized Hours: ${valueDuration?.getHours()}, Minutes: ${valueDuration?.getMinutes()}, Seconds: ${valueDuration?.getSeconds()}`}</div> */}
													</FormControl>
												</Grid>

												{/* R4 */}
												<Grid item xs={12}>
													<Typography variant="h6" component="div" mb={1}>
														{t("POI.DETAIL.DESCRIPTION")}
													</Typography>
													<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
														<TextEditor
															setFieldValue={(value) =>
																handleDescriptionChange(currentLanguage, value, formik.values.langFields, formik.setFieldValue)
															}
															defaultValue={formik?.values?.langFields?.find((element) => element.lang === currentLanguage)?.description}
														/>
													</Card>
												</Grid>

												{/*R5 */}
												{stagesList && stagesList.length > 0 ? (
													<Grid container item xs={12} justifyContent="space-between" alignItems={"baseline"}>
														<Grid item>
															<Typography variant="h6" component="div" mb={1} mt={2}>
																{t("ITINERARIES.DETAIL.STAGES")}
															</Typography>
														</Grid>
														<Grid item xs={12}>
															<PoiInsightsList insights={stagesList ?? []} dataType={DATATYPE.STAGE} />
														</Grid>
														<Grid item xs={12} pb={2}>
															{/* 		<GoogleWrapper> */}
															<GoogleMapsCard
																lat={stagesList[0].coordinates[0].lat}
																lng={stagesList[0].coordinates[0].lng}
																stagesList={extractCoordinates(stagesList)}
															/>
															{/* 	</GoogleWrapper> */}
														</Grid>
													</Grid>
												) : null}
											</Grid>
											{/*R6 */}
											{/* RIGHT*/}
											{/* R1*/}
											<Grid container item id="container-right" xs={12} sm={12} md={4} alignContent={"flex-start"} rowSpacing={1}>
												<Grid item xs={12}>
													<Grid item xs={12} pt={9}>
														<PhotoDropZone
															photos={itineraryDetail.photos || []}
															setPhotoList={setPhotos}
															resetFiles={(action) => setResetFiles(action)}
															navigationData={NAVIGATION_DATA.ITINERARY}
															setEntity={setItineraryDetail}
															setIsUploading={setIsUploading}
														/>
													</Grid>
												</Grid>
												{/* R2*/}
												<Grid item xs={12}>
													<TextField
														fullWidth
														label="Website"
														name="website"
														id="website"
														type="website"
														variant="outlined"
														value={formik.values.website || ""}
														onChange={formik.handleChange}
													/>
												</Grid>
												{/* <Grid alignItems="center" item xs={12}>
													<Card style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
														<CardContent style={{ display: "flex", alignItems: "center" }}>
															<Typography variant={"h6"}>{t("ITINERARIES.ADD.UNSTABLE_CONNECTION")}</Typography>
															<Checkbox name="unstableConnection" checked={formik.values.unstableConnection} onChange={formik.handleChange} />
														</CardContent>
													</Card>
												</Grid> */}
												{itineraryDetail._id && (
													<Grid item xs={12}>
														<Ratings
															averageRate={itineraryDetail.averageRate}
															numberOfRate={itineraryDetail.numberOfRate}
															favorites={itineraryDetail.favorites?.length}
														/>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Form>
								</>
							);
						}}
					</Formik>
				</>
			)}
		</>
	);
};

export default ItineraryDetail;

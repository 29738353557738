import { Button, CircularProgress, IconButton, Pagination, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SearchBar from "../barraRicerca/SearchBar";
import FilterButton from "../filterButton/FilterButton";
import { useNavigate } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../deleteModal/DeleteModal";
import GridColumns from "../data-table/gridColumns/GridColumns";
import { NAVIGATION_DATA } from "../../type/commons-artplace";
import { BE_LINKS } from "../../api/api";
import { ORDERBY, PAGESIZE, SORTASC } from "../../constants/artplace.constant";
import { fetchData } from "../../services/data-table/PaginationDataGrid";
import { UserStore, UserStoreContext } from "../../stores/UserStore";
import { observer } from "mobx-react-lite";
import { ToastContainer, toast } from "react-toastify";
import EntityUtilities from "../../utils/entityUtilities/EntityUtilities";
import { del, get } from "../../utils/call-api";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import { LanguageStore, LanguageStoreContext } from "../../stores/LanguageStore";
import "./DataGridStyles.css";

interface Props {
	type: NAVIGATION_DATA;
	numberRows: string;
	searchField: string;
	formatRows?: (item: any) => any;
	isButtonVisible?: boolean;
	labelAdd?:string;
}

const CustomDataGrid = ({ type, numberRows, searchField, formatRows, isButtonVisible = true , labelAdd}: Props) => {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
	const [selectedRow, setSelectedRow] = useState();
	const [rows, setRows] = useState<any[]>([]);
	const pageSize: number = PAGESIZE;
	const orderBy: string = ORDERBY;
	const sort: number = SORTASC;
	const [rowCount, setRowCount] = useState<number | undefined>(0);
	const userStore: UserStore = useContext(UserStoreContext);
	const [searchTerm, setSearch] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const userRole = userStore.getUserRole();
	const [page, setPage] = useState<number>(1);
	const languageStore: LanguageStore = useContext(LanguageStoreContext);
	const currentLanguage = languageStore.getGlobalLanguage;
	const { createUrlFile } = EntityUtilities();
	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			try {
				const response = await get(BE_LINKS[userRole][type].ALL, undefined, false, undefined, searchTerm, searchField, page, pageSize, orderBy, sort);
				// console.log("List of " + type + ": ", response);
				const formattedRows = formatRows ? response.data.map((item: any) => formatRows(item)) : response.data;
				setRows(formattedRows);
			} catch (error) {
				setRows([]);
			} finally {
				setLoading(false);
			}
		};
		getData();
	}, [formatRows, page, pageSize, searchField, searchTerm, type, userRole]);

	//icona di edit a inizio riga
	const editColumn = {
		field: "edit",
		headerName: "",
		sortable: false,
		editable: false,
		filterable: false,
		width: 50,
		renderCell: (params: { id: any; row: any }) => {
			const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				navigate(`/${type.toLowerCase()}/detail/${params.id}`);
			};

			return (
				<>
					<IconButton onClick={onEdit}>
						<Edit />
					</IconButton>
				</>
			);
		},
	};
	//icona di delete a fine riga
	const deleteColumn = {
		field: "delete",
		headerName: "",
		sortable: false,
		editable: false,
		filterable: false,
		renderCell: (params: { id: any; row: any }) => {
			const onDelete = (rowData: any) => {
				const rowIndex = rows.indexOf(rowData);
				if (rowIndex !== -1) {
					setSelectedRowIndex(rowIndex);
					setSelectedRow(() => rowData);
					setShowModal(true);
				}
			};
			return (
				<>
					{type !== NAVIGATION_DATA.CUSTOMER ? (
						<IconButton
							onClick={() => {
								onDelete(params.row);
							}}
						>
							<Delete />
						</IconButton>
					) : null}
				</>
			);
		},
	};

	const clientColumn: GridColDef = {
		field: "tenantData",
		headerName: t("DATA_TABLE.CUSTOMER"),
		headerAlign: "left",
		type: "string",
		width: 200,
		editable: false,
		filterable: true,
		sortable: false,
		renderCell: (params) => {
			return <Typography>{params.value?.businessName ? params.value.businessName : userStore.getBusinessName}</Typography>;
		},
	};
	//colonne della tabella dei componenti List
	let columns = [editColumn, ...GridColumns(numberRows, currentLanguage, createUrlFile), deleteColumn];

	//Se superadmin,  non siamo in news o non siamo in customer, allora aggiungi nella penultima posizione la colonna del tenant
	if (userRole === RegistryRoleEnum.SUPERADMIN.toUpperCase() && type !== NAVIGATION_DATA.NEWS && type !== NAVIGATION_DATA.CUSTOMER) {
		// Insert the clientColumn as the penultimate element
		columns.splice(columns.length - 2, 0, clientColumn);
	}

	const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	useEffect(() => {
		const getTotalNumber = async () => {
			try {
				const data = await get(BE_LINKS[userRole][type].COUNT, undefined, undefined, undefined, searchTerm, searchField, undefined, undefined);
				setRowCount(data.data);
			} catch {
				setRowCount(0);
			}
		};
		getTotalNumber();
	}, [type, setSearch, searchTerm, searchField, userRole]);

	const handleDeleteConfirmed = async (rowIndex: number) => {
		try {
			const idToDelete = rows[rowIndex].id;
			await del(BE_LINKS[userRole][type].ALL, idToDelete);

			const updatedRows = rows.filter((_, index) => index !== rowIndex);
			setRows(updatedRows);
			setShowModal(false);
			toast.success(t("MESSAGES.ELIMINATION_OK"));
		} catch (error) {
			toast.error(t("MESSAGES.ELIMINATION_ERROR"));

			console.error("Error deleting data:", error);
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};
	//Searchbar
	const handleSearch = (searchTerm: string) => {
		if (searchTerm.length >= 3 || searchTerm.trim() === "") {
			setSearch(searchTerm);
			setPage(1); //fix riporta alla prima pagina ora ogni volta che cerca
		}
	};

	return (
		<div>
			<ToastContainer position="top-center" hideProgressBar autoClose={1000} closeOnClick theme="colored" />

			<div className="d-flex justify-content-between align-items-center">
				<Typography variant={"h2"} mb={2}>
					{t(`SIDEBAR.${type}`)}
				</Typography>
				{isButtonVisible && (
					<Button
						variant="contained"
						color="secondary"
						onClick={() => {
							navigate(`/${type.toLowerCase()}/add`);
						}}
						size="large"
					>
						{labelAdd? t(labelAdd): t("COMMONS.ADD_NEW")}
					</Button>
				)}
			</div>

			<div>
				<div className="d-flex align-items-center">
					<SearchBar onSearch={handleSearch} placeholder={t("COMMONS.NAME_SEARCH")} />

					{/* TODO: da reintegrare quando ne sapremo di più */}
					{/* <FilterButton /> */}
				</div>
				<Box
					sx={{
						height: "68vh",
						width: "100%",
						marginTop: "1.5%",

						// "& .MuiDataGrid-columnHeaders": { backgroundColor: "#EFF5F8", textTransform: "uppercase" },
						// "& .MuiDataGrid-cell:focus-within": { outline: 0 },
						// "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "700" },
					}}
				>
					{loading || !rows ? (
						<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
							<CircularProgress size={100} />
						</div>
					) : (
						<DataGrid
							autoHeight={rows && rows.length > 0 ? false : true}
							autoPageSize
							getRowHeight={() => "auto"}
							rowCount={PAGESIZE}
							rows={rows}
							columns={columns}
							// rowHeight={120}
							paginationMode="server"
							disableRowSelectionOnClick
							localeText={{ noRowsLabel: t("TABLE.NO_RESULTS") }}
							slots={{
								footer: () => (
									<Pagination
										page={page}
										size="small"
										color="primary"
										shape="rounded"
										onChange={handlePageChange}
										sx={{ alignSelf: "flex-end", padding: 2 }}
										count={Math.ceil((rowCount as number) / pageSize)}
									/>
								),
							}}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: pageSize,
									},
								},
							}}
							sx={{
								"& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
									// border: "none",
								},
								"& .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								border: "none",
								height: "100%",
								width: "100%",
								marginBottom: "10px",
								marginTop: "1.5%",
								"& .MuiDataGrid-columnHeaders": { backgroundColor: "#EFF5F8" },
								"& .MuiDataGrid-cell:focus-within": { outline: 0 },
								"& .MuiDataGrid-columnHeaderTitle": { fontWeight: "700", color: "#828B8F", textTransform: "uppercase" },
							}}
						/>
					)}

					{showModal && (
						<DeleteModal
							open={showModal}
							onClose={handleCloseModal}
							onDeleteConfirmed={() => handleDeleteConfirmed(selectedRowIndex)}
							rowIndex={selectedRowIndex}
							rowData={selectedRow}
						/>
					)}
				</Box>
			</div>
		</div>
	);
};

export default observer(CustomDataGrid);
